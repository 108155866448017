import {
  chain,
  filter,
  find,
  forEach,
  get,
  has,
  includes,
  isEmpty,
  isEqual,
  keys,
  map,
  replace,
  set,
  some,
  sortBy,
} from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  VineaNovaActions,
  VineaNovaSelectors,
  VineaHooks,
} from 'vineanova-redux-artifacts';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import {
  BlockVintageStatusIDs,
  dateFormat,
  ImportTypeIDs,
} from '../../../constants';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';
import { useSnackbar } from 'notistack';
import { syncValidator } from '../../../utils/validator';
import { BlockSheetSchema } from '../Validations/validations';
import { RenderDataGridHeader } from '../../../components/Grid';
import { UseFetchDataHook } from './UseFetchDataHook';
import { useDispatch } from 'react-redux';
import { MetaDataType } from '../../Jobs/interfaces/jobInterfaces';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

type WorkbookData = {
  [key: string]: any;
};

type SheetData = {
  id: number;
  sheetName: string;
};

type FilterOption = {
  id: number;
  filterDescription: string;
};

type ImportFilter = {
  filterID: number;
  filterName: string;
  filterOptions: FilterOption[];
  filterParameter: string;
  filterSource: string;
  importTypeFilterID: number;
  importTypeID: number;
  isMultiSelect: boolean;
};

export type AdditionalParameterType = {
  name: string;
  label: string;
  options: any;
};

export const useExcelCsvImporter = () => {
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  const { enqueueSnackbar } = useSnackbar();
  const dispatchAPI = useDispatch();

  const standardSteps = ['Upload file', 'Import Data'];
  const [apiTrigger, setApiTrigger] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [showSheetSelectWarning, setShowSheetSelectWarning] = useState(false);
  const [fileReadLoading, setFileReadLoading] = useState(false);
  const [formdata, setFormData] = useState<any>({
    importTypeID: 0,
    VineyardID: 0,
  });
  const [sheets, setSheets] = useState<SheetData[]>([]);
  const [rawData, setRawData] = useState<WorkbookData>([]);

  // Object, each activeStep is a key whose value is an array of data
  const [parsedRows, setParsedRows] = useState<any>({});

  // Object, each activeStep is a key whose value is an array of object fields
  const [objFields, setObjFields] = useState<any>({});

  const [columnNames, setColumnNames] = useState<any>([]);
  const [sheetsToParse, setSheetsToParse] = useState<number[]>([]);
  const [fileName, setFileName] = useState('');
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error reading file');

  const { licensedUserIdentityID, firstName, lastName } = useSelector(
    (state: any) => state.userAccess.data,
  );
  const { importTypeID, VineyardID = 0 } = formdata;

  const siteBlocks = useSelector(
    VineaNovaSelectors.getIdentityVineyardBlocksEntityData,
  );

  const { data: allVineyardBlocks } = VineaHooks.useFetchIdentityVineyardBlocks(
    {},
  );

  const invalidSheetSelectionMessage = useMemo(() => {
    if (importTypeID === ImportTypeIDs.BLOCK_SHEET) {
      return `Some selected sheet blocks already exist for the selected ${vineyardTypeScreenName}, and can not be imported`;
    } else {
      return 'Some selected sheets are invalid and will be skipped';
    }
  }, [importTypeID]);

  const existingBlockNames = useMemo(() => {
    if (isEmpty(siteBlocks)) {
      return [];
    } else {
      const blocks = map(siteBlocks as any, 'blockFullName');
      return blocks;
    }
  }, [siteBlocks]);

  const {
    isLoading: importIsLoading,
    isLoaded: importIsLoaded,
    hasError: importHasError,
  } = useSelector(VineaNovaSelectors.getImportSheetsEntityMeta) as MetaDataType;

  const sheetsGridDataColums = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
    },
    {
      field: 'sheetName',
      headerName: 'Sheet Names',
      minwidth: '150px',
      flex: 0.3,
      resizable: false,
      hideable: false,
    },
  ];

  const sheetsGridXData = useMemo(() => {
    return {
      columns: sheetsGridDataColums,
      rows: sheets,
    };
  }, [sheetsGridDataColums, sheets]);

  const {
    lkpVariety,
    lkpVintage,
    lkpSpecialAttribute,
    importFilters,
    lkpMeasureInstance,
    dataLoading,
  } = UseFetchDataHook();

  const additionalParameters = useMemo(() => {
    if (importTypeID !== 0 && !isEmpty(importFilters)) {
      const validFilters = filter(importFilters as any, {
        importTypeID: importTypeID,
      });
      const filters = map(validFilters as any, (filter: ImportFilter) => {
        const options = map(filter.filterOptions, (option: FilterOption) => {
          return {
            id: option.id,
            key: option.id,
            value: option.filterDescription,
          };
        });
        return {
          name: filter.filterParameter,
          label: filter.filterName,
          options: options,
        };
      });
      return filters;
    } else {
      return [];
    }
  }, [importTypeID, importFilters]);

  // Can validate data if sheets are selected, import type is selected, no errors in data,
  // and user has selecte values for all additional parameters
  const canValidate = useMemo(() => {
    let hasAllAdditionalParams = true;
    if (
      sheetsToParse.length > 0 &&
      get(formdata, 'importTypeID', 0) !== 0 &&
      !errorFlag
    ) {
      forEach(additionalParameters, (param: AdditionalParameterType) => {
        const paramValue = get(formdata, param.name, 0);
        if (paramValue === 0) hasAllAdditionalParams = false;
      });
      return hasAllAdditionalParams;
    }
  }, [sheetsToParse, additionalParameters, formdata]);

  // Can import data if no error, if api has not already been called
  const canImport = useMemo(() => {
    if (!apiTrigger && !errorFlag) return true;
    else return false;
  }, [apiTrigger, errorFlag]);

  const dataGridCols = useMemo(() => {
    const gridCols: any[] = [];
    const activeObjectFields = get(objFields, [activeStep]);
    gridCols.push({
      field: 'Error',
      headerName: 'Errors',
      minWidth: 250,
      hide: !errorFlag,
      renderHeader: RenderDataGridHeader,
    });

    if (importTypeID === ImportTypeIDs.BLOCK_SHEET) {
      gridCols.push({
        field: 'BlockName',
        headerName: 'Block',
        minWidth: 150,
        flex: 1,
        hideable: false,
        renderHeader: RenderDataGridHeader,
      });
    }

    forEach(activeObjectFields, (field, idx: number) => {
      gridCols.push({
        field: field,
        headerName: get(columnNames, [activeStep])[idx],
        minWidth: 150,
        flex: 1,
        hideable: false,
        renderHeader: RenderDataGridHeader,
        valueFormatter: (params: any) => {
          const numValue = Number(params.value);
          if (isNaN(numValue)) {
            return params.value;
          } else {
            return numValue % 1 === 0
              ? `${numValue}`
              : `${numValue.toFixed(2)}`;
          }
        },
      });
    });

    return gridCols;
  }, [objFields, columnNames, errorFlag, activeStep]);

  const importTypeStepsMap = {
    [ImportTypeIDs.BLOCK_SHEET]: standardSteps,
    [ImportTypeIDs.CROP_MEASUREMENTS]: standardSteps,
    [ImportTypeIDs.CROPSY_PRUNING]: [
      'Upload file',
      'Import Row Data',
      'Import Block Data',
    ],
  };

  const steps = useMemo(() => {
    if (importTypeID === 0) return standardSteps;
    else return importTypeStepsMap[importTypeID];
  }, [importTypeID]);
  ['Upload file', 'Import Data'];

  const clearFileData = useCallback(() => {
    setSheetsToParse([]);
    setParsedRows({});
    setRawData([]);
    setFileName('');
    setErrorFlag(false);
    setApiTrigger(false);
    setColumnNames({});
    setObjFields({});
  }, []);

  const handleOnChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = evt;

    if (!isNaN(Number(value))) {
      setFormData({ ...formdata, [name]: parseInt(value) });
    }
  };

  // For sheets grid checkbox selection
  const handleOnSheetsSelectionChange = (selectedSheets: number[]) => {
    // For now, can only import blocks that don't already exist for the given site
    if (importTypeID === ImportTypeIDs.BLOCK_SHEET) {
      const validSheets = filter(selectedSheets, s => {
        const name = find(sheets, { id: s })?.sheetName;
        return !includes(existingBlockNames, name);
      });
      // Warn if not all selected sheets were valid
      if (validSheets.length < selectedSheets.length) {
        setShowSheetSelectWarning(true);
      }
      setSheetsToParse(validSheets);
    } else {
      setSheetsToParse(selectedSheets);
    }
  };

  const handleOnNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleOnBack = () => {
    setActiveStep(activeStep - 1);
    // setParsedRows([]);
    setErrorFlag(false);
    setApiTrigger(false);
  };

  const flagError = (isError: boolean, errorMsg: string) => {
    setErrorFlag(isError);
    setErrorMessage(errorMsg);
    enqueueSnackbar(`${errorMsg}`, { variant: 'error' });
  };

  // Set file name, workbook names, and all excel data
  const handleFileRead = (file: any) => {
    setFileReadLoading(true);
    clearFileData();
    const fileName = file?.name;
    setFileName(fileName);
    const fileExtension = fileName.split('.').pop();

    if (['xls', 'xlsx'].includes(fileExtension)) {
      readExcelFile(file);
    } else if (fileExtension === 'csv') {
      readCsvFile(file);
    } else {
      flagError(
        true,
        'Error - Invalid file type. Please upload an Excel or CSV file',
      );
    }
  };

  const readExcelFile = (file: any) => {
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const data = new Uint8Array(event.target.result);
      let allJsonData: any = [];
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetNames = workbook.SheetNames;
      let objFieldNames: string[] = [];

      const sheetNamesData = map(sheetNames, (name, idx) => {
        return { id: idx, sheetName: name };
      });
      setSheets(sheetNamesData);

      if (sheetNames.length === 1) {
        const sheet = workbook.Sheets[sheetNames[0]];
        let jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const colHeaders: any = jsonData[0];

        // Initialize an array to keep track of columns that have data
        const columnsWithData = colHeaders
          .map((header: any, index: number) => {
            // Check if the column has any data in rows, excluding the header
            const hasData = jsonData
              .slice(1)
              .some(
                (row: any) => row[index] !== undefined && row[index] !== '',
              );
            return hasData ? index : null; // Keep the index if it has data, otherwise null
          })
          .filter((index: number) => index !== null); // Filter out null values

        // Filter the jsonData to only include columns with data
        const filteredData = jsonData.map((row: any) =>
          columnsWithData.map((index: number) => row[index]),
        );

        const filterColHeaders = filteredData[0];
        objFieldNames = map(filterColHeaders, (header: string) => {
          return cleanString(header);
        });

        setColumnNames({ 1: filterColHeaders });

        filteredData[0] = objFieldNames;
        allJsonData = [filteredData];
      } else if (sheetNames.length > 1) {
        forEach(sheetNames, (sheetName, index) => {
          const sheet = workbook.Sheets[sheetName];
          let jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          const colHeaders: any = jsonData[0];

          //@ts-ignore
          const currentSheetFieldNames = map(colHeaders, (header: string) => {
            return cleanString(header);
          });

          jsonData[0] = currentSheetFieldNames;
          allJsonData = allJsonData.concat([jsonData]);

          // If multiple sheets, check that all sheets have the same column headers
          if (index === 0) {
            objFieldNames = currentSheetFieldNames;
            setColumnNames({ 1: colHeaders });
          } else {
            if (
              !isEqual(sortBy(currentSheetFieldNames), sortBy(objFieldNames))
            ) {
              flagError(
                true,
                'Error - Not All Sheets Have The Same Column Headers',
              );
            }
          }
        });
      }

      setObjFields({ 1: objFieldNames });
      setRawData(allJsonData);
      setSheets(sheetNamesData);
    };

    reader.readAsArrayBuffer(file);

    reader.onloadend = () => {
      setFileReadLoading(false);
    };
  };

  const readCsvFile = (file: any) => {
    Papa.parse(file, {
      complete: result => {
        const fileData = result.data.slice(1);
        const colRows = result.data.slice(0, 2);
        const colHeaders: any =
          importTypeID !== ImportTypeIDs.CROPSY_PRUNING
            ? fileData[0]
            : getColHeadersFromTopTwoRows(colRows);

        //@ts-ignore
        const fieldNames = map(colHeaders, (header: string) => {
          return cleanString(header);
        });

        setSheets([{ id: 0, sheetName: file?.name }]);
        if (importTypeID !== ImportTypeIDs.CROPSY_PRUNING) {
          setColumnNames({ 1: colHeaders });
          setObjFields({ 1: fieldNames });
        } else {
          setColumnNames({
            1: colHeaders,
            2: [
              'Vineyard',
              'Block',
              'Productive Vines',
              'Avg Buds Per Vine',
              'Planted Vines',
            ],
          });
          setObjFields({
            1: fieldNames,
            2: [
              'Vineyard',
              'Block',
              'ProductiveVines',
              'AvgBudsPerVine',
              'PlantedVines',
            ],
          });
        }
        setRawData({ 0: fileData });
        setFileReadLoading(false);
      },
      header: false, // Set to true if the first row contains column headers
    });
  };

  // Custom for Cropsy Pruning report where they have two rows of column headers
  const getColHeadersFromTopTwoRows = (colRows: any[]) => {
    const colHeaders: string[] = [];
    const firstColHeaders: string[] = colRows[0];
    const secondColHeaders: string[] = colRows[1];
    forEach(firstColHeaders, (row: string, rowIdx: number) => {
      colHeaders.push(`${row} ${secondColHeaders[rowIdx]}`.trim());
    });

    return colHeaders;
  };

  const cleanString = (str: string) => {
    return replace(str, /[^a-zA-Z0-9]/g, '');
  };

  const handleDisplayDataAsTable = () => {
    const parsedRowsData: any[] = [];

    forEach(sheetsToParse, (sheetIdx: number) => {
      const sheetName = find(sheets, { id: sheetIdx })?.sheetName || '';
      const sheetData = rawData[sheetIdx];

      forEach(sheetData, (row: any, index: number) => {
        // DataGridPro requires all rows to have an id, not used for anything else
        const uniqueIdx = `${sheetIdx}${index}`;
        let parsedRowData: any = null;
        // Skip header row
        if (index > 0) {
          if (importTypeID === ImportTypeIDs.BLOCK_SHEET)
            parsedRowData = getBlockSheetDataToDisplay(sheetName, row);
          else if (importTypeID === ImportTypeIDs.CROP_MEASUREMENTS)
            parsedRowData = getCropMeasurementsDataToDisplay(row);
          else if (
            importTypeID === ImportTypeIDs.CROPSY_PRUNING &&
            activeStep === 1
          )
            parsedRowData = getCropsyPruningRowDataToDisplay(row);
          if (parsedRowData) {
            if (parsedRowData.Error) {
              setErrorFlag(true);
            }
            parsedRowsData.push({ ...parsedRowData, id: uniqueIdx });
          }
        }
      });
    });

    if (importTypeID === ImportTypeIDs.CROPSY_PRUNING && activeStep === 2) {
      const stepData = get(parsedRows, [activeStep - 1]);
      const blockData = getCropsyPruningBlockDataToDisplay(stepData);
      setParsedRows({
        ...parsedRows,
        [activeStep]: blockData,
      });
    } else {
      console.log(10001, parsedRowsData);
      const updatedParsedRows = {
        ...parsedRows,
        [activeStep]: parsedRowsData,
      };
      console.log(10002, updatedParsedRows);
      console.log(10003, [
        { id: 1, key: 'value' },
        { id: 2, key: 'value2' },
        { id: 3, key: 'value3' },
      ]);
      setParsedRows(updatedParsedRows);
    }
  };

  // Transform array data with headers in 0th position of array, to array of objects with each
  // header as key and corresponding value as value
  const transformSheetRow = useCallback(
    (row: any) => {
      const activeObjectFields: string[] = get(objFields, activeStep);
      const parsedRow = activeObjectFields.reduce<Record<string, number>>(
        (acc, current, idx) => {
          if (row.length > idx) {
            const rowValue = row[idx];
            acc[current] = rowValue;

            if (
              importTypeID === ImportTypeIDs.BLOCK_SHEET &&
              current === 'VarietyProduct'
            ) {
              acc['VarietyID'] =
                find(lkpVariety as any, {
                  varietyName: rowValue,
                })?.id || 'Invalid';
            }
            if (
              importTypeID === ImportTypeIDs.BLOCK_SHEET &&
              current === 'SpecialAttribute'
            ) {
              acc['SpecialAttributeID'] =
                find(lkpSpecialAttribute as any, {
                  rowSpecialAttribute: rowValue,
                })?.id || 'Invalid';
            }
          }
          return acc;
        },
        {},
      );

      // Make sure each row has all the fields
      forEach(activeObjectFields, (header: string) => {
        if (!has(parsedRow, header)) {
          set(parsedRow, header, null);
        }
      });

      return parsedRow;
    },
    [objFields, importTypeID, activeStep],
  );

  // Parse block sheet spreadsheet data
  const getBlockSheetDataToDisplay = useCallback(
    (sheetName: string, row: any) => {
      const parsedRow = transformSheetRow(row);

      const currentErrors = syncValidator(BlockSheetSchema)(parsedRow);
      const rowErrors = JSON.stringify(Object.values(currentErrors)).replace(
        /[\[\]{}""]/g,
        '',
      );

      return {
        ...parsedRow,
        Error: rowErrors, // Display validation error
        BlockName: sheetName,
      };
    },
    [objFields, activeStep],
  );

  const getBlockSheetDataToSend = useCallback(() => {
    const dataToSend = chain(parsedRows[activeStep])
      .groupBy('BlockName')
      .map((value, key) => {
        const block = {
          BlockName: key,
          BlockVintageStatusID: BlockVintageStatusIDs.PRODUCING, // Necessary for API call to insert block
          VarietyID: value[0]?.VarietyID, // Necessary for API call to insert block
          Rows: value,
        };

        // Add additional parameters
        forEach(additionalParameters, (param: AdditionalParameterType) => {
          if (has(formdata, param.name))
            set(block, param.name, get(formdata, param.name));
        });

        return block;
      })
      .value();

    return dataToSend;
  }, [parsedRows, formdata, activeStep]);

  const getCropMeasurementsDataToDisplay = useCallback(
    (row: any) => {
      let hasDataToImport = false;
      const currentErrors: string[] = [];
      const parsedRow = transformSheetRow(row);

      const nonMeasureFields = ['VineyardBlockID', 'VineyardName', 'BlockName'];
      if (
        !some(allVineyardBlocks, {
          vineyardName: parsedRow.VineyardName,
          id: parsedRow.VineyardBlockID,
          blockFullName: parsedRow.BlockName,
        })
      ) {
        currentErrors.push(
          `Invalid combination of VineyardBlockID, Vineyard Name, and Block Name`,
        );
      }

      forEach(keys(parsedRow), (key: string) => {
        const rowMeasureInstance =
          find(dataGridCols, { field: key })?.headerName || '';
        if (!nonMeasureFields.includes(rowMeasureInstance)) {
          // If field is not a valid measure instance, add error
          if (
            !some(lkpMeasureInstance as any, {
              measureInstanceReportName: rowMeasureInstance,
            })
          ) {
            currentErrors.push(
              `Invalid measure instance: ${rowMeasureInstance}`,
            );
          } else if (get(parsedRow, key, '') !== '') {
            // If field is a measure instance, check it's a number
            // and mark row as having data to import
            if (isNaN(Number(parsedRow[key]))) {
              currentErrors.push(
                `${rowMeasureInstance} must be a number (${parsedRow[key]})`,
              );
            }
            hasDataToImport = true;
          }
        }
      });

      // Only show rows which have measure instances to import
      if (hasDataToImport) {
        return {
          ...parsedRow,
          Error: currentErrors.join('. '), // Display validation error
        };
      } else return null;
    },
    [objFields, dataGridCols, activeStep, allVineyardBlocks],
  );

  const getCropMeasurementsDataToSend = useCallback(() => {
    // Need to convert measureInstanceNames to MeasureInstanceIDs
    const dataToSend: any[] = [];
    // For each row, and each of it's keys
    forEach(parsedRows[activeStep], (row: any) => {
      forEach(keys(row), (key: string) => {
        const keyMeasureInstanceName =
          find(dataGridCols, { field: key })?.headerName || '';
        const keyValue = get(row, key, '');
        // If key has value and key is a measure instance, then add to dataToSend
        if (keyValue !== '') {
          const measureInstanceID = find(lkpMeasureInstance as any, {
            measureInstanceReportName: keyMeasureInstanceName,
          })?.id;
          if (measureInstanceID) {
            const cropMeasurement = {
              VintageID: formdata.VintageID,
              VineyardBlockID: row.VineyardBlockID,
              MeasureInstanceID: measureInstanceID,
              MeasureValue1: keyValue,
              ModifiedBy: `${firstName} ${lastName} (${licensedUserIdentityID})`,
              IsPrivate: false,
              CropMeasurementComment:
                'Imported from VN Crop Measurement Importer',
              MeasureDate: format(new Date(), dateFormat),
            };

            // Add additional parameters
            forEach(additionalParameters, (param: AdditionalParameterType) => {
              if (has(formdata, param.name))
                set(cropMeasurement, param.name, get(formdata, param.name));
            });

            dataToSend.push(cropMeasurement);
          }
        }
      });
    });

    return dataToSend;
  }, [parsedRows, formdata, dataGridCols, activeStep]);

  const getCropsyPruningRowDataToDisplay = useCallback(
    (row: any) => {
      const currentErrors: string[] = [];
      let hasDataToImport = false;
      const parsedRow = transformSheetRow(row);
      const nonMeasureFields = ['Date', 'Vineyard', 'Block', 'Row'];
      if (
        !some(allVineyardBlocks, {
          vineyardName: parsedRow.Vineyard,
          blockFullName: parsedRow.Block,
        })
      ) {
        currentErrors.push(
          `Invalid combination of Vineyard Name, and Block Name`,
        );
      }

      forEach(keys(parsedRow), (key: string) => {
        const col = find(dataGridCols, { field: key })?.headerName || '';
        if (!nonMeasureFields.includes(col)) {
          if (get(parsedRow, key, '') !== '') {
            // Check that measure fields are numbers
            if (isNaN(Number(parsedRow[key]))) {
              currentErrors.push(`${col} must be a number(${parsedRow[key]})`);
            }
            hasDataToImport = true;
          }
        }
      });

      // Only show rows which have measure instances to import
      if (hasDataToImport) {
        return {
          ...parsedRow,
          Error: currentErrors.join('. '), // Display validation error
        };
      } else return null;
    },
    [objFields, columnNames, activeStep, allVineyardBlocks],
  );

  const getCropsyPruningRowDataToSend = useCallback(() => {
    // Need to convert measureInstanceNames to MeasureInstanceIDs
    const dataToSend = map(parsedRows[activeStep], (row: any) => {
      const {
        Vineyard,
        Block,
        Row: RowNumber,
        NormalVines,
        YoungVines,
        MissingVines,
        ['0CanesDead']: ZeroCanesDead,
        LowQuality,
      } = row;

      const productiveVines =
        Number(NormalVines) + Number(YoungVines) + Number(MissingVines);
      const plantedVines =
        Number(NormalVines) +
        Number(YoungVines) +
        Number(MissingVines) +
        Number(ZeroCanesDead) +
        Number(LowQuality);

      const vineyardBlockID = find(allVineyardBlocks, {
        vineyardName: Vineyard,
        blockFullName: Block,
      })?.id;

      const rowData = {
        VineyardBlockID: vineyardBlockID,
        RowNumber: RowNumber,
        PlantedUnits: plantedVines,
        ActualUnits: productiveVines,
        RowComments: 'Imported from VN Cropsy Pruning Report Importer',
      };

      return rowData;
    });

    return dataToSend;
  }, [parsedRows, formdata, activeStep, allVineyardBlocks]);

  const getCropsyPruningBlockDataToDisplay = useCallback(
    (parsedRowsData: any[]) => {
      const parsedBlockData: any[] = [];
      const blockData = parsedRowsData.reduce((acc, curr) => {
        const {
          Vineyard,
          Block,
          NormalVines,
          YoungVines,
          MissingVines,
          ['0CanesDead']: ZeroCanesDead,
          LowQuality,
          BudsPerVineMean,
        } = curr;
        const key = `${Vineyard}-${Block}`; // Unique key

        // If the group doesn't exist, create it with initial values
        if (!acc[key]) {
          const vineyardBlockID = find(allVineyardBlocks, {
            vineyardName: Vineyard,
            blockFullName: Block,
          })?.id;

          acc[key] = {
            id: vineyardBlockID ? vineyardBlockID : key,
            Vineyard,
            Block,
            ProductiveVines: 0,
            TotalBuds: 0,
            AvgBudsPerVine: 0,
            PlantedVines: 0,
          };
        }

        acc[key].ProductiveVines +=
          Number(NormalVines) + Number(YoungVines) + Number(MissingVines);
        acc[key].TotalBuds +=
          (Number(NormalVines) + Number(YoungVines) + Number(MissingVines)) *
          Number(BudsPerVineMean);
        acc[key].AvgBudsPerVine = acc[key].TotalBuds / acc[key].ProductiveVines;
        acc[key].PlantedVines +=
          Number(NormalVines) +
          Number(YoungVines) +
          Number(MissingVines) +
          Number(ZeroCanesDead) +
          Number(LowQuality);

        return acc;
      }, {});

      // Convert the result back to an array if needed
      const result = Object.values(blockData);
      parsedBlockData.push(...result);

      return parsedBlockData;
    },
    [objFields, columnNames, activeStep],
  );

  const getCropsyPruningBlockDataToSend = useCallback(() => {
    // Need to convert measureInstanceNames to MeasureInstanceIDs
    const dataToSend = map(parsedRows[activeStep], (row: any) => {
      const { Vineyard, Block, AvgBudsPerVine, PlantedVines } = row;

      const vineyardBlock = find(allVineyardBlocks, {
        vineyardName: Vineyard,
        blockFullName: Block,
      });

      const measureInstanceID = find(lkpMeasureInstance as any, {
        measureInstanceReportName: 'Initial Average Bud Numbers per vine',
      })?.id;

      const currentDate = new Date();
      const currentVintageID = find(lkpVintage as any, (vintage: any) => {
        const fromDate = new Date(vintage?.fromDate);
        const toDate = new Date(vintage?.toDate);
        return currentDate >= fromDate && currentDate <= toDate;
      })?.id;

      const cropMeasurement = {
        VintageID: currentVintageID,
        VineyardBlockID: vineyardBlock?.id,
        MeasureInstanceID: measureInstanceID,
        MeasureValue1: AvgBudsPerVine,
        ModifiedBy: `${firstName} ${lastName} (${licensedUserIdentityID})`,
        IsPrivate: false,
        CropMeasurementComment:
          'Imported from VN Cropsy Pruning Report Importer',
        MeasureDate: format(new Date(), dateFormat),
      };

      const rowData = {
        VineyardBlock: { ...vineyardBlock, PlantedVines: PlantedVines },
        CropMeasurement: cropMeasurement,
      };

      return rowData;
    });

    return dataToSend;
  }, [parsedRows, formdata, activeStep, allVineyardBlocks]);

  // Call relevant API based on importTypeID
  const handleOnImport = useCallback(() => {
    setApiTrigger(true);
    let dataToSend: any = {};
    if (importTypeID === ImportTypeIDs.BLOCK_SHEET) {
      dataToSend = getBlockSheetDataToSend();
    } else if (importTypeID === ImportTypeIDs.CROP_MEASUREMENTS) {
      dataToSend = getCropMeasurementsDataToSend();
    } else if (importTypeID === ImportTypeIDs.CROPSY_PRUNING) {
      if (activeStep === 1) {
        dataToSend = getCropsyPruningRowDataToSend();
      } else if (activeStep === 2) {
        dataToSend = getCropsyPruningBlockDataToSend();
      }
    }

    if (!isEmpty(dataToSend)) {
      dispatchAPI(
        VineaNovaActions.api.v1.importSheets.post.request({
          queryParams: { importTypeID: importTypeID, stepNumber: activeStep },
          postBody: dataToSend,
        }),
      );
    }
  }, [importTypeID, parsedRows, formdata, activeStep]);

  // Fetch vineyard blocks if a site is selected, or if user navigates back to first step
  useEffect(() => {
    if (
      importTypeID === ImportTypeIDs.BLOCK_SHEET &&
      VineyardID !== 0 &&
      activeStep === 0
    ) {
      dispatchAPI(
        VineaNovaActions.api.v1.identityVineyardBlocks.get.request({
          queryParams: { IdentityID: VineyardID },
        }),
      );
    }
  }, [VineyardID, activeStep]);

  // Show success or error message when file is read
  useEffect(() => {
    if (activeStep === 0 && !fileReadLoading && fileName !== '') {
      if (sheets.length > 0 && !errorFlag)
        enqueueSnackbar('Successfully read file', { variant: 'success' });
      else enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  }, [fileReadLoading, fileName, sheets]);

  // Show success or error message when data is imported
  useEffect(() => {
    if (apiTrigger && importIsLoaded && !importIsLoading) {
      if (!importHasError) {
        enqueueSnackbar('Successfully imported data', { variant: 'success' });
      } else {
        enqueueSnackbar('Error importing data', { variant: 'error' });
      }
      setApiTrigger(true);
    }
  }, [apiTrigger, importIsLoading, importIsLoaded, importHasError]);

  // Show invalid sheet selection warning
  useEffect(() => {
    if (showSheetSelectWarning) {
      enqueueSnackbar(invalidSheetSelectionMessage, {
        variant: 'warning',
      });
      setShowSheetSelectWarning(false);
    }
  }, [showSheetSelectWarning]);

  useEffect(() => {
    if (activeStep > 0) {
      try {
        handleDisplayDataAsTable();
        setApiTrigger(false);
      } catch (error) {
        flagError(
          true,
          'Unable to validate file data - make sure to choose the correct template for your import type',
        );
      }
    }
  }, [activeStep]);

  return {
    activeStep,
    steps,
    fileName,
    sheetsGridXData,
    sheetsToParse,
    formdata,
    canValidate,
    additionalParameters,
    fileReadLoading,
    dataGridCols,
    parsedRows,
    canImport,
    existingBlockNames,
    dataLoading,
    handleFileRead,
    handleOnSheetsSelectionChange,
    handleOnChange,
    handleOnNext,
    handleOnBack,
    handleOnImport,
  };
};
