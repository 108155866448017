import React from 'react';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { VineaButton } from '../../../components/VineaButton';
import { VineaAlert } from '../../../components/VineaAlert';
import { VineaTextField } from '../../../components/TextField';
import { Datepicker } from '../../../components/Datepicker';
import logger from '../../../utils/winstonLogger';
import { useIdentityNotesHook } from '../Hooks/useIdentityNotesHook';
import { getLkpNoteStatus, getLkpKeyword } from '../../../redux/selectors';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { SplitButton } from '../../../components/SplitButton';
import { MultiSelectCheckbox } from '../../../components/MultiSelectCheckbox/MultiSelectCheckbox';
import { DeleteDialog, DiscardDialog } from '../../../components/Dialog';
import { viewDateFormat } from '../../../constants';
import { DocumentsGrid } from '../Documents';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';

const StyledBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  minWidth: 250,
  '& .MuiFormControl-root': {
    minWidth: 250,
  },
}));

const StyledFormControl = styled(FormControl)(() => ({
  root: { width: 250 },
}));

export const ManageIdentityNotes = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  logger.debug('Manage Identity Note Details');
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);
  const navigate = useNavigate();

  const {
    noteSelected,
    errorInSubmit,
    isNewNote,
    isStatusReviewer,
    showReviewer,
    saveButtonStatus,
    confirmDeleteIsOpen,
    setErrorInSubmit,
    handleOnChange,
    handleOnBlur,
    handleOnChangeKeyWord,
    onSave,
    onUpdate,
    onDelete,
    setConfirmDeleteIsOpen,
  } = useIdentityNotesHook();

  const formdata = { errors: {}, ...noteSelected };

  // get selector values
  const noteStatusLookUp = useSelector(getLkpNoteStatus);
  const keywordLookUp = useSelector(getLkpKeyword);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleDelete = () => {
    onDelete();
    navigate(-1);
  };

  const handleClose = () => {
    if (!saveButtonStatus) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={12} data-testid="vineaalert">
          <VineaAlert
            isOpen={!!errorInSubmit}
            onExit={() => setErrorInSubmit(false)}
          />
        </Grid>
        <Grid item xs={12} data-id="grid-top-wrapper">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              rowGap: theme.spacing(5),
              columnGap: theme.spacing(10),
            }}
          >
            <Grid
              container
              spacing={1}
              style={{ maxWidth: '550px' }}
              data-testid="grid-field"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                {/* Note Date */}
                <Grid item xs={6}>
                  <StyledBox mt={1}>
                    <StyledFormControl
                      error={!!formdata.errors.subjectDate}
                      data-testid="subjectDate-formcontrol"
                    >
                      <Datepicker
                        margin="dense"
                        disablePast={false}
                        id="subjectDate"
                        inputFormat={viewDateFormat}
                        placeholder="DD/MM/YYYY"
                        variant="outlined"
                        size="small"
                        value={formdata.subjectDate}
                        onChange={handleOnChange}
                        name="subjectDate"
                        label={t('Note Date')}
                      />
                      {formdata.errors.subjectDate && (
                        <FormHelperText error id="component-error-text">
                          {formdata.errors.subjectDate}
                        </FormHelperText>
                      )}
                    </StyledFormControl>
                  </StyledBox>
                </Grid>

                {/* Status */}
                <Grid item xs={6}>
                  <StyledBox>
                    <FormControl
                      sx={{
                        width: 250,
                      }}
                      error={!!formdata.errors.noteStatusID}
                    >
                      <VineaAutoComplete
                        value={formdata.noteStatusID || 0}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        options={noteStatusLookUp}
                        label={t('Status')}
                        inputProps={{
                          name: 'noteStatusID',
                        }}
                      />
                      {formdata.errors.noteStatusID && (
                        <FormHelperText id="component-error-text">
                          {formdata.errors.noteStatusID}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </StyledBox>
                </Grid>

                {/* Note Subject */}
                <Grid item xs={6} data-testid="grid-inner-fields">
                  <StyledBox mt={1}>
                    <VineaTextField
                      margin="dense"
                      autoComplete="off"
                      id="noteSubject"
                      name="noteSubject"
                      label={t('Note Subject')}
                      value={formdata.noteSubject}
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      error={formdata.errors.noteSubject}
                    />
                  </StyledBox>
                </Grid>

                {/* Reviewer */}
                <Grid item xs={6}>
                  {showReviewer && (
                    <StyledBox mt={1}>
                      <VineaTextField
                        autoComplete="off"
                        id="reviewer"
                        name="reviewer"
                        label={t('Reviewer')}
                        margin="dense"
                        disabled={!isStatusReviewer}
                        value={formdata.reviewer}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        error={formdata.errors.Reviewer}
                      />
                    </StyledBox>
                  )}
                </Grid>

                {/* Keywords */}
                <Grid item xs={6}>
                  <StyledBox mt={1}>
                    <FormControl
                      sx={{
                        width: 250,
                      }}
                      error={!!formdata.errors.keywordID}
                      margin="dense"
                    >
                      <MultiSelectCheckbox
                        value={formdata.keywordListID || []}
                        onChange={handleOnChangeKeyWord}
                        onBlur={handleOnBlur}
                        sx={{
                          width: 250,
                        }}
                        options={keywordLookUp}
                        label={t('Keywords')}
                        inputProps={{
                          name: 'keywordListID',
                        }}
                      />
                      {formdata.errors.keywordID && (
                        <FormHelperText id="component-error-text">
                          {formdata.errors.keywordID}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </StyledBox>
                </Grid>

                {/* Review Date */}
                <Grid item xs={6}>
                  {showReviewer && (
                    <StyledBox mt={1}>
                      <StyledFormControl
                        error={!!formdata.errors.reviewDate}
                        data-testid="reviewDate-formcontrol"
                      >
                        <Datepicker
                          disablePast={false}
                          id="reviewDate"
                          inputFormat={viewDateFormat}
                          margin="dense"
                          disabled={!isStatusReviewer}
                          value={formdata.reviewDate}
                          onChange={handleOnChange}
                          name="reviewDate"
                          label={t('Review Date')}
                        />
                        {formdata.errors.reviewDate && (
                          <FormHelperText id="component-error-text">
                            {formdata.errors.reviewDate}
                          </FormHelperText>
                        )}
                      </StyledFormControl>
                    </StyledBox>
                  )}
                </Grid>

                {/* Note Text */}
                <Grid item xs={12}>
                  <StyledBox mt={1}>
                    <VineaTextField
                      id="noteText"
                      name="noteText"
                      label={t('Note Text')}
                      margin="dense"
                      value={formdata.noteText}
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      error={formdata.errors.noteText}
                      multiline
                      maxRows={16}
                      minRows={8}
                      fullWidth
                    />
                  </StyledBox>
                </Grid>
              </ErrorBoundary>
            </Grid>

            <Box m={2}>
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <DocumentsGrid isNewNote={isNewNote} />
              </ErrorBoundary>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
          }}
        >
          <Box mx={2} mt={1}>
            {/* {isNewNote && (
              <VineaButton
                color="success"
                onClick={onSave}
                variant="contained"
                sx={{
                  marginRight: theme.spacing(1),
                  minWidth: 150,
                }}
                minWidth={100}
              >
                {t('SAVE & CLOSE')}
              </VineaButton>
            )} */}
            <SplitButton
              color="success"
              onClick={isNewNote ? onSave : onUpdate}
              variant="contained"
              minWidth={130}
              disabled={saveButtonStatus}
            />
          </Box>
          {!isNewNote && (
            <Box mx={2} mt={1}>
              <VineaButton
                color="error"
                variant="contained"
                onClick={() => setConfirmDeleteIsOpen(true)}
              >
                {t('Delete')}
              </VineaButton>
            </Box>
          )}
          <Box mx={2} mt={1}>
            <VineaButton
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              {t('Close')}
            </VineaButton>
          </Box>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={handleOnDialogClose}
            handleSaveChanges={onUpdate}
            handleDiscardChanges={handleCancel}
          />
          <DeleteDialog
            onClose={() => setConfirmDeleteIsOpen(false)}
            dialogHeader={t(`Are you sure you wish to delete this note?`)}
            open={confirmDeleteIsOpen}
            dialogActionTrigger={handleDelete}
            dialogContent={''}
            rowId={0}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
