import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { isEmpty } from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VineaButton } from '../VineaButton';
import { GreenSwitch } from '../Switch';
import GridWithFilter from './GridWithFilter';
import { RenderDataGridHeader } from './RenderDataGridHeader';
import { GridNoRowMessage } from './GridNoRowMessage';
import { reducers, SiteTypeIDs } from '../../constants';

export const BlocksGridComponent = ({
  heading,
  manage = f => f,
  add,
  hasAddOption,
  tableData,
  hasManageOption,
  pathParamId,
  gridColumnState,
  quickSearchFilter,
  identityBaseURL,
  siteTypeID,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showActive, setShowActive] = React.useState(true);
  const navigate = useNavigate();

  const tableDataFormattedData = tableData
    .filter(f => {
      if (!showActive) return true;
      return f.isActive === true;
    })
    .map(da => ({
      id: da.id,
      blockFullName: da.blockFullName,
      datePlantedFormat: da.datePlantedFormat,
      latestVintageYear: da.latestVintageYear,
      blockStatus: da.blockStatus,
      varietyName: da.varietyName,
      varietyClone: da.varietyClone,
      rootStock: da.rootStock,
      plantSpacing: da.plantSpacing,
      rowSpacing: da.rowSpacing,
      plantedVines: da.plantedVines,
      startEndRow: da.startEndRow,
      fromTo: da.fromTo,
      version: da.version,
      plantedAreaHa: da.plantedAreaHa,
      isActive: da.isActive,
    }));

  const orderByFormattedTable = orderBy(
    tableDataFormattedData,
    ['blockFullName', 'activeFromDate'],
    ['asc', 'desc'],
  );

  const RenderCellVintage = params => {
    const {
      row: { blockFullName, id },
      value,
    } = params;
    return (
      <Link
        component={RouterLink}
        to={`/${identityBaseURL}/blocks/${id}/vintagedetails`}
        state={{ blockFullName }}
      >
        {value}
      </Link>
    );
  };

  const RenderCellBlockRows = params => {
    const {
      row: { blockFullName, id },
      value,
    } = params;
    // replace whitespace regex
    // replace more then one white space

    let newValue = value;
    if (isNaN(value)) {
      newValue = value.replace(/\s\s+/g, '');
      // trim value
      newValue = newValue && newValue.trim();
      // check if still empty
      newValue = newValue === ' ' ? '' : newValue;
      // check if still has empty space
      newValue = newValue && newValue.replace(/\s+/g, '');
      // replace with null
      newValue = newValue === '' ? null : newValue;
    }

    return (
      <Link
        component={RouterLink}
        to={`/${identityBaseURL}/blocks/${id}/blockrows`}
        state={{ blockFullName }}
      >
        {isEmpty(newValue) ? 'Add Rows' : value}
      </Link>
    );
  };

  function getSpacing(params) {
    return `${params.row.plantSpacing || ''} X ${params.row.rowSpacing || ''}`;
  }

  const gridDataColums = [
    {
      field: 'id',
      headerName: 'id',
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'blockFullName',
      headerName: 'Name',
      minwidth: '150px',
      flex: 0.3,
      resizable: false,
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'datePlantedFormat',
      headerName: 'Date Planted',
      minwidth: '150px',
      flex: 0.3,
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'latestVintageYear',
      headerName: 'Latest Vintage',
      minwidth: '150px',
      flex: 0.2,
      hideable: false,
      renderHeader: RenderDataGridHeader,
      renderCell: RenderCellVintage,
    },
    {
      field: 'blockStatus',
      headerName: 'Status',
      minwidth: '150px',
      flex: 0.2,
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'varietyName',
      headerName: 'Variety',
      minwidth: '150px',
      flex: 0.2,
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'varietyClone',
      headerName: 'Clone',
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'rootStock',
      headerName: 'Root Stock',
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },

    {
      field: 'plantedAreaHa',
      headerName: 'Planted',
      minwidth: '150px',
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'plantedVines',
      headerName: siteTypeID === SiteTypeIDs.ORCHARD_TREES ? 'Trees' : 'Vines',
      minwidth: '150px',
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'startEndRow',
      headerName: 'Rows',
      minwidth: '150px',
      hideable: false,
      flex: 0.1,
      renderHeader: RenderDataGridHeader,
      renderCell: RenderCellBlockRows,
    },
    {
      field: 'Spacing',
      headerName: 'VS x RS',
      hideable: false,
      renderHeader: RenderDataGridHeader,
      valueGetter: getSpacing,
    },
    {
      field: 'fromTo',
      headerName: 'From - To',
      hideable: false,
      flex: 0.1,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'version',
      headerName: 'Version',
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
  ];

  const gridXData = { columns: gridDataColums, rows: orderByFormattedTable };

  const handleOnCellClick = evt => {
    const { field, id } = evt;
    if (field === 'latestVintageYear' || field === 'startEndRow') {
      // navigate(
      //   `/organisation/vineyards/${pathParamId}/blocks/${id}/vintagedetails`,
      // );
    } else {
      navigate(`/${identityBaseURL}/blocks/${id}`);
    }
  };

  const NoRowsOverlayMessage = () => (
    <Box mt={10} data-testid="blockgrid-norowmessage">
      <GridNoRowMessage message="Sorry, there were no records found." />
    </Box>
  );

  return (
    <Stack
      data-testid="blockgrid-component"
      sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
    >
      <Paper
        elevation={0}
        data-testid="detail-info"
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          p={2}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h6" color="textPrimary">
              {heading}
            </Typography>
          </Box>

          <Box>
            <Box display="inline-flex">
              <FormControlLabel
                control={
                  <GreenSwitch
                    checked={showActive}
                    onChange={() => setShowActive(active => !active)}
                    name="activeOnly"
                  />
                }
                label={
                  <Typography variant="caption">{t('Active only')}</Typography>
                }
              />
              {hasManageOption && (
                <VineaButton
                  variant="text"
                  color="secondary"
                  sx={{
                    marginRight: theme.spacing(1),
                  }}
                  onClick={manage}
                  data-testid="btn-add"
                >
                  {t('Manage')}
                </VineaButton>
              )}
              {hasAddOption && (
                <VineaButton
                  variant="contained"
                  color="secondary"
                  sx={{
                    marginRight: theme.spacing(1),
                  }}
                  onClick={add}
                  data-testid="btn-add"
                >
                  {t('Add Block')}
                </VineaButton>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
      <Paper
        elevation={0}
        data-testid="blocks-grid-detail-info"
        sx={{ height: '100%' }}
      >
        <GridWithFilter
          data={gridXData}
          onCellClick={handleOnCellClick}
          rowHeight={30}
          headerHeight={56}
          noRowsOverlay={NoRowsOverlayMessage}
          actionType="BLOCKS_GRID_COLUMN_UPDATE"
          gridColumnState={gridColumnState}
          advancedSearch={false}
          filterUpdateActionType={reducers.vineyardBlockSearchFilter}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                ...quickSearchFilter,
              },
            },
          }}
        />
      </Paper>
    </Stack>
  );
};

BlocksGridComponent.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  manage: PropTypes.func.isRequired,

  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  // isLoading: PropTypes.bool,
  hasManageOption: PropTypes.bool,
  add: PropTypes.func,
  hasAddOption: PropTypes.bool,
  pathParamId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  quickSearchFilter: PropTypes.shape({}),
};

BlocksGridComponent.defaultProps = {
  tableData: [],
  hasManageOption: true,
  add: f => f,
  hasAddOption: false,
  quickSearchFilter: {},
};

function areEqual(prevProps, nextProps) {
  const px = pick(prevProps, ['pathParamId', 'tableData']);
  const nx = pick(nextProps[('pathParamId', 'tableData')]);
  return isEqual(px, nx);
}

export const BlocksGrid = React.memo(BlocksGridComponent, areEqual);
