import { combineReducers } from 'redux';
import { VineaNovaRootReducer } from 'vineanova-redux-artifacts';
import { reducers } from '../../constants';
import createNamedReducer from './createNamedReducer';
import snackbarReducer from './snackbarReducer';
import { settingsReducer } from '../../containers/Setting';
import { counterReducer } from '../../containers/Home';
import authReducer from './authReducer';
import languageSelectorReducer from './languageSelectorReducer';
import userSettingsReducer from './userPreferencesReducers';

const createReducer = asyncReducer =>
  combineReducers({
    ...VineaNovaRootReducer,
    snackbar: snackbarReducer,
    settings: settingsReducer,
    counter: counterReducer,
    authentication: authReducer,
    pageDimension: createNamedReducer(reducers.pageDimension),
    deeplink: createNamedReducer(reducers.deeplink),
    featureToggles: createNamedReducer(reducers.featureToggles),
    language: languageSelectorReducer,
    userAccess: createNamedReducer(reducers.userAccess),
    personOverviewDetails: createNamedReducer(reducers.personOverviewDetails),
    identityRepository: createNamedReducer(reducers.repository),
    identityDetails: createNamedReducer(reducers.details),
    identityReferences: createNamedReducer(reducers.references),
    identityRelationship: createNamedReducer(reducers.relationship),
    identityRelationshipType: createNamedReducer(reducers.relationshipType),
    identityClassifications: createNamedReducer(reducers.classifications),
    identityContactMethods: createNamedReducer(reducers.contactMethods),
    identityRoles: createNamedReducer(reducers.roles),
    identityOverviewDetails: createNamedReducer(
      reducers.identityOverviewDetails,
    ),
    identityAddressDetails: createNamedReducer(reducers.addressDetails),
    searchIdentities: createNamedReducer(reducers.searchIdentities),
    searchUsers: createNamedReducer(reducers.searchUsers),
    searchPlans: createNamedReducer(reducers.searchPlans),
    addDetail: createNamedReducer(reducers.addDetail),
    addReference: createNamedReducer(reducers.addReference),
    // lookups
    formWrite: createNamedReducer(reducers.formWrite),
    refreshPages: createNamedReducer(reducers.refreshPages),
    vineyardBlocks: createNamedReducer(reducers.vineyardBlocks),
    vineyardBlockRows: createNamedReducer(reducers.vineyardBlockRows),
    identityNotes: createNamedReducer(reducers.identityNotes),
    blockVintages: createNamedReducer(reducers.blockVintages),
    trellis: createNamedReducer(reducers.trellis),
    sampleSetSearchFilter: createNamedReducer(reducers.sampleSetSearchFilter),
    trackingSearchFilter: createNamedReducer(reducers.trackingSearchFilter),
    dataGridFilterModel: createNamedReducer(reducers.dataGridFilterModel),
    dataGridSortModel: createNamedReducer(reducers.dataGridSortModel),
    vineyardSearchFilter: createNamedReducer(reducers.vineyardSearchFilter),
    personSearchFilter: createNamedReducer(reducers.personSearchFilter),
    supplyContractSearchFilter: createNamedReducer(
      reducers.supplyContractSearchFilter,
    ),
    jobSearchFilter: createNamedReducer(reducers.jobSearchFilter),
    organisationSearchFilter: createNamedReducer(
      reducers.organisationSearchFilter,
    ),
    serviceProviderSearchFilter: createNamedReducer(
      reducers.serviceProviderSearchFilter,
    ),
    connectionSearchFilter: createNamedReducer(reducers.connectionSearchFilter),
    consumableSearchFilter: createNamedReducer(reducers.consumableSearchFilter),
    vehicleSearchFilter: createNamedReducer(reducers.vehicleSearchFilter),
    accommodationSearchFilter: createNamedReducer(
      reducers.accommodationSearchFilter,
    ),
    vineyardExplorerSearchFilter: createNamedReducer(
      reducers.vineyardExplorerSearchFilter,
    ),
    usersSearchFilter: createNamedReducer(reducers.usersSearchFilter),
    plansSearchFilter: createNamedReducer(reducers.plansSearchFilter),
    activitySearchFilter: createNamedReducer(reducers.activitySearchFilter),
    tabChangeLock: createNamedReducer(reducers.tabChangeLock),
    userPreferences: userSettingsReducer,
    newNoteDocuments: createNamedReducer(reducers.newNoteDocuments),
    chartFilter: createNamedReducer(reducers.chartFilter),
    dashboardPageFilter: createNamedReducer(reducers.dashboardPageFilter),
    connectionSensorsPageFilter: createNamedReducer(
      reducers.connectionSensorsPageFilter,
    ),
    connectionSitePageFilter: createNamedReducer(
      reducers.connectionSitePageFilter,
    ),
    vineyardBlockSearchFilter: createNamedReducer(
      reducers.vineyardBlockSearchFilter,
    ),
    latestChartData: createNamedReducer(reducers.latestChartData),
    ...asyncReducer,
  });

export default createReducer;
