import { find } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  VineaNovaSelectors,
  VineaNovaActions,
} from 'vineanova-redux-artifacts';

type UseEventAlertsProps = {
  eventStatusID: number;
};

type ApiMetaType = {
  isLoading: boolean;
  isLoaded: boolean;
  hasError: boolean;
  data: any;
};

export type EventLogAlertType = {
  id: number;
  eventStatusID: number;
  message: string;
};

export const useEventAlerts = ({ eventStatusID }: UseEventAlertsProps) => {
  const dispatchAPI = useDispatch();
  const { licensedUserIdentityID } = useSelector(
    (state: any) => state.userAccess.data,
  );

  const { isLoading: getEventLogLoading, data: userEventAlerts } = useSelector(
    VineaNovaSelectors.getEventLogEntity,
  ) as ApiMetaType;

  // const userEventAlerts = [
  //   { id: 1, eventStatusID: 2, message: 'Test', ts: 'abc' },
  //   { id: 2, eventStatusID: 22, message: 'Test', ts: 'abc2' },
  // ];

  const numAlerts = userEventAlerts.length;

  const { isLoading: updateEventLogLoading, isLoaded: updateEventLogLoaded } =
    useSelector(VineaNovaSelectors.getEventLogStatusEntityMeta) as ApiMetaType;

  const eventLogLoading = getEventLogLoading || updateEventLogLoading;

  const handleUpdateEventLogStatus = useCallback(
    (eventAlertID: number, eventStatusID: number) => {
      const eventLogts = find(userEventAlerts, { id: eventAlertID })?.ts;

      dispatchAPI(
        VineaNovaActions.api.v1.eventLogStatus.put.request({
          postBody: {
            id: eventAlertID,
            ts: eventLogts,
            eventStatusID: eventStatusID,
          },
        }),
      );
    },
    [userEventAlerts],
  );

  const handleFetchEventLogs = useCallback(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.eventLog.get.request({
        queryParams: {
          RecipientIdentityID: licensedUserIdentityID,
          //RecipientIdentityID: 1844,
          EventStatusID: eventStatusID,
        },
      }),
    );
  }, [licensedUserIdentityID, eventStatusID]);

  useEffect(() => {
    handleFetchEventLogs();
    // Set the interval to run every 300,000 milliseconds (5 minutes)
    const interval = setInterval(() => {
      handleFetchEventLogs();
    }, 300000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  // Fetch event logs when event logs are updated
  useEffect(() => {
    if (!updateEventLogLoading && updateEventLogLoaded) {
      handleFetchEventLogs();
    }
  }, [updateEventLogLoading, updateEventLogLoaded]);

  return {
    userEventAlerts,
    numAlerts,
    eventLogLoading,
    handleUpdateEventLogStatus,
  };
};
