import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { VineaNovaActions } from 'vineanova-redux-artifacts';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';

import { GridWithFilter, RenderDataGridHeader } from '../../components/Grid';
import { lgDataGridHeaderColumns } from './breakpointColumns';
import {
  BlockVintageStatusIDs,
  sagaActionTypes as Types,
  actionTypes,
  reducers,
} from '../../constants';
import {
  RenderCellWithTrellis,
  RenderCellWithVintageStatus,
  RenderEditButton,
} from './CellEditFields';
import { useBlockVintages } from './hooks/useBlockVintages';
import { getFormWriteData } from '../../redux/selectors';
import { TabPanel, TabView } from '../../components/TabView';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { ErrorBoundary } from 'react-error-boundary';
import useTabChangeLock from '../../hooks/useTabChangeLock';
import { OtherAttributesContainer } from '../Tracking/OtherAttributes/OtherAttributesContainer';
import { NotesListContainer } from '../Tracking/Notes/NotesListContainer';
import { BlockVintageDetailsContainer } from './BlockVintageDetailsContainer';
import { dataGridOtherAttributeVineyardBlockColumns } from '../Tracking/gridColumns';
import { orderBy } from 'lodash';
import { BlockVintageCostAllocations } from './BlockVintageCostAllocations';

export const ManageVintageDetails = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [alertOpen, setAlertOpen] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const { setIsLocked } = useTabChangeLock();
  const theme = useTheme();
  const { id: pathParamId, blockId } = useParams();

  const {
    cropPhenologyTable,
    cropMeasurementsTable,
    blockVintageDetails,
    currentBlockVintage,
    validationErrors,
    actionTriggered,
    tabHeaders,
    lkpVintage,
    handleOnEditClick,
    handleOnChangeCommon,
    handleOnSaveRow,
    setActionTriggered,
  } = useBlockVintages(blockId);

  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const { state: { blockFullName } = {} } = location;

  const {
    isLoading: isFormSubmitLoading,
    hasError: hasErrorFormSubmit,
    error: formErrorMessage,
    isLoaded,
  } = useSelector(getFormWriteData);

  const lgColumnsTable = lgDataGridHeaderColumns(
    RenderDataGridHeader,
    RenderCellWithTrellis,
    RenderCellWithVintageStatus,
    RenderEditButton,
    handleOnChangeCommon,
    handleOnEditClick,
  );

  const lgDataTable = {
    columns: lgColumnsTable,
    rows: orderBy(blockVintageDetails, ['vintageID'], ['desc']),
  };

  // do nothing with clicks
  const handleOnCellClick = () => {};

  const handleOnCancel = () => {
    navigate(-1);
  };

  const handleonTabChange = index => {
    setTabIndex(index);
    setIsLocked(true);
  };

  const handleChangeIndex = index => {
    setTabIndex(index);
  };

  useEffect(() => {
    dispatchAPI({
      type: Types.FETCH_BLOCK_VINTAGES,
      name: reducers.blockVintages,
      payload: {
        id: blockId,
        name: reducers.blockVintages,
      },
    });
  }, [blockId, dispatchAPI]);

  /** update form_submit status */
  useEffect(() => {
    /** When saving the selected reference id */
    if (actionTriggered) {
      if (!isFormSubmitLoading && isLoaded) {
        if (hasErrorFormSubmit) {
          // set form submitting has errors;
          setAlertOpen(true);
          enqueueSnackbar(t('Error'), { variant: 'Error' });
        } else if (!hasErrorFormSubmit) {
          enqueueSnackbar(t('Success'), { variant: 'Success' });
          dispatchAPI({
            type: Types.FETCH_BLOCK_VINTAGES,
            name: reducers.blockVintages,
            payload: {
              id: blockId,
              name: reducers.blockVintages,
            },
          });
        }
        setActionTriggered(false);
      }
    }
  }, [
    actionTriggered,
    setActionTriggered,
    isFormSubmitLoading,
    hasErrorFormSubmit,
    isLoaded,
    enqueueSnackbar,
    t,
    blockId,
    dispatchAPI,
  ]);

  useEffect(() => {
    const payload = { vintageID: 0, vineyardBlockId: blockId };
    dispatchAPI({
      type: actionTypes.updateData,
      name: reducers.trackingSearchFilter,
      payload,
    });
    dispatchAPI(
      VineaNovaActions.api.v1.cropBlockNotes.get.request({
        queryParams: {
          VineyardBlockID: blockId,
          VintageID: 0,
        },
      }),
    );
    dispatchAPI(
      VineaNovaActions.api.v1.cropBlockSetting.get.request({
        queryParams: {
          VineyardBlockID: blockId,
          VintageID: 0,
        },
      }),
    );
  }, [blockId]);

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      data-testid="manage-vintage-box-details"
      height="100%"
      p={2}
    >
      <Box
        display="flex"
        flex={!isEmpty(validationErrors) || hasErrorFormSubmit ? 1 / 6 : 0}
        flexDirection="column"
        data-testid="griditem-01"
      >
        <Box>
          {!isEmpty(validationErrors) && (
            <>
              <Collapse in={alertOpen}>
                <Alert
                  variant="outlined"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {Object.keys(validationErrors).map(key => {
                    return <p key={key}>{validationErrors[key]}</p>;
                  })}
                </Alert>
              </Collapse>
            </>
          )}
        </Box>
        <Box>
          {hasErrorFormSubmit && (
            <Collapse in={alertOpen}>
              <Alert
                variant="outlined"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {formErrorMessage}
              </Alert>
            </Collapse>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        flex={1 / 12}
        flexDirection="column"
        data-testid="griditem-02"
      >
        <Paper elevation={0} data-testid="detail-info">
          <Box
            display="flex"
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            p={2}
          >
            <Box justifyContent="flex-start">
              <Typography variant="h5">
                {`Vintage details for block ${blockFullName}`}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        data-testid="griditem-03"
      >
        <Paper
          elevation={0}
          data-testid="detail-info"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            height: '100%',
          }}
        >
          <TabView
            tabHeaders={tabHeaders}
            onTabChange={handleonTabChange}
            onSwipe={handleChangeIndex}
            value={tabIndex}
          >
            <TabPanel
              value={tabIndex}
              index={0}
              dir={theme.direction}
              data-testid="blocktabpanel-vintagedetails"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <BlockVintageDetailsContainer
                  handleOnCancel={handleOnCancel}
                  handleOnCellClick={handleOnCellClick}
                  handleOnSaveRow={handleOnSaveRow}
                  data={lgDataTable}
                />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={1}
              dir={theme.direction}
              data-testid="blocktabpanel-measures"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <Paper
                  elevation={0}
                  data-testid="detail-info"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 500,
                  }}
                >
                  <GridWithFilter
                    data={cropMeasurementsTable}
                    rowHeight={30}
                    autoPageSize
                    rowsPerPageOptions={[25]}
                    //onRowClick={handleOnCellClick}
                    advancedSearch={false}
                  />
                </Paper>
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={2}
              dir={theme.direction}
              data-testid="blocktabpanel-phenology"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <Paper
                  elevation={0}
                  data-testid="detail-info"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    height: 500,
                  }}
                >
                  <GridWithFilter
                    data={cropPhenologyTable}
                    rowHeight={30}
                    autoPageSize
                    rowsPerPageOptions={[25]}
                    //onRowClick={handleOnCellClick}
                    advancedSearch={false}
                  />
                </Paper>
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={3}
              dir={theme.direction}
              data-testid="blocktabpanel-notes"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <NotesListContainer isVineyardBlockScreen={true} />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={4}
              dir={theme.direction}
              data-testid="blocktabpanel-attributes"
            >
              <OtherAttributesContainer
                isVineyardBlockScreen={true}
                dataColumns={dataGridOtherAttributeVineyardBlockColumns}
              />
            </TabPanel>
            {currentBlockVintage?.blockVintageStatusID ===
              BlockVintageStatusIDs.ADHOC && (
              <TabPanel
                value={tabIndex}
                index={5}
                dir={theme.direction}
                data-testid="blocktabpanel-costallocation"
              >
                <BlockVintageCostAllocations
                  theme={theme}
                  vintages={lkpVintage}
                  currentBlockVintage={currentBlockVintage}
                />
              </TabPanel>
            )}
          </TabView>
        </Paper>
      </Box>
    </Box>
  );
};
