import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useTheme, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { VineaButton } from '../../../components/VineaButton';
import GridWithFilter from '../../../components/Grid/GridWithFilter';
import { GridNoRowMessage } from '../../../components/Grid/GridNoRowMessage';
// import logger from '../../../utils/winstonLogger';
import { sagaActionTypes as Types, reducers } from '../../../constants';
import { noteGridColumn } from './noteGridHeaderColumn';
import { getIdentityNotesSelector } from '../../../redux/selectors';

const StyledRoot = styled('div')(({ noRows }) => ({
  flexGrow: 1,
  minHeight: 100,
  ...(noRows && {
    height: 450,
  }),
}));

export const IdentityNotes = ({ pathParamId, identityBaseURL }) => {
  const dispatchAPI = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOnRowClick = evt => {
    const { id } = evt;
    navigate(`/${identityBaseURL}/notes/${id}`);
  };

  const handleOnAdd = () => {
    navigate(`/${identityBaseURL}/notes/create`);
  };

  const NoRowsOverlayMessage = () => (
    <Box mt={10} data-testid="blockgrid-norowmessage">
      <GridNoRowMessage message="Sorry, there were no records found." />
    </Box>
  );

  React.useEffect(() => {
    dispatchAPI({
      type: Types.FETCH_IDENTITY_NOTES,
      name: reducers.identityNotes,
      payload: {
        id: pathParamId,
        name: reducers.identityNotes,
      },
    });
  }, [pathParamId, dispatchAPI]);

  // get selector values
  const notesData = useSelector(getIdentityNotesSelector);

  const lgColumnsTable = noteGridColumn();
  const noRows = notesData.length === 0;

  const gridXData = { columns: lgColumnsTable, rows: notesData };

  return (
    <StyledRoot noRows={noRows} data-testid="notesgrididentity-component">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper elevation={0} data-testid="detail-info">
            <Box
              display="flex"
              flex={1}
              flexDirection="row"
              justifyContent="space-between"
              p={2}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6" color="textPrimary">
                  {() => {
                    '';
                  }}
                </Typography>
              </Box>

              <Box>
                <Box display="inline-flex">
                  <VineaButton
                    variant="contained"
                    color="secondary"
                    sx={{
                      marginRight: theme.spacing(1),
                    }}
                    onClick={handleOnAdd}
                    data-testid="btn-add"
                  >
                    {t('Add Note')}
                  </VineaButton>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            data-testid="detail-info-notes"
            sx={{
              height: 450,
            }}
          >
            <GridWithFilter
              data={gridXData}
              onRowClick={handleOnRowClick}
              rowHeight={30}
              autoPageSize
              components={{
                NoRowsOverlay: NoRowsOverlayMessage,
              }}
              advancedSearch={false}
            />
          </Paper>
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

IdentityNotes.propTypes = {
  pathParamId: PropTypes.number.isRequired,
};
