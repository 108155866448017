/* eslint-disable jsx-a11y/label-has-associated-control */

import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { isNil } from 'lodash';

export const StyledInput = styled('input')(() => ({ display: 'none' }));

type ExcelCsvUploadProps = {
  handleFileRead: (e: any) => void;
};

export const ExcelCsvUpload = ({ handleFileRead }: ExcelCsvUploadProps) => {
  const [fileRef, setFileRef] = useState<any>();
  const acceptedFiles =
    '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  const handleChange = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      if (file) {
        handleFileRead(file);
        // Reset file input after file is read
        if (!isNil(fileRef)) setFileRef(null);
        e.target.value = null;
      }
    }
  };

  return (
    <>
      <label htmlFor="contained-button-file">
        <Grid container justifyContent="center" alignItems="center">
          <Fab component="span">
            <NoteAddIcon />
          </Fab>
        </Grid>
        <StyledInput
          accept={acceptedFiles}
          id="contained-button-file"
          type="file"
          onChange={handleChange}
          ref={input => setFileRef(input)}
        />
      </label>
    </>
  );
};
