import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Skeleton,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { get, isEmpty } from 'lodash';
import { VineaNovaActions } from 'vineanova-redux-artifacts';

import { VineaAutoComplete } from '../ComboBox';
import { Datepicker } from '../Datepicker';
import { FieldLabelInput } from '../TextField';
import { VineaButton } from '../VineaButton';
import { viewDateFormat } from '../../constants';
import { DeleteDialog } from '../Dialog';
import { useDispatch } from 'react-redux';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 250,
  '& .makeStyles-root-150 .MuiOutlinedInput-adornedEnd': {
    minWidth: '250px',
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 250,
  '& .makeStyles-root-150 .MuiOutlinedInput-adornedEnd': {
    minWidth: '250px',
  },
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

export const RelationshipManage = ({
  relationshipID,
  formdata = {},
  relatesToList = [],
  relatedIdentityOptions = {},
  duplicateWarning = false,
  showDeletePopup = false,
  isAdministrator = false,
  t,
  onChange,
  onBlur,
  onSave,
  onDelete,
  onCancel,
  setShowDeletePopup,
}: {
  relationshipID: number;
  formdata: any;
  relatesToList: any[];
  relatedIdentityOptions: any;
  duplicateWarning: boolean;
  showDeletePopup: boolean;
  isAdministrator: boolean;
  t: (t: any) => string;
  onChange: (e: any, row: any) => void;
  onBlur: () => void;
  onSave: (id: any) => void;
  onDelete: () => void;
  onCancel: () => void;
  setShowDeletePopup: (t: boolean) => void;
}) => {
  const theme = useTheme();
  const dispatchAPI = useDispatch();
  const handleOnChange = (event: any) => {
    if (onChange) {
      onChange(event, formdata);
    }
  };

  const handleOnBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  const handleOnDelete = () => {
    setShowDeletePopup(true);
  };

  return (
    <>
      <Box
        sx={{
          margin: '16px',
        }}
      >
        <Grid container spacing={2} direction="column">
          {isEmpty(formdata) ? (
            <Box width="100%" px={1}>
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="100%" height={20} />
            </Box>
          ) : (
            <>
              <Grid item xs={12}>
                <StyledFormControl
                  margin="none"
                  error={!!formdata.errors.relationshipName}
                >
                  <StyledFieldLabelInput
                    autoComplete="off"
                    id="relatesTo"
                    name="relatesTo"
                    label={'Relationship'}
                    size="small"
                    inlineLabel
                    value={formdata.relatesTo}
                    onChange={handleOnChange}
                    disabled={true}
                    onBlur={undefined}
                    classes={undefined}
                    error={undefined}
                    rows={5}
                    multiline
                    sx={undefined}
                  />
                </StyledFormControl>
              </Grid>

              {formdata.isAdditionalText && (
                <Grid item xs={12}>
                  <StyledFormControl
                    margin="none"
                    error={!!formdata.errors.relationshipName}
                  >
                    <StyledFieldLabelInput
                      autoComplete="off"
                      id="relationshipName"
                      name="relationshipName"
                      label={formdata.additionalText}
                      size="small"
                      inlineLabel
                      value={formdata.relationshipName}
                      onChange={handleOnChange}
                      disabled={true}
                      onBlur={undefined}
                      classes={undefined}
                      error={undefined}
                      rows={undefined}
                      sx={undefined}
                    />
                    {formdata.errors.relationshipName && (
                      <FormHelperText id="component-error-text">
                        {formdata.errors.relationshipName}
                      </FormHelperText>
                    )}
                  </StyledFormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <StyledFieldLabelInput
                  id="comment"
                  name="comment"
                  label={t('Comments')}
                  size="small"
                  inlineLabel
                  value={formdata.comment}
                  onChange={handleOnChange}
                  multiline
                  rowsMax={4}
                  rows={3}
                  onBlur={undefined}
                  classes={undefined}
                  disabled={false}
                  error={undefined}
                  sx={undefined}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledFormControl
                  margin="none"
                  error={!!formdata.errors.effectiveFromDate}
                >
                  <Datepicker
                    // @ts-ignore
                    sx={{
                      marginTop: theme.spacing(1),
                      minWidth: 250,
                      '& .makeStyles-root-150 .MuiOutlinedInput-adornedEnd': {
                        minWidth: '250px',
                      },
                    }}
                    disablePast={false}
                    id="effectivefrom"
                    inputFormat={viewDateFormat}
                    placeholder="DD/MM/YYYY"
                    variant="outlined"
                    size="small"
                    value={formdata.effectiveFromDate}
                    onChange={handleOnChange}
                    name="effectiveFromDate"
                    label={t('Effective From')}
                    inputProps={{
                      'data-testid': 'effectiveFromDate',
                      'data-name': 'effectiveFromDate',
                    }}
                  />
                  {formdata.errors.effectiveFromDate && (
                    <FormHelperText id="component-error-text">
                      {formdata.errors.effectiveFromDate}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              </Grid>
              <Grid item xs={12}>
                <StyledFormControl
                  margin="none"
                  error={!!formdata.errors.effectiveToDate}
                >
                  <Datepicker
                    // @ts-ignore
                    sx={{
                      marginTop: theme.spacing(1),
                      minWidth: 250,
                      '& .makeStyles-root-150 .MuiOutlinedInput-adornedEnd': {
                        minWidth: '250px',
                      },
                    }}
                    disablePast={false}
                    id="effectiveto"
                    inputFormat={viewDateFormat}
                    placeholder="DD/MM/YYYY"
                    variant="outlined"
                    size="small"
                    value={formdata.effectiveToDate}
                    onChange={handleOnChange}
                    name="effectiveToDate"
                    label={t('Effective To')}
                    inputProps={{
                      'data-testid': 'effectiveToDate',
                      'data-name': 'effectiveToDate',
                    }}
                  />
                </StyledFormControl>
                {formdata.errors.effectiveToDate && (
                  <FormHelperText id="component-error-text">
                    {formdata.errors.effectiveToDate}
                  </FormHelperText>
                )}
                <Grid>
                  {duplicateWarning && (
                    <Alert severity="warning">
                      You cannot create a duplicate record. Please, update
                      details or reuse the existing one.
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            spacing={2}
            sx={{
              display: 'flex',
              flex: '1',
            }}
          >
            <Box
              sx={{
                marginRight: theme.spacing(2),
              }}
            >
              <VineaButton
                color="success"
                onClick={() => {
                  onSave(relationshipID);
                }}
                variant="contained"
                minWidth={100}
                disabled={duplicateWarning || !isEmpty(formdata.errors)}
              >
                {t('Save')}
              </VineaButton>
            </Box>
            {isAdministrator && get(formdata, 'canDelete', false) === true && (
              <Box
                sx={{
                  marginRight: theme.spacing(2),
                }}
              >
                <VineaButton
                  color="error"
                  onClick={handleOnDelete}
                  variant="contained"
                  minWidth={150}
                  disabled={false}
                >
                  {t('Delete')}
                </VineaButton>
              </Box>
            )}
            <Box>
              <VineaButton
                onClick={onCancel}
                variant="outlined"
                color="secondary"
                minWidth={100}
              >
                {t('Cancel')}
              </VineaButton>
            </Box>
          </Grid>
        </Grid>
        {/* @ts-ignore */}
        <DeleteDialog
          onClose={() => setShowDeletePopup(false)}
          dialogHeader={t(`Are You Sure You Want To Delete This Relationship?`)}
          open={showDeletePopup}
          dialogActionTrigger={() => onDelete()}
        />
      </Box>
    </>
  );
};
