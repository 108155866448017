/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, { useEffect, useCallback, useState, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, isValid, isBefore } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { filter, find, get, isNil, isNull } from 'lodash';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';

import {
  getIdentityRelationshipEntityData,
  getLookupRelationship,
} from '../../../redux/selectors';
import { syncValidator } from '../../../utils/validator';
import { validDateFormat } from '../../../utils/DateUtil';
import {
  sagaActionTypes,
  apiTypes,
  actionTypes,
  reducers,
  vineaDetails,
  dateFormat,
  RelationshipTypeIDs,
} from '../../../constants';
import { types, addGenericReducer, relationshipState } from '../stateReducers';
import logger from '../../../utils/winstonLogger';
import { RelationshipSchema } from '../validations';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useUserResponsiveLayout from '../../../hooks/useUserResponsiveLayout';

const useIdRelationshipHook = (identityId, relationshipID) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // state
  const [identityRelationship, setIdentityRelationship] = useState([]);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [seqToSave, setSeqToSave] = useState(null);
  const [relatesToList, setRelatesToList] = useState([]);
  const [dependentListTrigger, setDependentListTrigger] = useState(false);
  const [formLoadingStatus, setFormLoadingStatus] = useState(false);
  const [previous, setPrevious] = useState([]);
  const [formdata, dispatch] = useReducer(addGenericReducer, {
    ...relationshipState,
    effectiveFromDate: format(new Date(), dateFormat),
  });
  const [dependentIdentityTypeID, setDependentTypeID] = useState(0);
  const [duplicateWarning, setDuplicateWarning] = useState(false);
  const [alert, setAlert] = useState('');
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [apiTrigger, setApiTrigger] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const { isAdministrator } = useUserResponsiveLayout();

  // dispatches;
  const dispatchAPI = useDispatch();

  // selectors
  const lkpRelationship = useSelector(state => getLookupRelationship(state));

  const { data: relationshipTypeData } = useSelector(
    state => state.identityRelationshipType,
  );

  const { data: identityDetailsData } = useSelector(
    state => state.identityDetails,
  );
  const {
    isLoading,
    hasError,
    isLoaded,
    data: relationshipData,
  } = useSelector(state => getIdentityRelationshipEntityData(state));
  const identityData = useSelector(state => state.identityRepository).data;

  const {
    isLoading: formLoading,
    hasError: formError,
    isLoaded: formLoaded,
    data: formWriteData,
  } = useSelector(state => state.formWrite);

  const [relatedIdentityOptions, setIdentityOptions] = useState([]);

  const [canDeleteRelationship, setCanDeleteRelationship] = useState(true);

  const {
    isLoading: isSearchLoading,
    hasError: hasSearchError,
    isLoaded: isSearchLoaded,
    error: searchError,
  } = useSelector(VineaNovaSelectors.getSearchIdentityEntityMeta);

  const searchData = useSelector(
    VineaNovaSelectors.getSearchIdentityEntityData,
  );

  const updateRelatesToList = () => {
    let count = 0;
    if (!isEmpty(relationshipTypeData)) {
      const relatesList = relationshipTypeData.map(rel => {
        count += 1;
        return {
          id: count,
          key: count,
          value: rel.relatedToDescription,
          relationshipTypeID: rel.id,
          source: rel?.source,
        };
      });
      setRelatesToList(relatesList);
    }
  };

  const constructRelationshipRows = data => {
    if (
      Array.isArray(data) &&
      !isEmpty(data) &&
      !isEmpty(lkpRelationship) &&
      !isEmpty(relationshipTypeData) &&
      !isEmpty(identityData)
    ) {
      const newRowData = data.map(f => {
        let direction =
          f?.ownerIdentityID === parseInt(identityId) ? 'Owner' : 'Dependent';

        return {
          id: f.id,
          relatesTo: f.relatesTo,
          relationshipName: f.relationshipName,
          relationshipTypeID: f.relationshipTypeID,
          relationshipFrom:
            direction === 'Owner'
              ? find(lkpRelationship, g => g.id === f.relationshipTypeID)
                  ?.ownerDescription || null
              : find(lkpRelationship, g => g.id === f.relationshipTypeID)
                  ?.dependentDescription || null,
          relationshipType:
            find(lkpRelationship, g => g.id === f.relationshipTypeID)
              ?.relationshipType || null,
          ownerIdentityID: f.ownerIdentityID,
          ownerName:
            find(identityData, g => g.id === f.ownerIdentityID)?.displayName ||
            null,
          dependentIdentityID: f.dependentIdentityID,
          dependentName:
            find(identityData, g => g.id === f.dependentIdentityID)
              ?.displayName || null,
          comment: f.comment,
          isDisplayOnPanel: f.isDisplayOnPanel,
          effectiveFromDate: f.effectiveFromDate,
          effectiveToDate: f.effectiveToDate,
          isEditMode: false,
          methodType: f.methodType || apiTypes.POST,
          isActive: f.isActive,
          direction: direction,
          isAdditionalText:
            find(lkpRelationship, g => g.id === f.relationshipTypeID)
              ?.isAdditionalText || false,
          additionalText:
            find(lkpRelationship, g => g.id === f.relationshipTypeID)
              ?.additionalText || null,
          errors: {},
          warning: null,
          ts: f.ts,
        };
      });
      return newRowData;
    }
    return [];
  };

  /** changes to the row data */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnChangeNewRowData = useCallback(
    (e, row) => {
      const validationErrors = syncValidator(RelationshipSchema)(formdata);
      dispatch({
        type: types.ERROR,
        payload: !isEmpty(validationErrors) ? validationErrors : {},
      });

      const {
        target: { name, value },
      } = e;

      const { effectiveFromDate } = row;

      setDuplicateWarning(false);

      if (name === 'relatesTo' && !isNull(value) && !isEmpty(relatesToList)) {
        setDependentListTrigger(true);
        const {
          relationshipTypeID: currentRelationshipTypeID,
          source: currentRelationshipSource,
        } = find(relatesToList, f => f.id === value);
        dispatch({
          type: types.UPDATE,
          payload: {
            relatesToID: value,
            relatesTo: find(relatesToList, f => f.id === value).value,
            relationshipTypeID: currentRelationshipTypeID,
            isAdditionalText: find(
              relationshipTypeData,
              f => f.id === currentRelationshipTypeID,
            ).isAdditionalText,
            additionalText: find(
              relationshipTypeData,
              f => f.id === currentRelationshipTypeID,
            ).additionalText,
            direction: currentRelationshipSource,
          },
        });
      } else if (name === 'relatesTo' && isNull(value)) {
        dispatch({
          type: types.UPDATE,
          payload: {
            relatesTo: '',
            relatesToID: 0,
          },
        });
      }
      if (name === 'parent' && !isNull(value)) {
        if (formdata.direction === 'Owner') {
          dispatch({
            type: types.UPDATE,
            payload: {
              ownerIdentityID: identityDetailsData.id,
              dependentIdentityID: value,
              ownerIdentityTypeID: find(
                identityData,
                f => f.id === identityDetailsData.id,
              ).identityTypeID,
              dependentIdentityTypeID: find(identityData, f => f.id === value)
                .identityTypeID,
            },
          });
        } else {
          dispatch({
            type: types.UPDATE,
            payload: {
              ownerIdentityID: value,
              ownerIdentityTypeID: find(identityData, f => f.id === value)
                .identityTypeID,
              dependentIdentityID: identityDetailsData.id,
              dependentIdentityTypeID: find(
                identityData,
                f => f.id === identityDetailsData.id,
              ).identityTypeID,
            },
          });
        }
      } else if (name === 'parent' && isNull(value)) {
        dispatch({
          type: types.UPDATE,
          payload: {
            ownerIdentityID: null,
            dependentIdentityID: null,
          },
        });
      }

      if (
        name === 'relationshipName' ||
        name === 'comment' ||
        name === 'effectiveFromDate' ||
        (name === 'effectiveToDate' && isNil(effectiveFromDate))
      ) {
        dispatch({
          type: types.UPDATE,
          payload: {
            [name]: value,
          },
        });
      }

      if (name === 'effectiveToDate' && !isNil(effectiveFromDate)) {
        const fromDate = isValid(validDateFormat(effectiveFromDate))
          ? validDateFormat(effectiveFromDate)
          : null;
        const toDate = isValid(validDateFormat(value))
          ? validDateFormat(value)
          : null;
        const isBeforeStartDate =
          fromDate && toDate && isBefore(toDate, fromDate);

        if (!isBeforeStartDate) {
          dispatch({
            type: types.UPDATE,
            payload: {
              [name]: value,
            },
          });
        }
      }
    },
    [formdata, relatesToList],
  );

  const handleOnChangeRowData = useCallback(
    (e, rowdata) => {
      const {
        target: { value, name },
      } = e;
      const { id } = rowdata;
      const isFromDateModified = name === 'effectiveFromDate';
      const isToDateModified = name === 'effectiveToDate';
      const isCommentModified = name === 'comment';
      const newRows = identityRelationship.map(row => {
        if (row.id === id) {
          if (isCommentModified) {
            return {
              ...row,
              comment: value,
            };
          }
          return {
            ...row,
            effectiveFromDate: isFromDateModified
              ? value
              : row.effectiveFromDate,
            effectiveToDate: isToDateModified ? value : row.effectiveToDate,
          };
        }
        return row;
      });
      const currentRecord = find(newRows, f => f.id === id);
      const duplicateRecord = filter(
        identityRelationship,
        f =>
          f.relatesTo === currentRecord.relatesTo &&
          f.relationshipName === currentRecord.relationshipName &&
          f.relationshipTypeID === currentRecord.relationshipTypeID &&
          f.ownerIdentityID === currentRecord.ownerIdentityID &&
          f.dependentIdentityID === currentRecord.dependentIdentityID &&
          f.comment === currentRecord.comment &&
          f.effectiveFromDate === currentRecord.effectiveFromDate &&
          f.effectiveToDate === currentRecord.effectiveToDate,
      );

      const updatedRows = newRows.map(row => {
        if (row.id === id) {
          if (!isEmpty(duplicateRecord)) {
            return {
              ...row,
              warning:
                'You cannot create a duplicate record. Please, update details or reuse the existing one.',
            };
          }
          return {
            ...row,
            warning: null,
          };
        }
        return row;
      });

      setIdentityRelationship(updatedRows);
    },
    [identityRelationship],
  );

  const onToggleEditMode = useCallback((id, rows) => {
    setPrevious(rows);

    const newRows = rows.map(row => {
      if (row.id === id) {
        return {
          ...row,
          isEditMode: !row.isEditMode,
          methodType: apiTypes.PUT,
        };
      }
      return row;
    });
    setIdentityRelationship(newRows);

    /* Business logic - cannot delete job supervisor relationships as these affect which 
     jobs supervisors can see on their tablets */
    const dataToEdit = newRows.find(f => f.id === id);
    if (
      get(dataToEdit, 'relationshipTypeID', 0) ===
      RelationshipTypeIDs.JOB_SUPERVISOR
    )
      setCanDeleteRelationship(false);
    else setCanDeleteRelationship(true);
  }, []);

  const handleRevert = useCallback(
    id => {
      let newRows;
      const currentRow = find(identityRelationship, f => f.id === id);
      if (isNull(currentRow.ts)) {
        newRows = identityRelationship.filter(f => f.id !== id);
      } else {
        newRows = identityRelationship.map(row => {
          if (row.id === id) {
            const prevState = find(previous, f => f.id === id);
            return {
              ...row,
              relatesTo: prevState.relatesTo,
              parent: prevState.contactMethodType,
              isPrimaryContact: prevState.isPrimaryContact,
              contactMethodDetails: prevState.contactMethodDetails,
              effectiveFromDate: prevState.effectiveFromDate,
              effectiveToDate: prevState.effectiveToDate,
              warning: null,
            };
          }
          return row;
        });
      }
      setIdentityRelationship(newRows);
      onToggleEditMode(id, newRows);
    },
    [identityRelationship, onToggleEditMode, previous],
  );

  const handleOnBlur = () => {
    const validationErrors = syncValidator(RelationshipSchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  const handleOnSaveRow = (id = null) => {
    const validationErrors = syncValidator(RelationshipSchema)(formdata);
    // verify validations
    if (!isEmpty(formdata.errors)) {
      logger.debug('validations errors');
    } else if (!isEmpty(validationErrors)) {
      dispatch({ type: types.ERROR, payload: validationErrors });
    } else {
      const duplicateRecord = filter(
        identityRelationship,
        f =>
          f.id !== formdata.id &&
          f.relationshipName === formdata.relationshipName &&
          f.relationshipTypeID === formdata.relationshipTypeID &&
          f.ownerIdentityID === formdata.ownerIdentityID &&
          f.dependentIdentityID === formdata.dependentIdentityID &&
          f.comment === formdata.comment &&
          f.effectiveFromDate === formdata.effectiveFromDate &&
          f.effectiveToDate === formdata.effectiveToDate,
      );
      if (isEmpty(duplicateRecord)) {
        const data = {
          ID: formdata.methodType === apiTypes.POST ? null : id,
          relatesTo: formdata.relatesTo, // TODO: remove later
          relationshipName: formdata.relationshipName,
          relationshipTypeID: formdata.relationshipTypeID,
          ownerIdentityID: formdata.ownerIdentityID,
          dependentIdentityID: formdata.dependentIdentityID,
          comment: formdata.comment,
          effectiveFromDate: formdata.effectiveFromDate,
          effectiveToDate: formdata.effectiveToDate || null,
          ts: formdata.methodType === apiTypes.POST ? null : formdata.ts,
        };
        dispatchAPI({
          type: actionTypes.clear,
          name: reducers.formWrite,
        });

        dispatchAPI({
          type: sagaActionTypes.FORM_SUBMIT,
          payload: {
            data,
            name: vineaDetails.relationship,
            methodType: formdata.methodType,
          },
        });
        setApiTrigger(true);
        if (isNull(id)) setSeqToSave(formdata.id);
        else setSeqToSave(id);
        setActionTriggered(true);
        setDuplicateWarning(false);
      } else {
        setDuplicateWarning(true);
      }
      if (formdata.methodType === apiTypes.PUT) {
        setAlert('Update');
      } else {
        setAlert('Created');
      }
    }
  };

  const handleOnDeleteRow = () => {
    if (
      showDeletePopup &&
      !isNil(get(formdata, 'id')) &&
      !isNil(get(formdata, 'ts'))
    ) {
      dispatchAPI(
        VineaNovaActions.api.v1.identityRelationship.delete.request({
          postBody: {
            id: get(formdata, 'id'),
            ts: get(formdata, 'ts'),
          },
        }),
      );
      setApiTrigger(true);
    }
  };

  const clearFormData = () => {
    dispatch({
      type: types.UPDATE,
      payload: {
        ...relationshipState,
        effectiveFromDate: format(new Date(), dateFormat),
      },
    });
  };

  // Called when the Relationship From dropdown is selected from
  useEffect(() => {
    if (!formdata) return;
    // if relationshipTypeID is not null or 0, search for related identity options
    const relationship = lkpRelationship.find(
      e => e.id === get(formdata, 'relationshipTypeID', 0),
    );
    if (!isNil(relationship)) {
      const isOwner = formdata.direction === 'Owner';
      const relatedIdentityTypeID = isOwner
        ? relationship.dependentIdentityTypeID
        : relationship.ownerIdentityTypeID;
      const relatedIdentityRoleTypeID = isOwner
        ? relationship.dependentIdentityRoleTypeID
        : relationship.ownerIdentityRoleTypeID;

      dispatchAPI(
        VineaNovaActions.api.v1.searchIdentity.post.request({
          postBody: {
            IdentityTypeID: relatedIdentityTypeID,
            RoleTypeID: relatedIdentityRoleTypeID,
            FindNonActive: false,
          },
        }),
      );
      setSearchTriggered(true);
    } else if (lkpRelationship.length === 0) {
      dispatchAPI(
        VineaNovaActions.api.v1.getLookUp.get.request({
          queryParams: {
            LookUpName: 'RelationshipType',
          },
        }),
      );
    } else {
      setIdentityOptions([]);
    }
  }, [formdata, lkpRelationship]);

  useEffect(() => {
    if (searchTriggered && !isSearchLoading && isSearchLoaded) {
      if (!hasSearchError) {
        const options = searchData
          .map(e => ({
            id: e.id,
            key: e.id,
            value: e.name,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          );

        setIdentityOptions(options);
      }

      setSearchTriggered(false);
    }
  }, [
    isSearchLoading,
    hasSearchError,
    isSearchLoaded,
    searchError,
    searchData,
    searchTriggered,
    setSearchTriggered,
    setIdentityOptions,
  ]);

  // Show snackbar once api loads
  useEffect(() => {
    if (isLoaded && !isLoading && apiTrigger) {
      setApiTrigger(false);
      if (!hasError) {
        enqueueSnackbar(t('Success'), { variant: 'success' });
        dispatchAPI(
          VineaNovaActions.api.v1.identityRelationship.get.request({
            queryParams: { IdentityID: identityId },
          }),
        );
      } else {
        enqueueSnackbar(t('Error'), { variant: 'Error' });
      }
    }
  }, [isLoading, isLoaded, apiTrigger]);

  useEffect(() => {
    if (formLoading && actionTriggered) setFormLoadingStatus(true);
    if (
      !formLoading &&
      formLoaded &&
      !isEmpty(formWriteData) &&
      !formError &&
      formLoadingStatus
    ) {
      setFormLoadingStatus(false);
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
    }
  }, [
    formLoading,
    actionTriggered,
    formLoaded,
    formWriteData,
    formError,
    formLoadingStatus,
    dispatchAPI,
  ]);

  useEffect(() => {
    if (dependentListTrigger) {
      setDependentListTrigger(false);
      setDependentTypeID(
        formdata.direction === 'Owner'
          ? find(
              relationshipTypeData,
              f => f.id === formdata.relationshipTypeID,
            ).dependentIdentityTypeID
          : find(
              relationshipTypeData,
              f => f.id === formdata.relationshipTypeID,
            ).ownerIdentityTypeID,
      );
    }
  }, [relationshipTypeData, dependentListTrigger, formdata]);

  useEffect(() => {
    updateRelatesToList();
  }, [relationshipTypeData]);

  useEffect(() => {
    if (!actionTriggered) {
      if (isLoading || hasError || !isLoaded) {
        setIdentityRelationship([]);
      } else {
        const newRows = constructRelationshipRows(relationshipData);
        setIdentityRelationship(newRows);
      }
    } else {
      if (!isLoading && !hasError && isLoaded) {
        const newRows = constructRelationshipRows(relationshipData);
        setIdentityRelationship(newRows);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipData, isLoading, hasError, isLoaded, actionTriggered]);

  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.identityRelationship.get.request({
        queryParams: {
          IdentityID: identityId,
        },
      }),
    );
  }, [identityId, actionTriggered]);

  // Set formdata based on selected relationship
  useEffect(() => {
    const currentRelationship = find(
      relationshipData,
      f => f.id === relationshipID,
    );
    if (!isNil(currentRelationship)) {
      const direction =
        currentRelationship?.ownerIdentityID === parseInt(identityId)
          ? 'Owner'
          : 'Dependent';

      const relatesToID = find(
        relatesToList,
        f =>
          f.relationshipTypeID === currentRelationship.relationshipTypeID &&
          f.source === direction,
      )?.id;

      const canDelete =
        currentRelationship.relationshipTypeID ===
        RelationshipTypeIDs.JOB_SUPERVISOR
          ? false
          : true;

      dispatch({
        type: types.UPDATE,
        payload: {
          ...currentRelationship,
          relatesToID: relatesToID,
          methodType: apiTypes.PUT,
          canDelete: canDelete,
        },
      });
    }
  }, [relationshipID, relationshipData]);

  return {
    isLoadingIdRelationship: isLoading,
    isLoadedIdRelationship: isLoaded,
    hasErrorIdRelationship: hasError,
    dependentIdentityTypeID,
    identityData,
    actionTriggered,
    seqToSave,
    identityRelationship,
    alert,
    relatesToList,
    formdata,
    lkpRelationship,
    duplicateWarning,
    relatedIdentityOptions,
    canDeleteRelationship,
    showDeletePopup,
    isAdministrator,
    setSeqToSave,
    setActionTriggered,
    handleOnChangeRowData,
    handleOnBlur,
    handleOnSaveRow,
    handleOnDeleteRow,
    handleOnChangeNewRowData,
    onToggleEditMode,
    handleRevert,
    dispatch,
    setShowDeletePopup,
    clearFormData,
  };
};

export { useIdRelationshipHook };
