import { useSelector } from 'react-redux';
import {
  VineaHooks,
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { MetaDataType } from '../../Jobs/interfaces/jobInterfaces';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

export const UseFetchDataHook = () => {
  const dispatchAPI = useDispatch();

  const { data: lkpImportType } = useSelector(
    (state: any) => state.entities.lookupImportType,
  );

  const {
    isLoaded: lkpImportTypeLoaded,
    isLoading: lkpImportTypeLoading,
    hasError: lkpImportTypeHasError,
  } = useSelector(
    VineaNovaSelectors.getlookupImportTypeEntityMeta,
  ) as MetaDataType;

  const lkpVariety = useSelector(VineaNovaSelectors.getlookupVarietyEntityData);
  const {
    isLoaded: lkpVarietyLoaded,
    isLoading: lkpVarietyLoading,
    hasError: lkpVarietyHasError,
  } = useSelector(
    VineaNovaSelectors.getlookupVarietyEntityMeta,
  ) as MetaDataType;

  const lkpVintage = useSelector(VineaNovaSelectors.getlookupVintageEntityData);
  const {
    isLoaded: lkpVintageLoaded,
    isLoading: lkpVintageLoading,
    hasError: lkpVintageHasError,
  } = useSelector(
    VineaNovaSelectors.getlookupVintageEntityMeta,
  ) as MetaDataType;

  const lkpSpecialAttribute = useSelector(
    VineaNovaSelectors.getlookupRowSpecialAttributeEntityData,
  );
  const {
    isLoaded: lkpSpecialAttributeLoaded,
    isLoading: lkpSpecialAttributeLoading,
    hasError: lkpSpecialAttributeHasError,
  } = useSelector(
    VineaNovaSelectors.getlookupRowSpecialAttributeEntityMeta,
  ) as MetaDataType;

  const lkpMeasureInstance = useSelector(
    VineaNovaSelectors.getlookupMeasureInstanceEntityData,
  );
  const {
    isLoaded: lkpMeasureInstanceLoaded,
    isLoading: lkpMeasureInstanceLoading,
    hasError: lkpMeasureInstanceHasError,
  } = useSelector(
    VineaNovaSelectors.getlookupMeasureInstanceEntityMeta,
  ) as MetaDataType;

  const importFilters = useSelector(
    VineaNovaSelectors.getImportTypeFiltersEntityData,
  );
  const {
    isLoaded: importFiltersLoaded,
    isLoading: importFiltersLoading,
    hasError: importFiltersHasError,
  } = useSelector(
    VineaNovaSelectors.getImportTypeFiltersEntityMeta,
  ) as MetaDataType;

  const dataLoading =
    lkpImportTypeLoading ||
    lkpVarietyLoading ||
    lkpSpecialAttributeLoading ||
    lkpMeasureInstanceLoading ||
    importFiltersLoading;

  useEffect(() => {
    if (
      isEmpty(lkpImportType) &&
      !lkpImportTypeLoaded &&
      !lkpImportTypeLoading &&
      !lkpImportTypeHasError
    ) {
      dispatchAPI(VineaNovaActions.api.v1.lookupImportType.get.request());
    }
  }, [
    lkpImportType,
    lkpImportTypeLoaded,
    lkpImportTypeLoading,
    lkpImportTypeHasError,
  ]);

  useEffect(() => {
    if (
      isEmpty(lkpVariety) &&
      !lkpVarietyLoaded &&
      !lkpVarietyLoading &&
      !lkpVarietyHasError
    )
      dispatchAPI(VineaNovaActions.api.v1.lookupVariety.get.request());
  }, [lkpVariety, lkpVarietyLoaded, lkpVarietyLoading, lkpVarietyHasError]);

  useEffect(() => {
    if (
      isEmpty(lkpVintage) &&
      !lkpVintageLoaded &&
      !lkpVintageLoading &&
      !lkpVintageHasError
    )
      dispatchAPI(VineaNovaActions.api.v1.lookupVintage.get.request());
  }, [lkpVintage, lkpVintageLoaded, lkpVintageLoading, lkpVintageHasError]);

  useEffect(() => {
    if (
      isEmpty(lkpSpecialAttribute) &&
      !lkpSpecialAttributeLoaded &&
      !lkpSpecialAttributeLoading &&
      !lkpSpecialAttributeHasError
    )
      dispatchAPI(
        VineaNovaActions.api.v1.lookupRowSpecialAttribute.get.request(),
      );
  }, [
    lkpSpecialAttribute,
    lkpSpecialAttributeLoaded,
    lkpSpecialAttributeLoading,
    lkpSpecialAttributeHasError,
  ]);

  useEffect(() => {
    if (
      isEmpty(lkpMeasureInstance) &&
      !lkpMeasureInstanceLoaded &&
      !lkpMeasureInstanceLoading &&
      !lkpMeasureInstanceHasError
    )
      dispatchAPI(VineaNovaActions.api.v1.lookupMeasureInstance.get.request());
  }, [
    lkpMeasureInstance,
    lkpMeasureInstanceLoaded,
    lkpMeasureInstanceLoading,
    lkpMeasureInstanceHasError,
  ]);

  useEffect(() => {
    if (
      isEmpty(importFilters) &&
      !importFiltersLoaded &&
      !importFiltersLoading &&
      !importFiltersHasError
    )
      dispatchAPI(VineaNovaActions.api.v1.importTypeFilters.get.request({}));
  }, [
    importFilters,
    importFiltersLoaded,
    importFiltersLoading,
    importFiltersHasError,
  ]);

  return {
    dataLoading,
    lkpImportType,
    lkpVariety,
    lkpVintage,
    lkpSpecialAttribute,
    lkpMeasureInstance,
    importFilters,
  };
};
