/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */

// eslint-disable-next-line import/extensions
import '../../globals';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

// Report Viewer source
import '@boldreports/javascript-reporting-controls/Content/v2.0/tailwind-light/bold.report-viewer.min.css';
import '@boldreports/javascript-reporting-controls/Scripts/v2.0/common/bold.reports.common.min';
import '@boldreports/javascript-reporting-controls/Scripts/v2.0/common/bold.reports.widgets.min';
import '@boldreports/javascript-reporting-controls/Scripts/v2.0/bold.report-viewer.min';
// Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';
import { VineaButton } from '../../components/VineaButton';
import { Box } from '@mui/material';

const viewerStyle = { height: '700px', width: '90%' };

const StyledGridItem = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
}));

type BoldReportViewerProps = {
  reportPath: string;
  reportServerURL: string;
  reportServiceURL: string;
  token: string;
  showViewer: (closed: boolean) => void;
};

export const BoldReportViewer = ({
  reportPath,
  reportServerURL,
  reportServiceURL,
  token,
  showViewer,
}: BoldReportViewerProps) => {
  return (
    <Grid
      container
      spacing={0}
      sx={{
        margin: 0,
        width: '99%',
      }}
    >
      <StyledGridItem item xs={12} sm={12}>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          marginBottom={4}
        >
          <VineaButton
            color="secondary"
            onClick={() => showViewer(false)}
            variant="outlined"
            minWidth={150}
          >
            {'Close'}
          </VineaButton>
        </Box>

        <Grid container spacing={2}>
          <div id="viewer" style={viewerStyle}>
            {/* @ts-ignore */}
            <BoldReportViewerComponent
              id="reportviewer-container"
              reportPath={reportPath}
              reportServerUrl={reportServerURL}
              reportServiceUrl={reportServiceURL}
              serviceAuthorizationToken={token}
            />
          </div>
        </Grid>
      </StyledGridItem>
    </Grid>
  );
};
