import Grid from '@mui/material/Grid';
import { ErrorBoundary } from 'react-error-boundary';
import { filter, isNil, map } from 'lodash';
import { VineaCommonGrid } from '../../components/Grid';
import {
  IdentityDrawerTypes,
  IdentityTypeIds,
  commonGridHeaderNames,
  viewDateFormat,
} from '../../constants';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { format, parseISO } from 'date-fns';

export const OverviewGrids = ({
  t,
  identityId = 0,
  handleOperation,
  isSubDetailsLoading,
  contactData = [],
  classifications = [],
  identityRefData = [],
  identityRelationshipData = [],
  identityTypeId = [],
  supplyContractBlocks = [],
  supplyContractBlocksColumnHeaders,
  jobBlocksColumnHeaders,
  isWorkerRole = false,
  isWorkerOverviewTab = false,
  workerPayPeriods,
  isOverviewTab = false,
  jobSupervisorData = [],
  jobEventData = [],
  jobActivitiesData = [],
  jobBlocksData = [],
  onRowClick = () => {},
  handleOpenAddDrawer = () => {},
  handleEditSupplyContractBlock = () => {},
  handleEditJobSupervisor = () => {},
  handleOnOpenWorkerWorkRecords = () => {},
  handleManageJobBlockRows = () => {},
  onManageRelationship = () => {},
}) => {
  const jobBlocksMapData = !Array.isArray(jobBlocksData)
    ? [jobBlocksData]
    : jobBlocksData;

  const modifiedjobActivitiesData = filter(jobActivitiesData, {
    isLatest: true,
  });

  const formattedWorkerPayPeriods = map(workerPayPeriods, workerPayPeriod => {
    return {
      ...workerPayPeriod,
      payPeriod: !isNil(workerPayPeriod?.payPeriod)
        ? format(parseISO(workerPayPeriod?.payPeriod), viewDateFormat)
        : '',
    };
  });

  return (
    <Grid
      container
      spacing={0.5}
      sx={{
        margin: 2,
        height: '98%',
        width: '98%',
        flex: 0,
      }}
      gap={0}
      data-testid="grid-content-details"
    >
      {identityTypeId === IdentityTypeIds.JOB && !isOverviewTab && (
        <>
          <Grid container spacing={0.5} data-testid="grid-row2" gap={0}>
            <Grid container item xs={6} direction="column" spacing={0.5}>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                data-testid="gridreferences"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    heading={t('References')}
                    manage={() => handleOperation('REFERENCES')}
                    colHeaders={['Reference', 'Value', 'From - To']}
                    isLoading={!!isSubDetailsLoading}
                    tableData={identityRefData.data}
                  />
                </ErrorBoundary>
              </Grid>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                data-testid="gridreferences"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    heading={t('Classifications')}
                    colHeaders={['Group', 'Name', 'From - To']}
                    tableData={classifications.data}
                    manage={() => handleOperation('CLASSIFICATIONS')}
                    isLoading={!!isSubDetailsLoading}
                  />
                </ErrorBoundary>
              </Grid>
            </Grid>
            <Grid container item xs={6} direction="column" spacing={0.5}>
              <Grid
                container
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                data-testid="gridrelationship"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    heading={t('Relationships')}
                    colHeaders={[t('Relates To'), t('Comment'), t('From - To')]}
                    isLoading={!!isSubDetailsLoading}
                    tableData={identityRelationshipData.data}
                    buttonText="Add Relationship"
                    manage={() =>
                      handleOpenAddDrawer(IdentityDrawerTypes.RELATIONSHIPS)
                    }
                    onRowClick={onManageRelationship}
                    identityId={identityId}
                    identityTypeId={identityTypeId}
                  />
                </ErrorBoundary>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {identityTypeId === IdentityTypeIds.JOB && isOverviewTab && (
        <>
          <Grid container spacing={0.5} data-testid="grid-row2" gap={0}>
            <Grid container item xs={6} direction="column" spacing={0.5}>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                data-testid="JOB_ACTIVITIES"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    heading={t('Job Activities')}
                    manage={() =>
                      handleOpenAddDrawer(IdentityDrawerTypes.ADD_JOB_ACTIVITY)
                    }
                    colHeaders={[
                      'Code',
                      'Description',
                      'Row Attribute',
                      'Contracted Rate',
                      'Payroll Rate',
                      'Default',
                    ]}
                    isLoading={!!isSubDetailsLoading}
                    tableData={modifiedjobActivitiesData}
                    hasManageOption
                    hasActiveToggle={false}
                    buttonText={t('Add Activity')}
                    onRowClick={onRowClick}
                    identityId={identityId}
                    identityTypeId={identityTypeId}
                  />
                </ErrorBoundary>
              </Grid>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                data-testid="contracted-blocks"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    heading={t(commonGridHeaderNames.JOB_BLOCKS)}
                    colHeaders={jobBlocksColumnHeaders}
                    tableData={jobBlocksMapData}
                    onRowClick={handleManageJobBlockRows}
                    manage={() =>
                      handleOpenAddDrawer(IdentityDrawerTypes.JOB_BLOCK_EDIT)
                    }
                    isLoading={!!isSubDetailsLoading}
                    hasManageOption={true}
                    identityTypeId={identityTypeId}
                    buttonText={t('Edit Blocks')}
                    identityId={identityId}
                  />
                </ErrorBoundary>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={6}
              direction="column"
              spacing={0.5}
              gap={0}
            >
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                data-testid="JOB_SUPERVISORS"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    identityTypeId={identityTypeId}
                    heading={t(commonGridHeaderNames.JOB_SUPERVISORS)}
                    buttonText={t('Add Supervisor')}
                    manage={() =>
                      handleOpenAddDrawer(IdentityDrawerTypes.JOB_SUPERVISOR)
                    }
                    onRowClick={handleEditJobSupervisor}
                    colHeaders={[
                      'Supervisor',
                      'Effective From',
                      'Effective To',
                    ]}
                    isLoading={!!isSubDetailsLoading}
                    tableData={jobSupervisorData}
                    identityId={identityId}
                  />
                </ErrorBoundary>
              </Grid>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                data-testid="job-events"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    heading={t(commonGridHeaderNames.JOB_EVENTS)}
                    colHeaders={['Action', 'On']}
                    tableData={jobEventData}
                    manage={() => handleOperation('JOB_EVENTS')}
                    isLoading={!!isSubDetailsLoading}
                    hasManageOption={false}
                    hasActiveToggle={false}
                    identityTypeId={IdentityTypeIds.JOB}
                    identityId={identityId}
                  />
                </ErrorBoundary>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {identityTypeId === IdentityTypeIds.SUPPLY_CONTRACT && (
        <>
          <Grid container spacing={0.5}>
            <Grid item xs={6} direction="column">
              <Grid data-testid="gridsupplycontractoverview">
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    heading={t(commonGridHeaderNames.SUPPLY_CONTRACT_BLOCKS)}
                    colHeaders={supplyContractBlocksColumnHeaders}
                    buttonText={t('Add Block')}
                    isLoading={!!isSubDetailsLoading}
                    tableData={supplyContractBlocks}
                    onRowClick={handleEditSupplyContractBlock}
                    manage={() =>
                      handleOpenAddDrawer(
                        IdentityDrawerTypes.SUPPLY_CONTRACT_BLOCK_ADD,
                      )
                    }
                    tableHeight={500}
                    identityId={identityId}
                    identityTypeId={identityTypeId}
                  />
                </ErrorBoundary>
              </Grid>
            </Grid>
            <Grid container item xs={6} direction="column" spacing={0.5}>
              <Grid item data-testid="gridclassification">
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    heading={t('Classifications')}
                    colHeaders={['Group', 'Name', 'From - To']}
                    tableData={classifications.data}
                    manage={() => handleOperation('CLASSIFICATIONS')}
                    isLoading={!!isSubDetailsLoading}
                    identityId={identityId}
                    identityTypeId={identityTypeId}
                  />
                </ErrorBoundary>
              </Grid>

              <Grid item data-testid="gridreferences">
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    heading={t('References')}
                    manage={() => handleOperation('REFERENCES')}
                    colHeaders={['Reference', 'Value', 'From - To']}
                    isLoading={!!isSubDetailsLoading}
                    tableData={identityRefData.data}
                    identityId={identityId}
                    identityTypeId={identityTypeId}
                  />
                </ErrorBoundary>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {identityTypeId === IdentityTypeIds.CONSUMABLE && (
        <>
          <Grid item xs={12}>
            <Grid container spacing={0.5} data-testid="grid-row1">
              <Grid item xs={12} sm={6} lg={6} data-testid="gridclassification">
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    heading={t('Classifications')}
                    colHeaders={['Group', 'Name', 'From - To']}
                    tableData={classifications.data}
                    manage={() => handleOperation('CLASSIFICATIONS')}
                    isLoading={!!isSubDetailsLoading}
                    identityId={identityId}
                    identityTypeId={identityTypeId}
                  />
                </ErrorBoundary>
              </Grid>
              <>
                <Grid item xs={12} sm={6} lg={6} data-testid="gridreferences">
                  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                    <VineaCommonGrid
                      heading={t('References')}
                      manage={() => handleOperation('REFERENCES')}
                      colHeaders={['Reference', 'Value', 'From - To']}
                      isLoading={!!isSubDetailsLoading}
                      tableData={identityRefData.data}
                      identityId={identityId}
                      identityTypeId={identityTypeId}
                    />
                  </ErrorBoundary>
                </Grid>
              </>
            </Grid>
          </Grid>
          <Grid item xs={12} data-testid="grid-row2">
            <Grid container spacing={0.5}>
              <Grid item xs={12} sm={6} lg={6} data-testid="gridrelationship">
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    heading={t('Relationships')}
                    colHeaders={[t('Relates To'), t('Comment'), t('From - To')]}
                    isLoading={!!isSubDetailsLoading}
                    tableData={identityRelationshipData.data}
                    buttonText="Add Relationship"
                    manage={() =>
                      handleOpenAddDrawer(IdentityDrawerTypes.RELATIONSHIPS)
                    }
                    onRowClick={onManageRelationship}
                    identityId={identityId}
                    identityTypeId={identityTypeId}
                  />
                </ErrorBoundary>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {identityTypeId !== IdentityTypeIds.DATA_CONNECTION &&
        identityTypeId !== IdentityTypeIds.CONSUMABLE &&
        identityTypeId !== IdentityTypeIds.SUPPLY_CONTRACT &&
        identityTypeId !== IdentityTypeIds.JOB &&
        !isWorkerRole && (
          <>
            <Grid item xs={12} gap={0}>
              <Grid container spacing={0.5} data-testid="grid-row1" gap={0}>
                <Grid item xs={12} sm={6} lg={6} data-testid="contactinfo">
                  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                    <VineaCommonGrid
                      heading={t('Contact Information')}
                      colHeaders={['Type', 'Value', 'From - To']}
                      manage={() => handleOperation('CONTACTS')}
                      isLoading={!!isSubDetailsLoading}
                      tableData={contactData}
                      identityId={identityId}
                      identityTypeId={identityTypeId}
                    />
                  </ErrorBoundary>
                </Grid>
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={6}
                    data-testid="gridclassification"
                  >
                    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                      <VineaCommonGrid
                        heading={t('Classifications')}
                        colHeaders={['Group', 'Name', 'From - To']}
                        tableData={classifications.data}
                        manage={() => handleOperation('CLASSIFICATIONS')}
                        isLoading={!!isSubDetailsLoading}
                        identityId={identityId}
                        identityTypeId={identityTypeId}
                      />
                    </ErrorBoundary>
                  </Grid>
                </>
              </Grid>
            </Grid>
            <Grid item xs={12} data-testid="grid-row2">
              <Grid container spacing={0.5}>
                <Grid item xs={12} sm={6} lg={6} data-testid="gridreferences">
                  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                    <VineaCommonGrid
                      heading={t('References')}
                      manage={() => handleOperation('REFERENCES')}
                      colHeaders={['Reference', 'Value', 'From - To']}
                      isLoading={!!isSubDetailsLoading}
                      tableData={identityRefData.data}
                      identityId={identityId}
                      identityTypeId={identityTypeId}
                    />
                  </ErrorBoundary>
                </Grid>
                <Grid item xs={12} sm={6} lg={6} data-testid="gridrelationship">
                  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                    <VineaCommonGrid
                      heading={t('Relationships')}
                      colHeaders={[
                        t('Relates To'),
                        t('Comment'),
                        t('From - To'),
                      ]}
                      isLoading={!!isSubDetailsLoading}
                      tableData={identityRelationshipData.data}
                      buttonText="Add Relationship"
                      manage={() =>
                        handleOpenAddDrawer(IdentityDrawerTypes.RELATIONSHIPS)
                      }
                      onRowClick={onManageRelationship}
                      identityId={identityId}
                      identityTypeId={identityTypeId}
                    />
                  </ErrorBoundary>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      {identityTypeId === IdentityTypeIds.DATA_CONNECTION ||
        (identityTypeId === IdentityTypeIds.PERSON &&
          isWorkerRole &&
          !isWorkerOverviewTab && (
            <Grid
              container
              spacing={0.5}
              data-testid="dataconn-person-more-grid"
              gap={0}
            >
              <Grid
                item
                xs={12}
                sm={6}
                lg={4}
                data-testid="contactinfo-grid-dataconn-person"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    data-testid="contactinfo-vineacommongrid-dataconn-person"
                    heading={t('Contact Information')}
                    colHeaders={['Type', 'Value', 'From - To']}
                    manage={() => handleOperation('CONTACTS')}
                    isLoading={!!isSubDetailsLoading}
                    tableData={contactData}
                    identityId={identityId}
                    identityTypeId={identityTypeId}
                  />
                </ErrorBoundary>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                lg={8}
                data-testid="relationship-grid-dataconn-person"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <VineaCommonGrid
                    data-testid="relationship-vineacommongrid-dataconn-person"
                    heading={t('Relationships')}
                    colHeaders={[t('Relates To'), t('Comment'), t('From - To')]}
                    isLoading={!!isSubDetailsLoading}
                    tableData={identityRelationshipData.data}
                    buttonText="Add Relationship"
                    manage={() =>
                      handleOpenAddDrawer(IdentityDrawerTypes.RELATIONSHIPS)
                    }
                    onRowClick={onManageRelationship}
                    identityId={identityId}
                    identityTypeId={identityTypeId}
                  />
                </ErrorBoundary>
              </Grid>
            </Grid>
          ))}
      {identityTypeId === IdentityTypeIds.PERSON &&
        isWorkerRole &&
        isWorkerOverviewTab && (
          <>
            <Grid container spacing={0.5}>
              <Grid item xs={6} direction="column">
                <Grid data-testid="gridworkeroverview">
                  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                    <VineaCommonGrid
                      heading={t(commonGridHeaderNames.WORKER_PAY_PERIODS)}
                      colHeaders={[
                        t('Payroll Status'),
                        t('Records'),
                        t('Pay Period'),
                      ]}
                      isLoading={!!isSubDetailsLoading}
                      tableData={formattedWorkerPayPeriods}
                      onRowClick={handleOnOpenWorkerWorkRecords}
                      hasManageOption={false}
                      tableHeight={500}
                      identityId={identityId}
                      identityTypeId={identityTypeId}
                    />
                  </ErrorBoundary>
                </Grid>
              </Grid>
              <Grid container item xs={6} direction="column" spacing={0.5}>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  data-testid="gridclassification"
                >
                  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                    <VineaCommonGrid
                      heading={t('Classifications')}
                      colHeaders={['Group', 'Name', 'From - To']}
                      tableData={classifications.data}
                      manage={() => handleOperation('CLASSIFICATIONS')}
                      isLoading={!!isSubDetailsLoading}
                      identityId={identityId}
                      identityTypeId={identityTypeId}
                    />
                  </ErrorBoundary>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  data-testid="gridreferences"
                >
                  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                    <VineaCommonGrid
                      heading={t('References')}
                      manage={() => handleOperation('REFERENCES')}
                      colHeaders={['Reference', 'Value', 'From - To']}
                      isLoading={!!isSubDetailsLoading}
                      tableData={identityRefData.data}
                      identityId={identityId}
                      identityTypeId={identityTypeId}
                    />
                  </ErrorBoundary>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
    </Grid>
  );
};
