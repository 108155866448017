import {
  filter,
  find,
  forEach,
  get,
  isEmpty,
  map,
  round,
  set,
  sortBy,
  sumBy,
} from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  VineaNovaSelectors,
  VineaNovaActions,
} from 'vineanova-redux-artifacts';
import {
  BlockVintageStatusIDs,
  commonFieldSelectOption,
  CostAllocationFactorIDs,
} from '../../../constants';

type FormType = {
  costAllocationFactorID: number;
  selectedBlocks: number[];
};

type CostAllocationProps = {
  currentBlockVintage: any;
};

export const useBlockCostAllocation = ({
  currentBlockVintage,
}: CostAllocationProps) => {
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [apiTrigger, setApiTrigger] = useState(false);
  const [form, setForm] = useState<FormType>({
    costAllocationFactorID: currentBlockVintage?.costAllocationFactorID || 0,
    selectedBlocks: [],
  });
  const [blockAllocationsData, setBlockAllocationsData] = useState<any[]>([]);
  const [excludedblockAllocationsData, setExcludedBlockAllocationsData] =
    useState<any[]>([]);

  const fieldAllocationMap = {
    [CostAllocationFactorIDs.PLANTED_AREA]: 'plantedArea',
    [CostAllocationFactorIDs.PLANTED_VINES]: 'plantedVines',
    [CostAllocationFactorIDs.ACTUAL_VINES]: 'actualUnits',
    [CostAllocationFactorIDs.ROW_KMS]: 'summedRowsLength',
  };

  const { data: lkpCostAllocationData, isLoading: lkpCostAllocationIsLoading } =
    useSelector(VineaNovaSelectors.getlookupCostAllocationFactorEntity) as any;

  const {
    data: existingAllocationData,
    isLoading: existingAllocationIsLoading,
    isLoaded: existingAllocationIsLoaded,
    hasError: existingAllocationHasError,
  } = useSelector(
    VineaNovaSelectors.getAdHocBlockVintageAllocationEntity,
  ) as any;
  const initalAllocationIDs = useMemo(() => {
    return map(
      filter(existingAllocationData, (e: any) => {
        return e?.allocationPercent !== null;
      }),
      'id',
    );
  }, [existingAllocationData]);

  const pageLoading = lkpCostAllocationIsLoading || existingAllocationIsLoading;

  // Business logic - at least two blocks must be selected
  const canSave = useMemo(() => {
    return form.selectedBlocks.length !== 1 && form.costAllocationFactorID > 0;
  }, [form, initalAllocationIDs]);

  // Default to HA
  const allocationFactorUnit = useMemo(() => {
    if (
      form?.costAllocationFactorID === CostAllocationFactorIDs.PLANTED_VINES ||
      form?.costAllocationFactorID === CostAllocationFactorIDs.ACTUAL_VINES
    )
      return 'Vines';
    else if (form?.costAllocationFactorID === CostAllocationFactorIDs.ROW_KMS)
      return 'Kms';
    else return 'Ha';
  }, [form?.costAllocationFactorID]);

  const costAllocationOptions = useMemo(() => {
    const selectOption = commonFieldSelectOption;
    if (lkpCostAllocationData.length === 0) {
      return selectOption;
    } else {
      const options = selectOption.concat(
        map(lkpCostAllocationData, (c: any) => ({
          id: c.id,
          key: c.id,
          value: c.costAllocationFactor,
        })),
      );
      return options;
    }
  }, [lkpCostAllocationData.length]);

  const checkBoxDataColumns = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
    },
    {
      field: 'blockFullName',
      headerName: 'Vineyard Blocks',
      minwidth: '150px',
      flex: 0.3,
      resizable: false,
      hideable: false,
    },
    {
      field: 'allocationFactorUnitValue',
      headerName: `${allocationFactorUnit}`,
      minwidth: '100px',
      flex: 0.3,
      resizable: false,
      hideable: false,
    },
    {
      field: 'allocationPercentDisplay',
      headerName: 'Allocation',
      minwidth: '100px',
      flex: 0.3,
      resizable: false,
      hideable: false,
    },
  ];

  const checkBoxGridData = {
    columns: checkBoxDataColumns,
    rows: blockAllocationsData,
  };

  const handleOnFormChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = evt;

    setForm({ ...form, [name]: value });

    calculateUpdatedBlockAllocations(Number(value), form.selectedBlocks);
  };

  const handleOnBlockSelectionChange = (selectedBlocks: number[]) => {
    setForm({
      ...form,
      selectedBlocks,
    });

    calculateUpdatedBlockAllocations(
      form.costAllocationFactorID,
      selectedBlocks,
    );
  };

  const calculateInitialBlockAllocations = useCallback(() => {
    if (!existingAllocationIsLoading && !isEmpty(existingAllocationData)) {
      // Get only non adhoc blocks
      const nonAdHocBlocks = filter(existingAllocationData, (e: any) => {
        return e?.blockVintageStatusID !== BlockVintageStatusIDs.ADHOC;
      });
      const existingSelectedBlocks: number[] = [];

      // Set existing blocks allocations
      const allocations = map(nonAdHocBlocks, (allocation: any) => {
        const unitProperty =
          fieldAllocationMap[CostAllocationFactorIDs.PLANTED_AREA];
        if (allocation?.allocationPercent !== null) {
          existingSelectedBlocks.push(allocation.id);
          const blockAllocation = `${parseFloat(
            (allocation.allocationPercent * 100).toFixed(2),
          )}%`;
          set(allocation, 'allocationPercentDisplay', blockAllocation);
          set(
            allocation,
            'allocationFactorUnitValue',
            get(allocation, unitProperty),
          );
        }

        return allocation;
      });

      const { validBlocks, invalidBlocks } =
        calculateValidAndInvalidBlocks(allocations);

      setBlockAllocationsData(validBlocks);
      setExcludedBlockAllocationsData(invalidBlocks);
      setForm(prev => ({ ...prev, selectedBlocks: existingSelectedBlocks }));
    }
  }, [existingAllocationData, form]);

  const calculateUpdatedBlockAllocations = useCallback(
    (costAllocationFactorID: number, selectedBlocks: number[]) => {
      // If no blocks selected, reset to intial state, else calculate allocation percentages
      if (costAllocationFactorID > 0 && !isEmpty(blockAllocationsData)) {
        const invalidBlockNames: string[] = [];
        // Get sum of selected blocks units
        const selectedBlocksData = filter(
          blockAllocationsData,
          (block: any) => {
            return selectedBlocks.includes(block?.id);
          },
        );
        const totalUnits = Number(
          sumBy(selectedBlocksData, fieldAllocationMap[costAllocationFactorID]),
        );

        // Calculate allocation percentage of block based on totalUnits
        const blocksDataAllocated = map(blockAllocationsData, (block: any) => {
          return calculateBlockAllocationPercent(
            costAllocationFactorID,
            selectedBlocks,
            totalUnits,
            block,
            invalidBlockNames,
          );
        });
        if (invalidBlockNames.length > 0) {
          enqueueSnackbar(
            `${invalidBlockNames.join(', ')} has no ${
              find(costAllocationOptions, {
                id: costAllocationFactorID,
              })?.value
            }`,
            {
              variant: 'warning',
            },
          );
        }

        setBlockAllocationsData(blocksDataAllocated);
      }
    },
    [costAllocationOptions, blockAllocationsData, form],
  );

  const calculateBlockAllocationPercent = useCallback(
    (
      costAllocationFactorID: number,
      selectedBlocks: number[],
      totalUnits: number,
      block: any,
      invalidBlockNames: string[],
    ) => {
      if (!selectedBlocks.includes(block.id)) {
        return {
          ...block,
          allocationFactorUnitValue: null,
          allocationPercent: null,
          allocationPercentDisplay: null,
        };
      } else {
        const val = get(block, fieldAllocationMap[costAllocationFactorID], 0);

        if (val > 0) {
          const allocationPercentValue = Number(val) / totalUnits;

          const blockAllocation = `${parseFloat(
            (allocationPercentValue * 100).toFixed(2),
          )}%`;
          const blockallocationValue = round(Number(val), 2);
          return {
            ...block,
            allocationFactorUnitValue: blockallocationValue,
            allocationPercentDisplay: blockAllocation,
            allocationPercent: allocationPercentValue,
          };
        } else {
          invalidBlockNames.push(block.blockFullName);
          return block;
        }
      }
    },
    [],
  );

  const calculateValidAndInvalidBlocks = useCallback(
    (allBlockAllocationsData: any[]) => {
      const validBlocks: any[] = [];
      const invalidBlocks: any[] = [];

      forEach(allBlockAllocationsData, (block: any) => {
        if (
          get(block, fieldAllocationMap[form?.costAllocationFactorID], 0) > 0
        ) {
          validBlocks.push(block);
        } else {
          invalidBlocks.push({
            ...block,
            allocationFactorUnitValue: null,
            allocationPercentDisplay: null,
          });
        }
      });

      const orderedValidBlocks = sortBy(validBlocks, 'blockFullName');
      return { validBlocks: orderedValidBlocks, invalidBlocks };
    },
    [form, blockAllocationsData, excludedblockAllocationsData],
  );

  const filterBlocksToSelect = useCallback(() => {
    const allBlockAllocationsData = blockAllocationsData.concat(
      excludedblockAllocationsData,
    );
    if (form?.costAllocationFactorID > 0) {
      const { validBlocks, invalidBlocks } = calculateValidAndInvalidBlocks(
        allBlockAllocationsData,
      );
      setBlockAllocationsData(validBlocks);
      setExcludedBlockAllocationsData(invalidBlocks);

      const validBlockIDsToSelect = map(validBlocks, 'id');
      const validSelectedBlocks = filter(
        form.selectedBlocks,
        (blockID: number) => {
          return validBlockIDsToSelect.includes(blockID);
        },
      );
      setForm({ ...form, selectedBlocks: validSelectedBlocks });
    } else {
      setBlockAllocationsData(allBlockAllocationsData);
    }
  }, [blockAllocationsData, excludedblockAllocationsData, form]);

  const handleOnSave = useCallback(() => {
    // Get all intial allocations that are no longer selected
    const allocationsToDelete: any[] = filter(
      existingAllocationData,
      (e: any) => {
        return (
          !form.selectedBlocks.includes(e.id) && e?.allocationPercent !== null
        );
      },
    );

    const allocationIDsToDelete = map(
      allocationsToDelete,
      'childBlockVintageID',
    ).join(',');

    const selectedAllocations = filter(blockAllocationsData, (b: any) => {
      return form.selectedBlocks.includes(b.id);
    });

    const allocationsToUpdate: any[] = map(selectedAllocations, (s: any) => {
      return {
        blockVintageAllocationID: s?.blockVintageAllocationID,
        childBlockVintageID: s.childBlockVintageID,
        allocationPercent: s.allocationPercent,
        ts: s?.ts,
      };
    });

    setApiTrigger(true);
    dispatchAPI(
      VineaNovaActions.api.v1.adHocBlockVintageAllocation.put.request({
        postBody: {
          costAllocationFactorID: form.costAllocationFactorID,
          adhocBlockVintageID: currentBlockVintage.id,
          childBlockVintageIDsToDelete: allocationIDsToDelete,
          blockAllocations: allocationsToUpdate,
        },
      }),
    );
  }, [existingAllocationData, form, blockAllocationsData]);

  // Get cost allocations and lookup initially
  useEffect(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.lookupCostAllocationFactor.get.request(),
    );

    dispatchAPI(
      VineaNovaActions.api.v1.adHocBlockVintageAllocation.get.request({
        queryParams: { AdhocBlockVintageID: currentBlockVintage?.id },
      }),
    );
  }, [currentBlockVintage]);

  // Once cost allocations are fetched, set initial checkbox allocations data
  useEffect(() => {
    calculateInitialBlockAllocations();
  }, [existingAllocationData, existingAllocationIsLoading]);

  // Filter available blocks to select based on cost allocation selected
  useEffect(() => {
    filterBlocksToSelect();
  }, [form?.costAllocationFactorID]);

  // Show success or error message when cost allocations are updated
  useEffect(() => {
    if (
      apiTrigger &&
      existingAllocationIsLoaded &&
      !existingAllocationIsLoading
    ) {
      if (!existingAllocationHasError) {
        enqueueSnackbar('Successfully updated cost allocations', {
          variant: 'success',
        });

        dispatchAPI(
          VineaNovaActions.api.v1.adHocBlockVintageAllocation.get.request({
            queryParams: { AdhocBlockVintageID: currentBlockVintage?.id },
          }),
        );
      } else {
        enqueueSnackbar('Error updating cost allocations', {
          variant: 'error',
        });
      }
      setApiTrigger(false);
    }
  }, [
    apiTrigger,
    existingAllocationIsLoaded,
    existingAllocationIsLoading,
    existingAllocationHasError,
  ]);

  return {
    form,
    costAllocationOptions,
    checkBoxGridData,
    canSave,
    pageLoading,
    handleOnFormChange,
    handleOnBlockSelectionChange,
    handleOnSave,
  };
};
