import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { MetaDataType } from '../containers/Jobs/interfaces/jobInterfaces';
import { get, isEmpty, isNil } from 'lodash';

const useBoldReportsHook = (category: string, reportName: string) => {
  const dispatchAPI = useDispatch();
  const [showReportViewer, setShowReportViewer] = useState(false);

  const { data: tokenData } = useSelector(
    VineaNovaSelectors.getGetTokenEntity,
  ) as MetaDataType;

  const {
    isLoaded: getTokenIsLoaded,
    isLoading: getTokenIsLoading,
    hasError: getTokenHasError,
  } = useSelector(VineaNovaSelectors.getGetTokenEntityMeta) as MetaDataType;

  const accessToken = useMemo(
    () => `bearer ${get(tokenData, 'access_token')}`,
    [tokenData],
  );
  const tokenExpireTime = useMemo(
    () => parseInt(get(tokenData, 'expires_in', '0')) + Date.now(),
    [tokenData?.expires_in],
  );

  const { reportServerURL, reportServiceURL } = tokenData;

  const reportPath = useMemo(() => {
    if (isNil(category)) return '';
    return `/${category}/${reportName}`;
  }, [category, reportName]);

  const tokenReady = useMemo(() => {
    if (isEmpty(tokenData)) return false;
    return (
      tokenData?.access_token !== '' &&
      getTokenIsLoaded &&
      !getTokenIsLoading &&
      !getTokenHasError
    );
  }, [tokenData, getTokenIsLoaded, getTokenIsLoading, getTokenHasError]);

  useEffect(() => {
    dispatchAPI(VineaNovaActions.api.v1.getToken.get.request({}));
  }, []);

  return {
    reportPath,
    accessToken,
    tokenReady,
    showReportViewer,
    tokenExpireTime,
    reportServerURL,
    reportServiceURL,
    setShowReportViewer,
  };
};
export default useBoldReportsHook;
