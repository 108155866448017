// @ts-ignore
// import { ILookupProps, IRouteProps, CommonRouteProps } from './datatypes.ts';

// export { ILookupProps, IRouteProps, CommonRouteProps } from './datatypes.d.ts';

export * from './countrylist';
export * from './actionTypes';
export * from './contactMethodTypes';
export * from './reducers';
export * from './lookupNames';
export * from './featureFlags';
export * from './pageLookups';
export * from './customBreadcrumbs';

export const dateFormat = 'yyyy-MM-dd';
export const viewDateFormat = 'dd/MM/yyyy';
export const dateTimeFormat = 'dd/MM/yyyy HH:mm:ss';

export const apiTypes = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
};

export const languageList = [
  { id: 'en', key: 'en', value: 'English' },
  { id: 'de', key: 'de', value: 'Deutsch' },
  // { id: 'fr', key: 'fr', value: 'Français' },
  { id: 'es', key: 'es', value: 'Español' },
];

export const landingPagesList = [
  { id: 0, key: 0, value: 'Select', path: '' },
  { id: 1, key: 1, value: 'Home Page', path: 'home' },
  { id: 2, key: 2, value: 'Dashboard', path: 'dashboard' },
  { id: 3, key: 3, value: 'Vineyards', path: 'organisation/vineyards' },
  { id: 4, key: 4, value: 'People', path: 'person' },
  {
    id: 5,
    key: 5,
    value: 'Activity Progress',
    path: 'maps/activity-progress',
  },
  {
    id: 6,
    key: 6,
    value: 'Vineyard Explorer',
    path: 'maps/vineyard-explorer',
  },
  {
    id: 7,
    key: 7,
    value: 'Supply Contracts',
    path: 'supplycontract',
  },
];

export const APP_FUNCTION_KEY =
  'q02YCVvfNJKdQAAfPXLyNrdndLRgsX7XAxkAiKKuXNev9GRlS6kf6w==';

// The id of the ArcGIS developer application found in the developer portal
export const ARC_GIS_CLIENT_ID = 'gXUYLrTkyXVKe75k';
// export const ARC_GIS_CLIENT_ID = 'CGArylTfbyQtnxAa';

export const LOCAL_ARC_GIS_TOKEN = 'arcGISToken';
export const LOCAL_ARC_GIS_TOKEN_CREATED = 'arcGISTokenCreated';

export const IdentityTypeIds = {
  PERSON: 1,
  ORGANISATION: 2,
  VINEYARD: 3,
  SERVICE_PROVIDER: 4,
  JOB: 5,
  DATA_CONNECTION: 8,
  VEHICLE: 20,
  ACCOMMODATION: 21,
  CONSUMABLE: 23,
  ACTIVITY: 25,
  SUPPLY_CONTRACT: 26,
  PLAN: 30,
};

export const IdentityDrawerTypes = {
  JOB_SUPERVISOR: 1,
  SUPPLY_CONTRACT_BLOCK_ADD: 2,
  SUPPLY_CONTRACT_BLOCK_EDIT: 3,
  JOB_BLOCK_EDIT: 4,
  ADD_JOB_ACTIVITY: 'ADD_JOB_ACTIVITY',
  RELATIONSHIPS: 'RELATIONSHIPS',
};

export const RelationshipTypeIDs = {
  JOB_SUPERVISOR: 35,
};

export const JobStatusIDs = {
  UNSCHEDULED: 2,
  SCHEDULED: 3,
};

export const JobTypeIDs = {
  HOURLY: 2,
  BY_POST: 3,
  BY_VINE: 6,
  TONNAGE: 7,
  BY_METRE: 9,
  BY_UNIT_COUNT: 11,
  BY_TREE: 12,
  BY_BAY: 13,
  STOCK: 17,
};

export const WorkRecordLevelIDs = {
  BLOCK_LEVEL: 1,
  ROW_LEVEL: 2,
  HOURLY: 4,
};

export const jobQuantityTypeIDs = {
  ACTUAL: 1,
  PLANTED: 2,
};

export const JobInvoiceMethodIDs = {
  QUOTED_PAYER: 5,
  QUOTED_CONTRACT: 6,
  ACTUAL_PAYER: 7,
  ACTUAL_CONTRACT: 8,
  NO_INVOICE: 9,
};

export const PlanStatusIDs = {
  INACTIVE: 4,
};

export const IdentityRelationshipTypes = {
  VINEYARD_TO_PARENT_VINEYARD: 43,
};

export const IdentityRoleTypes = {
  WORKER: 302,
  SUPERVISOR: 304,
  GROWER: 317,
  WINE_COMPANY: 350,
};

export const ClassificationIDs = {
  PAYER: 88,
};

export const FilterTypes = {
  BUSINESS_UNIT: 'businessUnitID',
  NAME: 'name',
  ROLE: 'roleTypeID',
  REFERENCE_TYPE: 'referenceTypeID',
  REFERENCE_VALUE: 'referenceValue',
  CLASSIFICATION_GROUP: 'classificationGroupID',
  CLASSIFICATION_NAME: 'classificationNameID',
  EMAIL: 'email',
  JOB_NAME: 'jobName',
  JOB_ID: 'jobID',
  STATUS: 'statusID',
  SITE_TYPE: 'siteTypeID',
};

export const DataProviderTypeIDs = {
  SENSORS: 1,
  VINEA_INTEGRATION: 2,
  ARC_GIS: 3,
  WINERY: 4,
};

export const BlockVintageStatusIDs = {
  PRODUCING: 1,
  NOT_PRODUCING: 2,
  IN_DEV: 3,
  UNDEVELOPED: 4,
  OUT_OF_CONTRACT: 5,
  ADHOC: 6,
};

export const vineaDetails = {
  identity: 'IDENTITY',
  reference: 'REFERENCE',
  classification: 'CLASSIFICATION',
  role: 'ROLE',
  contactMethod: 'CONTACT_METHOD',
  addressContact: 'ADDRESS_CONTACT',
  relationship: 'RELATIONSHIP',
  person: 'PERSON',
  vineyard: 'VINEYARD',
  serviceProvider: 'SERVICE_PROVIDER',
  job: 'JOB',
  consumable: 'CONSUMABLE',
  accommodation: 'ACCOMMODATION',
  activity: 'ACTIVITY',
  user: 'USERS',
  plans: 'PLANS',
  blocks: 'BLOCKS',
  blockVintage: 'BLOCK_VINTAGE',
  blockRows: 'BLOCK_ROWS',
  samplesSet: 'SAMPLES_SET',
  note: 'NOTE',
  postSampleHeaderBlocks: 'POST_SAMPLE_HEADER_BLOCKS',
  sampleBlockStatusUpdate: 'SAMPLE_HEAD_BOCK_STATUS_UPDATE',
  dataConnection: 'DATA_CONNECTION',
  supplyContract: 'SUPPLY_CONTRACT',
};

export const commonFieldSelectOption = [
  {
    key: 0,
    id: 0,
    value: 'Select',
  },
];

export const commonFieldAllOption = [
  {
    key: 0,
    id: 0,
    value: 'All',
  },
];

export const commonGridHeaderNames = {
  SUPPLY_CONTRACT_BLOCKS: 'Contracted Blocks',
  WORKER_PAY_PERIODS: 'Work Record',
  WORKER_WORK_RECORDS: 'Work Records',
  JOB_ACTIVITIES: 'Job Activities',
  JOB_SUPERVISORS: 'Supervisors',
  JOB_WORKERS: 'Job Workers',
  JOB_BLOCKS: 'Blocks',
  JOB_PLANS: 'Job Plans',
  JOB_EVENTS: 'Job Events',
  JOB_BLOCK_ROWS: 'Rows',
  VINEYARD_BLOCK_ROWS: 'Rows',
  SIMILAR_IDENTITIES: 'Similar Identities',
  IMPORT_BLOCK_SHEET: 'Block Rows To Import',
};

export const DashboardPageTypes = {
  HOME: 'Homepage',
  DASHBOARD: 'Main Dashboard',
  SUPERVISOR: 'Supervisor Dashboard',
};

export const commonGridColumns = {
  SIMILAR_IDENTITIES: ['ID', 'Name', 'Business Unit', 'Status'],
};

export const LicenseeTypeIDs = {
  WINE_GROWER: 1,
  FRUIT_GROWER: 2,
  CONTRACTOR_VINEYARDS_ONLY: 3,
  CONTRACTOR_VINEYARDS_AND_ORCHARDS: 4,
};

export const SiteTypeIDs = {
  VINEYARD: 1,
  ORCHARD_TREES: 2,
  ORCHARD_KIWIFRUIT: 3,
};

export const ReportGroups = {
  NOVA: 'Built-In Reports',
  WEB: 'Web Reports',
};

export const ImportTypeIDs = {
  BLOCK_SHEET: 1,
  CROP_MEASUREMENTS: 2,
  CROPSY_PRUNING: 5,
};

export const EventStatusIDs = {
  NO_ACTION_REQ: 1,
  ACTION_REQ: 2,
  RESOLVED: 3,
};

export const CostAllocationFactorIDs = {
  PLANTED_AREA: 1,
  PLANTED_VINES: 2,
  ACTUAL_VINES: 3,
  ROW_KMS: 4,
};
