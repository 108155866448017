import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { filter, get, isNil } from 'lodash';

import {
  JobTypeIDs,
  JobInvoiceMethodIDs,
  WorkRecordLevelIDs,
  jobQuantityTypeIDs,
  LicenseeTypeIDs,
  SiteTypeIDs,
  commonFieldSelectOption,
} from '../../../constants';
import {
  getJobWorkRecordUnitType,
  getLkpJobInvoiceMethod,
  getLkpJobQuantityType,
  getLkpJobTypeWithoutSelect,
  getLkpJobWorkRecordLevel,
  getSiteTypeWithSelect,
} from '../../../redux/selectors';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';

interface LookupType {
  id: number;
  key: number;
  value: string;
}

interface JobWorkRecordUnitType extends LookupType {
  jobTypeID: number;
}

interface LookupJobType extends LookupType {
  siteTypeID: number | null;
}

export interface VineyardBlocksGridType {
  id: number;
  name: string;
  vineyardID: number;
}

export const useJobHook = (formData: any) => {
  const { data: jobSearchFilterData } = useSelector(
    (state: any) => state.jobSearchFilter,
  );
  const lkpSystemData = useSelector(
    (state: any) => state.entities.lookupSystemData,
  );

  const { licenseeTypeID } = useIdentityTypeScreenNameHook();
  const [updatedFormData, setUpdatedFormData] = useState(formData);
  const [saveHasBeenClicked, setSaveHasBeenClicked] = useState(false);

  const lkpJobInvoiceMethod = useSelector(state =>
    getLkpJobInvoiceMethod(state),
  );
  const lkpJobQuantityType = useSelector(state => getLkpJobQuantityType(state));
  const [quantityTypeOptions, setQuantityTypeOptions] =
    useState(lkpJobQuantityType);
  const [jobInvoiceMethodOptions, setJobInvoiceMethodOptions] =
    useState(lkpJobInvoiceMethod);
  const lkpWorkRecordLevel = useSelector(state =>
    getLkpJobWorkRecordLevel(state),
  );
  const [workRecordLevelOptions, setWorkRecordLevelOptions] =
    useState(lkpWorkRecordLevel);
  const lkpJobWorkRecordUnitType = useSelector(state =>
    getJobWorkRecordUnitType(state),
  );
  const [workRecordUnitTypeOptions, setWorkRecordUnitTypeOptions] = useState(
    lkpJobWorkRecordUnitType,
  );

  const lkpSiteType = useSelector(state => getSiteTypeWithSelect(state));

  const siteTypeOptions = useMemo(() => {
    if (licenseeTypeID === LicenseeTypeIDs.CONTRACTOR_VINEYARDS_AND_ORCHARDS) {
      return lkpSiteType;
    } else if (
      licenseeTypeID === LicenseeTypeIDs.WINE_GROWER ||
      licenseeTypeID === LicenseeTypeIDs.CONTRACTOR_VINEYARDS_ONLY
    ) {
      const options = filter(
        lkpSiteType,
        (s: LookupType) => s.id === SiteTypeIDs.VINEYARD,
      );
      return options;
    } else {
      const options = filter(
        lkpSiteType,
        (s: LookupType) => s.id !== SiteTypeIDs.VINEYARD,
      );
      return options;
    }
  }, [lkpSiteType, licenseeTypeID]);

  const lkpJobType = useSelector(state => getLkpJobTypeWithoutSelect(state));
  const jobTypeOptions = useMemo(() => {
    const options = filter(
      lkpJobType,
      (s: LookupJobType) =>
        !(
          isNil(updatedFormData.siteTypeID) || updatedFormData.siteTypeID === 0
        ) &&
        (s?.siteTypeID === updatedFormData.siteTypeID || isNil(s?.siteTypeID)),
    );
    return commonFieldSelectOption.concat(options as any);
  }, [lkpJobType, updatedFormData.siteTypeID]);

  const [workRecordLevelDisabled, setWorkLevelDisabled] = useState(false);
  const [quantityTypeDisabled, setQuantityTypeDisabled] = useState(false);
  const [unitTypeDisabled, setUnitTypeDisabled] = useState(false);

  const { jobTypeID, invoiceMethodID, workRecordLevelID } = formData;

  useEffect(() => {
    const tempData = { ...formData };

    if (jobTypeID === JobTypeIDs.HOURLY || jobTypeID === JobTypeIDs.TONNAGE) {
      setWorkLevelDisabled(true);
      tempData.workRecordLevelID = WorkRecordLevelIDs.HOURLY;
    } else if (jobTypeID === JobTypeIDs.BY_BAY) {
      setWorkLevelDisabled(true);
      tempData.workRecordLevelID = WorkRecordLevelIDs.ROW_LEVEL;
    } else if (
      jobTypeID === JobTypeIDs.BY_UNIT_COUNT ||
      jobTypeID === JobTypeIDs.STOCK
    ) {
      setWorkLevelDisabled(true);
      tempData.workRecordLevelID = WorkRecordLevelIDs.BLOCK_LEVEL;
    } else if (jobTypeID === 0 || isNil(jobTypeID)) {
      setWorkLevelDisabled(true);
      tempData.workRecordLevelID = null;
    } else if (isNil(workRecordLevelID) || workRecordLevelID === 0) {
      setWorkLevelDisabled(false);
      tempData.workRecordLevelID = WorkRecordLevelIDs.ROW_LEVEL;
    } else {
      setWorkLevelDisabled(false);
    }

    // Quantity type field is only available if job type is by vine or by tree
    if (jobTypeID === JobTypeIDs.BY_VINE || jobTypeID === JobTypeIDs.BY_TREE) {
      tempData.quantityTypeID = jobQuantityTypeIDs.ACTUAL;
      const quantityOptions = filter(lkpJobQuantityType, (i: LookupType) => {
        return [jobQuantityTypeIDs.ACTUAL, jobQuantityTypeIDs.PLANTED].includes(
          i.id,
        );
      });
      setQuantityTypeOptions(quantityOptions);
      setQuantityTypeDisabled(false);
    } else {
      tempData.quantityTypeID = null;
      setQuantityTypeDisabled(true);
    }

    // Unit type field is only available if job type is by unit count
    if (jobTypeID === JobTypeIDs.BY_UNIT_COUNT) {
      setUnitTypeDisabled(false);
    } else {
      tempData.workRecordUnitTypeID = null;
      setUnitTypeDisabled(true);
    }

    // Filter work record level options depending on job type
    if (
      jobTypeID === JobTypeIDs.BY_VINE ||
      jobTypeID === JobTypeIDs.BY_METRE ||
      jobTypeID === JobTypeIDs.BY_POST ||
      jobTypeID === JobTypeIDs.BY_TREE ||
      jobTypeID === JobTypeIDs.STOCK
    ) {
      const workRecordOptions = filter(lkpWorkRecordLevel, (i: LookupType) => {
        return i.id !== WorkRecordLevelIDs.HOURLY;
      });
      setWorkRecordLevelOptions(workRecordOptions);
    } else {
      setWorkRecordLevelOptions(lkpWorkRecordLevel);
    }

    // Filter invoice options depending on job type
    if (
      jobTypeID === JobTypeIDs.HOURLY ||
      jobTypeID === JobTypeIDs.TONNAGE ||
      jobTypeID === JobTypeIDs.BY_UNIT_COUNT
    ) {
      const invoiceOptions = filter(lkpJobInvoiceMethod, (i: LookupType) => {
        return [
          0,
          JobInvoiceMethodIDs.ACTUAL_CONTRACT,
          JobInvoiceMethodIDs.ACTUAL_PAYER,
          JobInvoiceMethodIDs.NO_INVOICE,
        ].includes(i.id);
      });
      setJobInvoiceMethodOptions(invoiceOptions);
    } else {
      setJobInvoiceMethodOptions(lkpJobInvoiceMethod);
    }

    // Filter work record unit options based on job type
    const workRecordUnitOptions = filter(
      lkpJobWorkRecordUnitType,
      (i: JobWorkRecordUnitType) => {
        return i.jobTypeID === jobTypeID;
      },
    );
    // @ts-ignore
    setWorkRecordUnitTypeOptions(workRecordUnitOptions);

    // Default invoice method to Actual Work By Contract if no default specified in systemData lookup
    const defaultInvoiceMethodID = get(
      lkpSystemData,
      'data.coreData.invoiceMethodLookUpId',
      0,
    );
    if (invoiceMethodID === 0 || isNil(invoiceMethodID)) {
      if (defaultInvoiceMethodID !== 0)
        tempData.invoiceMethodID = defaultInvoiceMethodID;
      else tempData.invoiceMethodID = JobInvoiceMethodIDs.ACTUAL_CONTRACT;
    }
    setUpdatedFormData(tempData);
  }, [jobTypeID]);

  // If licensee is vineyards only, then set siteType to vineyard
  useEffect(() => {
    if (
      licenseeTypeID === LicenseeTypeIDs.WINE_GROWER ||
      licenseeTypeID === LicenseeTypeIDs.CONTRACTOR_VINEYARDS_ONLY
    ) {
      const tempData = { ...formData };
      tempData.siteTypeID = SiteTypeIDs.VINEYARD;
      setUpdatedFormData(tempData);
    }
  }, [formData, licenseeTypeID]);

  return {
    formData: updatedFormData,
    quantityTypeOptions,
    jobInvoiceMethodOptions,
    workRecordLevelOptions,
    workRecordUnitTypeOptions,
    workRecordLevelDisabled,
    unitTypeDisabled,
    quantityTypeDisabled,
    saveHasBeenClicked,
    jobSearchFilterData,
    licenseeTypeID,
    siteTypeOptions,
    jobTypeOptions,
    setSaveHasBeenClicked,
    setUpdatedFormData,
  };
};
