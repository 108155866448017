/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material';

import { DataGridPro } from '@mui/x-data-grid-pro';
import { isEqual, pick, isEmpty, isNil } from 'lodash';
import { CustomGridLoadingOverlay } from './CustomGridLoadingOverlay';
import { CustomPagination } from './CustomPagination';
import { CustomToolbar } from './CustomToolBar';
import logger from '../../utils/winstonLogger';
function GridWithFilter({
  data,
  onRowClick,
  className,
  actionType,
  gridColumnState,
  handleOnAdvancedSearch,
  advancedSearch,
  selectedFilterValuesText,
  noRowsOverlay,
  resetPagination = false,
  excludeBusinessUnit = false, // new prop to indicate which filter value to exclude
  components = {},
  sortModel = [{ field: 'name', sort: 'asc' }],
  filterModel = {},
  filterUpdateActionType = '',
  hasButton,
  onButtonClick,
  buttonText,
  hasSearch = true,
  hasActiveToggle = false,
  activeToggleLabel = 'Active only',
  toggleActive = false,
  handleOnToggleActive = () => {},
  handleOnSortModelChange = () => {},
  handleOnFilterModelChange = () => {},
  ...restProps
}) {
  const theme = useTheme();
  const gridRootRef = useRef(null);

  const handleOnRowClick = evt => {
    onRowClick(evt);
  };

  const dispatchAPI = useDispatch();
  const handleOnColumnVisibilityChange = model => {
    dispatchAPI({
      type: actionType,
      payload: model,
    });
  };

  const handleOnPageSizeChange = newPageSize => {
    logger.debug('handleOnPageSizeChange', { newPageSize });
  };

  const handleOnResize = newSize => {
    logger.debug('handleOnResize', { newSize });
  };

  return (
    <DataGridPro
      ref={gridRootRef}
      data-testid="gridwithfilter-datagridpro"
      id="data-grid-root"
      onPageSizeChange={handleOnPageSizeChange}
      onResize={handleOnResize}
      sx={{
        '& .MuiDataGridPro-main .rendering-zone>div:nth-of-type(even)': {
          backgroundColor: theme.palette.action.hover,
        },
        '& .vinea-xgrid--false': {
          backgroundColor: theme.palette.action.hover,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        '& .MuiDataGrid-pinnedColumnHeaders': {
          backgroundColor: theme.palette.white.main,
          boxShadow: 0,
        },
        '& .MuiDataGrid-pinnedColumns': {
          backgroundColor: theme.palette.white.main,
          boxShadow: 0,
        },
        '& 	.MuiDataGridPro-row .Mui-selected': {
          backgroundColor: 'rgba(25, 118, 210, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(25, 118, 210, 0.12)',
          },
        },
        '& MuiCheckbox-colorPrimary MuiDataGrid-checkboxInput': {
          color: theme.palette.secondary.main,
        },
        height: '100%',
      }}
      components={{
        ...components,
        LoadingOverlay: CustomGridLoadingOverlay,
        Pagination: CustomPagination,
        Toolbar: CustomToolbar,
        NoRowsOverlay: noRowsOverlay,
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'desc' }],
        },
        ...restProps.initialState,
      }}
      sortModel={sortModel}
      onSortModelChange={handleOnSortModelChange}
      componentsProps={{
        actionType,
        handleOnAdvancedSearch,
        advancedSearch,
        selectedFilterValuesText,
        excludeBusinessUnit,
        hasButton,
        onButtonClick,
        buttonText,
        hasSearch,
        hasActiveToggle,
        activeToggleLabel,
        toggleActive,
        handleOnToggleActive,
      }}
      onRowClick={handleOnRowClick}
      pinnedColumns={
        restProps.disableColumnPinning ? undefined : { left: ['name'] }
      }
      autoPageSize
      pagination
      resetPagination={resetPagination}
      {...(!isNil(gridColumnState) && {
        columnVisibilityModel: gridColumnState,
        onColumnVisibilityModelChange: handleOnColumnVisibilityChange,
      })}
      {...(!isEmpty(filterModel) && {
        filterModel: filterModel,
      })}
      onFilterModelChange={handleOnFilterModelChange}
      {...restProps}
      {...data}
    />
  );
}

GridWithFilter.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string]),
    }),
  ).isRequired,
  onRowClick: PropTypes.func,
  gridColumnState: PropTypes.shape({}),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  resetPagination: PropTypes.bool,
  components: PropTypes.shape({}),
  filterModel: PropTypes.shape({}),
  filterUpdateActionType: PropTypes.string,
};

GridWithFilter.defaultProps = {
  onRowClick: f => f,
  gridColumnState: {},
  className: '',
  resetPagination: false,
  components: {},
  filterModel: {},
  filterUpdateActionType: '',
};

function areEqual(prevProps, nextProps) {
  const px = pick(prevProps, ['selectionModel', 'data', 'initlaState']);
  const nx = pick(nextProps[('selectionModel', 'data', 'initlaState')]);
  return isEqual(px, nx);
}

export default memo(GridWithFilter, areEqual);
