import { Box, Paper } from '@mui/material';

import { DataGridPro } from '@mui/x-data-grid-pro';
import { orderBy } from 'lodash';

type DisplayDataProps = {
  tableColumns: any;
  parsedRows: any[];
  theme: any;
};

export const DisplayData = ({
  tableColumns = [],
  parsedRows = [],
  theme,
}: DisplayDataProps) => {
  const orderedRows = orderBy(parsedRows, 'Error', 'desc');

  return (
    <Paper
      elevation={0}
      data-testid="upload-data-paper"
      sx={{ height: '100%' }}
    >
      <Box
        sx={{
          height: 400,
          width: '100%',
          marginTop: theme.spacing(2),
        }}
      >
        <DataGridPro
          rows={orderedRows}
          columns={tableColumns}
          pageSize={5}
          rowsPerPageOptions={[20]}
          rowHeight={30}
          disableColumnMenu
          disableSelectionOnClick
          disableColumnSelector
        />
      </Box>
    </Paper>
  );
};
