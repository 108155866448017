import * as Yup from 'yup';

export const BlockSheetSchema = Yup.object()
  .shape({
    Row: Yup.string().required('No row number provided'),
    VarietyID: Yup.number()
      .nullable()
      .typeError('Invalid Variety')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    Planted: Yup.number()
      .nullable()
      .typeError('Planted must be a number')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    CurrentProducing: Yup.number()
      .nullable()
      .typeError('Current/Producing must be a number')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    PostsPoles: Yup.number()
      .nullable()
      .typeError('Posts/Poles must be a number')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    RowLength: Yup.number()
      .nullable()
      .typeError('Row Length must be a number')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    SpecialAttributeID: Yup.number()
      .nullable()
      .typeError('Invalid Special Attribute')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    RowNotes: Yup.string().nullable(),
    PlantSpacing: Yup.number()
      .nullable()
      .typeError('Plant Spacing must be a number')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    RowWidth: Yup.number()
      .nullable()
      .typeError('Row Width must be a number')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    BayCount: Yup.number()
      .nullable()
      .typeError('Bay Count must be a number')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    BayAvgArea: Yup.number()
      .nullable()
      .typeError('Bay Avg Area must be a number')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
  })
  .test(
    'at-least-one',
    'Planted, Current/Producing, Posts/Poles, and Row Length cannot all be empty',
    function (value) {
      const { Planted, CurrentProducing, PostsPoles, RowLength } = value;
      return (
        Planted !== null ||
        CurrentProducing !== null ||
        PostsPoles !== null ||
        RowLength !== null
      ); // At least one of vines or trees must be provided
    },
  );
