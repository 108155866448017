import {
  Box,
  FormControl,
  Paper,
  Skeleton,
  Stack,
  styled,
} from '@mui/material';
import { get, isEmpty, map } from 'lodash';

import { ExcelCsvUpload } from '../../components/ImageUpload/ExcelCsvUpload';
import { VineaAutoComplete } from '../../components/ComboBox';
import { FieldLabelInput } from '../../components/TextField';
import CheckboxSelectionGrid from '../../components/Grid/CheckboxSelectionGrid';
import { useSelector } from 'react-redux';
import { AdditionalParameterType } from './hooks/UseExcelCsvImporter';
import { commonFieldSelectOption } from '../../constants';
import { useMemo } from 'react';

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 300,
  '& .makeStyles-root-150 .MuiOutlinedInput-adornedEnd': {
    minWidth: '250px',
  },
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

type UploadDataProps = {
  fileName: string;
  sheetsGridXData: any;
  selectedSheets: number[];
  theme: any;
  data: any;
  additionalParameters: AdditionalParameterType[];
  fileReadLoading: boolean;
  dataLoading: boolean;
  handleFileRead: (e: any) => void;
  handleOnSheetsSelectionChange: (selectedSheets: number[]) => void;
  handleOnChange: (evt: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const UploadData = ({
  fileName,
  sheetsGridXData,
  selectedSheets,
  theme,
  data,
  additionalParameters,
  fileReadLoading = false,
  dataLoading = false,
  handleFileRead,
  handleOnSheetsSelectionChange,
  handleOnChange,
}: UploadDataProps) => {
  const { data: lkpImportType } = useSelector(
    (state: any) => state.entities.lookupImportType,
  );

  const importTypes = useMemo(() => {
    if (isEmpty(lkpImportType)) return [];
    else
      return commonFieldSelectOption.concat(
        map(lkpImportType, (t: any) => {
          return { id: t?.id, key: t?.id, value: t?.importType };
        }),
      );
  }, [lkpImportType]);

  return (
    <Paper elevation={0} data-testid="upload-data-paper">
      <Stack
        direction="row"
        display="flex"
        justifyContent="center"
        sx={{
          marginTop: theme.spacing(2),
        }}
      >
        <Stack
          mt={2}
          mb={3}
          sx={{
            width: 300,
            margin: theme.spacing(2),
          }}
        >
          {dataLoading ? (
            <Skeleton variant="text" width="100%" height={60} />
          ) : (
            <FormControl>
              <VineaAutoComplete
                value={data?.importTypeID || 0}
                onChange={handleOnChange}
                options={importTypes || []}
                labelVariant="body1"
                inlineLabel={false}
                id="importTypeID"
                name={'importTypeID'}
                label={'Import Type'}
                inputProps={{
                  name: 'importTypeID',
                }}
                fullWidth={false}
                disabled={false}
              />
            </FormControl>
          )}
          {additionalParameters.map((param, index) =>
            dataLoading ? (
              <Skeleton variant="text" width="100%" height={60} />
            ) : (
              <FormControl
                sx={{
                  marginTop: theme.spacing(2),
                }}
              >
                <VineaAutoComplete
                  value={get(data, `${param?.name}`) || 0}
                  onChange={handleOnChange}
                  options={param?.options || []}
                  labelVariant="body1"
                  inlineLabel={false}
                  id={`${param?.name}`}
                  name={`${param?.name}`}
                  label={`${param?.label}`}
                  inputProps={{
                    name: `${param?.name}`,
                  }}
                  fullWidth={false}
                  disabled={false}
                />
              </FormControl>
            ),
          )}

          <Stack
            direction="column"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            {dataLoading ? (
              <Skeleton variant="circular" width={60} height={60} />
            ) : (
              <ExcelCsvUpload handleFileRead={handleFileRead} />
            )}
            {dataLoading || fileReadLoading ? (
              <Skeleton variant="text" width="100%" height={60} />
            ) : (
              <Box
                width="100%"
                sx={{
                  marginTop: theme.spacing(2),
                }}
              >
                <StyledFieldLabelInput
                  autoComplete="off"
                  id="fileName"
                  name="fileName"
                  label={'File Name'}
                  fullwidth={true}
                  size="small"
                  inlineLabel
                  value={fileName}
                  onChange={undefined}
                  onBlur={undefined}
                  classes={undefined}
                  error={undefined}
                  rows={1}
                  disabled={true}
                  sx={undefined}
                />
              </Box>
            )}
          </Stack>
        </Stack>
        {dataLoading ? (
          <Skeleton variant="rectangular" width={300} height={300} />
        ) : (
          <Box sx={{ margin: theme.spacing(2), height: 300, width: 300 }}>
            <CheckboxSelectionGrid
              gridXData={sheetsGridXData}
              handleOnSelectionModelChange={handleOnSheetsSelectionChange}
              selectionModel={selectedSheets}
              rowUnit={'Sheet'}
              isLoading={fileReadLoading}
            />
          </Box>
        )}
      </Stack>
    </Paper>
  );
};
