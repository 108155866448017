import { createSelector } from 'reselect';
import shortId from 'shortid';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import { isWithinInterval } from 'date-fns';
import { VineaNovaSelectors } from 'vineanova-redux-artifacts';
import { filter, find, get, isNil, isNull, map, orderBy } from 'lodash';
import { commonFieldAllOption, commonFieldSelectOption } from '../../constants';
import { formatAsDateFormat } from '../../constants/formatter';
import logger from '../../utils/winstonLogger';
import { orderedLookupList } from './orderedList';

const getLkpReference = state => state.entities.lookupReferenceType;
const lkpContactMethod = state => state.entities.lookupContactMethod;
const lkpGender = state => state.entities.lookupGender;
const lkpEthnicity = state => state.entities.lookupEthnicity;
const lkpNationality = state => state.entities.lookupNationality;
const lkpSalutation = state => state.entities.lookupSalutation;
const lkpLanguage = state => state.entities.lookupLanguage;
const lkpBusinessUnit = state => state.entities.lookupBusinessUnit.data;
const lkpSiteType = state => state.entities.lookupSiteType.data;
const lkpIdentityActivity = state => state.entities.lookupIdentityActivity.data;
const lkpSupplyContractType = state =>
  state.entities.lookupSupplyContractType.data;
const lkpDisplayAttribute = state => state.entities.lookupDisplayAttribute.data;
const lkpConsumable = state => state.entities.lookupConsumableType.data;
const lkpRoleType = state => state.entities.lookupRoleType;
const lkpCountry = state => state.entities.lookupCountry;
const lkpSubRegion = state => state.entities.lookupGrowingSubRegion;
const lkpTown = state => state.entities.lookupTown;
const lkpVariety = state => state.entities.lookupVariety;
const lkpBlockVintageStatus = state => state.entities.lookupBlockVintageStatus;
const lkpRootStock = state => state.entities.lookupRootStock;
const lkpVarietyClone = state => state.entities.lookupVarietyClone;
const lkpBlockVintage = state => state.entities.lookupBlockVintage;
const lkpTrellis = state => state.entities.lookupTrellis;
const lkpVintage = state => state.entities.lookupVintage;
const lkpRowSpecialAttribute = state =>
  state.entities.lookupRowSpecialAttribute;
const lkpRelationship = state => state.entities.lookupRelationshipType;
const lkpRegion = state => state.entities.lookupGrowingRegion;
const lkpMeasure = state => state.entities.lookupMeasure;
const lkpMeasureGroup = state => state.entities.lookupMeasureGroup;
const lkpMeasureInstance = state => state.entities.lookupMeasureInstance;
const lkpNoteStatus = state => state.entities.lookupNoteStatus;
const lkpKeyword = state => state.entities.lookupKeyword;
const lkpSampleReqStatus = state => state.entities.lookupSampleRequestStatus;
const lkpPayrollSystem = state => state.entities.lookupPayrollSystem;
const lkpDataProvider = state => state.entities.lookupDataProvider;
const lkpDataProviderType = state => state.entities.lookupDataProviderType;
const lkpRoleTypeIdentityType = state =>
  state.entities.lookupRoleTypeIdentityType;
const lkpPhenologyMeasure = state => state.entities.lookupPhenologyMeasure;
const lkpPhenologyMeasureOption = state =>
  state.entities.lookupPhenologyMeasureOption;
const lkpPhenologyMeasureSet = state =>
  state.entities.lookupPhenologyMeasureSet;
const lkpPhenologyMeasureType = state =>
  state.entities.lookupPhenologyMeasureType;
const lkpCropSettingInstance = state =>
  state.entities.lookupCropSettingInstance;
const lkpCropSettingOption = state => state.entities.lookupCropSettingOption;
const getSensorStatus = state => state.entities.lookupSensorStatus;
const getDocumentType = state => state.entities.lookupDocumentType;
const getActivity = state => state.entities.lookupActivity;
const lkpIdentityIds = state => state.searchIdentities.data;
const lkpResourceTypeUnit = state => state.entities.lookupResourceTypeUnit.data;
const lkpResourceType = state => state.entities.lookupResourceType.data;
const lkpActivityGroup = state => state.entities.lookupActivityGroup.data;
const lkpWorkUnit = state => state.entities.lookupWorkUnit.data;
const lkpDuration = state => state.entities.lookupDuration.data;
const lkpCostingUnit = state => state.entities.lookupCostingUnit.data;
const lkpPlanStatus = state => state.entities.lookupPlanStatus.data;
const lkpPlanType = state => state.entities.lookupPlanType.data;
/** Job Selectors */

const lkpJobType = state => state.entities.lookupJobType;
const lkpJobQuantityType = state => state.entities.lookupJobQuantityType;
const lkpWorkRecordLevel = state => state.entities.lookupWorkRecordLevel;
const lkpJobTypeWorkRecordType = state =>
  state.entities.lookupJobTypeWorkRecordType;
const lkpJobWorkUnit = state => state.entities.lookupJobWorkUnit;
const lkpPayrollRecordType = state => state.entities.lookupPayrollRecordType;
const lkpJobInvoiceMethod = state => state.entities.lookupJobInvoiceMethod;
const lkpWorkRecordUnitType = state => state.entities.lookupWorkRecordUnitType;
const lkpJobStatus = state => state.entities.lookupJobStatus;
const lkpJobWorkUnits = state => state.entities.lookupJobWorkUnit;
const lkpJobEventAction = state => state.entities.lookupJobEventAction;
const lkpVehicleType = state => state.entities.lookupVehicleType;
const lkpVehicleMake = state => state.entities.lookupVehicleMake;

// Data Import lookups
const lkpImportType = state => state.entities.lookupImportType;

export const formWriteState = state => state.formWrite;

const getLkpReferenceTypeIdentityType = (state, id) => {
  const refIdentityType = state.entities.lookupReferenceTypeIdentityType.data;
  if (isEmpty(refIdentityType)) return null;
  return refIdentityType.filter(f => f.identityTypeID === id);
};

const getLkpClassificationGroupType = state =>
  state.entities.lookupClassificationGroup;

const getLkpClassificationGroupIdentityType = (state, id) => {
  const lkpGroupIdentityType =
    state.entities.lookupClassificationGroupIdentityType.data;
  if (isEmpty(lkpGroupIdentityType)) return null;
  return lkpGroupIdentityType.filter(f => f.identityTypeID === id);
};

const getLkplkpVarietyCloneByID = (state, id) => {
  const lkpGroupIdentityType = state.entities.lookupVarietyClone.data;
  if (isEmpty(lkpGroupIdentityType)) return null;
  return lkpGroupIdentityType.filter(f => f.varietyID === id);
};

export const getLkpGenderList = createSelector([lkpGender], gender => {
  const { hasError, isLoaded } = gender;
  if (!hasError && isLoaded) {
    const { data = [] } = gender;
    return commonFieldSelectOption.concat(
      data
        .map(f => ({
          id: f.id,
          key: f.genderCode,
          value: f.genderDescription,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
  }
  return null;
});

export const getLkpEthnicityList = createSelector([lkpEthnicity], ethnicity => {
  const { hasError, isLoaded } = ethnicity;
  if (!hasError && isLoaded) {
    const { data = [] } = ethnicity;
    return commonFieldSelectOption.concat(
      data
        .map(f => ({
          id: f.id,
          key: f.ethnicity,
          value: f.ethnicity,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
  }
  return null;
});

export const getLkpRoleTypeList = createSelector([lkpRoleType], roleTypes => {
  const { hasError, isLoading, isLoaded, data = [] } = roleTypes;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!hasError && isLoaded) {
    return commonFieldSelectOption.concat(
      sortBy(
        data.map(f => ({
          id: f.id,
          key: f.id,
          value: f.roleType,
        })),
        ['value'],
      ),
    );
  }
  return null;
});

export const getLkpRoleTypeIdentityType = (state, id = null) => {
  const roleTypes = lkpRoleType(state);
  const roleTypeIdType = lkpRoleTypeIdentityType(state);
  const { hasError = false, isLoading, isLoaded, data = [] } = roleTypes;
  const {
    hasError: idTypeHasError = false,
    isLoading: idTypeIsLoading,
    isLoaded: idTypeIsLoaded,
    data: idTypeData = [],
  } = roleTypeIdType || {};
  if (isLoading || hasError || idTypeIsLoading || idTypeHasError) return [];
  if (!Array.isArray(data) || !Array.isArray(idTypeData)) return [];
  if (
    !hasError &&
    isLoaded &&
    !idTypeHasError &&
    idTypeIsLoaded &&
    !isNull(id)
  ) {
    const idTypeList = filter(idTypeData, f => f.identityTypeID === id);
    const roleTypeList = filter(data, f =>
      find(idTypeList, g => g.roleTypeID === f.id),
    );
    return commonFieldSelectOption.concat(
      sortBy(
        roleTypeList.map(f => ({
          id: f.id,
          key: f.id,
          value: f.roleType,
        })),
        ['value'],
      ),
    );
  }
  return [];
};

export const getLkpNationalityList = createSelector(
  [lkpNationality],
  nationality => {
    const { isLoading, isLoaded, hasError, data = [] } = nationality;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!hasError && isLoaded) {
      return commonFieldSelectOption.concat(
        data
          .map(f => ({
            id: f.id,
            key: f.nationality,
            value: f.nationality,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
    }
    return null;
  },
);

export const getLkpLanguageList = createSelector([lkpLanguage], language => {
  const { hasError, isLoaded } = language;
  if (!hasError && isLoaded) {
    const { data = [] } = language;
    return commonFieldSelectOption.concat(
      data
        .map(f => ({
          id: f.id,
          key: f.language,
          value: f.language,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
  }
  return null;
});

export const getLkpSalutationList = createSelector(
  [lkpSalutation],
  salutation => {
    const { hasError, isLoaded } = salutation;
    if (!hasError && isLoaded) {
      const { data = [] } = salutation;
      return commonFieldSelectOption.concat(
        sortBy(
          data.map(f => ({
            id: f.id,
            key: f.salutation,
            value: f.salutation,
          })),
          ['value'],
        ),
      );
    }
    return null;
  },
);

export const getLookupReferencesByOrgId = createSelector(
  [getLkpReference, getLkpReferenceTypeIdentityType],
  (referenceType, refIdentityTypeId) => {
    const { isLoading, hasError, data = [] } = referenceType;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (
      !refIdentityTypeId ||
      isEmpty(refIdentityTypeId) ||
      isEmpty(referenceType)
    ) {
      return [];
    }

    logger.debug('refIdType', 1);

    const refIdentityList = [];
    refIdentityTypeId.forEach(f => {
      refIdentityList.push(f.referenceTypeID);
    });

    if (isEmpty(refIdentityList)) return [];

    const referenceTypes = data.filter(f => refIdentityList.includes(f.id));

    if (isEmpty(referenceTypes)) return [];
    const lkpRefData = commonFieldSelectOption.concat(
      sortBy(
        referenceTypes.map(f => ({
          id: f.id,
          key: f.id,
          value: f.referenceType,
        })),
        ['value'],
      ),
    );
    return lkpRefData;
  },
);

export const getLookupClassificationGroupsByOrgId = createSelector(
  [getLkpClassificationGroupType, getLkpClassificationGroupIdentityType],
  (clsGroupType, clsGroupIdType) => {
    if (isEmpty(clsGroupIdType)) return [];
    const { data: clsGroupTypeData } = clsGroupType;
    if (!Array.isArray(clsGroupTypeData) || isEmpty(clsGroupTypeData))
      return [];

    const classificationGroups = filter(
      map(clsGroupTypeData, classificationGroup => {
        const classificationGroupIdentityType = find(clsGroupIdType, {
          classificationGroupID: classificationGroup.id,
        });
        if (!isNil(classificationGroupIdentityType)) {
          return {
            ...classificationGroup,
            roleTypeID: classificationGroupIdentityType.roleTypeID,
            isRoleSpecific: classificationGroupIdentityType.isRoleSpecific,
          };
        }
      }),
      c => !isNil(c),
    );

    if (isEmpty(classificationGroups)) return [];
    const lkpRefGroupData = commonFieldSelectOption.concat(
      sortBy(
        classificationGroups.map(f => ({
          id: f.id,
          key: f.id,
          value: f.classificationGroup,
          roleTypeID: f.roleTypeID,
          isRoleSpecific: f.isRoleSpecific,
        })),
        ['value'],
      ),
    );
    return lkpRefGroupData;
  },
);

export const getLookupContactMethods = createSelector(
  [lkpContactMethod],
  contact => {
    const { isLoading, hasError, data = [] } = contact;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    const lkpContacts = commonFieldSelectOption.concat(
      data
        .map(f => ({
          id: f.id,
          key: f.id,
          value: f.contactMethodType,
          contactMethodType: f.contactMethodType,
          isAllowMultiple: f.isAllowMultiple,
          isAddress: f.isAddress,
          isEmail: f.isEmail,
          isPhone: f.isPhone,
          isMailMethod: f.isMailMethod,
          isDeliveryService: f.isDeliveryService,
          sortOrder: f.sortOrder,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
    return lkpContacts;
  },
);

export const getLookupRelationship = createSelector(
  [lkpRelationship],
  relationship => {
    const { isLoading, hasError, data = [] } = relationship;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    const lkpValue = data
      .map(f => ({
        id: f.id,
        relationshipType: f.relationshipType,
        ownerIdentityTypeID: f.ownerIdentityTypeID,
        dependentIdentityTypeID: f.dependentIdentityTypeID,
        ownerDescription: f.ownerDescription,
        dependentDescription: f.dependentDescription,
        additionalText: f.additionalText,
        isAdditionalText: f.isAdditionalText,
        ownerIdentityRoleTypeID: f.ownerIdentityRoleTypeID,
        dependentIdentityRoleTypeID: f.dependentIdentityRoleTypeID,
        ownerIdentityClassificationGroupID:
          f.ownerIdentityClassificationGroupID,
        dependentIdentityClassificationGroupID:
          f.dependentIdentityClassificationGroupID,
        comment: f.comment,
        systemValue: f.systemValue,
        isDisplayPanel: f.isDisplayPanel,
        inActive: f.inActive,
      }))
      .sort((a, b) =>
        a.relationshipType.toLowerCase() > b.relationshipType.toLowerCase()
          ? 1
          : -1,
      );
    return lkpValue;
  },
);

export const getBusinessUnit = createSelector([lkpBusinessUnit], data => {
  if (!isEmpty(data)) {
    const businessUnitList = commonFieldSelectOption.concat(
      data.map(f => ({
        id: f.id,
        key: f.id,
        value: f.businessUnit,
      })),
    );
    return businessUnitList;
  }
  return null;
});

export const getBusinessUnitDashboardEnabled = createSelector(
  [lkpBusinessUnit],
  data => {
    if (!isEmpty(data)) {
      const businessUnitList = commonFieldSelectOption.concat(
        filter(data, d => get(d, 'isDashboardDisabled', false) === false).map(
          f => ({
            id: f.id,
            key: f.id,
            value: f.businessUnit,
          }),
        ),
      );
      return businessUnitList;
    }
    return null;
  },
);

export const getSiteType = createSelector([lkpSiteType], data => {
  if (!isEmpty(data)) {
    const siteTypeList = commonFieldAllOption.concat(
      data.map(f => ({
        id: f.id,
        key: f.id,
        value: f.siteType,
      })),
    );
    return siteTypeList;
  }
  return null;
});

export const getSiteTypeWithSelect = createSelector([lkpSiteType], data => {
  if (!isEmpty(data)) {
    const siteTypeList = commonFieldSelectOption.concat(
      data.map(f => ({
        id: f.id,
        key: f.id,
        value: f.siteType,
      })),
    );
    return siteTypeList;
  }
  return null;
});

export const getIdentityActivity = createSelector(
  [lkpIdentityActivity],
  data => {
    if (!isEmpty(data)) {
      const identityActivityList = commonFieldSelectOption.concat(
        data.map(f => ({
          id: f.id,
          key: f.id,
          value: f.activityCode,
          workUnitID: f.workUnitID,
          activityDescription: f.activityDescription,
          defaultChargeOutRate: f.defaultChargeOutRate,
          defaultCostRate: f.defaultCostRate,
        })),
      );
      return identityActivityList;
    }
    return null;
  },
);

export const getJobWorkRecordUnitType = createSelector(
  [lkpWorkRecordUnitType],
  workRecordUnitType => {
    const { isLoading, hasError, data = [] } = workRecordUnitType;
    if (isLoading || hasError) return [];
    if (!isEmpty(data)) {
      const workRecordUnitTypeList = commonFieldSelectOption.concat(
        data.map(f => ({
          id: f.id,
          key: f.id,
          value: f.workRecordUnitType,
          workRecordTypeID: f.workRecordTypeID,
          jobTypeID: f.jobTypeID,
        })),
      );
      return workRecordUnitTypeList;
    }
    return null;
  },
);

export const getJobStatus = createSelector([lkpJobStatus], jobStatus => {
  const { isLoading, hasError, data = [] } = jobStatus;
  if (isLoading || hasError) return [];
  if (!isEmpty(data)) {
    const jobStatusList = commonFieldSelectOption.concat(
      data.map(f => ({
        id: f.id,
        key: f.id,
        value: f.jobStatus,
      })),
    );
    return jobStatusList;
  }
  return null;
});

export const getJobWorkUnits = createSelector(
  [lkpJobWorkUnits],
  jobWorkUnits => {
    const { isLoading, hasError, data = [] } = jobWorkUnits;
    if (isLoading || hasError) return [];
    if (!isEmpty(data)) {
      const jobWorkUnitList = commonFieldSelectOption.concat(
        data.map(f => ({
          id: f.id,
          key: f.id,
          workUnitID: f.workUnitID,
          jobTypeID: f.jobTypeID,
        })),
      );
      return jobWorkUnitList;
    }
    return null;
  },
);

export const getSupplyContractType = createSelector(
  [lkpSupplyContractType],
  data => {
    if (!isEmpty(data)) {
      const supplyContractTypeList = commonFieldSelectOption.concat(
        data.map(f => ({
          id: f.id,
          key: f.id,
          value: f.supplyContractAbbreviation,
        })),
      );
      return supplyContractTypeList;
    }
    return null;
  },
);

export const getDisplayAttribute = createSelector(
  [lkpDisplayAttribute],
  data => {
    if (!isEmpty(data)) {
      const displayAttributeList = commonFieldSelectOption.concat(
        data.map(f => ({
          id: f.id,
          key: f.id,
          value: f.displayAttribute,
        })),
      );
      return displayAttributeList;
    }
    return null;
  },
);

export const getActivityGroup = createSelector([lkpActivityGroup], data => {
  if (!isEmpty(data)) {
    const activityGroupList = commonFieldSelectOption.concat(
      data.map(f => ({
        id: f.id,
        key: f.id,
        value: f.activityGroup,
      })),
    );
    return activityGroupList;
  }
  return null;
});

export const getResourceType = createSelector([lkpResourceType], data => {
  if (!isEmpty(data)) {
    const resourceTypeList = commonFieldSelectOption.concat(
      data.map(f => ({
        id: f.id,
        key: f.id,
        value: f.resourceType,
      })),
    );
    return resourceTypeList;
  }
  return null;
});

export const getResourceTypeUnit = createSelector(
  [lkpResourceTypeUnit],
  data => {
    if (!isEmpty(data)) {
      const resourceTypeUnitList = commonFieldSelectOption.concat(
        data.map(f => ({
          id: f.id,
          key: f.id,
          value: f.resourceUnit,
          resourceTypeID: f.resourceTypeID,
        })),
      );
      return resourceTypeUnitList;
    }
    return null;
  },
);

export const getDuration = createSelector([lkpDuration], data => {
  if (!isEmpty(data)) {
    const durationList = commonFieldSelectOption.concat(
      data.map(f => ({
        id: f.id,
        key: f.id,
        value: f.duration,
      })),
    );
    return durationList;
  }
  return null;
});

export const getWorkUnit = createSelector([lkpWorkUnit], data => {
  if (!isEmpty(data)) {
    const workUnitList = commonFieldSelectOption.concat(
      data.map(f => ({
        id: f.id,
        key: f.id,
        value: f.workUnitPlural,
      })),
    );
    return workUnitList;
  }
  return null;
});

export const getCostingUnit = createSelector([lkpCostingUnit], data => {
  if (!isEmpty(data)) {
    const costingUnitList = commonFieldSelectOption.concat(
      data.map(f => ({
        id: f.id,
        key: f.id,
        value: f.costingUnit,
      })),
    );
    return costingUnitList;
  }
  return null;
});

export const getPlanType = createSelector([lkpPlanType], data => {
  if (!isEmpty(data)) {
    const planTypeList = commonFieldSelectOption.concat(
      data.map(f => ({
        id: f.id,
        key: f.id,
        value: f.planType,
      })),
    );
    return planTypeList;
  }
  return null;
});

export const getPlanStatus = createSelector([lkpPlanStatus], data => {
  if (!isEmpty(data)) {
    const planStatusList = commonFieldSelectOption.concat(
      data.map(f => ({
        id: f.id,
        key: f.id,
        value: f.planStatus,
      })),
    );
    return planStatusList;
  }
  return null;
});

export const getAllActiveIdentityIds = createSelector(
  [lkpIdentityIds],
  data => {
    if (!isEmpty(data)) {
      const activeIdentities = filter(data, { status: 'Active' });

      const identityIdList = commonFieldSelectOption.concat(
        orderBy(
          activeIdentities.map(f => ({
            id: f.id,
            key: shortId.generate(),
            value: f.name,
            businessUnit: f.businessUnit,
          })),
          'value',
          'asc',
        ),
      );
      return identityIdList;
    }
    return null;
  },
);

export const getConsumableType = createSelector([lkpConsumable], data => {
  if (!isEmpty(data)) {
    const consumableList = commonFieldSelectOption.concat(
      data.map(f => ({
        id: f.id,
        key: f.id,
        value: f.consumableType,
      })),
    );
    return consumableList;
  }
  return null;
});

export const getLookupCountry = createSelector([lkpCountry], country => {
  const { isLoading, hasError, data = [] } = country;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const countryList = commonFieldSelectOption.concat(
      data
        .map(f => ({
          id: f.id,
          key: f.id,
          value: f.country,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );

    return countryList;
  }
  return null;
});

export const getLookupTowns = createSelector([lkpTown], towns => {
  const { isLoading, hasError, data = [] } = towns;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const townList = commonFieldSelectOption
      .concat(
        data.map(f => ({
          id: f.id,
          key: f.id,
          value: f.town,
          countryID: f.countryID,
        })),
      )
      .sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1));
    return townList;
  }
  return null;
});

export const getLookupVariety = createSelector([lkpVariety], variety => {
  const { isLoading, hasError, data = [] } = variety;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const varietyList = commonFieldSelectOption.concat(
      data
        .map(f => ({
          id: f.id,
          key: f.id,
          value: f.varietyName,
          varietyTypeID: f.varietyTypeID,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
    return varietyList;
  }
  return null;
});

export const getLookupBlockVintageStatus = createSelector(
  [lkpBlockVintageStatus],
  blockStatus => {
    const { isLoading, hasError, data = [] } = blockStatus;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const blockStatusList = commonFieldSelectOption.concat(
        data
          .map(f => ({
            id: f.id,
            key: f.id,
            value: f.blockVintageStatus,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      return blockStatusList;
    }
    return null;
  },
);

export const getLookupBlockVintage = createSelector(
  [lkpBlockVintage],
  blockVintage => {
    const { isLoading, hasError, data = [] } = blockVintage;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const blockVintageStatusList = commonFieldSelectOption.concat(
        data
          .map(f => ({
            id: f.id,
            key: f.id,
            value: f.blockVintageStatus,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      return blockVintageStatusList;
    }
    return null;
  },
);

export const getLookupVintage = createSelector([lkpVintage], vintages => {
  const { isLoading, hasError, data = [] } = vintages;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const vintagesList = data
      .map(f => ({
        id: f.id,
        key: f.id,
        value: f.vintage,
        fromDate: f.fromDate,
        toDate: f.toDate,
        isActive: isWithinInterval(new Date(), {
          start: new Date(f.fromDate),
          end: new Date(f.toDate),
        }),
      }))
      .sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1));

    // Set most recent vintage as active if no others are
    const vintagesListWithActive = vintagesList.map((f, i) => ({
      ...f,
      isActive:
        f.isActive ||
        (i === data.length - 1 && !vintagesList.some(v => v.isActive)),
    }));

    return orderedLookupList(vintagesListWithActive);
  }
  return [];
});

export const getLookupTrellis = createSelector([lkpTrellis], trellis => {
  const { isLoading, hasError, data = [] } = trellis;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const trellisList = commonFieldSelectOption.concat(
      data
        .map(f => ({
          id: f.id,
          key: f.id,
          value: f.trellis,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
    return trellisList;
  }
  return null;
});

export const getLookupRowSpecialAttribute = createSelector(
  [lkpRowSpecialAttribute],
  rowSpecialAttribute => {
    const { isLoading, hasError, data = [] } = rowSpecialAttribute;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const RowSpecialAttributeList = commonFieldSelectOption.concat(
        data
          .map(f => ({
            id: f.id,
            key: f.id,
            value: f.rowSpecialAttribute,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      return RowSpecialAttributeList;
    }
    return null;
  },
);

export const getLookupRootStock = createSelector([lkpRootStock], rootStock => {
  const { isLoading, hasError, data = [] } = rootStock;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const rootStockList = commonFieldSelectOption.concat(
      data
        .map(f => ({
          id: f.id,
          key: f.id,
          value: f.rootStock,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
    return rootStockList;
  }
  return null;
});

export const getLookupVarietyClone = createSelector(
  [lkpVarietyClone],
  varietyClone => {
    const { isLoading, hasError, data = [] } = varietyClone;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const varietyCloneList = commonFieldSelectOption.concat(
        data
          .map(f => ({
            id: f.id,
            key: f.id,
            value: f.varietyClone,
            varietyID: f.varietyID,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      return varietyCloneList;
    }
    return null;
  },
);

export const getLookupVarietyCloneByID = createSelector(
  [getLkplkpVarietyCloneByID],
  varietyClone => {
    const data = varietyClone;
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const varietyCloneList = commonFieldSelectOption.concat(
        data
          .map(f => ({
            id: f.id,
            key: f.id,
            value: f.varietyClone,
            varietyID: f.varietyID,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      return varietyCloneList;
    }
    return null;
  },
);

export const getGrowerSubRegion = createSelector([lkpSubRegion], subRegion => {
  const { isLoading, hasError, data = [] } = subRegion;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const subRegionList = commonFieldSelectOption.concat(
      data
        .map(f => ({
          id: f.id,
          key: f.id,
          value: f.growingSubRegion,
          growingSubRegion: f.growingSubRegion,
          growingRegionID: f.growingRegionID,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
    return subRegionList;
  }
  return [];
});

export const getGrowerSubRegionWithAll = createSelector(
  [lkpSubRegion],
  subRegion => {
    const { isLoading, hasError, data = [] } = subRegion;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const subRegionList = commonFieldAllOption.concat(
        data
          .map(f => ({
            id: f.id,
            key: f.id,
            value: f.growingSubRegion,
            growingSubRegion: f.growingSubRegion,
            growingRegionID: f.growingRegionID,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      return subRegionList;
    }
    return [];
  },
);

export const getPayrollSystemData = createSelector(
  [lkpPayrollSystem],
  payroll => {
    const { isLoading, hasError, data = [] } = payroll;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const payrollList = commonFieldSelectOption.concat(
        data
          .map(f => ({
            id: f.id,
            key: f.id,
            value: f.payrollSystem,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      return payrollList;
    }
    return [];
  },
);

export const getGrowerRegion = createSelector([lkpRegion], subRegion => {
  const { isLoading, hasError, data = [] } = subRegion;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const list = commonFieldSelectOption.concat(
      data
        .map(f => ({
          id: f.id,
          key: f.id,
          value: f.growingRegion,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
    return list;
  }
  return [];
});

export const getLkpMeasure = createSelector([lkpMeasure], measureLkp => {
  const { isLoading, hasError, data = [] } = measureLkp;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const list = commonFieldSelectOption.concat(
      data
        .filter(x => x.isActive)
        .map(f => ({
          id: f.id,
          key: f.id,
          value: f.measure,
          measure: f.measure,
          measureGroupID: f.measureGroupID,
          measureDescription: f.measureDescription,
          measureScaleID: f.measureScaleID,
          measureFrom: f.measureFrom,
          isActive: f.isActive,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
    return list;
  }
  return [];
});

export const getLkpMeasureGroup = createSelector(
  [lkpMeasureGroup],
  measureLkp => {
    const { isLoading, hasError, data = [] } = measureLkp;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data
          .map(f => ({
            id: f.id,
            key: f.id,
            value: f.measureGroup,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      return list;
    }
    return [];
  },
);

export const getLkpMeasureInstance = createSelector(
  [lkpMeasureInstance],
  measureLkp => {
    const { isLoading, hasError, data = [] } = measureLkp;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data
          .filter(
            f => f.isActive && f.isEnableSampleCreation,
            //  f.measureGroupID === 1 /** Yield Model */,
          )
          .map(f => ({
            id: f.id,
            key: f.id,
            value: f.measureInstanceReportName,
            measureSort: f.measureSort,
            measureID: f.measureID,
            measureTypeID: f.measureTypeID,
            measureSourceID: f.measureSourceID,
            measureGroupID: f.measureGroupID,
            isActive: f.isActive,
            isEditable: f.isEditable,
            isEnableSampleCreation: f.isEnableSampleCreation,
            defaultSampleSize: f.defaultSampleSize,
            isDefaultSampleSize: f.isDefaultSampleSize,
            isAllowMultiple: f.isAllowMultiple,
            isYieldValue: f.isYieldValue,
            isYieldEstimate: f.isYieldEstimate,
            targetMeasureInstanceID: f.targetMeasureInstanceID,
            isValuePair: f.isValuePair,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      console.log('>>>', list);
      return list;
    }
    return [];
  },
);

export const getLkpNoteStatus = createSelector(
  [lkpNoteStatus],
  noteStatusLkp => {
    const { isLoading, hasError, data = [] } = noteStatusLkp;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data
          .map(f => ({
            id: f.id,
            key: f.id,
            value: f.noteStatus,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      return list;
    }
    return [];
  },
);

export const getLkpKeyword = createSelector([lkpKeyword], keywordLkp => {
  const { isLoading, hasError, data = [] } = keywordLkp;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const list = commonFieldSelectOption.concat(
      data
        .map(f => ({
          id: f.id,
          key: f.id,
          value: f.keyword,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
    return list;
  }
  return [];
});

export const getLkpSampleReqStatus = createSelector(
  [lkpSampleReqStatus],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data
          .map(f => ({
            id: f.id,
            key: f.id,
            value: f.sampleRequestStatus,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      return list;
    }
    return [];
  },
);

export const getLkpDataProvider = createSelector([lkpDataProvider], lkpData => {
  const { isLoading, hasError, data = [] } = lkpData;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const list = commonFieldSelectOption.concat(
      data
        .map(f => ({
          id: f.id,
          key: f.id,
          value: `${f.dataProviderName} (${f.dataProviderDescription})`,
          dataProviderTypeID: f.dataProviderTypeID,
          isCallExternal: f.isCallExternal,
        }))
        .sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        ),
    );
    return list;
  }
  return [];
});

export const getLkpDataProviderType = createSelector(
  [lkpDataProviderType],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data
          .map(f => ({
            id: f.id,
            key: f.id,
            value: f.providerType,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      return list;
    }
    return [];
  },
);

export const getLkpPhenologyMeasure = createSelector(
  [lkpPhenologyMeasure],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data
          .filter(f => f.isActive)
          .map(f => ({
            ...f,
            id: f.id,
            key: f.id,
            value: f.measure,
          }))
          .sort((a, b) =>
            a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
          ),
      );
      return list;
    }
    return [];
  },
);

export const getLkpPhenologyMeasureOption = createSelector(
  [lkpPhenologyMeasureOption],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data
          .map(f => ({
            ...f,
            id: f.id,
            key: f.id,
            value: f.measureOption,
          }))
          .sort((a, b) => (a.value > b.value ? 1 : -1)),
      );
      return list;
    }
    return [];
  },
);

export const getLkpPhenologyMeasureSet = createSelector(
  [lkpPhenologyMeasureSet],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data
          .map(f => ({
            ...f,
            id: f.id,
            key: f.id,
            value: f.phenologyMeasureSet,
          }))
          .sort((a, b) => (a.value > b.value ? 1 : -1)),
      );

      return list;
    }
    return [];
  },
);

export const getLkpPhenologyMeasureType = createSelector(
  [lkpPhenologyMeasureType],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data
          .map(f => ({
            ...f,
            id: f.id,
            key: f.id,
            value: f.phenologyMeasureType,
          }))
          .sort((a, b) => (a.value > b.value ? 1 : -1)),
      );
      return list;
    }
    return [];
  },
);

export const getLkpCropSettingInstance = createSelector(
  [lkpCropSettingInstance],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data
          .map(f => ({
            ...f,
            id: f.id,
            key: f.id,
            value: f.cropSettingInstance,
          }))
          .sort((a, b) => (a.cropSettingSort > b.cropSettingSort ? 1 : -1)),
      );
      return list;
    }
    return [];
  },
);

export const getLkpCropSettingOption = createSelector(
  [lkpCropSettingOption],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data
          .map(f => ({
            ...f,
            id: f.id,
            key: f.id,
            value: f.cropSettingOption,
          }))
          .sort((a, b) =>
            a.cropSettingOptionSortOrder > b.cropSettingOptionSortOrder
              ? 1
              : -1,
          ),
      );
      return list;
    }
    return [];
  },
);

export const getLkpSensorStatus = createSelector([getSensorStatus], lkpData => {
  const { isLoading, hasError, data = [] } = lkpData;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const list = commonFieldSelectOption.concat(
      data.map(f => ({
        ...f,
        id: f.id,
        key: f.id,
        value: f.sensorStatus,
      })),
    );
    return list;
  }
  return [];
});

export const getLkpDocumentType = createSelector([getDocumentType], lkpData => {
  const { isLoading, hasError, data = [] } = lkpData;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const list = commonFieldSelectOption.concat(
      data.map(f => ({
        ...f,
        id: f.id,
        key: f.id,
        value: f.documentTypeDescription,
      })),
    );
    return list;
  }
  return [];
});

export const getLkpActivity = createSelector([getActivity], lkpData => {
  const { isLoading, hasError, data = [] } = lkpData;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const list = commonFieldSelectOption.concat(
      data.map(f => ({
        ...f,
        id: f.id,
        key: f.id,
        value: f.activityCode,
        lastUsedDate: formatAsDateFormat(f.lastUsedDate),
      })),
    );
    return list;
  }
  return [];
});

export const getLkpJobType = createSelector([lkpJobType], lkpData => {
  const { isLoading, hasError, data = [] } = lkpData;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const list = commonFieldSelectOption.concat(
      orderBy(
        data.map(f => ({
          ...f,
          id: f.id,
          key: f.id,
          value: f.jobType,
          siteTypeID: f.siteTypeID,
          defaultWorkUnitID: f.defaultWorkUnitID,
          defaultWorkUnitValue: f.defaultWorkUnitValue,
        })),
        ['value'],
        ['asc'],
      ),
    );
    return list;
  }
  return [];
});

export const getLkpJobTypeWithoutSelect = createSelector(
  [lkpJobType],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = orderBy(
        data.map(f => ({
          ...f,
          id: f.id,
          key: f.id,
          value: f.jobType,
          siteTypeID: f.siteTypeID,
          defaultWorkUnitID: f.defaultWorkUnitID,
          defaultWorkUnitValue: f.defaultWorkUnitValue,
        })),
        ['value'],
        ['asc'],
      );
      return list;
    }
    return [];
  },
);

export const getLkpJobQuantityType = createSelector(
  [lkpJobQuantityType],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data.map(f => ({
          ...f,
          id: f.id,
          key: f.id,
          value: f.description,
        })),
      );
      return list;
    }
    return [];
  },
);

export const getLkpJobWorkRecordLevel = createSelector(
  [lkpWorkRecordLevel],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data.map(f => ({
          ...f,
          id: f.id,
          key: f.id,
          value: f.description,
        })),
      );
      return list;
    }
    return [];
  },
);

export const getLkpJobTypeWorkRecordType = createSelector(
  [lkpJobTypeWorkRecordType],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data.map(f => ({
          ...f,
          id: f.id,
          key: f.id,
          value: f.jobTypeID,
        })),
      );
      return list;
    }
    return [];
  },
);

export const getLkpJobWorkUnit = createSelector([lkpJobWorkUnit], lkpData => {
  const { isLoading, hasError, data = [] } = lkpData;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const list = commonFieldSelectOption.concat(
      data.map(f => ({
        ...f,
        id: f.id,
        key: f.id,
        value: f.workUnitID,
      })),
    );
    return list;
  }
  return [];
});

export const getLkpJobPayrollRecordType = createSelector(
  [lkpPayrollRecordType],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data.map(f => ({
          ...f,
          id: f.id,
          key: f.id,
          value: f.payrollRecordType,
        })),
      );
      return list;
    }
    return [];
  },
);

export const getLkpJobInvoiceMethod = createSelector(
  [lkpJobInvoiceMethod],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data.map(f => ({
          ...f,
          id: f.id,
          key: f.id,
          value: f.invoiceMethod,
        })),
      );
      return list;
    }
    return [];
  },
);

export const getLkpJobEventAction = createSelector(
  [lkpJobEventAction],
  lkpData => {
    const { isLoading, hasError, data = [] } = lkpData;
    if (isLoading || hasError) return [];
    if (!Array.isArray(data)) return [];
    if (!isEmpty(data)) {
      const list = commonFieldSelectOption.concat(
        data.map(f => ({
          ...f,
          id: f.id,
          key: f.id,
          value: f.invoiceMethod,
        })),
      );
      return list;
    }
    return [];
  },
);

export const getLkpVehicleType = createSelector([lkpVehicleType], lkpData => {
  const { isLoading, hasError, data = [] } = lkpData;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const list = commonFieldSelectOption.concat(
      data.map(f => ({
        ...f,
        id: f.id,
        key: f.id,
        value: f.vehicleType,
      })),
    );
    return list;
  }
  return [];
});

export const getLkpVehicleMake = createSelector([lkpVehicleMake], lkpData => {
  const { isLoading, hasError, data = [] } = lkpData;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const list = commonFieldSelectOption.concat(
      data.map(f => ({
        ...f,
        id: f.id,
        key: f.id,
        value: f.vehicleMake,
      })),
    );
    return list;
  }
  return [];
});

export const getLkpImportType = createSelector([lkpImportType], lkpData => {
  const { isLoading, hasError, data = [] } = lkpData;
  if (isLoading || hasError) return [];
  if (!Array.isArray(data)) return [];
  if (!isEmpty(data)) {
    const list = commonFieldSelectOption.concat(
      data.map(f => ({
        ...f,
        id: f.id,
        key: f.id,
        value: f.importType,
      })),
    );
    return list;
  }
  return [];
});

export const areLookupsLoading = createSelector(
  [VineaNovaSelectors.areLookupsLoading],
  areLookupsLoadingResult => areLookupsLoadingResult,
);

export const areLookupsLoaded = createSelector(
  [VineaNovaSelectors.areLookupsLoaded],
  areLoadedLookups => areLoadedLookups,
);
