/* eslint-disable react/prop-types */
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import useLocalStorage from 'react-use-localstorage';
import { get, some } from 'lodash';
import classNames from 'classnames';
// eslint-disable-next-line import/no-cycle
import { VineaNovaActions, VineaHooks } from 'vineanova-redux-artifacts';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from './ErrorBoundary';
import {
  sagaActionTypes,
  LOCAL_ARC_GIS_TOKEN,
  LOCAL_ARC_GIS_TOKEN_CREATED,
} from '../constants';
import { Header } from '../components/Header';
import Sidebar from '../components/Sidebar';
import { getAuthenticationResult } from '../redux/selectors/authenticationSelector';
import { getUserPreferences } from '../redux/selectors';
import withAuthProvider from '../hooks/AuthProvider';
import { ResponsiveLayout } from './ResponsiveLayout';
import SharedLayout from './SharedLayout';
import useRefDimensions from '../hooks/useRefDimensions';

const drawerWidth = 240;

const Root = styled('div')(() => ({
  display: 'flex',
  height: '100vh',
}));

const StyledMain = styled('main')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    flexGrow: 1,
    marginLeft: theme.spacing(9),
    overflowX: 'hidden',
  },
  contentShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  grid: {
    margin: '10px',
    backgroundColor: theme.palette.common.white,
    height: '90vh',
  },
  contentWrapper: {
    // flexGrow: 1,
    marginLeft: theme.spacing(9),
    marginTop: theme.spacing(7),
    overflowX: 'hidden',
  },
  paper: {
    padding: theme.spacing(1),
  },
  landingpage: {
    // marginLeft: theme.spacing(5),
    // flex: 1,
  },
}));

const ProtectedLayout = props => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const mainPageRef = React.useRef(null);
  const { isAuthenticated } = useSelector(state => state.authentication);
  const language = useSelector(state => state?.language?.selectedLanguage);
  const { isUserAllowed, isVineaAdmin } = useSelector(state =>
    getAuthenticationResult(state),
  );
  const [, setArcGISToken] = useLocalStorage(LOCAL_ARC_GIS_TOKEN, null);
  const [, setArcGISTokenCreated] = useLocalStorage(
    LOCAL_ARC_GIS_TOKEN_CREATED,
    null,
  );

  const { data: allDataConnectors } =
    VineaHooks.useFetchAllIdentityDataConnectors({});
  const hasSensorData = some(allDataConnectors, {
    providerType: 'Sensor Data',
  });
  const hasWineryData = some(allDataConnectors, {
    providerType: 'Winery Data',
  });
  const { basicSettings } = useSelector(state => getUserPreferences(state));
  const dimensions = useRefDimensions(mainPageRef);
  // const deepLinkValue = useSelector(state => state.deeplink.data);
  const { login, logout, children } = props;

  useEffect(() => {
    if (isAuthenticated) {
      dispatch({ type: sagaActionTypes.USER_LOGIN });
      if (hasSensorData) {
        dispatch(
          VineaNovaActions.api.v1.triggerSensorSummaryDataSync.get.request(),
        );
      }
      if (hasWineryData) {
        dispatch(
          VineaNovaActions.api.v1.triggerVintraceMetricDataSync.get.request(),
        );
      }
    }
  }, [dispatch, isAuthenticated, hasSensorData, hasWineryData]);

  useEffect(() => {
    // only do something if the location path is matching /mangeconnections
    if (location.pathname.includes('/manageconnections')) {
      const hash = get(location, 'hash', '');
      if (hash.includes('#access_token')) {
        // get token from hash
        const token = hash.split('=')[1];
        // dispatch action to store token
        setArcGISToken(token);
        setArcGISTokenCreated(Math.floor(new Date().getTime()));
        // eslint-disable-next-line no-undef
        window.close();
      }
    }
  }, [location]);

  const handleOnLanguageSelect = React.useCallback(langValue => {
    dispatch({ type: 'LANGUAGE_CHANGE', payload: langValue });
  }, []);

  const handleOnSetDeepLink = React.useCallback(val => {
    dispatch({ type: 'SUCCESS', payload: val, name: 'DEEP_LINK' });
  }, []);

  const handleLanguageChange = langKey => {
    const langValue = langKey;
    i18n.changeLanguage(langKey);
    handleOnLanguageSelect(langValue);
  };

  const handleOnSettingsClick = () => {
    navigate('/settings');
  };

  const handleToggleDrawer = () => {
    const sideBarIsOpen = !open;
    setOpen(sideBarIsOpen);
  };

  useEffect(() => {
    handleOnSetDeepLink(location.pathname);
  }, []);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_DATA',
      payload: dimensions,
      name: 'PAGE_DIMENSION',
    });
  }, [dimensions]);

  useEffect(() => {
    handleOnLanguageSelect(basicSettings.language);
  }, [basicSettings.language, handleOnLanguageSelect]);

  const showPaddingForRoutes = [
    '/organisation',
    '/person',
    '/samples',
    '/tracking',
  ];
  // const isVineyard = path.includes('/organisation');
  // const isPerson = path.includes('/person');
  const showTopPadding = showPaddingForRoutes.includes(location);

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <SharedLayout>
        <Root data-testid="main-layout" ref={mainPageRef}>
          {/** No header show for landing page */}
          {isUserAllowed && (
            <Header
              handleToggleDrawer={handleToggleDrawer}
              isAuthenticated={!!isAuthenticated}
              login={login}
              logout={logout}
              selectedLanguageValue={language}
              handleLanguageChange={handleLanguageChange}
              handleOnSettingsClick={handleOnSettingsClick}
            />
          )}
          <StyledMain data-testid="main">
            {/** When landing page */}
            {/** When not  the landing page */}
            <>
              {!showTopPadding && isUserAllowed && (
                <div className={classes.contentWrapper}>
                  <Paper
                    elevation={0}
                    data-testid="submenu-paper"
                    className={classes.paper}
                  />
                </div>
              )}
              <div
                data-testid="child-area"
                className={classNames(classes.content, {
                  [classes.contentShift]: open,
                })}
              >
                {/* <Outlet /> */}
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <ResponsiveLayout>{children}</ResponsiveLayout>
                </ErrorBoundary>
              </div>
            </>
          </StyledMain>
        </Root>
        {/** No sidebar shown for landing page */}
        {isAuthenticated && isUserAllowed && (
          <Sidebar
            open={open}
            drawerWidth={drawerWidth}
            isAuthenticated
            isVineaAdmin={isVineaAdmin}
          />
        )}
      </SharedLayout>
    </>
  );
};

export default withAuthProvider(ProtectedLayout);
