/* eslint-disable no-unused-vars */
import { Routes, Route } from 'react-router-dom';
import { useState } from 'react';
/* eslint-disable import/no-cycle */
import { Dashboard } from '../containers';
import Setting from '../containers/Setting/Setting';
import { FeatureInDevelopment } from '../components/FeatureInDevelopment';
import { Login } from '../containers/Login';
import { Logout } from '../containers/Logout';
import { NotFound } from '../components/NotFound';
import { OrganisationContainer } from '../containers/Organisation';
import UnauthorizedContainer from '../containers/Unauthorized/Unauthorized';
import {
  IdentitySearch,
  IdentityScreen,
  StepperCreateIdentity,
  ManageClassifications,
  ManageContacts,
  ManageReferences,
  ManageVineyards,
  ManageRelationships,
  ManageConsumables,
  ManageAccommodation,
  ManageActivity,
} from '../containers/VineyardIdentity';
import Plans from '../containers/Plans/Plans';
import {
  VineyardSamplingContainer,
  SampleDetailsContainer,
  SampleSearchResult,
  NewSampleSet,
  VineyardSampleDetailsContainer,
  AddSampleRequestBlocks,
  SampleDetails,
} from '../containers/Samples';
import { ManagePersonDetails } from '../containers/PersonIdentity';
import { FormSamples } from '../containers/FormSamples';
import { BoldReportExample } from '../containers/FormSamples/BoldReportsExample';
import { Landing } from '../containers/Landing';
import {
  BlockDetailsContainer,
  ManageBlockRowDetails,
  AddBlockRowDetail,
} from '../containers/VineyardBlocks';
import { ManageIdentityNotes } from '../containers/IdentityNotes/Manage';
import { ManageVintageDetails } from '../containers/Vintage';
import { ManageServiceProvider } from '../containers/ServieProviderIdentity/Manage/ManageServiceProvider';
import { ManageConnectionDetails } from '../containers/DataConnectionIdentity/Manage/ManageConnection';
import { ManageOrganisation } from '../containers/Organisation/Manage/ManageOrganisation';
import { ManageRoles } from '../containers/VineyardIdentity/Manage/ManageRoles';
import { TrackingSearchResult } from '../containers/Tracking';
import { ManageStatus } from '../containers/VineyardIdentity/Manage';
import LookupLayout from '../layouts/LookupLayout';
import ProtectedLayout from '../layouts/ProtectedLayout';
import { ManageSensor } from '../containers/DataConnectionIdentity/Sensors/ManageSensor';
import ActivityProgressMap from '../containers/ActivityProgress';
import VineyardLocationMap from '../containers/VineyardLocation';
import { ReportingContainer } from '../containers/Reporting';
import Users from '../containers/Users/Users';
import CreateUsers from '../containers/Users/Create/CreateUser';
import ManageUsers from '../containers/Users/Manage/ManageUser';
import CreatePlan from '../containers/Plans/Create/CreatePlan';
import ManagePlan from '../containers/Plans/Manage/ManagePlan';
import PlanDetails from '../containers/Plans/PlanDetails';
import { ActivityEdit } from '../containers/Plans';
import ManageSupplyContract from '../containers/SupplyContracts/ManageSupplyContract';
import { UserSettings } from '../containers/UserSettings/UserSettings';
import { PAGE_LOOKSUPS } from '../constants';
import SupplyContracts from '../containers/SupplyContracts/SupplyContracts';
import WorkerSearch from '../containers/Workers/WorkerSearch';
import WorkerWorkRecords from '../containers/Workers/WorkerWorkRecords';
import ManageJob from '../containers/Jobs/Manage/ManageJob';
import ManageJobBlockRows from '../containers/Jobs/Manage/ManageJobBlockRows';
import { ManageJobActivity } from '../containers/Jobs';
import useIdentityTypeScreenNameHook from '../hooks/useIdentityTypeScreenNameHook';
import { ExcelCsvImporter } from '../containers/ImportData/ExcelCsvImporter';

export const RoutesLayout = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  return (
    <Routes>
      <Route path="/" element={<Landing />} />

      <Route element={<ProtectedLayout />}>
        <Route path="unauthorized" element={<UnauthorizedContainer />} />
        <Route
          path="dashboard"
          element={
            <LookupLayout lookups={[PAGE_LOOKSUPS.DASHBOARD_LOOKUPS]}>
              <Dashboard />
            </LookupLayout>
          }
        />
        <Route
          path="home"
          element={
            <LookupLayout lookups={[PAGE_LOOKSUPS.DASHBOARD_LOOKUPS]}>
              <Dashboard />
            </LookupLayout>
          }
        />
        <Route path="maps">
          <Route path="activity-progress">
            <Route
              index
              element={
                <LookupLayout
                  lookups={[PAGE_LOOKSUPS.ACTIVITY_PROGRESS_LOOKUPS]}
                >
                  <ActivityProgressMap />
                </LookupLayout>
              }
            />
          </Route>
          <Route path={`${vineyardTypeScreenName}-explorer`}>
            <Route
              index
              element={
                <LookupLayout
                  lookups={[PAGE_LOOKSUPS.VINEYARD_EXPLORER_LOOKUPS]}
                >
                  <VineyardLocationMap />
                </LookupLayout>
              }
            />
          </Route>
        </Route>
        <Route path="picker" element={<Setting />} />
        <Route path="setting" element={<Setting />} />
        <Route path="users">
          <Route
            index
            element={
              <LookupLayout lookups={[PAGE_LOOKSUPS.USER_LOOKUPS]}>
                <Users />
              </LookupLayout>
            }
          />
          <Route path="newuser" element={<CreateUsers />} />
          <Route path=":id">
            <Route path="manageuser" element={<ManageUsers />} />
          </Route>
        </Route>
        <Route path="reporting" element={<ReportingContainer />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="sampleform" element={<FormSamples />} />
        <Route path="boldreports" element={<BoldReportExample />} />

        <Route path="dataimport">
          <Route index element={<ExcelCsvImporter />} />
        </Route>

        <Route path="connections">
          <Route
            index
            element={
              <LookupLayout
                lookups={[
                  PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                  PAGE_LOOKSUPS.DATA_CONNECTION_LOOKUPS,
                ]}
              >
                <IdentitySearch />
              </LookupLayout>
            }
          />
          <Route path="newconnection" element={<StepperCreateIdentity />} />

          <Route index element={<IdentitySearch />} />
          <Route path=":id">
            <Route
              index
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route
              path="manageconnections"
              element={<ManageConnectionDetails />}
            />
            <Route
              path="managerelationships"
              element={<ManageRelationships />}
            />
            <Route path="managecontacts" element={<ManageContacts />} />
            <Route
              path="syncmaps"
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route
              path="more"
              element={
                <LookupLayout
                  lookups={[
                    PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                    PAGE_LOOKSUPS.DATA_CONNECTION_LOOKUPS,
                    PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                  ]}
                >
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </LookupLayout>
              }
            />
            <Route path="managestatus" element={<ManageStatus />} />
            <Route path="notes">
              <Route
                index
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route path=":noteId" element={<ManageIdentityNotes />} />
            </Route>
            <Route
              path="sites"
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route path="sensors">
              <Route
                index
                element={
                  <LookupLayout lookups={[PAGE_LOOKSUPS.SENSORS_LOOKUPS]}>
                    <IdentityScreen
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </LookupLayout>
                }
              />

              <Route path=":sensorId" element={<ManageSensor />} />
            </Route>
          </Route>
        </Route>
        <Route path="supplycontract">
          <Route
            index
            element={
              <LookupLayout lookups={[PAGE_LOOKSUPS.IDENTITY_LOOKUPS]}>
                <SupplyContracts />
              </LookupLayout>
            }
          />
          <Route path=":id">
            <Route
              index
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route
              path="overview"
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route
              path="maps"
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route path="notes">
              <Route
                index
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route path=":noteId" element={<ManageIdentityNotes />} />
            </Route>
            <Route
              path="more"
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route
              path="manageclassifications"
              element={<ManageClassifications />}
            />
            <Route path="managereferences" element={<ManageReferences />} />
            <Route
              path="managesupplycontract"
              element={<ManageSupplyContract />}
            />
            <Route path="managestatus" element={<ManageStatus />} />
          </Route>
          <Route path="newsupplycontract" element={<StepperCreateIdentity />} />
        </Route>
        <Route path="person">
          <Route
            index
            element={
              <LookupLayout lookups={[PAGE_LOOKSUPS.IDENTITY_LOOKUPS]}>
                <IdentitySearch />
              </LookupLayout>
            }
          />
          <Route path="newperson" element={<StepperCreateIdentity />} />
          <Route index element={<IdentitySearch />} />
          <Route path=":id">
            <Route
              index
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route
              path="managepersondetails"
              element={<ManagePersonDetails />}
            />
            <Route path="managereferences" element={<ManageReferences />} />
            <Route
              path="manageclassifications"
              element={<ManageClassifications />}
            />
            <Route path="managecontacts" element={<ManageContacts />} />
            <Route
              path="managerelationships"
              element={<ManageRelationships />}
            />
            <Route
              path="more"
              element={
                <LookupLayout
                  lookups={[
                    PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                    PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                  ]}
                >
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </LookupLayout>
              }
            />
            <Route path="managestatus" element={<ManageStatus />} />
            <Route path="manageroles" element={<ManageRoles />} />
            <Route path="notes">
              <Route
                index
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route path=":noteId" element={<ManageIdentityNotes />} />
            </Route>
          </Route>
        </Route>
        <Route path="worker">
          <Route
            index
            element={
              <LookupLayout lookups={[PAGE_LOOKSUPS.IDENTITY_LOOKUPS]}>
                <WorkerSearch />
              </LookupLayout>
            }
          />
          <Route path="newworker" element={<StepperCreateIdentity />} />
          <Route path=":id">
            <Route
              index
              element={
                <LookupLayout lookups={[PAGE_LOOKSUPS.IDENTITY_LOOKUPS]}>
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </LookupLayout>
              }
            />
            <Route
              path="workrecords/:payPeriodId"
              element={<WorkerWorkRecords />}
            />
            <Route
              path="manageworkerdetails"
              element={<ManagePersonDetails />}
            />
            <Route path="managereferences" element={<ManageReferences />} />
            <Route
              path="manageclassifications"
              element={<ManageClassifications />}
            />
            <Route path="managecontacts" element={<ManageContacts />} />
            <Route
              path="managerelationships"
              element={<ManageRelationships />}
            />
            <Route
              path="overview"
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route
              path="more"
              element={
                <LookupLayout
                  lookups={[
                    PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                    PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                  ]}
                >
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </LookupLayout>
              }
            />
            <Route path="managestatus" element={<ManageStatus />} />
            <Route path="manageroles" element={<ManageRoles />} />
            <Route path="notes">
              <Route
                index
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route path=":noteId" element={<ManageIdentityNotes />} />
            </Route>
          </Route>
        </Route>
        <Route path="consumable">
          <Route
            index
            element={
              <LookupLayout
                lookups={[
                  PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                  PAGE_LOOKSUPS.CONSUMABLE_LOOKUPS,
                ]}
              >
                <IdentitySearch />
              </LookupLayout>
            }
          />
          <Route path="newconsumable" element={<StepperCreateIdentity />} />
          <Route path=":id">
            <Route
              index
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route path="manageconsumables" element={<ManageConsumables />} />
            <Route
              path="manageclassifications"
              element={<ManageClassifications />}
            />
            <Route
              path="managerelationships"
              element={<ManageRelationships />}
            />
            <Route path="managereferences" element={<ManageReferences />} />
            <Route path="managestatus" element={<ManageStatus />} />
            <Route
              path="more"
              element={
                <LookupLayout
                  lookups={[
                    PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                    PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                  ]}
                >
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </LookupLayout>
              }
            />
            <Route path="notes">
              <Route
                index
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route path=":noteId" element={<ManageIdentityNotes />} />
            </Route>
            <Route
              path="transactions"
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
          </Route>
        </Route>
        <Route path="tasks" element={<FeatureInDevelopment />} />
        <Route path="activity">
          <Route
            index
            element={
              <LookupLayout
                lookups={[
                  PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                  PAGE_LOOKSUPS.IDENTITY_ACTIVITY_LOOKUPS,
                ]}
              >
                <IdentitySearch />
              </LookupLayout>
            }
          />
          <Route path="newactivity" element={<StepperCreateIdentity />} />
          <Route path=":id">
            <Route
              index
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route
              path="defaultrates"
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route
              path="references"
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />{' '}
            <Route path="managereferences" element={<ManageReferences />} />
            <Route path="notes">
              <Route
                index
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route path=":noteId" element={<ManageIdentityNotes />} />
            </Route>
            <Route path="manageactivity" element={<ManageActivity />} />
            <Route path="managestatus" element={<ManageStatus />} />
          </Route>
        </Route>
        <Route path="plans">
          <Route
            index
            element={
              <LookupLayout
                lookups={[
                  PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                  PAGE_LOOKSUPS.PLANS_LOOKUPS,
                ]}
              >
                <Plans />
              </LookupLayout>
            }
          />
          <Route path="newplan" element={<CreatePlan />} />
          <Route path=":id">
            <Route index element={<PlanDetails />} />
            <Route path="overview" element={<PlanDetails />} />
            <Route path="blocks" element={<PlanDetails />} />
            <Route path="history" element={<PlanDetails />} />
            <Route path="jobs" element={<PlanDetails />} />
            <Route path="manageplan" element={<ManagePlan />} />
            <Route path="managestatus" element={<ManageStatus />} />
            <Route
              path="edit-plan-activity/:activityId"
              element={<ActivityEdit />}
            />
            <Route path="add-plan-activity/" element={<ActivityEdit />} />
          </Route>
        </Route>
        <Route path="vehicle">
          <Route
            index
            element={
              <LookupLayout
                lookups={[
                  PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                  PAGE_LOOKSUPS.VEHICLE_LOOKUPS,
                ]}
              >
                <IdentitySearch />
              </LookupLayout>
            }
          />
          <Route path="newvehicle" element={<StepperCreateIdentity />} />
          <Route path=":id">
            <Route
              index
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route
              path="more"
              element={
                <LookupLayout lookups={[PAGE_LOOKSUPS.IDENTITY_LOOKUPS]}>
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </LookupLayout>
              }
            />
            <Route path="notes">
              <Route
                index
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route path=":noteId" element={<ManageIdentityNotes />} />
            </Route>
            <Route path="managecontacts" element={<ManageContacts />} />
            <Route
              path="manageclassifications"
              element={<ManageClassifications />}
            />
            <Route
              path="managerelationships"
              element={<ManageRelationships />}
            />
            <Route path="managereferences" element={<ManageReferences />} />
            <Route path="managestatus" element={<ManageStatus />} />
          </Route>
        </Route>
        <Route path="accommodation">
          <Route
            index
            element={
              <LookupLayout lookups={[PAGE_LOOKSUPS.IDENTITY_LOOKUPS]}>
                <IdentitySearch />
              </LookupLayout>
            }
          />
          <Route path="newaccommodation" element={<StepperCreateIdentity />} />
          <Route path=":id">
            <Route
              index
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route path="managecontacts" element={<ManageContacts />} />
            <Route
              path="manageaccommodation"
              element={<ManageAccommodation />}
            />
            <Route
              path="manageclassifications"
              element={<ManageClassifications />}
            />
            <Route
              path="managerelationships"
              element={<ManageRelationships />}
            />
            <Route path="managereferences" element={<ManageReferences />} />
            <Route path="managestatus" element={<ManageStatus />} />
            <Route
              path="more"
              element={
                <LookupLayout lookups={[PAGE_LOOKSUPS.IDENTITY_LOOKUPS]}>
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                </LookupLayout>
              }
            />
            <Route path="notes">
              <Route
                index
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route path=":noteId" element={<ManageIdentityNotes />} />
            </Route>
          </Route>
        </Route>
        <Route path="organisation">
          <Route index element={<OrganisationContainer />} />
          <Route path={`${vineyardTypeScreenName}s`}>
            <Route
              index
              element={
                <LookupLayout
                  lookups={[
                    PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                    PAGE_LOOKSUPS.BLOCK_LOOKUPS,
                  ]}
                >
                  <IdentitySearch />
                </LookupLayout>
              }
            />
            <Route
              path={`new${vineyardTypeScreenName}`}
              element={<StepperCreateIdentity />}
            />
            <Route path=":id">
              <Route
                index
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route
                path={`manage${vineyardTypeScreenName}s`}
                element={<ManageVineyards />}
              />
              <Route path="managereferences" element={<ManageReferences />} />
              <Route
                path="manageclassifications"
                element={<ManageClassifications />}
              />
              <Route path="managecontacts" element={<ManageContacts />} />
              <Route
                path="managerelationships"
                element={<ManageRelationships />}
              />
              <Route
                path="more"
                element={
                  <LookupLayout
                    lookups={[
                      PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                      PAGE_LOOKSUPS.BLOCK_LOOKUPS,
                      PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                    ]}
                  >
                    <IdentityScreen
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </LookupLayout>
                }
              />
              <Route path="manageroles" element={<ManageRoles />} />
              <Route
                path="blockmaps"
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route
                path="assets"
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route
                path="jobs"
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route path="managestatus" element={<ManageStatus />} />
              <Route
                path="more"
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route path="notes">
                <Route
                  index
                  element={
                    <LookupLayout
                      lookups={[
                        PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                        PAGE_LOOKSUPS.BLOCK_LOOKUPS,
                        PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                      ]}
                    >
                      <IdentityScreen
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                      />
                    </LookupLayout>
                  }
                />
                <Route path=":noteId" element={<ManageIdentityNotes />} />
              </Route>
              <Route path="blocks">
                <Route
                  index
                  element={
                    <LookupLayout
                      lookups={[
                        PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                        PAGE_LOOKSUPS.BLOCK_LOOKUPS,
                        PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                        PAGE_LOOKSUPS.SAMPLING_TRACKING_LOOKUPS,
                      ]}
                    >
                      <IdentityScreen
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                      />
                    </LookupLayout>
                  }
                />
                <Route
                  path="create"
                  element={
                    <LookupLayout
                      lookups={[
                        PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                        PAGE_LOOKSUPS.BLOCK_LOOKUPS,
                        PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                      ]}
                    >
                      <BlockDetailsContainer />
                    </LookupLayout>
                  }
                />
                <Route path=":blockId">
                  <Route index element={<BlockDetailsContainer />} />
                  <Route
                    path="vintagedetails"
                    element={<ManageVintageDetails />}
                  />
                  <Route path="blockRows" element={<ManageBlockRowDetails />} />
                  <Route path="blockRows">
                    <Route index element={<ManageBlockRowDetails />} />
                    <Route path="createRows" element={<AddBlockRowDetail />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="other-organisations">
            <Route
              index
              element={
                <LookupLayout lookups={[PAGE_LOOKSUPS.IDENTITY_LOOKUPS]}>
                  <IdentitySearch />
                </LookupLayout>
              }
            />
            <Route path="neworganisation" element={<StepperCreateIdentity />} />
            <Route path=":id">
              <Route
                index
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route
                path="manageorganisation"
                element={<ManageOrganisation />}
              />
              <Route path="managereferences" element={<ManageReferences />} />
              <Route
                path="manageclassifications"
                element={<ManageClassifications />}
              />
              <Route path="managecontacts" element={<ManageContacts />} />
              <Route
                path="managerelationships"
                element={<ManageRelationships />}
              />
              <Route
                path="more"
                element={
                  <LookupLayout
                    lookups={[
                      PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                      PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                    ]}
                  >
                    <IdentityScreen
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </LookupLayout>
                }
              />
              <Route path="managestatus" element={<ManageStatus />} />
              <Route path="manageroles" element={<ManageRoles />} />
              <Route path="notes">
                <Route
                  index
                  element={
                    <LookupLayout
                      lookups={[
                        PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                        PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                      ]}
                    >
                      <IdentityScreen
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                      />
                    </LookupLayout>
                  }
                />
                <Route path=":noteId" element={<ManageIdentityNotes />} />
              </Route>
            </Route>
          </Route>
          <Route path="serviceproviders">
            <Route
              index
              element={
                <LookupLayout lookups={[PAGE_LOOKSUPS.IDENTITY_LOOKUPS]}>
                  <IdentitySearch />
                </LookupLayout>
              }
            />
            <Route
              path="newserviceprovider"
              element={<StepperCreateIdentity />}
            />
            <Route path=":id">
              <Route
                index
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route
                path="manageserviceproviders"
                element={<ManageServiceProvider />}
              />
              <Route path="managereferences" element={<ManageReferences />} />
              <Route
                path="manageclassifications"
                element={<ManageClassifications />}
              />
              <Route path="managecontacts" element={<ManageContacts />} />
              <Route
                path="managerelationships"
                element={<ManageRelationships />}
              />
              <Route path="manageroles" element={<ManageRoles />} />
              <Route
                path="activitymapping"
                element={
                  <LookupLayout
                    lookups={[
                      PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                      PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                    ]}
                  >
                    <IdentityScreen
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </LookupLayout>
                }
              />
              <Route
                path="more"
                element={
                  <LookupLayout
                    lookups={[
                      PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                      PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                    ]}
                  >
                    <IdentityScreen
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                    />
                  </LookupLayout>
                }
              />
              <Route path="managestatus" element={<ManageStatus />} />
              <Route path="notes">
                <Route
                  index
                  element={
                    <LookupLayout
                      lookups={[
                        PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                        PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                      ]}
                    >
                      <IdentityScreen
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                      />
                    </LookupLayout>
                  }
                />
                <Route path=":noteId" element={<ManageIdentityNotes />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="job">
          <Route
            index
            element={
              <LookupLayout
                lookups={[
                  PAGE_LOOKSUPS.JOB_LOOKUPS,
                  PAGE_LOOKSUPS.IDENTITY_LOOKUPS,
                  PAGE_LOOKSUPS.BLOCK_LOOKUPS,
                  PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS,
                ]}
              >
                <IdentitySearch />
              </LookupLayout>
            }
          />
          <Route path="newjob" element={<StepperCreateIdentity />} />
          <Route path=":id">
            <Route
              index
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route
              path="overview"
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route
              path="more"
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route path="notes">
              <Route
                index
                element={
                  <IdentityScreen
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                  />
                }
              />
              <Route path=":noteId" element={<ManageIdentityNotes />} />
            </Route>
            <Route
              path="maps"
              element={
                <IdentityScreen tabIndex={tabIndex} setTabIndex={setTabIndex} />
              }
            />
            <Route path="managejob" element={<ManageJob />} />
            <Route
              path="managejobblock/:jobBlockID"
              element={<ManageJobBlockRows />}
            />
            <Route
              path="manageclassifications"
              element={<ManageClassifications />}
            />
            <Route path="managereferences" element={<ManageReferences />} />
            <Route
              path="managerelationships"
              element={<ManageRelationships />}
            />
            <Route
              path="managejobactivity/:jobactivityid"
              element={<ManageJobActivity />}
            />
          </Route>
        </Route>
        <Route path="samples">
          <Route
            index
            element={
              <LookupLayout lookups={[PAGE_LOOKSUPS.SAMPLING_TRACKING_LOOKUPS]}>
                <VineyardSamplingContainer />
              </LookupLayout>
            }
          />
          <Route path="sampling">
            <Route index element={<SampleSearchResult />} />
            <Route path="createnew" element={<NewSampleSet />} />
            <Route path=":id">
              <Route index element={<VineyardSampleDetailsContainer />} />
              <Route path="sampledetails">
                <Route index element={<SampleDetailsContainer />} />
                <Route path=":sampleHeaderID" element={<SampleDetails />} />
              </Route>
              <Route
                path="assignsamplers"
                element={<VineyardSampleDetailsContainer />}
              />
              <Route path="addblocks" element={<AddSampleRequestBlocks />} />
            </Route>
          </Route>
          <Route path="tracking">
            <Route
              index
              element={
                <LookupLayout
                  lookups={[PAGE_LOOKSUPS.SAMPLING_TRACKING_LOOKUPS]}
                >
                  <TrackingSearchResult />
                </LookupLayout>
              }
            />

            <Route path="vintage" element={<TrackingSearchResult />} />
            <Route path="phenology" element={<TrackingSearchResult />} />
            <Route path="notes" element={<TrackingSearchResult />} />
            <Route path="attributes" element={<TrackingSearchResult />} />
          </Route>
        </Route>
        <Route path="settings">
          <Route
            index
            element={
              <LookupLayout lookups={[PAGE_LOOKSUPS.USER_LOOKUPS]}>
                <UserSettings />
              </LookupLayout>
            }
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
