import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorBoundary } from 'react-error-boundary';
import { DiscardDialog } from '../../../components/Dialog';
import ErrorBoundaryFallback from '../../../layouts/ErrorBoundary';
import { IdentityDrawerTypes } from '../../../constants';

import { RelationshipCreate } from '../../../components/RelationshipComponent';
import { useIdRelationshipHook } from '../hooks/useIdRelationshipHook';
import { RelationshipManage } from '../../../components/RelationshipComponent/RelationshipManage';
import { isNil } from 'lodash';

export function EditRelationshipsDrawer({
  isOpen,
  identityId,
  relationshipID,
  handleOnClose,
}: {
  isOpen: boolean;
  identityId: number;
  relationshipID: number | null;
  handleOnClose: (drawerType: any) => void;
}) {
  const { t } = useTranslation();
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const headerText = isNil(relationshipID) ? 'Add' : 'Edit';

  const {
    formdata,
    relatesToList,
    relatedIdentityOptions,
    duplicateWarning,
    showDeletePopup,
    isAdministrator,
    handleOnChangeNewRowData,
    handleOnSaveRow,
    handleOnBlur,
    setShowDeletePopup,
    handleOnDeleteRow,
    clearFormData,
  } = useIdRelationshipHook(identityId, relationshipID);

  const onSave = (id: number) => {
    //@ts-ignore
    handleOnSaveRow(id);
    handleOnClose(IdentityDrawerTypes.RELATIONSHIPS);
    clearFormData();
  };

  const onDelete = () => {
    handleOnDeleteRow();
    handleOnClose(IdentityDrawerTypes.RELATIONSHIPS);
    clearFormData();
  };

  const toggleDrawer = (event: React.KeyboardEvent) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Esc' || event.key === 'Escape')
    ) {
      handleOnDiscard();
    }
  };

  const handleOnDiscard = () => {
    handleOnClose(IdentityDrawerTypes.RELATIONSHIPS);
    clearFormData();
  };

  const handleOnDiscardSaveChanges = () => {
    setDiscardDialogOpen(false);
    // handleOnSave();
  };

  const handleOnDiscardConfirm = () => {
    setDiscardDialogOpen(false);
    handleOnClose(IdentityDrawerTypes.RELATIONSHIPS);
    clearFormData();
  };

  const handleOnCancel = () => {
    handleOnClose(IdentityDrawerTypes.RELATIONSHIPS);
    clearFormData();
  };

  const handleOnClickAway = () => {
    handleOnDiscard();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={handleOnClickAway}
      >
        <Box sx={{ width: 380 }} role="presentation" onKeyDown={toggleDrawer}>
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
          >
            <Box>
              <Typography sx={{ padding: 2 }} variant="body1">
                {t(`${headerText} Relationship`)}
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleOnDiscard}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            {isNil(relationshipID) ? (
              <RelationshipCreate
                t={t}
                formdata={formdata}
                relatesToList={relatesToList}
                onChange={handleOnChangeNewRowData}
                onSave={onSave}
                onBlur={handleOnBlur}
                onCancel={handleOnCancel}
                relatedIdentityOptions={relatedIdentityOptions}
                duplicateWarning={duplicateWarning}
              />
            ) : (
              <RelationshipManage
                relationshipID={relationshipID}
                formdata={formdata}
                relatesToList={relatesToList}
                relatedIdentityOptions={relatedIdentityOptions}
                duplicateWarning={duplicateWarning}
                showDeletePopup={showDeletePopup}
                t={t}
                isAdministrator={isAdministrator}
                onChange={handleOnChangeNewRowData}
                onBlur={handleOnBlur}
                onSave={onSave}
                onCancel={handleOnCancel}
                onDelete={onDelete}
                setShowDeletePopup={setShowDeletePopup}
              />
            )}
          </ErrorBoundary>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={() => setDiscardDialogOpen(false)}
            handleSaveChanges={handleOnDiscardSaveChanges}
            handleDiscardChanges={handleOnDiscardConfirm}
          />
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
}
