import { Box, Paper, Step, StepLabel, Stepper } from '@mui/material';
import { UploadData } from './UploadData';
import { useExcelCsvImporter } from './hooks/UseExcelCsvImporter';
import { useTheme } from '@mui/material';
import { VineaButton } from '../../components/VineaButton';
import { DisplayData } from './DisplayData';
import { get } from 'lodash';

export const ExcelCsvImporter = () => {
  const theme = useTheme();
  const {
    activeStep,
    steps,
    fileName,
    sheetsGridXData,
    sheetsToParse,
    formdata,
    canValidate,
    additionalParameters,
    fileReadLoading,
    dataGridCols,
    parsedRows,
    canImport,
    dataLoading,
    handleFileRead,
    handleOnSheetsSelectionChange,
    handleOnChange,
    handleOnNext,
    handleOnBack,
    handleOnImport,
  } = useExcelCsvImporter();

  const activeParsedRows = get(parsedRows, activeStep);
  return (
    <Paper
      elevation={0}
      data-testid="import-data-paper"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Stepper
        activeStep={activeStep}
        sx={{ backgroundColor: '#FFFFFF' }}
        alternativeLabel
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box>
        {activeStep === 0 && (
          <UploadData
            fileName={fileName}
            sheetsGridXData={sheetsGridXData}
            selectedSheets={sheetsToParse}
            theme={theme}
            data={formdata}
            additionalParameters={additionalParameters}
            fileReadLoading={fileReadLoading}
            dataLoading={dataLoading || false}
            handleFileRead={handleFileRead}
            handleOnSheetsSelectionChange={handleOnSheetsSelectionChange}
            handleOnChange={handleOnChange}
          />
        )}
        {activeStep > 0 && (
          <DisplayData
            tableColumns={dataGridCols}
            parsedRows={activeParsedRows}
            theme={theme}
          />
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          margin: theme.spacing(2),
        }}
      >
        {activeStep === 0 && (
          <VineaButton
            variant={'outlined'}
            color="secondary"
            onClick={handleOnNext}
            disabled={!canValidate}
          >
            {'Next'}
          </VineaButton>
        )}
        {activeStep > 0 && activeStep !== steps.length - 1 && (
          <>
            <Box
              sx={{
                marginRight: theme.spacing(1),
              }}
            >
              <VineaButton
                disabled={activeStep === 0}
                onClick={handleOnBack}
                color="secondary"
                variant="text"
              >
                {'Back'}
              </VineaButton>
            </Box>
            <Box
              sx={{
                marginRight: theme.spacing(1),
              }}
            >
              <VineaButton
                variant={'contained'}
                color="secondary"
                onClick={handleOnImport}
                disabled={!canImport}
              >
                {'Import'}
              </VineaButton>
            </Box>
            <VineaButton
              variant={'outlined'}
              color="secondary"
              onClick={handleOnNext}
              disabled={!canValidate}
            >
              {'Next'}
            </VineaButton>
          </>
        )}
        {activeStep === steps.length - 1 && (
          <>
            <Box
              sx={{
                marginRight: theme.spacing(1),
              }}
            >
              <VineaButton
                disabled={activeStep === 0}
                onClick={handleOnBack}
                color="secondary"
                variant="text"
              >
                {'Back'}
              </VineaButton>
            </Box>
            <VineaButton
              variant={'contained'}
              color="secondary"
              onClick={handleOnImport}
              disabled={!canImport}
            >
              {'Import'}
            </VineaButton>{' '}
          </>
        )}
      </Box>
    </Paper>
  );
};
