import { memo } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import GridWithFilter from './GridWithFilter';
import { RenderDataGridHeader } from './RenderDataGridHeader';
import { GridNoRowMessage } from './GridNoRowMessage';

export const ActivityMappingGridComponent = ({
  tableData,
  onRowClick,
  gridColumnState,
  toggleMatchedOnly,
  handleOnToggleMatchedOnly,
}) => {
  const tableDataFormattedData = tableData.map(data => ({
    id: data.activityID,
    activityCode: data.activityCode,
    unmatchedTransactionCount: data.unmatchedTransactionCount,
    matchedActivityCode: data.matchedActivityCode,
  }));

  const gridDataColums = [
    {
      field: 'activityCode',
      headerName: 'Service Provider Activity Code',
      minwidth: '150px',
      flex: 0.1,
      resizable: false,
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'unmatchedTransactionCount',
      headerName: 'Unmatched Transactions',
      minwidth: '150px',
      flex: 0.1,
      hideable: false,
      renderHeader: RenderDataGridHeader,
    },
    {
      field: 'matchedActivityCode',
      headerName: 'Matched Activity',
      hideable: false,
      flex: 0.1,
      renderHeader: RenderDataGridHeader,
    },
  ];

  const gridXData = { columns: gridDataColums, rows: tableDataFormattedData };

  const NoRowsOverlayMessage = () => (
    <Box mt={10} data-testid="activity-mapping-grid-norowmessage">
      <GridNoRowMessage message="Sorry, there were no records found." />
    </Box>
  );

  return (
    <Stack data-testid="activity-mapping-grid-stack" sx={{ height: '100%' }}>
      <Paper
        elevation={0}
        data-testid="activity-mapping-grid-paper"
        sx={{ height: '100%' }}
      >
        <GridWithFilter
          data={gridXData}
          rowHeight={30}
          headerHeight={56}
          noRowsOverlay={NoRowsOverlayMessage}
          actionType="ACTIVITY_MAPPING_GRID_COLUMN_UPDATE"
          gridColumnState={gridColumnState}
          advancedSearch={false}
          onRowClick={onRowClick}
          hasActiveToggle={true}
          activeToggleLabel={'Unmatched Only'}
          toggleActive={toggleMatchedOnly}
          handleOnToggleActive={handleOnToggleMatchedOnly}
        />
      </Paper>
    </Stack>
  );
};

ActivityMappingGridComponent.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

ActivityMappingGridComponent.defaultProps = {
  tableData: [],
};

function areEqual(prevProps, nextProps) {
  const px = pick(prevProps, ['pathParamId', 'tableData']);
  const nx = pick(nextProps[('pathParamId', 'tableData')]);
  return isEqual(px, nx);
}

export const ActivityMappingGrid = memo(ActivityMappingGridComponent, areEqual);
