export const customBreadcrumbs = (
  identityBreadCrumbName,
  vineyardTypeScreenName,
) => {
  return [
    {
      path: '/connections',
      breadcrumb: 'Data Connections',
    },
    {
      path: '/connections/newconnection',
      breadcrumb: 'New Data Connection',
    },
    {
      path: '/connections/:id',
      breadcrumb: identityBreadCrumbName || 'Data Connection Details',
    },
    {
      path: '/connections/:id/manageconnections',
      breadcrumb: 'Manage Data Connection',
    },
    {
      path: '/connections/:id/syncmaps',
      breadcrumb: 'Sync Maps',
    },
    {
      path: '/connections/:id/managerelationships',
      breadcrumb: 'Manage Relationships',
    },
    {
      path: '/connections/:id/managecontacts',
      breadcrumb: 'Manage Contacts',
    },
    {
      path: '/connections/:id/notes/:noteId',
      breadcrumb: 'Manage Note',
    },
    {
      path: '/connections/:id/sensors/:sensorId',
      breadcrumb: 'Manage Sensor',
    },
    {
      path: '/connections/:id/managestatus',
      breadcrumb: 'Manage Status',
    },
    {
      path: '/person',
      breadcrumb: 'People',
    },
    {
      path: '/person/newperson',
      breadcrumb: 'New Person',
    },
    {
      path: '/person/:id',
      breadcrumb: identityBreadCrumbName || 'Person Details',
    },
    {
      path: '/person/:id/managepersondetails',
      breadcrumb: 'Manage Person',
    },
    {
      path: '/person/:id/managereferences',
      breadcrumb: 'Manage References',
    },
    {
      path: '/person/:id/manageclassifications',
      breadcrumb: 'Manage Classifications',
    },
    {
      path: '/person/:id/managecontacts',
      breadcrumb: 'Manage Contacts',
    },
    {
      path: '/person/:id/managerelationships',
      breadcrumb: 'Manage Relationships',
    },
    {
      path: '/person/:id/notes/:noteId',
      breadcrumb: 'Manage Note',
    },
    {
      path: '/person/:id/manageroles',
      breadcrumb: 'Manage Roles',
    },
    {
      path: '/person/:id/managestatus',
      breadcrumb: 'Manage Status',
    },
    {
      path: '/worker',
      breadcrumb: 'Workers',
    },
    {
      path: '/worker/newworker',
      breadcrumb: 'New Worker',
    },
    {
      path: '/worker/:id',
      breadcrumb: identityBreadCrumbName || 'Worker Details',
    },
    {
      path: '/worker/:id/workrecords/:payPeriodId',
      breadcrumb: 'Work Records',
    },
    {
      path: '/worker/:id/manageworkerdetails',
      breadcrumb: 'Manage Worker',
    },
    {
      path: '/worker/:id/managereferences',
      breadcrumb: 'Manage References',
    },
    {
      path: '/worker/:id/manageclassifications',
      breadcrumb: 'Manage Classifications',
    },
    {
      path: '/worker/:id/managecontacts',
      breadcrumb: 'Manage Contacts',
    },
    {
      path: '/worker/:id/managerelationships',
      breadcrumb: 'Manage Relationships',
    },
    {
      path: '/worker/:id/notes/:noteId',
      breadcrumb: 'Manage Note',
    },
    {
      path: '/worker/:id/manageroles',
      breadcrumb: 'Manage Roles',
    },
    {
      path: '/worker/:id/managestatus',
      breadcrumb: 'Manage Status',
    },
    {
      path: '/supplycontract',
      breadcrumb: 'Supply Contracts',
    },
    {
      path: '/supplycontract/newsupplycontract',
      breadcrumb: 'New Supply Contract',
    },
    {
      path: '/supplycontract/:id',
      breadcrumb: identityBreadCrumbName || 'Contract Details',
    },
    {
      path: '/supplycontract/:id/maps',
      breadcrumb: 'Blocks Map',
    },
    {
      path: '/supplycontract/:id/notes',
      breadcrumb: 'Notes',
    },
    {
      path: '/supplycontract/:id/managereferences',
      breadcrumb: 'Manage References',
    },
    {
      path: '/supplycontract/:id/manageclassifications',
      breadcrumb: 'Manage Classifications',
    },
    {
      path: '/supplycontract/:id/managesupplycontract',
      breadcrumb: 'Manage Supply Contract',
    },
    {
      path: '/supplycontract/:id/managestatus',
      breadcrumb: 'Manage Status',
    },
    {
      path: '/job/newjob',
      breadcrumb: 'New Job',
    },
    {
      path: '/job/:id',
      breadcrumb: identityBreadCrumbName || 'Job Details',
    },
    {
      path: '/job/:id/notes',
      breadcrumb: 'Notes',
    },
    {
      path: '/job/:id/managejobblock',
      breadcrumb: 'Manage Job Block',
    },
    {
      path: '/job/:id/managejobactivity',
      breadcrumb: 'Manage Job Activity',
    },
    {
      path: '/job/:id/managereferences',
      breadcrumb: 'Manage References',
    },
    {
      path: '/job/:id/manageclassifications',
      breadcrumb: 'Manage Classifications',
    },
    {
      path: '/job/:id/managejob',
      breadcrumb: 'Manage Job',
    },
    {
      path: '/organisation',
      breadcrumb: 'Organisations',
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/new${vineyardTypeScreenName}`,
      breadcrumb: `New ${vineyardTypeScreenName}`,
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id`,
      breadcrumb: identityBreadCrumbName || `${vineyardTypeScreenName} Details`,
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/manage${vineyardTypeScreenName}s`,
      breadcrumb: `Manage ${vineyardTypeScreenName}`,
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/managereferences`,
      breadcrumb: 'Manage References',
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/manageclassifications`,
      breadcrumb: 'Manage Classifications',
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/managecontacts`,
      breadcrumb: 'Manage Contacts',
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/managerelationships`,
      breadcrumb: 'Manage Relationships',
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/blocks/:blockId`,
      breadcrumb: 'Manage Block',
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/blocks/:blockId/vintagedetails`,
      breadcrumb: 'Vintage details',
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/blocks/:blockId/blockrows`,
      breadcrumb: 'Block Rows',
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/blocks/:blockId/blockrows/createRows`,
      breadcrumb: 'New Row',
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/blocks/create`,
      breadcrumb: 'New Block',
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/notes/:noteId`,
      breadcrumb: 'Manage Note',
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/blockmaps`,
      breadcrumb: 'Blocks Map',
    },
    {
      path: `/organisation/${vineyardTypeScreenName}s/:id/managestatus`,
      breadcrumb: 'Manage Status',
    },
    {
      path: '/organisation/other-organisations/neworganisation',
      breadcrumb: 'New Organisation',
    },
    {
      path: '/organisation/other-organisations/:id',
      breadcrumb: identityBreadCrumbName || 'Organisation Details',
    },
    {
      path: '/organisation/other-organisations/:id/manageorganisation',
      breadcrumb: 'Manage Organisation',
    },
    {
      path: '/organisation/other-organisations/:id/managereferences',
      breadcrumb: 'Manage References',
    },
    {
      path: '/organisation/other-organisations/:id/manageclassifications',
      breadcrumb: 'Manage Classifications',
    },
    {
      path: '/organisation/other-organisations/:id/managecontacts',
      breadcrumb: 'Manage Contacts',
    },
    {
      path: '/organisation/other-organisations/:id/managerelationships',
      breadcrumb: 'Manage Relationships',
    },
    {
      path: '/organisation/other-organisations/:id/manageroles',
      breadcrumb: 'Manage Roles',
    },
    {
      path: '/organisation/other-organisations/:id/notes/:noteId',
      breadcrumb: 'Manage Note',
    },
    {
      path: '/organisation/other-organisations/:id/managestatus',
      breadcrumb: 'Manage Status',
    },
    {
      path: '/organisation/serviceproviders',
      breadcrumb: 'Service Providers',
    },
    {
      path: '/organisation/serviceproviders/newserviceprovider',
      breadcrumb: 'New Service Provider',
    },
    {
      path: '/organisation/serviceproviders/:id',
      breadcrumb: identityBreadCrumbName || 'Service Provider Details',
    },
    {
      path: '/organisation/serviceproviders/:id/activitymapping',
      breadcrumb: 'Activity Mapping',
    },
    {
      path: '/organisation/serviceproviders/:id/manageserviceproviders',
      breadcrumb: 'Manage Service Provider',
    },
    {
      path: '/organisation/serviceproviders/:id/managereferences',
      breadcrumb: 'Manage References',
    },
    {
      path: '/organisation/serviceproviders/:id/manageclassifications',
      breadcrumb: 'Manage Classifications',
    },
    {
      path: '/organisation/serviceproviders/:id/managecontacts',
      breadcrumb: 'Manage Contacts',
    },
    {
      path: '/organisation/serviceproviders/:id/managerelationships',
      breadcrumb: 'Manage Relationships',
    },
    {
      path: '/organisation/serviceproviders/:id/notes/:noteId',
      breadcrumb: 'Manage Note',
    },
    {
      path: '/organisation/serviceproviders/:id/managestatus',
      breadcrumb: 'Manage Status',
    },
    {
      path: '/accommodation/newaccommodation',
      breadcrumb: 'New Accommodation',
    },
    {
      path: '/accommodation/:id',
      breadcrumb: identityBreadCrumbName || 'Accommodation Details',
    },
    {
      path: '/accommodation/accommodationdetails/:id/manageaccommodationdetails',
      breadcrumb: 'Manage Accommodation',
    },
    {
      path: '/accommodation/accommodationdetails/:id/managereferences',
      breadcrumb: 'Manage References',
    },
    {
      path: '/accommodation/accommodationdetails/:id/manageclassifications',
      breadcrumb: 'Manage Classifications',
    },
    {
      path: '/accommodation/accommodationdetails/:id/managecontacts',
      breadcrumb: 'Manage Contacts',
    },
    {
      path: '/accommodation/accommodationdetails/:id/managerelationships',
      breadcrumb: 'Manage Relationships',
    },
    {
      path: '/accommodation/accommodationdetails/:id/notes/:noteId',
      breadcrumb: 'Manage Note',
    },
    {
      path: '/accommodation/accommodationdetails/:id/manageroles',
      breadcrumb: 'Manage Roles',
    },
    {
      path: '/accommodation/accommodationdetails/:id/managestatus',
      breadcrumb: 'Manage Status',
    },
    {
      path: '/samples',
      breadcrumb: 'Sampling And Tracking',
    },
    {
      path: '/samples/sampling',
      breadcrumb: 'Sample Sets',
    },
    {
      path: '/samples/sampling/createnew',
      breadcrumb: 'New Sample Set',
    },
    {
      path: '/samples/sampling/:id',
      breadcrumb: 'Sample Set Details',
    },
    {
      path: '/samples/sampling/:id/addblocks',
      breadcrumb: 'Add Blocks',
    },
    {
      path: '/samples/sampling/:id/sampledetails',
      breadcrumb: 'Sample Requests',
    },
    {
      path: '/samples/sampling/:id/sampledetails/:id',
      breadcrumb: 'Sample Request Details',
    },
    {
      path: '/samples/sampling/:id/assignsamplers',
      breadcrumb: 'Assign Samplers',
    },
    {
      path: '/consumable/:id',
      breadcrumb: identityBreadCrumbName || 'Consumable Details',
    },
    {
      path: '/consumable/newconsumable',
      breadcrumb: 'New Consumable',
    },
    {
      path: '/consumable/:id/manageconsumables',
      breadcrumb: 'Manage Consumable',
    },
    {
      path: '/consumable/:id/manageclassifications',
      breadcrumb: 'Manage Classifications',
    },
    {
      path: '/consumable/:id/managerelationships',
      breadcrumb: 'Manage Relationships',
    },
    {
      path: '/consumable/:id/managereferences',
      breadcrumb: 'Manage References',
    },
    {
      path: '/consumable/:id/managestatus',
      breadcrumb: 'Manage Status',
    },
    {
      path: '/users/newuser',
      breadcrumb: 'New User',
    },
    {
      path: '/users/:id/manageuser',
      breadcrumb: 'Manage User',
    },
    {
      path: '/vehicle/:id',
      breadcrumb: identityBreadCrumbName || 'Vehicles and Equipment',
    },
    {
      path: '/vehicle/newvehicle',
      breadcrumb: 'New Vehicle/Equipment',
    },
    {
      path: '/vehicle/:id/managevehicle',
      breadcrumb: 'Manage Vehicle/Equipment',
    },
    {
      path: '/vehicle/:id/managereferences',
      breadcrumb: 'Manage References',
    },
    {
      path: '/vehicle/:id/manageclassifications',
      breadcrumb: 'Manage Classifications',
    },
    {
      path: '/vehicle/:id/managecontacts',
      breadcrumb: 'Manage Contacts',
    },
    {
      path: '/vehicle/:id/managerelationships',
      breadcrumb: 'Manage Relationships',
    },
    {
      path: '/activity/:id',
      breadcrumb: identityBreadCrumbName || 'Activity',
    },
    {
      path: '/activity/newactivity',
      breadcrumb: 'New Activity',
    },
    {
      path: '/activity/:id/manageactivity',
      breadcrumb: 'Manage Activity',
    },
    {
      path: '/activity/:id/managereferences',
      breadcrumb: 'Manage References',
    },
    {
      path: '/activity/:id/defaultrates',
      breadcrumb: 'Default Rates',
    },
    {
      path: '/activity/:id/references',
      breadcrumb: 'References',
    },
    {
      path: '/activity/:id/notes',
      breadcrumb: 'Notes',
    },
    {
      path: '/plans',
      breadcrumb: 'Plans',
    },
    {
      path: '/plans/:id',
      breadcrumb: identityBreadCrumbName || 'Plan Details',
    },
    {
      path: '/plans/newplan',
      breadcrumb: 'New Plan',
    },
    {
      path: '/plans/:id/manageplan',
      breadcrumb: 'Manage Plan',
    },
    {
      path: '/plans/:id/overview',
      breadcrumb: 'Overview',
    },
    {
      path: '/plans/:id/blocks',
      breadcrumb: 'Blocks',
    },
    {
      path: '/plans/:id/jobs',
      breadcrumb: 'Jobs',
    },
    {
      path: '/plans/:id/history',
      breadcrumb: 'History',
    },
    {
      path: '/plans/:id/managestatus',
      breadcrumb: 'Manage Status',
    },
    {
      path: '/dataimport',
      breadcrumb: 'Data Import',
    },
  ];
};
