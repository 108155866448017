import { RenderDataGridHeader } from '../../components/Grid';

export const reportingGridColumns = () => [
  {
    field: 'reportName',
    headerName: 'Name',
    flex: 2,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'reportGroup',
    headerName: 'Type',
    flex: 1,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
  {
    field: 'category',
    headerName: 'Category',
    flex: 1,
    resizable: false,
    hideable: false,
    renderHeader: RenderDataGridHeader,
  },
];
