import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Stack,
  useTheme,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { VineaButton } from '../VineaButton';
import { isEmpty } from 'lodash';
import { EventLogAlertType, useEventAlerts } from '../../hooks/useEventAlerts';
import { EventStatusIDs } from '../../constants';

type EventAlertsProps = {
  open: boolean;
  setOpen: any;
};

export const EventAlertsWindow = ({ open, setOpen }: EventAlertsProps) => {
  const theme = useTheme();
  const windowWidth = 550;
  const { userEventAlerts, eventLogLoading, handleUpdateEventLogStatus } =
    useEventAlerts({
      eventStatusID: EventStatusIDs.ACTION_REQ,
    });

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={toggleOpen}
        PaperProps={{
          sx: {
            position: 'absolute',
            right: 50,
            top: 50,
            margin: 0,
            overflow: 'visible', // Allows the triangle to be visible outside the dialog
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-10px', // triangle height
              right: '20px', // triangle horizontal position
              width: 0,
              height: 0,
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderBottom: '20px solid white',
            },
          },
        }}
        sx={{ maxHeight: 600 }}
      >
        <DialogTitle>
          {eventLogLoading ? (
            <Stack
              direction="row"
              alignItems="center"
              margin={theme.spacing(2)}
            >
              <Skeleton
                variant="text"
                width="100%"
                sx={{ marginRight: theme.spacing(2) }}
              />
              <Skeleton variant="circular" width={30} height={30} />
            </Stack>
          ) : (
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">{'Alerts'}</Typography>{' '}
              <Box>
                <IconButton onClick={toggleOpen}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Stack>
          )}
        </DialogTitle>

        {eventLogLoading ? (
          <DialogContent dividers>
            <Stack direction="row" sx={{ minWidth: windowWidth }}>
              <Skeleton
                variant="text"
                height={60}
                width={'100%'}
                sx={{ marginRight: theme.spacing(2) }}
              />
            </Stack>
          </DialogContent>
        ) : (
          <DialogContent dividers>
            <Box>
              {isEmpty(userEventAlerts) || !Array.isArray(userEventAlerts) ? (
                <Box sx={{ minWidth: windowWidth }}>
                  <Typography variant="body2">
                    {'No event log alerts to resolve'}
                  </Typography>
                </Box>
              ) : (
                <>
                  {userEventAlerts.map((alert: EventLogAlertType) => {
                    return (
                      <Stack sx={{ minWidth: windowWidth }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          key={alert.id}
                          sx={{ margin: theme.spacing(2) }}
                        >
                          <Typography variant="body2">
                            {alert.message}
                          </Typography>
                          <Box>
                            <VineaButton
                              data-testid="alert-window-dismiss-button"
                              color="secondary"
                              variant="contained"
                              onClick={() =>
                                handleUpdateEventLogStatus(
                                  alert.id,
                                  EventStatusIDs.RESOLVED,
                                )
                              }
                            >
                              {'Dismiss'}
                            </VineaButton>
                          </Box>
                        </Stack>

                        <Divider variant="middle" />
                      </Stack>
                    );
                  })}
                </>
              )}
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
