/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */

// eslint-disable-next-line import/extensions
import '../../globals';

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

// Report Viewer source
import '@boldreports/javascript-reporting-controls/Content/v2.0/tailwind-light/bold.report-viewer.min.css';
import '@boldreports/javascript-reporting-controls/Scripts/v2.0/common/bold.reports.common.min';
import '@boldreports/javascript-reporting-controls/Scripts/v2.0/common/bold.reports.widgets.min';
import '@boldreports/javascript-reporting-controls/Scripts/v2.0/bold.report-viewer.min';
// Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';

const viewerStyle = { height: '700px', width: '100%' };

const StyledGridItem = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  marginTop: theme.spacing(12),
  marginLeft: theme.spacing(2),
}));

export const BoldReportExample = () => {
  return (
    <Grid
      container
      spacing={0}
      sx={{
        margin: 0,
        width: '99%',
      }}
    >
      <StyledGridItem item xs={12} sm={12}>
        <Typography component="p" variant="h5">
          Contained Buttons
        </Typography>
        <Grid container spacing={2}>
          <div id="viewer" style={viewerStyle}>
            <BoldReportViewerComponent
              id="reportviewer-container"
              reportPath="/Payroll/Workers Hours Summary Grid"
              reportServerUrl="https://vineareporting.azurewebsites.net/reporting/api/site/shanewoodinfopowerco"
              reportServiceUrl="https://vineareporting.azurewebsites.net/reporting/reportservice/api/Viewer"
              serviceAuthorizationToken="bearer
            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InZpbmVhcmVwb3J0c0BpbmZvcG93ZXIuY28ubnoiLCJuYW1laWQiOiI0IiwidW5pcXVlX25hbWUiOiI5NDc4NDU1YS00YWM3LTQ5OTctOGQ2My00NjY3MDA5N2U5NTEiLCJJUCI6IjUyLjI0My43Ni43MyIsImlzc3VlZF9kYXRlIjoiMTcyNTUxNjE5NiIsIm5iZiI6MTcyNTUxNjE5NiwiZXhwIjoxNzI2MTIwOTk2LCJpYXQiOjE3MjU1MTYxOTYsImlzcyI6Imh0dHBzOi8vdmluZWFyZXBvcnRpbmcuYXp1cmV3ZWJzaXRlcy5uZXQvcmVwb3J0aW5nL3NpdGUvc2hhbmV3b29kaW5mb3Bvd2VyY28iLCJhdWQiOiJodHRwczovL3ZpbmVhcmVwb3J0aW5nLmF6dXJld2Vic2l0ZXMubmV0L3JlcG9ydGluZy9zaXRlL3NoYW5ld29vZGluZm9wb3dlcmNvIn0.PHqKeeCzw8L3eOFCxt0x2F6c-iHzXIzKnaGo_pfWZDc"
            />
          </div>
        </Grid>
      </StyledGridItem>
    </Grid>
  );
};

const mapStateToProps = state => ({
  authUser: state.authentication,
  language: state.language.selectedLanguage,
});
const mapDispatchToProps = dispatch => ({
  languageSelect: langValue =>
    dispatch({ type: 'LANGUAGE_CHANGE', payload: langValue }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  BoldReportExample,
);
