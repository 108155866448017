import React from 'react';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BlocksGrid } from '../../components/Grid';
import { sagaActionTypes as Types, reducers } from '../../constants';
import {
  getVineyardBlocks,
  getVineyardBlocksInfo,
  getUserPreferences,
  getIdentityOverviewDetailsFromContact,
} from '../../redux/selectors';

// eslint-disable-next-line react/prop-types
const VineyardBlocksComponent = ({ pathParamId, identityBaseURL }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const vineyardblockRef = React.useRef(null);
  const dataGridColumnsPreferences = useSelector(state =>
    getUserPreferences(state),
  );

  // get selector values
  const blocksData = useSelector(getVineyardBlocks);
  const { isLoading: blocksIsLoading } = useSelector(getVineyardBlocksInfo);
  const { data: vineyardBlockSearchFilter } = useSelector(
    state => state.vineyardBlockSearchFilter,
  );
  const identityOverviewDetails = useSelector(state =>
    getIdentityOverviewDetailsFromContact(state),
  );
  const { siteTypeID } = identityOverviewDetails;

  const handleOnAdd = () => {
    navigate(`/${identityBaseURL}/blocks/create`);
  };

  React.useEffect(() => {
    dispatchAPI({ type: 'CLEAR_DATA', name: reducers.vineyardBlockRows });
    dispatchAPI({
      type: Types.FETCH_VINEYARD_BLOCKS,
      name: reducers.vineyardBlocks,
      payload: {
        id: pathParamId,
        name: reducers.vineyardBlocks,
      },
    });
  }, [pathParamId, dispatchAPI]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      data-testid="grid-blocks"
      sx={{ padding: 3 }}
      ref={vineyardblockRef}
    >
      <BlocksGrid
        heading={t(' ')}
        add={handleOnAdd}
        hasAddOption
        tableData={blocksData}
        hasManageOption={false}
        pathParamId={pathParamId}
        isLoading={blocksIsLoading}
        manage={f => f}
        gridColumnState={dataGridColumnsPreferences.blocksDataGrid}
        quickSearchFilter={vineyardBlockSearchFilter}
        identityBaseURL={identityBaseURL}
        siteTypeID={siteTypeID}
      />
    </Box>
  );
};

function pathNameAreEqual(prevProps, nextProps) {
  return isEqual(prevProps.pathParamId, nextProps.pathParamId);
}

export const VineyardBlocks = React.memo(
  VineyardBlocksComponent,
  pathNameAreEqual,
);
