import { useEffect, useMemo, useState } from 'react';
import { get, map } from 'lodash';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { Paper, Typography, Box } from '@mui/material';
import { GridWithFilter } from '../../components/Grid';
import { DownloadDrawer } from './DownloadDrawer';
import { reportingGridColumns } from './reportingGridColumns';
import {
  getLkpDocumentType,
  getAllReportsSelector,
} from '../../redux/selectors';
import serviceConfig from '../../services/class/serviceConfig';
import useBoldReportsHook from '../../hooks/useBoldReportsHook';
import { BoldReportViewer } from '../FormSamples/BoldReportViewer';
import { ReportGroups } from '../../constants';

export function ReportingContainer() {
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [reportFiltersUpdate, setReportFiltersUpdate] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const {
    reportPath,
    accessToken,
    reportServerURL,
    reportServiceURL,
    tokenReady,
    showReportViewer,
    setShowReportViewer,
  } = useBoldReportsHook(
    get(selectedRow, 'category', ''),
    get(selectedRow, 'reportName', ''),
  );

  const [reportGenerating, setReportGenerating] = useState(false);
  const [reportGenerated, setReportGenerated] = useState(false);
  const [reportHasError, setReportHasError] = useState(false);

  const reportsData = useSelector(getAllReportsSelector);
  const { isLoading } = useSelector(
    VineaNovaSelectors.getReportFiltersEntityMeta,
  );

  const generateButtonLoading = useMemo(() => {
    if (get(selectedRow, 'reportGroup', '') === ReportGroups.NOVA) {
      return reportGenerating;
    } else {
      return !tokenReady;
    }
  }, [tokenReady, reportGenerating, selectedRow]);

  const documentTypeOptions = useSelector(getLkpDocumentType);
  const lgColumnsTable = reportingGridColumns();
  const gridXData = {
    columns: lgColumnsTable,
    rows: reportsData,
  };

  const handleOnCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleOnClick = evt => {
    const { id } = evt;
    const row = reportsData.find(r => r.id === id);
    setSelectedRow(row);
    setDrawerOpen(true);
  };

  const handleGenerateReport = async (
    reportData,
    reportOptions,
    filterValues,
  ) => {
    const postBody = {
      ReportID: reportData.id,
      ReportDataSource: reportData.reportDataSource,
      DocumentTypeID: reportOptions?.outputFormat,
      FilterOptions: map(filterValues, v => ({
        FilterParameter: v?.filterParameter,
        FilterValue: v?.value?.join(',') || null,
      })),
    };

    const fileExtension = documentTypeOptions.filter(
      d => d?.id === reportOptions?.outputFormat,
    )?.[0]?.documentType;
    const filename = `${reportData?.defaultFileName}.${fileExtension}`;

    try {
      setReportGenerating(true);
      await serviceConfig
        .request({
          url: `/ReportFile`,
          method: 'POST',
          data: JSON.stringify(postBody),
          responseType: 'blob', // don't forget this
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response]));
          saveAs(url, filename);
          setReportGenerated(true);
        });
    } catch (err) {
      setReportHasError(true);
      setReportGenerated(true);
    }
  };

  useEffect(() => {
    dispatchAPI(VineaNovaActions.api.v1.lookupDocumentType.get.request());
    dispatchAPI(VineaNovaActions.api.v1.reports.get.request());
    dispatchAPI(
      VineaNovaActions.api.v1.reportFilters.post.request({
        postBody: {
          reportFilterValues: [],
        },
      }),
    );
  }, []);

  // Show snackbars based on report generation status
  useEffect(() => {
    if (reportGenerated) {
      if (!reportHasError) {
        enqueueSnackbar(t('Success'), { variant: 'Success' });
      } else {
        enqueueSnackbar(t('Error'), { variant: 'Error' });
        setReportHasError(false);
      }

      setReportGenerating(false);
      setReportGenerated(false);
      setDrawerOpen(false);
    }
  }, [reportGenerated, reportHasError]);

  // Update filter options when api loaded
  useEffect(() => {
    if (reportFiltersUpdate && !isLoading && drawerOpen) {
      const currentRowID = selectedRow?.id;
      setSelectedRow(reportsData.find(r => r.id === currentRowID));
      setReportFiltersUpdate(false);
    }
  }, [reportFiltersUpdate, reportsData, isLoading, drawerOpen]);

  return (
    <Box p={2} sx={{ height: '100%' }} data-testid="reports-table">
      <Paper
        sx={{
          width: '100%',
          height: '100%',
          overflowX: 'auto',
          '& .MuiDataGrid-cell': {
            padding: '0 5px',
          },
          '& .MuiDataGrid-colCell': {
            padding: '0 5px',
          },
        }}
      >
        {!showReportViewer || !tokenReady ? (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <Box p={2}>
              <Typography variant="h6" color="textPrimary">
                {t('Reports')}
              </Typography>
            </Box>
            <Box sx={{ height: '100%' }} data-testid="reportsgridfilter">
              <GridWithFilter
                data={gridXData}
                onRowClick={handleOnClick}
                loading={isLoading}
                rowHeight={30}
                autoPageSize
                headerHeight={56}
                advancedSearch={false}
                getCellClassName={params => {
                  return params.value?.replace(/\s/g, '_');
                }}
              />
            </Box>
          </Box>
        ) : (
          <BoldReportViewer
            reportPath={reportPath}
            reportServerURL={reportServerURL}
            reportServiceURL={reportServiceURL}
            token={accessToken}
            showViewer={setShowReportViewer}
          />
        )}
      </Paper>

      <DownloadDrawer
        isOpen={drawerOpen}
        reportData={selectedRow}
        documentTypeOptions={documentTypeOptions}
        isReportGenerating={generateButtonLoading}
        handleOnClose={handleOnCloseDrawer}
        handleGenerateReport={handleGenerateReport}
        setShowReportViewer={setShowReportViewer}
        setReportFiltersUpdate={setReportFiltersUpdate}
        setDrawerOpen={setDrawerOpen}
      />
    </Box>
  );
}
