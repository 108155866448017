import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { isNumber } from 'lodash';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { VineaButton } from '../components/VineaButton';
import { getUserPreferences } from '../redux/selectors';
import useIdentityTypeScreenNameHook from '../hooks/useIdentityTypeScreenNameHook';

const FullScreenErrorBoundary = ({ error, resetErrorBoundary }) => {
  const { landingPagesList } = useIdentityTypeScreenNameHook();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    basicSettings: { landingPage },
  } = useSelector(getUserPreferences);

  // Go to the user's default landing page, or the dashboard
  const navigateHome = () => {
    if (!isNumber(landingPage) || landingPage === null) {
      navigate('/dashboard');
    } else {
      navigate(`/${landingPagesList[landingPage].path}`);
    }
  };

  if (error) {
    return (
      <div>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Stack sx={{ alignItems: 'center', gap: 2 }}>
            <Stack mb={2} direction="row" sx={{ alignItems: 'center', gap: 3 }}>
              <WarningOutlinedIcon color="error" fontSize="large" />
              <Typography
                sx={{ fontSize: '32px', lineHeight: '32px', fontWeight: 500 }}
              >
                {t('error.unexpected.title')}
              </Typography>
            </Stack>
            <Typography variant="body1" sx={{ fontSize: '16px' }}>
              {t('error.unexpected.subtitle')}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '16px' }}>
              {t('error.unexpected.contactDetails')}
            </Typography>

            <Box mt={3}>
              <VineaButton
                variant="contained"
                color="secondary"
                data-testid="btn-learnmore"
                onClick={() => {
                  resetErrorBoundary();
                  navigateHome();
                }}
              >
                {t('Go Home')}
              </VineaButton>
            </Box>
          </Stack>
        </Paper>
      </div>
    );
  }
  return null;
};

export default FullScreenErrorBoundary;
