import React, { useEffect, useMemo, useState } from 'react';
import {
  isEmpty,
  map,
  get,
  find,
  isNil,
  filter,
  has,
  merge,
  some,
} from 'lodash';
import { isBefore, isValid } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  VineaNovaSelectors,
  VineaNovaActions,
  VineaHooks,
} from 'vineanova-redux-artifacts';
import { validDateFormat } from '../../../utils/DateUtil';
import {
  IdentityDrawerTypes,
  commonFieldSelectOption,
} from '../../../constants';
import { syncValidator } from '../../../utils/validator';
import { JobActivitiesStepperSchema } from '../validations';
import { getJobWorkUnits, getLkpJobType } from '../../../redux/selectors';
import { useFetchJobData } from './useFetchJobData';
import { EntityType } from './useJobBlockRowsHook';
import { useNavigate } from 'react-router';
import { IdentityActivityDropdownType } from '../interfaces/jobInterfaces';
import { useFetchActivityData } from './useFetchActivityData';

interface JobActivityAndRateType {
  jobActivityRateID: number | null;
  jobActivityID: number | null;
  jobID: number | null;
  activityID: number | null;
  jobActivityDescription: string | null;
  jobActivityCode: string | null;
  isDefault: boolean | null;
  canDeleteActivity?: boolean | null;
  effectiveFrom: string | null;
  effectiveTo: string | null;
  contractedRate: number | null;
  payrollRate: number | null;
  rowAttributeID?: number | null;
}

interface MetaDataType {
  isLoaded: boolean;
  isLoading: boolean;
  error: any;
  hasError?: any;
}

export const useJobActivityHook = (
  jobID: number,
  isOpen: boolean,
  handleOnClose: any,
) => {
  const { t } = useTranslation();
  const dispatchAPI = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [saveIndex, setSaveIndex] = useState(0);

  const EmptyJobActivityRateObject = {
    jobActivityRateID: null,
    jobActivityID: null,
    jobID: jobID,
    activityID: null,
    jobActivityCode: null,
    jobActivityDescription: null,
    isDefault: null,
    canDeleteActivity: null,
    rowAttributeID: null,
    effectiveFrom: null,
    effectiveTo: null,
    contractedRate: 0,
    payrollRate: 0,
  };

  const noErrors = {
    jobActivityRateID: false,
    jobActivityID: false,
    jobID: false,
    activityID: false,
    jobActivityDescription: false,
    jobActivityCode: false,
    isDefault: false,
    canDeleteActivity: false,
    rowAttributeID: false,
    effectiveFrom: false,
    effectiveTo: false,
    contractedRate: false,
    payrollRate: false,
  };

  const [showWarning, setShowWarning] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = useState('');
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    ...noErrors,
  });
  const [activityAPITrigger, setActivityAPITrigger] = useState(false);
  const [activityRateAPITrigger, setActivityRateAPITrigger] = useState(false);
  const [refreshJobActivityAPITrigger, setRefreshJobActivityAPITrigger] =
    useState(false);
  const [deleteJobActivityAPITrigger, setDeleteJobActivityAPITrigger] =
    useState(false);
  const [jobActivityDrawerOpen, setJobActivityDrawerOpen] = useState(false);
  const [isAddDrawer, setIsAddDrawer] = useState(true);
  const { identityJob } = useFetchJobData({ jobId: jobID });
  const {
    data: jobData,
    isLoaded: jobDataLoaded,
    isLoading: jobDataLoading,
  } = identityJob as EntityType;

  const defaultRateDate = useMemo(() => {
    let rateDate = get(jobData, 'scheduledStartDate');
    if (isNil(rateDate)) {
      rateDate = new Date().toISOString();
    }
    return rateDate.slice(0, 10);
  }, [jobData]);
  const { identityActivity: activityData } = useFetchActivityData({
    defaultRateDate: defaultRateDate,
  }) as { identityActivity: any[] };

  const identityActivities = useMemo(() => {
    return commonFieldSelectOption.concat(
      activityData.map((a: any) => ({
        id: a.id,
        key: a.id,
        value: a.activityCode,
        ...a,
      })),
    );
  }, [activityData]);

  const lkpJobWorkUnits = useSelector(state => getJobWorkUnits(state));
  const lkpJobType = useSelector(state => getLkpJobType(state));
  const [filteredActivityOptions, setFilteredActivityOptions] = useState<
    IdentityActivityDropdownType[]
  >([]);
  const [activityOptions, setActivityOptions] = useState<
    IdentityActivityDropdownType[]
  >([]);

  const { data: jobActivityRates } = VineaHooks.useFetchGetJobActivityRates({
    queryParams: {
      JobID: jobID,
    },
  });

  const { data: jobRowSpecialAttributes } =
    VineaHooks.useFetchJobRowSpecialAttributes({
      queryParams: {
        JobID: jobID,
      },
    });

  const rowSpecialAttributeOptions = useMemo(
    () =>
      commonFieldSelectOption.concat(
        map(jobRowSpecialAttributes, (j: any) => {
          return { id: j?.id, key: j?.id, value: j?.rowSpecialAttribute };
        }),
      ),
    [jobRowSpecialAttributes],
  );

  // Selected job activity rate object
  const [jobActivityRateObject, setJobActivityRateObject] =
    useState<JobActivityAndRateType>(EmptyJobActivityRateObject) as any;
  const {
    isLoaded: jobActivityRateLoaded,
    isLoading: jobActivityRateLoading,
    error: jobActivityRateError,
  } = useSelector(
    VineaNovaSelectors.getJobActivityRateEntityMeta,
  ) as MetaDataType;

  const { data: jobActivityUpdate } = useSelector(
    (state: any) => state.entities.jobActivity,
  );
  const {
    isLoaded: jobActivityLoaded,
    hasError: hasjobActivityError,
    isLoading: jobActivityLoading,
    error: jobActivityError,
  } = useSelector(VineaNovaSelectors.getJobActivityEntityMeta) as MetaDataType;

  const hasDefault = some(
    jobActivityRates,
    j =>
      j?.isDefault === true &&
      j?.jobActivityRateID !== jobActivityRateObject?.jobActivityRateID,
  );

  const deleteActivityConfirmation = useMemo(() => {
    if (get(jobActivityRateObject, 'isDefault')) {
      return t(
        'Are you sure you want to delete the DEFAULT activity for this job? If so, make sure to choose a new default activity afterwards.',
      );
    } else {
      return t('Are you sure you want to delete this activity?');
    }
  }, [jobActivityRateObject]);

  const columnHeaders = [
    t('Code'),
    t('Description'),
    t('Contracted Rate'),
    t('Payroll Rate'),
    t('Default'),
    t('Active From'),
    t('Active To'),
  ];

  // Open drawer to edit selected job activity rate
  const handleEditJobActivityRate = (row: any) => {
    const selectedActivityRate = find(jobActivityRates, {
      jobActivityRateID: row?.jobActivityRateID,
    });
    setJobActivityDrawerOpen(true);
    setJobActivityRateObject(selectedActivityRate);
    setIsAddDrawer(false);
    setValidationErrors({ ...noErrors });
  };

  // Open drawer to add new activity rate to job
  const handleAddJobActivityRate = () => {
    setJobActivityDrawerOpen(true);
    setIsAddDrawer(true);
    setValidationErrors({ ...noErrors });
  };

  // Close job activity rate drawer
  const handleOnCloseDrawer = () => {
    setJobActivityDrawerOpen(false);
    setJobActivityRateObject(EmptyJobActivityRateObject);
    setValidationErrors({ ...noErrors });
  };

  // Update job activity rate object data that will be sent to API
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    const newValue = value;

    if (name === 'activityID') {
      const act = find(identityActivities, {
        id: Number(value),
      }) as IdentityActivityDropdownType;

      //@ts-ignore
      setJobActivityRateObject({
        ...jobActivityRateObject,
        [name]: Number(value),
        jobActivityDescription: get(act, 'activityDescription', act?.value),
        contractedRate: get(
          act,
          'defaultChargeOutRate',
          jobActivityRateObject?.contractedRate,
        ),
        payrollRate: get(
          act,
          'defaultCostRate',
          jobActivityRateObject?.payrollRate,
        ),
      });
    } else {
      //@ts-ignore
      setJobActivityRateObject({
        ...jobActivityRateObject,
        [name]: newValue,
      });
    }
  };

  const handleOnChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { checked, name },
    } = event;
    //@ts-ignore
    setJobActivityRateObject({
      ...jobActivityRateObject,
      [name]: checked,
    });
  };

  // Need to work out delete logic, do we delete a job activity rate or a job activity too?
  const handleOnDeleteActivityRate = () => {
    setJobActivityDrawerOpen(false);
  };

  const postActivityRate = React.useCallback((data: any) => {
    dispatchAPI(
      VineaNovaActions.api.v1.jobActivityRate.post.request({
        postBody: {
          id: data?.jobActivityRateID,
          jobActivityID: data?.jobActivityID,
          effectiveFrom: isNil(data?.effectiveFrom)
            ? null
            : //@ts-ignore
              new Date(data?.effectiveFrom),
          effectiveTo: isNil(data?.effectiveTo)
            ? null
            : //@ts-ignore
              new Date(data?.effectiveTo),
          contractedRate: data?.contractedRate,
          payrollRate: data?.payrollRate,
          jobActivityDescription: data?.jobActivityDescription,
          isDefault: get(data, 'isDefault', false),
        },
      }),
    );
    setActivityRateAPITrigger(true);
    setRefreshJobActivityAPITrigger(false);
    setJobActivityRateObject(EmptyJobActivityRateObject);
  }, []);

  const fetchActivityRate = React.useCallback(() => {
    dispatchAPI(
      VineaNovaActions.api.v1.getJobActivityRates.get.request({
        queryParams: {
          JobID: jobID,
        },
      }),
    );
  }, [jobID]);

  // Either insert or update both job activity and job activity rates
  const handleOnSave = (event: any, index: number) => {
    setSaveIndex(index);
    let validationErrors = syncValidator(JobActivitiesStepperSchema)(
      jobActivityRateObject,
    );

    if (
      isEmpty(validationErrors) &&
      !isNil(jobActivityRateObject?.effectiveTo)
    ) {
      const effectiveFrom = isValid(
        validDateFormat(get(jobActivityRateObject, 'effectiveFrom')),
      )
        ? validDateFormat(get(jobActivityRateObject, 'effectiveFrom'))
        : null;
      const effectiveTo = isValid(get(jobActivityRateObject, 'effectiveTo'))
        ? validDateFormat(get(jobActivityRateObject, 'effectiveTo'))
        : null;
      const isBeforeFromDate =
        effectiveFrom && effectiveTo && isBefore(effectiveTo, effectiveFrom);

      if (isBeforeFromDate) {
        validationErrors = {
          activeTo: 'Active To date is before Active From date',
        };
      }
    }

    if (
      isEmpty(validationErrors) &&
      hasDefault &&
      jobActivityRateObject?.isDefault
    ) {
      validationErrors = {
        ...validationErrors,
        isDefault: 'Job already has a default activity',
      };
    }

    if (isEmpty(validationErrors) && jobActivityRateObject?.isDefault) {
      const activityWorkUnitID = get(
        find(identityActivities, { id: jobActivityRateObject?.activityID }),
        'workUnitID',
      );

      const jobTypeDefaultWorkUnit = find(lkpJobType, {
        id: get(jobData, 'jobTypeID'),
      });

      const jobTypeDefaultWorkUnitID = get(
        jobTypeDefaultWorkUnit,
        'defaultWorkUnitID',
        null,
      );

      if (
        !isNil(jobTypeDefaultWorkUnitID) &&
        !isNil(jobTypeDefaultWorkUnit) &&
        jobTypeDefaultWorkUnitID !== activityWorkUnitID
      ) {
        validationErrors = {
          ...validationErrors,
          isDefault:
            'The default activity must be of type: ' +
            get(jobTypeDefaultWorkUnit, 'defaultWorkUnitValue'),
        };
      }
    }

    if (isEmpty(validationErrors)) {
      if (isAddDrawer) {
        dispatchAPI(
          VineaNovaActions.api.v1.jobActivity.post.request({
            postBody: {
              jobID: jobActivityRateObject?.jobID,
              activityID: jobActivityRateObject?.activityID,
              jobActivityDescription:
                jobActivityRateObject?.jobActivityDescription,
              isDefault: get(jobActivityRateObject, 'isDefault', false),
              contractedRate: get(jobActivityRateObject, 'contractedRate', 0),
              payrollRate: get(jobActivityRateObject, 'payrollRate', 0),
              rowAttributeID: jobActivityRateObject?.rowAttributeID,
            },
          }),
        );

        setActivityAPITrigger(true);
      } else {
        dispatchAPI(
          VineaNovaActions.api.v1.jobActivity.put.request({
            postBody: {
              id: jobActivityRateObject?.jobActivityID,
              jobID: jobActivityRateObject?.jobID,
              activityID: jobActivityRateObject?.activityID,
              jobActivityDescription:
                jobActivityRateObject?.jobActivityDescription,
              isDefault: get(jobActivityRateObject, 'isDefault', false),
              contractedRate: jobActivityRateObject?.contractedRate,
              payrollRate: jobActivityRateObject?.payrollRate,
              rowAttributeID: jobActivityRateObject?.rowAttributeID,
            },
          }),
        );

        dispatchAPI(
          VineaNovaActions.api.v1.jobActivityRate.put.request({
            postBody: {
              id: jobActivityRateObject?.jobActivityRateID,
              jobActivityID: jobActivityRateObject?.jobActivityID,
              effectiveFrom: isNil(jobActivityRateObject?.effectiveFrom)
                ? null
                : //@ts-ignore
                  new Date(jobActivityRateObject?.effectiveFrom),
              effectiveTo: isNil(jobActivityRateObject?.effectiveTo)
                ? null
                : //@ts-ignore
                  new Date(jobActivityRateObject?.effectiveTo),
              contractedRate: jobActivityRateObject?.contractedRate,
              payrollRate: jobActivityRateObject?.payrollRate,
              jobActivityDescription:
                jobActivityRateObject?.jobActivityDescription,
              isDefault: get(jobActivityRateObject, 'isDefault', false),
            },
          }),
        );
        setActivityRateAPITrigger(true);
      }
      setJobActivityDrawerOpen(false);
      setValidationErrors({ ...noErrors });
    } else {
      setValidationErrors(validationErrors);
    }
  };

  const handleOnDeleteActivity = () => {
    if (jobActivityRateObject?.canDeleteActivity) {
      dispatchAPI(
        VineaNovaActions.api.v1.jobActivity.delete.request({
          queryParams: {
            ID: jobActivityRateObject?.jobActivityID,
          },
        }),
      );
    }
    setDeleteJobActivityAPITrigger(true);
  };

  // Show success snackbar and navigate to job main page after activity deletion
  useEffect(() => {
    if (deleteJobActivityAPITrigger) {
      if (!jobActivityLoading && jobActivityLoaded) {
        if (jobActivityError) {
          enqueueSnackbar(t('Error'), { variant: 'error' });
          setWarningInSubmit(
            'Unable to delete activity - as it already has related data in Vinea',
          );
          setShowWarning(true);
        } else {
          enqueueSnackbar(t('Success'), { variant: 'success' });
          setRefreshJobActivityAPITrigger(true);
          fetchActivityRate();
          setDeleteDialogOpen(false);
          navigate(-1);
        }
        setDeleteJobActivityAPITrigger(false);
      }
    }
  }, [deleteJobActivityAPITrigger, jobActivityLoading, jobActivityLoaded]);

  // update save status when success and create post activity rate object
  useEffect(() => {
    if (activityAPITrigger) {
      if (!jobActivityLoading && jobActivityLoaded) {
        if (jobActivityError) {
          enqueueSnackbar(t('Error'), { variant: 'error' });
          setErrorInSubmit(jobActivityError);
        } else {
          if (isAddDrawer) {
            enqueueSnackbar(t('Success'), { variant: 'success' });
            postActivityRate(
              merge(jobActivityRateObject, {
                jobActivityID: get(jobActivityUpdate, 'id'),
              }),
            );
          } else {
            enqueueSnackbar(t('Success'), { variant: 'success' });
            if (saveIndex === 0) navigate(-1);
          }
        }
        setSaveSuccess(true);
        setRefreshJobActivityAPITrigger(true);
        fetchActivityRate();
        setActivityAPITrigger(false);
      }
    }
  }, [
    postActivityRate,
    fetchActivityRate,
    activityAPITrigger,
    jobActivityUpdate,
    jobActivityLoading,
    jobActivityLoaded,
    jobActivityError,
    isAddDrawer,
  ]);

  // update save status when success
  useEffect(() => {
    if (activityRateAPITrigger) {
      if (!jobActivityRateLoading && jobActivityRateLoaded) {
        if (jobActivityRateError) {
          enqueueSnackbar(t('Error'), { variant: 'error' });
          setErrorInSubmit(jobActivityRateError);
        } else {
          enqueueSnackbar(t('Success'), { variant: 'success' });
          if (saveIndex === 0) navigate(-1);
        }
        setSaveSuccess(true);
        setRefreshJobActivityAPITrigger(true);
        setActivityRateAPITrigger(false);
      }
    }
  }, [
    activityRateAPITrigger,
    jobActivityUpdate,
    jobActivityRateLoading,
    jobActivityRateLoaded,
    jobActivityRateError,
    isAddDrawer,
  ]);

  // Set activity options once job data loads
  useEffect(() => {
    if (jobDataLoaded && !jobDataLoading && has(jobData, 'jobTypeID')) {
      const jobTypeID = get(jobData, 'jobTypeID');
      const validWorkUnitIDs = map(
        filter(lkpJobWorkUnits, {
          jobTypeID: jobTypeID,
        }),
        (a: any) => {
          return a.workUnitID;
        },
      );
      const validActivities = filter(identityActivities, (i: any) => {
        return validWorkUnitIDs.includes(i.workUnitID);
      }) as IdentityActivityDropdownType[];

      setActivityOptions(validActivities);
      setFilteredActivityOptions(validActivities);
    }
  }, [jobActivityRates, jobDataLoaded, jobActivityLoading]);

  useEffect(() => {
    if (isOpen) {
      setJobActivityDrawerOpen(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!jobActivityDrawerOpen) {
      handleOnClose(IdentityDrawerTypes.ADD_JOB_ACTIVITY);
    }
  }, [jobActivityDrawerOpen]);

  return {
    validationErrors,
    jobActivityRateObject,
    setJobActivityRateObject,
    jobActivityDrawerOpen,
    isAddDrawer,
    lkpIdentityActivity: identityActivities,
    activityOptions,
    filteredActivityOptions,
    jobActivityRates,
    activityRateAPITrigger,
    activityAPITrigger,
    refreshJobActivityAPITrigger,
    jobActivityRateLoaded,
    jobActivityRateLoading,
    jobActivityRateError,
    jobActivityLoaded,
    jobActivityLoading,
    jobActivityError,
    jobDataLoaded,
    jobDataLoading,
    columnHeaders,
    saveSuccess,
    errorInSubmit,
    rowSpecialAttributeOptions,
    deleteDialogOpen,
    warningInSubmit,
    showWarning,
    deleteActivityConfirmation,
    setShowWarning,
    setWarningInSubmit,
    setDeleteDialogOpen,
    setValidationErrors,
    handleEditJobActivityRate,
    handleAddJobActivityRate,
    handleOnCloseDrawer,
    handleOnChange,
    handleOnChangeCheckbox,
    handleOnDeleteActivityRate,
    handleOnSave,
    handleOnDeleteActivity,
    setJobActivityDrawerOpen,
    setIsAddDrawer,
  };
};
