import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { VineaHooks } from 'vineanova-redux-artifacts';
import {
  blockVintages,
  blockVintagesInfo,
  getLookupTrellis,
  areLookupsLoading,
  areLookupsLoaded,
  getLookupBlockVintageStatus,
  getLookupVintage,
} from '../../../redux/selectors';
import { syncValidator } from '../../../utils/validator';
import { BlockVintageSchema } from '../validations';
import logger from '../../../utils/winstonLogger';
import {
  reducers,
  sagaActionTypes,
  vineaDetails,
  actionTypes,
  viewDateFormat,
  BlockVintageStatusIDs,
} from '../../../constants';
import { find, forEach, groupBy, includes, map, orderBy, uniq } from 'lodash';
import { parseISO, format as dateFnsFormat } from 'date-fns';

export const useBlockVintages = blockId => {
  const [blockVintageDetails, setBlockVintageDetails] = useState([]);
  const [editRowId, setEditRowId] = useState(null);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  /** Dispatch api */
  const dispatchAPI = useDispatch();

  /** selectors */
  const allBlockVintageDetails = useSelector(blockVintages);
  const { isLoading, hasError, isLoaded } = useSelector(blockVintagesInfo);
  const lkpTrellis = useSelector(getLookupTrellis);
  const isLookupLoading = useSelector(areLookupsLoading);
  const isLookupLoaded = useSelector(areLookupsLoaded);
  const lkpBlockVintageStatus = useSelector(getLookupBlockVintageStatus);
  const lkpVintage = useSelector(getLookupVintage);

  /* 
    Crop measurements and phenology. All vintage records are displayed in the vintage tab, 
    all phenolofy records are displayed in the phenology tab, except Modified EL readings, 
    as per devops task #4130
  */
  const cropMeasurmentDataTypes = ['Vintage', 'Modified E-L Number'];

  const { data: vineyardBlockMeasurements } =
    VineaHooks.useFetchVineyardBlockCropMeasurements({
      queryParams: {
        VineyardBlockID: blockId,
      },
    });

  let cropMeasurementData = [];
  let cropPhenologyData = [];

  const currentBlockVintage = useMemo(() => {
    const currentVintageID = find(lkpVintage, { isActive: true })?.id;
    const current = find(allBlockVintageDetails, {
      vintageID: currentVintageID,
    });

    return current;
  }, [allBlockVintageDetails, lkpVintage.length]);

  // Show Cost Allocation tab if block is currently adhoc
  const tabHeaders = useMemo(() => {
    const headers = ['Details', 'Measures', 'Phenology', 'Notes', 'Attributes'];
    if (
      currentBlockVintage?.blockVintageStatusID === BlockVintageStatusIDs.ADHOC
    ) {
      headers.push('Cost Allocation');
    }

    return headers;
  }, [currentBlockVintage?.blockVintageStatusID]);

  forEach(vineyardBlockMeasurements, v => {
    if (includes(cropMeasurmentDataTypes, v?.measurementType)) {
      cropMeasurementData.push({ ...v, id: v?.measurementID });
    } else {
      cropPhenologyData.push({
        ...v,
        id: v?.measurementID,
        measureDate: dateFnsFormat(parseISO(v?.measureDate), viewDateFormat),
      });
    }
  });

  const cropPhenologyColumns = [
    {
      field: 'vintage',
      headerName: 'Vintage',
      hideable: false,
      type: 'string',
      flex: 1,
    },
    {
      field: 'measurementType',
      headerName: 'Measure',
      hideable: false,
      type: 'string',
      flex: 1,
    },
    {
      field: 'measureValue',
      headerName: 'Value',
      hideable: false,
      type: 'string',
      flex: 1,
    },
    {
      field: 'measureDate',
      headerName: 'Date',
      hideable: false,
      type: 'string',
      flex: 1,
    },
  ];

  let cropMeasurementColumns = [
    {
      field: 'measurementType',
      headerName: 'Category',
      hideable: false,
      type: 'string',
      flex: 1,
    },
    {
      field: 'measureName',
      headerName: 'Measure',
      hideable: false,
      type: 'string',
      flex: 2,
    },
  ];

  const vintages = orderBy(
    uniq(map(vineyardBlockMeasurements, 'vintage')),
    [],
    ['desc'],
  );

  vintages.forEach(vintage => {
    cropMeasurementColumns.push({
      field: vintage,
      headerName: vintage,
      hideable: false,
      type: 'string',
      flex: 1,
    });
  });

  const pivotData = data => {
    const grouped = groupBy(data, 'measureName');
    return map(grouped, items => {
      const { id, measureName, measurementType, vintageID, blockVintageID } =
        items[0];
      const result = {
        id,
        measureName,
        measurementType,
        vintageID,
        blockVintageID,
      };
      items.forEach(({ vintage, measureValue }) => {
        result[vintage] = measureValue;
      });
      return result;
    });
  };

  const pivotedBlockMeasurements = pivotData(cropMeasurementData);

  const cropMeasurementsTable = {
    columns: cropMeasurementColumns,
    rows: pivotedBlockMeasurements,
  };

  const cropPhenologyTable = {
    columns: cropPhenologyColumns,
    rows: cropPhenologyData,
  };

  const getBlockVintageStatusValue = useCallback(
    statusId => {
      const blockVintageStatusValue =
        !isEmpty(lkpBlockVintageStatus) &&
        get(
          lkpBlockVintageStatus.find(vn => vn.id === statusId),
          'value',
          ' ',
        );
      return blockVintageStatusValue;
    },
    [lkpBlockVintageStatus],
  );

  const getBlockTrellisStatusValue = useCallback(
    trellisID => {
      const trellisStatusValue =
        !isEmpty(lkpTrellis) &&
        get(
          lkpTrellis.find(vn => vn.id === trellisID),
          'value',
          ' ',
        );

      return trellisStatusValue;
    },
    [lkpTrellis],
  );

  useEffect(() => {
    if (isLookupLoaded && !isLookupLoading) {
      if (!isLoading && !hasError && isLoaded) {
        const blockVinDetails = allBlockVintageDetails.map(f => {
          const blockVintageStatusValue = getBlockVintageStatusValue(
            f.blockVintageStatusID,
          );

          const trellisStatusValue = getBlockTrellisStatusValue(f.trellisID);

          return {
            ...f,
            vintageStatus: blockVintageStatusValue,
            trellisStatus: trellisStatusValue,
            isActive: f.isActiveVintageYear,
            isEditMode: false,
          };
        });
        setBlockVintageDetails(blockVinDetails);
      }
    }
  }, [
    allBlockVintageDetails,
    lkpBlockVintageStatus,
    lkpTrellis,
    isLookupLoaded,
    isLookupLoading,
    isLoading,
    hasError,
    isLoaded,
    getBlockVintageStatusValue,
    getBlockTrellisStatusValue,
  ]);

  const handleOnEditClick = useCallback(
    rowId => {
      const newBlockVintageDetails = blockVintageDetails.map(f => {
        return {
          ...f,
          isEditMode: f.id === rowId,
        };
      });
      setEditRowId(rowId);
      setBlockVintageDetails(newBlockVintageDetails);
    },
    [blockVintageDetails],
  );

  const handleOnChangeCommon = useCallback(
    (evt, id) => {
      const {
        target: { name, value },
      } = evt;
      if (name === 'blockVintageStatusID') {
        const updatedState = blockVintageDetails.map(row => {
          if (row.id === id) {
            const blockVintageStatusValue = getBlockVintageStatusValue(value);
            return {
              ...row,
              blockVintageStatusID: value,
              vintageStatus: blockVintageStatusValue,
            };
          }
          return row;
        });
        setBlockVintageDetails(updatedState);
      }
      if (name === 'trellisID') {
        const updatedState = blockVintageDetails.map(row => {
          if (row.id === id) {
            const trellisStatusValue = getBlockTrellisStatusValue(value);

            return {
              ...row,
              trellisID: value,
              trellisStatus: trellisStatusValue,
            };
          }
          return row;
        });
        setBlockVintageDetails(updatedState);
      }
    },
    [
      blockVintageDetails,
      getBlockVintageStatusValue,
      getBlockTrellisStatusValue,
    ],
  );

  const handleOnSaveRow = () => {
    // if they have't edited a row, there is nothing to save
    if (editRowId === null) {
      return;
    }

    const dataToSave = blockVintageDetails.find(f => f.id === editRowId);
    const allValidationErrors = syncValidator(BlockVintageSchema)(dataToSave);
    if (!isEmpty(allValidationErrors)) {
      setValidationErrors(allValidationErrors);
      logger.debug('there are validations errors', { allValidationErrors });
    } else {
      const data = {
        id: dataToSave.id,
        vineyardBlockID: dataToSave.vineyardBlockID,
        vintageID: dataToSave.vintageID,
        blockVintageStatusID: dataToSave.blockVintageStatusID,
        defaultSampleSize: dataToSave.defaultSampleSize,
        trellisID: dataToSave.trellisID,
        ts: dataToSave.ts,
      };

      dispatchAPI({ type: actionTypes.clear, name: reducers.formWrite });
      dispatchAPI({
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.blockVintage,
          methodType: dataToSave.methodType,
        },
      });

      setActionTriggered(true);
    }
  };

  return {
    cropPhenologyTable,
    cropMeasurementsTable,
    blockVintageDetails,
    currentBlockVintage,
    isLoadingBlockVintages: isLoading,
    hasErrorBlockVintages: hasError,
    isLoadedBlockVintages: isLoaded,
    actionTriggered,
    validationErrors,
    tabHeaders,
    lkpVintage,
    handleOnEditClick,
    handleOnChangeCommon,
    handleOnSaveRow,
    setBlockVintageDetails,
    setActionTriggered,
  };
};
