import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import {
  map,
  isEmpty,
  find,
  isNil,
  some,
  get,
  reverse,
  sortBy,
  has,
} from 'lodash';
import Box from '@mui/material/Box';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { ErrorBoundary } from 'react-error-boundary';
import { format, parseISO, isValid, isBefore } from 'date-fns';
import { IdentityDetails } from '../../components/IdentityDetails';
import { VineaCommonGrid } from '../../components/Grid';
import { TabPanel, TabView } from '../../components/TabView';
import {
  commonClassificationPropTypes,
  commonReducerPropTypes,
  commonContactMethodPropTypes,
  commonRelatonshipPropTypes,
} from '../../utils/commonPropTypes';
import { syncValidator } from '../../utils/validator';
import { validDateFormat } from '../../utils/DateUtil';
import {
  IdentityTypeIds,
  DataProviderTypeIDs,
  reducers,
  sagaActionTypes,
  dateTimeFormat,
  IdentityDrawerTypes,
  SiteTypeIDs,
} from '../../constants';
import { formatDate } from '../../constants/formatter';
import {
  getIdentitySubDetailsIsLoading,
  getClassificationsById,
  getIdentityReferences,
  getAllIdentityRoles,
  getAllIdentityContactMethods,
  getIdentityOverviewDetailsFromContact,
  getIsIdentityOverviewLoading,
  getIsIdentityOverviewLoaded,
  getUserPreferences,
  getLkpJobEventAction,
  getBlocksForJobs,
  getLookupRowSpecialAttribute,
  getIdentityRelationshipEntityData,
} from '../../redux/selectors';
import { getLicenseActiveStatus } from '../../redux/selectors/userAccessSelector';
import { useIdentityTypeId } from '../../hooks/useIdentityTypeId';
import { useProfileImageUpload } from './hooks/useProfileImageUpload';
import { VineyardBlocks } from '../VineyardBlocks';
import { IdentityNotes } from '../IdentityNotes';
import BlocksMap from '../BlocksMap';
import { useIdentityStatus } from '../../hooks/useIdentityStatus';
import useTabChangeLock from '../../hooks/useTabChangeLock';
import { SitesGrid } from '../DataConnectionIdentity/Sensors/SitesGrid';
import { SensorsGrid } from '../DataConnectionIdentity/Sensors/SensorsGrid';
import ErrorBoundaryFallback from '../../layouts/ErrorBoundary';
import { ActivityDefaultRates } from '../ActivityDefaultRates';
import { SyncMaps } from '../DataConnectionIdentity/Sensors/SyncMaps';
import { ActivityMapping } from '../ServieProviderIdentity/ActivityMapping/ActivityMapping';
import SupplyContractBlocksMap from '../SupplyContracts/SupplyContractBlocksMap.tsx';
import { EditSupplyContractBlockDrawer } from '../SupplyContracts/EditSupplyContractBlockDrawer';
import { AddSupplyContractBlockDrawer } from '../SupplyContracts/AddSupplyContractBlockDrawer';
import {
  AddSupplyContractBlockSchema,
  EditSupplyContractBlockSchema,
} from '../SupplyContracts/validations';
import { OverviewGrids } from './IdentityOverviewGrids';
import { ManageJobSupervisorsDrawer } from '../Jobs/ManageJobSupervisorsDrawer';
import { EditJobBlocksDrawer } from '../Jobs/EditJobBlocksDrawer';
import { AddJobActivityDrawer } from '../Jobs/drawers/AddJobActivityDrawer';
import useRefDimensions from '../../hooks/useRefDimensions';
import useIdentityTypeScreenNameHook from '../../hooks/useIdentityTypeScreenNameHook';
import { EditRelationshipsDrawer } from './Manage/EditRelationshipsDrawer';

// start of the styled components
const RootDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '&  #tabpanelcontentwrapper': {
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
    height: '100%',
  },
  '& .react-swipeable-view-container': { height: '100%' },
  '& .tabpanel': { height: '100%' },
}));

const {
  PERSON,
  ORGANISATION,
  VINEYARD,
  SERVICE_PROVIDER,
  DATA_CONNECTION,
  CONSUMABLE,
  ACCOMMODATION,
  ACTIVITY,
  SUPPLY_CONTRACT,
  JOB,
  VEHICLE,
} = IdentityTypeIds;

const { SENSORS, ARC_GIS } = DataProviderTypeIDs;

const commonIdentityReducerNames = {
  [PERSON]: reducers.person,
  [ORGANISATION]: reducers.organisation,
  [VINEYARD]: reducers.vineyard,
  [SERVICE_PROVIDER]: reducers.serviceProvider,
  [DATA_CONNECTION]: reducers.dataConnection,
  [CONSUMABLE]: reducers.consumable,
  [ACCOMMODATION]: reducers.accommodation,
  [ACTIVITY]: reducers.activity,
  [SUPPLY_CONTRACT]: reducers.supplyContract,
  [JOB]: reducers.job,
  [VEHICLE]: reducers.vehicle,
};

const IdentityScreen = ({
  isSubDetailsLoading,
  fetchIdentityRoot,
  classifications,
  contactDetails,
  identityRefData,
  identityRoles,
  licenseStatus,
  updateFavouriteIdentity,
  tabIndex,
  setTabIndex,
}) => {
  const navigate = useNavigate();
  const dispatchAPI = useDispatch();
  const { t } = useTranslation();
  const { id: pathParam } = useParams();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [isWorker, setIsWorker] = React.useState(pathname.includes('worker'));
  const { vineyardTypeScreenName, identityBaseURL } =
    useIdentityTypeScreenNameHook(isWorker);
  const identityTypeId = useIdentityTypeId(vineyardTypeScreenName);
  const [validationsErrors, setValidationErrors] = useState({});

  const identityScreenRef = useRef(null);
  const dimensions = useRefDimensions(identityScreenRef);
  const mainPageHeight = useMemo(() => {
    const detailsH = 200;
    if (isNil(dimensions)) return '800' - detailsH;
    return get(dimensions, 'height', '800') - detailsH;
  }, [dimensions]);

  const identityRelationshipData = useSelector(state =>
    getIdentityRelationshipEntityData(state),
  );

  const [stateMap, setStateMap] = React.useState({
    1: isWorker ? ['/overview', '/notes', '/more'] : ['/notes', '/more'],
    2: ['/more', `/notes`],
    3: ['/blocks', `/more`, '/blockmaps', `/notes`, '/assets', '/jobs'],
    4: ['/activitymapping', '/more', `/notes`],
    5: ['/overview', '/more', '/notes'],
    8: includeSitesAndSensors
      ? ['/sites', '/sensors', `/notes`, '/more']
      : [`/notes`, '/more'],
    20: ['/notes', '/more'],
    21: ['/more', '/notes'],
    23: ['/notes', '/more', '/transactions'],
    25: ['/defaultrates', '/references', '/notes'],
    26: ['/overview', '/map', '/notes'],
  });

  const [
    supplyContractBlocksColumnHeaders,
    setSupplyContractBlocksColumnHeaders,
  ] = React.useState([
    t('Vineyard'),
    t('Block'),
    t('Area'),
    t('T/Ha'),
    t('Contracted Tonnes'),
    t('From'),
  ]);

  const [jobBlocksColumnHeaders, setJobBlocksColumnHeaders] = React.useState([
    t('Vineyard'),
    t('Block'),
    t('Selected Vines'),
    t('Completed'),
    t('Status'),
  ]);

  const [initialApiTriggered, setInitialTrigger] = React.useState(true);
  const { handleSaveCroppedImage, imageDataUrl, handleOnDeleteImage } =
    useProfileImageUpload(identityTypeId, pathParam);

  /** selectors */
  const { data: { refreshPage } = {} } = useSelector(
    state => state.refreshPages,
  );

  const identityOverviewDetails = useSelector(state =>
    getIdentityOverviewDetailsFromContact(state),
  );

  const { businessUnitID, siteTypeID } = identityOverviewDetails;

  const isIdentityOverviewDetaisLoading = useSelector(
    getIsIdentityOverviewLoading,
  );
  const isIdentityOverviewDetaisLoaded = useSelector(
    getIsIdentityOverviewLoaded,
  );

  const allUserPreferences = useSelector(state => getUserPreferences(state));
  const userIdentityId =
    useSelector(state => state.userAccess.data?.licensedUserIdentityID) || null;
  const identityStatus = useIdentityStatus();
  const { setIsLocked, isLocked } = useTabChangeLock();

  // Supply contracts
  const [apiTrigger, setApiTrigger] = useState(false);
  const [supplyContractBlocksTableData, setSupplyContractBlocksTableData] =
    React.useState([]);
  const supplyContractBlockRef = useRef([{}]);
  const [relationshipDrawerOpen, setRelationshipDrawerOpen] = useState(false);
  const [editRelationshipID, setEditRelationshipID] = useState(null);
  const [addSupplyContractDrawerOpen, setAddSupplyContractDrawerOpen] =
    React.useState(false);
  const [editSupplyContractDrawerOpen, setEditSupplyContractDrawerOpen] =
    React.useState(false);
  const [editSupplyContractBlock, setEditSupplyContractBlock] = useState({});
  const [addSupplyContractBlock, setAddSupplyContractBlock] = useState({
    supplyContractID: pathParam,
  });
  const [jobBlockDrawerOpen, setJobBlockDrawerOpen] = React.useState(false);
  const [jobActivityDrawerOpen, setJobActivityDrawerOpen] =
    React.useState(false);
  const [jobSupervisorDrawerOpen, setJobSupervisorDrawerOpen] =
    React.useState(false);
  const [jobSupervisorAdd, setJobSupervisorAdd] = React.useState(true);
  const [editJobSupervisorData, setEditJobSupervisorData] = useState({});
  const {
    data: jobSupervisorsData,
    isLoading: jobSupervisorLoading,
    isLoaded: jobSupervisorLoaded,
  } = useSelector(state => state.entities.jobSupervisor);

  const { isLoading: jobBlocksBulkLoading, isLoaded: jobBlocksBulkLoaded } =
    useSelector(VineaNovaSelectors.getJobBlocksBulkEntityMeta);
  const [jobSupervisorAPITrigger, setJobSupervisorAPITrigger] =
    React.useState(true);
  const { data: supplyContractBlocksData } = useSelector(
    state => state.entities.blocksForSupplyContract,
  );
  const {
    isLoading: isBlocksForSupplyContractLoading,
    isLoaded: isBlocksForSupplyContractLoaded,
  } = useSelector(VineaNovaSelectors.getBlocksForSupplyContractEntityMeta);
  const {
    isLoading: isSupplyContractBlocksLoading,
    isLoaded: isSupplyContractBlocksLoaded,
  } = useSelector(VineaNovaSelectors.getSupplyContractBlockEntityMeta);

  const lkpRowAttribute = useSelector(getLookupRowSpecialAttribute);
  // jobEvent lookup
  const lkpJobEventAction = useSelector(getLkpJobEventAction);
  // job events
  const jobEventData = useSelector(VineaNovaSelectors.getJobEventsEntityData);
  // job supervisordata
  const jobSupervisorData = useSelector(
    VineaNovaSelectors.getJobSupervisorEntityData,
  );
  const { isLoaded: jobActivityRateLoaded, isLoading: jobActivityRateLoading } =
    useSelector(VineaNovaSelectors.getJobActivityRateEntityMeta);

  const { data: jobActivitiesData } = useSelector(
    VineaNovaSelectors.getGetJobActivityRatesEntity,
  );

  const jobActivitiesMappedData = React.useMemo(() => {
    const jobActivities = map(jobActivitiesData, jobActivity => {
      const rowAttribute = lkpRowAttribute?.find(
        r => r.id === jobActivity?.rowAttributeID,
      );
      return {
        ...jobActivity,
        rowAttribute: rowAttribute?.value,
      };
    });

    return jobActivities;
  }, [jobActivitiesData, lkpRowAttribute]);

  // job blocks
  const { data: jobBlocksData } = useSelector(getBlocksForJobs);

  // Workers
  const { data: workerPayPeriodsData } = useSelector(
    state => state.entities.workerPayPeriods,
  );

  const workerPayPeriodsSorted = reverse(
    sortBy(workerPayPeriodsData, ['payrollStatus', 'payPeriod']),
  );

  const jobEventMapData = React.useMemo(() => {
    const jobEvents = map(jobEventData, jobEvent => {
      const jobEventAction = lkpJobEventAction?.find(
        action => action.id === jobEvent.jobEventActionID,
      );
      const jobEventDateFormatted = has(jobEvent, 'jobEventDate')
        ? format(parseISO(jobEvent?.jobEventDate), dateTimeFormat)
        : '';
      return {
        ...jobEvent,
        jobEventAction: jobEventAction?.jobEventAction,
        jobEventDate: jobEventDateFormatted,
      };
    });

    return jobEvents;
  }, [jobEventData, lkpJobEventAction]);

  const handleonTabChange = newValue => {
    setTabIndex(newValue);
    setIsLocked(true);
    handleURLChange(newValue);
  };

  const functionMap = {
    CLASSIFICATIONS: `manageclassifications`,
    REFERENCES: `managereferences`,
    RELATIONSHIPS: `managerelationships`,
    CONTACTS: `managecontacts`,
    ROLES: `manageroles`,
  };

  const includeSitesAndSensors = useMemo(() => {
    return identityOverviewDetails.dataProviderTypeID === SENSORS;
  }, [identityOverviewDetails.dataProviderTypeID]);

  const includeSyncMaps = useMemo(() => {
    return identityOverviewDetails.dataProviderTypeID === ARC_GIS;
  }, [identityOverviewDetails.dataProviderTypeID]);

  const handleURLChange = tabIndex => {
    navigate(`/${identityBaseURL}${stateMap[identityTypeId][tabIndex]}`);
  };

  const handleChangeIndex = index => {
    setTabIndex(index);
  };

  const { data: contactData } = contactDetails;

  useEffect(() => {
    setInitialTrigger(true);
  }, [pathParam]);

  useEffect(() => {
    setIsLocked(true);
    setApiTrigger(true);

    if (identityTypeId === IdentityTypeIds.DATA_CONNECTION) {
      dispatchAPI(
        VineaNovaActions.api.v1.identityDataConnector.get.request({
          queryParams: {
            IdentityID: pathParam,
          },
        }),
      );
    }

    dispatchAPI(
      VineaNovaActions.api.v1.userHistory.post.request({
        postBody: {
          userIdentityID: userIdentityId,
          identityID: pathParam,
          lastAccessDateTime: new Date(),
        },
      }),
    );

    dispatchAPI(
      VineaNovaActions.api.v1.identityRelationship.get.request({
        queryParams: {
          IdentityID: pathParam,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchAPI, userIdentityId, pathParam, identityTypeId]);

  useEffect(() => {
    if (apiTrigger) {
      if (identityTypeId === IdentityTypeIds.SUPPLY_CONTRACT) {
        dispatchAPI(VineaNovaActions.api.v1.identity.get.request({}));
        setApiTrigger(false);
      }
    }
  }, [dispatchAPI, identityTypeId, apiTrigger, identityOverviewDetails]);

  useEffect(() => {
    setIsWorker(pathname.includes('worker'));
  }, [pathname]);

  // Get blocks for supply contract when supply contract blocks loads
  useEffect(() => {
    if (isSupplyContractBlocksLoaded && !isSupplyContractBlocksLoading) {
      dispatchAPI(
        VineaNovaActions.api.v1.blocksForSupplyContract.get.request({
          queryParams: {
            SupplyContractID: pathParam,
          },
        }),
      );
    }
  }, [
    pathParam,
    dispatchAPI,
    isSupplyContractBlocksLoaded,
    isSupplyContractBlocksLoading,
  ]);

  // Get Related Identities of supply contract supplying party when identity overview loads
  useEffect(() => {
    if (
      !isIdentityOverviewDetaisLoading &&
      isIdentityOverviewDetaisLoaded &&
      identityTypeId === IdentityTypeIds.SUPPLY_CONTRACT
    ) {
      dispatchAPI(
        VineaNovaActions.api.v1.identityRelationship.get.request({
          queryParams: {
            IdentityID: identityOverviewDetails?.supplyingPartyIdentityID,
          },
        }),
      );
    }
    if (identityTypeId === IdentityTypeIds.JOB) {
      if (
        siteTypeID === SiteTypeIDs.ORCHARD_TREES ||
        siteTypeID === SiteTypeIDs.ORCHARD_KIWIFRUIT
      ) {
        setJobBlocksColumnHeaders([
          t('Orchard'),
          t('Block'),
          t('Selected Units'),
          t('Completed'),
          t('Status'),
        ]);
      } else {
        setJobBlocksColumnHeaders([
          t('Vineyard'),
          t('Block'),
          t('Selected Vines'),
          t('Completed'),
          t('Status'),
        ]);
      }
    }
  }, [isIdentityOverviewDetaisLoaded, isIdentityOverviewDetaisLoading]);

  useEffect(() => {
    if (!isBlocksForSupplyContractLoading && isBlocksForSupplyContractLoaded) {
      let blocks = supplyContractBlocksData;
      if (
        typeof supplyContractBlocksData === 'object' &&
        !Array.isArray(supplyContractBlocksData) &&
        supplyContractBlocksData !== null
      ) {
        // If it's a single object, convert it to an array with one object
        blocks = [supplyContractBlocksData];
      }
      const data = map(blocks, block => ({
        id: block?.id,
        vineyard: block?.vineyardName,
        vineyardBlockID: block?.vineyardBlockID,
        block: block?.blockFullName,
        area: block?.contractedArea,
        tha:
          block?.contractedWeightPerAreaUnit > 0
            ? block?.contractedWeightPerAreaUnit
            : 'N/A',
        contractedTonnes:
          block?.contractedWeightPerAreaUnit > 0
            ? Math.round(
                Number(block?.contractedArea) *
                  Number(block?.contractedWeightPerAreaUnit) *
                  100,
              ) / 100
            : 'N/A',
        fromTo: !isNil(block?.toDate)
          ? `${formatDate(block?.fromDate)} - ${formatDate(block?.toDate)}`
          : formatDate(block?.fromDate),
      }));
      setSupplyContractBlocksTableData(data);
      if (
        some(supplyContractBlocksData, block => !isNil(get(block, 'toDate')))
      ) {
        setSupplyContractBlocksColumnHeaders([
          t('Vineyard'),
          t('Block'),
          t('Area'),
          t('T/Ha'),
          t('Contracted Tonnes'),
          t('From - To'),
        ]);
      }
    }
  }, [
    isBlocksForSupplyContractLoaded,
    isBlocksForSupplyContractLoading,
    supplyContractBlocksData,
  ]);

  /* Job Supervisors useEffects */
  useEffect(() => {
    if (identityTypeId === IdentityTypeIds.JOB && jobSupervisorAPITrigger) {
      dispatchAPI(
        VineaNovaActions.api.v1.jobSupervisor.get.request({
          queryParams: {
            JobID: pathParam,
          },
        }),
      );
    }
  }, [jobSupervisorAPITrigger, pathParam]);

  useEffect(() => {
    if (jobSupervisorLoaded && !jobSupervisorLoading) {
      if (!jobSupervisorAPITrigger) setJobSupervisorAPITrigger(true);
      else setJobSupervisorAPITrigger(false);
    }
  }, [jobSupervisorLoaded, jobSupervisorLoading]);

  useEffect(() => {
    const setXStateMap = {};

    if (isWorker) {
      setXStateMap[IdentityTypeIds.PERSON] = ['/overview', '/notes', '/more'];
    } else {
      setXStateMap[IdentityTypeIds.PERSON] = ['/notes', '/more'];
    }

    if (includeSitesAndSensors) {
      setXStateMap[IdentityTypeIds.DATA_CONNECTION] = [
        '/sites',
        '/sensors',
        '/notes',
        '/more',
      ];
    } else if (includeSyncMaps) {
      setXStateMap[IdentityTypeIds.DATA_CONNECTION] = [
        '/syncmaps',
        '/notes',
        '/more',
      ];
    } else {
      setXStateMap[IdentityTypeIds.DATA_CONNECTION] = ['/notes', '/more'];
    }

    setXStateMap[IdentityTypeIds.ORGANISATION] = ['/notes', '/more'];
    setXStateMap[IdentityTypeIds.VINEYARD] = [
      '/blocks',
      '/blockmaps',
      '/notes',
      '/more',
      '/assets',
      '/jobs',
    ];
    setXStateMap[IdentityTypeIds.SERVICE_PROVIDER] = [
      '/activitymapping',
      '/more',
      '/notes',
    ];
    setXStateMap[IdentityTypeIds.JOB] = ['/overview', '/more', '/notes'];
    setXStateMap[IdentityTypeIds.ACCOMMODATION] = ['/more', '/notes'];
    setXStateMap[IdentityTypeIds.CONSUMABLE] = [
      '/notes',
      '/more',
      '/transactions',
    ];
    setXStateMap[IdentityTypeIds.ACTIVITY] = [
      '/defaultrates',
      '/references',
      '/notes',
    ];
    setXStateMap[IdentityTypeIds.SUPPLY_CONTRACT] = [
      '/overview',
      '/maps',
      '/notes',
    ];
    setXStateMap[IdentityTypeIds.VEHICLE] = ['/notes', '/more'];
    setStateMap(setXStateMap);
  }, [isWorker, includeSitesAndSensors, includeSyncMaps]);

  useEffect(() => {
    dispatchAPI({
      type: sagaActionTypes.FETCH_IDENTITY_VALUES,
      payload: {
        id: pathParam,
        name: commonIdentityReducerNames[identityTypeId],
      },
      forceFetch: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathParam, identityTypeId]);

  useEffect(() => {
    if (!initialApiTriggered && isLocked) return;

    if (initialApiTriggered || refreshPage) {
      fetchIdentityRoot({ id: pathParam, identityTypeId });
      setInitialTrigger(false);
      // dispatchAPI({
      //   type: sagaActionTypes.REFRESH_PAGE_DATA,
      //   payload: { refreshPage: false },
      // });

      dispatchAPI(
        VineaNovaActions.api.v1.identityRole.get.request({
          queryParams: {
            IdentityID: pathParam,
          },
        }),
      );
      if (identityTypeId === IdentityTypeIds.JOB) {
        dispatchAPI(
          VineaNovaActions.api.v1.getJobActivityRates.get.request({
            queryParams: {
              JobID: pathParam,
            },
          }),
        );
      }
      if (identityTypeId === IdentityTypeIds.SUPPLY_CONTRACT) {
        dispatchAPI(
          VineaNovaActions.api.v1.blocksForSupplyContract.get.request({
            queryParams: {
              SupplyContractID: pathParam,
            },
          }),
        );
      }
      if (identityTypeId === IdentityTypeIds.PERSON && isWorker) {
        dispatchAPI(
          VineaNovaActions.api.v1.workerPayPeriods.get.request({
            queryParams: {
              WorkerID: pathParam,
            },
          }),
        );
      }
    }
  }, [
    pathParam,
    fetchIdentityRoot,
    initialApiTriggered,
    dispatchAPI,
    identityTypeId,
    pathname,
    refreshPage,
    isLocked,
    isWorker,
  ]);

  useEffect(() => {
    if (identityTypeId === IdentityTypeIds.JOB) {
      dispatchAPI(
        VineaNovaActions.api.v1.jobEvents.get.request({
          queryParams: {
            JobID: pathParam,
          },
        }),
      );

      dispatchAPI(
        VineaNovaActions.api.v1.blocksForJob.get.request({
          queryParams: {
            JobID: pathParam,
          },
        }),
      );

      dispatchAPI(
        VineaNovaActions.api.v1.jobSupervisor.get.request({
          queryParams: {
            JobID: pathParam,
          },
        }),
      );

      dispatchAPI(
        VineaNovaActions.api.v1.getJobActivityRates.get.request({
          queryParams: {
            JobID: pathParam,
          },
        }),
      );
    }
  }, [identityTypeId, pathParam, dispatchAPI]);

  useEffect(() => {
    if (identityTypeId === IdentityTypeIds.SUPPLY_CONTRACT)
      supplyContractBlockRef.current = supplyContractBlocksTableData;
  }, [supplyContractBlocksTableData]);

  // If job blocks bulk api has been run, refresh blocks for job
  useEffect(() => {
    if (jobBlocksBulkLoaded && !jobBlocksBulkLoading) {
      dispatchAPI(
        VineaNovaActions.api.v1.blocksForJob.get.request({
          queryParams: {
            JobID: pathParam,
          },
        }),
      );
    }
  }, [jobBlocksBulkLoaded, jobBlocksBulkLoading]);

  useEffect(() => {
    if (jobActivityRateLoaded && !jobActivityRateLoading) {
      dispatchAPI(
        VineaNovaActions.api.v1.getJobActivityRates.get.request({
          queryParams: {
            JobID: pathParam,
          },
        }),
      );
    }
  }, [jobActivityRateLoaded, jobActivityRateLoading]);

  useEffect(() => {
    // navigate to tab based on url params
    if (pathname === `/organisation/${vineyardTypeScreenName}s/${pathParam}`) {
      navigate(`/organisation/${vineyardTypeScreenName}s/${pathParam}/blocks`, {
        replace: true,
      });
      setTabIndex(0);
    } else if (pathname === `/person/${pathParam}`) {
      navigate(`/person/${pathParam}/notes`, {
        replace: true,
      });
      setTabIndex(0);
    } else if (pathname === `/person/${pathParam}/notes`) {
      setTabIndex(0);
    } else if (pathname === `/person/${pathParam}/more`) {
      setTabIndex(1);
    } else if (pathname === `/worker/${pathParam}`) {
      navigate(`/worker/${pathParam}/overview`, {
        replace: true,
      });
      setTabIndex(0);
    } else if (pathname === `/worker/${pathParam}/notes`) {
      setTabIndex(1);
    } else if (pathname === `/worker/${pathParam}/more`) {
      setTabIndex(2);
    } else if (pathname === `/job/${pathParam}`) {
      navigate(`/job/${pathParam}/overview`, {
        replace: true,
      });
      setTabIndex(0);
    } else if (pathname === `/job/${pathParam}/overview`) {
      setTabIndex(0);
    } else if (pathname === `/job/${pathParam}/more`) {
      setTabIndex(1);
    } else if (pathname === `/job/${pathParam}/notes`) {
      setTabIndex(2);
    } else if (pathname === `/organisation/serviceproviders/${pathParam}`) {
      navigate(`/organisation/serviceproviders/${pathParam}/activitymapping`, {
        replace: true,
      });
      setTabIndex(0);
    } else if (pathname === `/organisation/other-organisations/${pathParam}`) {
      navigate(`/organisation/other-organisations/${pathParam}/more`, {
        replace: true,
      });
      setTabIndex(0);
    } else if (pathname === `/consumable/${pathParam}`) {
      navigate(`/consumable/${pathParam}/notes`, {
        replace: true,
      });
      setTabIndex(0);
    } else if (pathname === `/consumable/${pathParam}/more`) {
      setTabIndex(1);
    } else if (pathname === `/consumable/${pathParam}/transactions`) {
      setTabIndex(2);
    } else if (pathname === `/activity/${pathParam}`) {
      navigate(`/activity/${pathParam}/defaultrates`, {
        replace: true,
      });
      setTabIndex(0);
    } else if (pathname === `/activity/${pathParam}/references`) {
      setTabIndex(1);
    } else if (pathname === `/activity/${pathParam}/notes`) {
      setTabIndex(2);
    } else if (pathname === `/accommodation/${pathParam}`) {
      navigate(`/accommodation/${pathParam}/more`, {
        replace: true,
      });
      setTabIndex(0);
    } else if (pathname === `/accommodation/${pathParam}/notes`) {
      setTabIndex(1);
    } else if (
      pathname ===
      `/organisation/${vineyardTypeScreenName}s/${pathParam}/blockmaps`
    ) {
      setTabIndex(1);
    } else if (
      pathname === `/organisation/${vineyardTypeScreenName}s/${pathParam}/notes`
    ) {
      setTabIndex(2);
    } else if (
      pathname === `/organisation/${vineyardTypeScreenName}s/${pathParam}/more`
    ) {
      setTabIndex(3);
    } else if (
      pathname ===
      `/organisation/${vineyardTypeScreenName}s/${pathParam}/assets`
    ) {
      setTabIndex(4);
    } else if (
      pathname === `/organisation/${vineyardTypeScreenName}s/${pathParam}/jobs`
    ) {
      setTabIndex(5);
    } else if (
      pathname === `/organisation/serviceproviders/${pathParam}/more`
    ) {
      setTabIndex(1);
    } else if (
      pathname === `/organisation/serviceproviders/${pathParam}/notes`
    ) {
      setTabIndex(2);
    } else if (
      pathname === `/organisation/other-organisations/${pathParam}/notes`
    ) {
      setTabIndex(1);
    } else if (includeSitesAndSensors) {
      if (pathname === `/connections/${pathParam}`) {
        navigate(`/connections/${pathParam}/sites`, {
          replace: true,
        });
        setTabIndex(0);
      } else if (pathname === `/connections/${pathParam}/sensors`) {
        setTabIndex(1);
      } else if (pathname === `/connections/${pathParam}/notes`) {
        setTabIndex(2);
      } else if (pathname === `/connections/${pathParam}/more`) {
        setTabIndex(3);
      }
    } else if (includeSyncMaps) {
      if (pathname === `/connections/${pathParam}`) {
        navigate(`/connections/${pathParam}/syncmaps`, {
          replace: true,
        });
        setTabIndex(0);
      } else if (pathname === `/connections/${pathParam}/notes`) {
        setTabIndex(1);
      } else if (pathname === `/connections/${pathParam}/more`) {
        setTabIndex(2);
      }
    } else if (pathname === `/connections/${pathParam}`) {
      navigate(`/connections/${pathParam}/notes`, {
        replace: true,
      });
      setTabIndex(0);
    } else if (pathname === `/connections/${pathParam}/more`) {
      setTabIndex(1);
    } else if (pathname === `/vehicle/${pathParam}`) {
      navigate(`/vehicle/${pathParam}/notes`, {
        replace: true,
      });
      setTabIndex(0);
    } else if (pathname === `/vehicle/${pathParam}/notes`) {
      setTabIndex(0);
    } else if (pathname === `/vehicle/${pathParam}/more`) {
      setTabIndex(1);
    }
  }, [pathname, pathParam, navigate]);

  // Make sure data connection breadcrumbs match the initial tab
  useEffect(() => {
    if (identityTypeId === IdentityTypeIds.DATA_CONNECTION && tabIndex === 0) {
      if (includeSitesAndSensors && !pathname.includes('sites')) {
        navigate(`/connections/${pathParam}/sites`, {
          replace: true,
        });
      } else if (includeSyncMaps && !pathname.includes('syncmaps')) {
        navigate(`/connections/${pathParam}/syncmaps`, {
          replace: true,
        });
      } else if (!includeSitesAndSensors && !includeSyncMaps) {
        navigate(`/connections/${pathParam}/notes`, {
          replace: true,
        });
      }
    }
  }, [includeSitesAndSensors, includeSyncMaps]);

  const handleOperation = operation => {
    navigate(`/${identityBaseURL}/${functionMap[operation]}`);
  };

  const handleEdit = () => {
    const reducerName = Object.keys(IdentityTypeIds).find(
      key => IdentityTypeIds[key] === identityTypeId,
    );
    dispatchAPI({
      type: sagaActionTypes.FETCH_SPECIFIC_IDENTITY,
      payload: { id: pathParam, name: `${reducerName}` },
    });
    if (identityTypeId === IdentityTypeIds.PERSON && !isWorker) {
      navigate(`/person/${pathParam}/managepersondetails`);
    } else if (identityTypeId === IdentityTypeIds.PERSON && isWorker) {
      navigate(`/worker/${pathParam}/manageworkerdetails`);
    } else if (identityTypeId === IdentityTypeIds.VINEYARD) {
      navigate(
        `/organisation/${vineyardTypeScreenName}s/${pathParam}/manage${vineyardTypeScreenName}s`,
      );
    } else if (identityTypeId === IdentityTypeIds.SERVICE_PROVIDER) {
      navigate(
        `/organisation/serviceproviders/${pathParam}/manageserviceproviders`,
      );
    } else if (identityTypeId === IdentityTypeIds.DATA_CONNECTION) {
      navigate(`/connections/${pathParam}/manageconnections`);
    } else if (identityTypeId === IdentityTypeIds.ORGANISATION) {
      navigate(
        `/organisation/other-organisations/${pathParam}/manageorganisation`,
      );
    } else if (identityTypeId === IdentityTypeIds.CONSUMABLE) {
      navigate(`/consumable/${pathParam}/manageconsumables`);
    } else if (identityTypeId === IdentityTypeIds.ACCOMMODATION) {
      navigate(`/accommodation/${pathParam}/manageaccommodation`);
    } else if (identityTypeId === IdentityTypeIds.ACTIVITY) {
      navigate(`/activity/${pathParam}/manageactivity`);
    } else if (identityTypeId === IdentityTypeIds.SUPPLY_CONTRACT) {
      navigate(`/supplycontract/${pathParam}/managesupplycontract`);
    } else if (identityTypeId === IdentityTypeIds.JOB) {
      navigate(`/job/${pathParam}/managejob`);
    } else if (identityTypeId === IdentityTypeIds.VEHICLE) {
      navigate(`/vehicle/${pathParam}/managevehicle`);
    }
  };

  /** upload profile photo image */
  const handleOnProfileImageUpload = (image, fileName) => {
    handleSaveCroppedImage({
      image,
      fileName,
    });
  };

  const handleOnDeleteProfileImage = () => {
    handleOnDeleteImage();
  };

  const isOneOfMatch = [
    `/connections/${pathParam}`,
    `/organisation/other-organisations/${pathParam}`,
  ].includes(pathname);

  // render no screen for above links
  if (isOneOfMatch) return null;

  // Supply Contract Blocks
  const handleOnUpdateSupplyContractBlock = () => {
    let validationErrors = syncValidator(EditSupplyContractBlockSchema)(
      editSupplyContractBlock,
    );

    if (isEmpty(validationErrors)) {
      const fromDate = isValid(
        validDateFormat(editSupplyContractBlock.fromDate),
      )
        ? validDateFormat(editSupplyContractBlock.fromDate)
        : null;
      const toDate = isValid(validDateFormat(editSupplyContractBlock.toDate))
        ? validDateFormat(editSupplyContractBlock.toDate)
        : null;
      const isBeforeFromDate = fromDate && toDate && isBefore(toDate, fromDate);

      if (isBeforeFromDate) {
        validationErrors = { toDate: 'To date is before from date' };
      }
    }

    if (isEmpty(validationErrors)) {
      dispatchAPI(
        VineaNovaActions.api.v1.supplyContractBlock.put.request({
          postBody: {
            ...editSupplyContractBlock,
            contractedWeightPerAreaUnit:
              editSupplyContractBlock.contractedWeightPerAreaUnit,
            toDate: isEmpty(editSupplyContractBlock?.toDate)
              ? null
              : new Date(editSupplyContractBlock?.toDate),
          },
        }),
      );
      setEditSupplyContractDrawerOpen(false);
      setApiTrigger(true);
      setValidationErrors({});
      setEditSupplyContractBlock({});
    } else {
      setValidationErrors(validationErrors);
    }
  };

  const handleOnEditSupplyContractBlockDrawerChange = event => {
    const {
      target: { name, value },
    } = event;

    setEditSupplyContractBlock({
      ...editSupplyContractBlock,
      [name]: value,
    });
  };

  const handleOnDeleteSupplyContractBlock = () => {
    dispatchAPI(
      VineaNovaActions.api.v1.supplyContractBlock.delete.request({
        postBody: {
          id: editSupplyContractBlock.id,
        },
      }),
    );
    setEditSupplyContractDrawerOpen(false);
    setApiTrigger(true);
  };

  const handleOnAddSupplyContractBlock = () => {
    let validationErrors = syncValidator(AddSupplyContractBlockSchema)(
      addSupplyContractBlock,
    );

    if (isEmpty(validationErrors)) {
      const fromDate = isValid(validDateFormat(addSupplyContractBlock.fromDate))
        ? validDateFormat(addSupplyContractBlock.fromDate)
        : null;
      const toDate = isValid(validDateFormat(addSupplyContractBlock.toDate))
        ? validDateFormat(addSupplyContractBlock.toDate)
        : null;
      const isBeforeStartDate =
        fromDate && toDate && isBefore(toDate, fromDate);

      if (isBeforeStartDate) {
        validationErrors = { targetEndDate: 'To date is before from date' };
      }
    }

    if (isEmpty(validationErrors)) {
      dispatchAPI(
        VineaNovaActions.api.v1.supplyContractBlock.post.request({
          postBody: {
            SupplyContractID: addSupplyContractBlock.supplyContractID,
            VineyardBlockID: addSupplyContractBlock.vineyardBlockID,
            ContractedArea: addSupplyContractBlock.contractedArea,
            ContractedWeightPerAreaUnit:
              addSupplyContractBlock.contractedWeightPerAreaUnit,
            FromDate: new Date(addSupplyContractBlock.fromDate),
            ToDate: new Date(addSupplyContractBlock.toDate),
          },
        }),
      );
      setAddSupplyContractDrawerOpen(false);
      setApiTrigger(true);
      setAddSupplyContractBlock({});
    } else {
      setValidationErrors(validationErrors);
    }
  };

  const handleOnChangeSupplyContractBlock = event => {
    const {
      target: { name, value },
    } = event;

    setAddSupplyContractBlock({ ...addSupplyContractBlock, [name]: value });
  };

  const handleEditSupplyContractBlock = row => {
    const selectedBlock = find(supplyContractBlocksData, {
      id: row?.id,
    });
    setEditSupplyContractDrawerOpen(true);
    setEditSupplyContractBlock(selectedBlock);
    setValidationErrors({});
  };

  const handleOpenAddDrawer = drawerType => {
    if (drawerType === IdentityDrawerTypes.RELATIONSHIPS) {
      setRelationshipDrawerOpen(true);
    } else if (drawerType === IdentityDrawerTypes.SUPPLY_CONTRACT_BLOCK_ADD) {
      setAddSupplyContractDrawerOpen(true);
      setValidationErrors({});
    } else if (drawerType === IdentityDrawerTypes.JOB_SUPERVISOR) {
      setJobSupervisorAdd(true);
      setJobSupervisorDrawerOpen(true);
      setEditJobSupervisorData({ jobID: pathParam });
    } else if (drawerType === IdentityDrawerTypes.JOB_BLOCK_EDIT) {
      setJobBlockDrawerOpen(true);
    } else if (drawerType === IdentityDrawerTypes.ADD_JOB_ACTIVITY) {
      setJobActivityDrawerOpen(true);
    }
  };

  const onManageRelationship = row => {
    setEditRelationshipID(row?.id);
    setRelationshipDrawerOpen(true);
  };

  const handleEditJobSupervisor = row => {
    const clickedSupervisor = find(jobSupervisorsData, {
      relationshipID: row?.id,
    });

    setEditJobSupervisorData(clickedSupervisor);
    setJobSupervisorAdd(false);
    setJobSupervisorDrawerOpen(true);
  };

  const handleOnChangeJobSupervisorData = event => {
    const {
      target: { name, value },
    } = event;

    setEditJobSupervisorData({ ...editJobSupervisorData, [name]: value });
  };

  const handleCloseDrawer = drawerType => {
    if (drawerType === IdentityDrawerTypes.RELATIONSHIPS) {
      setRelationshipDrawerOpen(false);
      setEditRelationshipID(null);
    } else if (drawerType === IdentityDrawerTypes.JOB_SUPERVISOR) {
      setJobSupervisorDrawerOpen(false);
    } else if (drawerType === IdentityDrawerTypes.SUPPLY_CONTRACT_BLOCK_ADD) {
      setAddSupplyContractDrawerOpen(false);
      setValidationErrors({});
    } else if (drawerType === IdentityDrawerTypes.SUPPLY_CONTRACT_BLOCK_EDIT) {
      setEditSupplyContractDrawerOpen(false);
      setValidationErrors({});
    } else if (drawerType === IdentityDrawerTypes.JOB_BLOCK_EDIT) {
      setJobBlockDrawerOpen(false);
    } else if (drawerType === IdentityDrawerTypes.ADD_JOB_ACTIVITY) {
      setJobActivityDrawerOpen(false);
    }
  };

  // Workers
  const handleOnOpenWorkerWorkRecords = row => {
    navigate(`/worker/${pathParam}/workrecords/${row.id}`);
  };

  const handleManageJobBlockRows = row => {
    navigate(`/job/${pathParam}/managejobblock/${row.id}`);
  };

  // Jobs
  const handleOnManageJobServiceContracts = row => {
    navigate(`/job/${pathParam}/managejobactivity/${row.id}`);
  };

  return (
    <Box
      data-testid="vineyard-details"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      ref={identityScreenRef}
      sx={{ height: '95%' }}
    >
      <ErrorBoundary
        FallbackComponent={({ ...props }) => (
          <Box sx={{ height: '160px' }}>
            <ErrorBoundaryFallback {...props} />
          </Box>
        )}
      >
        <IdentityDetails
          identityTypeId={identityTypeId}
          licenseStatus={licenseStatus}
          identityStatus={identityStatus}
          handleEdit={handleEdit}
          identityOverviewDetails={identityOverviewDetails}
          isLoading={isIdentityOverviewDetaisLoading}
          saveCroppedImage={handleOnProfileImageUpload}
          imageDataUrl={imageDataUrl}
          onDeleteProfileImage={handleOnDeleteProfileImage}
          updateFavouriteIdentity={updateFavouriteIdentity}
          favIdentities={allUserPreferences.favouriteIdentity}
        />
      </ErrorBoundary>
      <RootDiv data-testid="vineyardidentity">
        {identityTypeId === IdentityTypeIds.VINEYARD && (
          <TabView
            tabHeaders={[
              t('Blocks'),
              t('Map'),
              t('Notes with Documents'),
              t('More'),
              t('Assets'),
              t('Jobs'),
            ]}
            onTabChange={handleonTabChange}
            onSwipe={handleChangeIndex}
            value={tabIndex}
          >
            <TabPanel
              value={tabIndex}
              index={0}
              dir={theme.direction}
              data-testid="tabpanel-blocks"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <VineyardBlocks
                  pathParamId={pathParam}
                  identityBaseURL={identityBaseURL}
                />
              </ErrorBoundary>
            </TabPanel>

            <TabPanel
              value={tabIndex}
              index={1}
              dir={theme.direction}
              data-testid="tabpanel-vineyard-blockmaps"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <BlocksMap />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={2}
              dir={theme.direction}
              data-testid="tabpanel-blocks"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <IdentityNotes
                  pathParamId={pathParam}
                  identityBaseURL={identityBaseURL}
                />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={3}
              dir={theme.direction}
              data-testid="tabpanel-more"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <OverviewGrids
                  t={t}
                  handleOperation={handleOperation}
                  isSubDetailsLoading={isSubDetailsLoading}
                  contactData={contactData}
                  classifications={classifications}
                  identityRefData={identityRefData}
                  identityRoles={identityRoles}
                  identityTypeId={identityTypeId}
                  identityRelationshipData={identityRelationshipData}
                  onManageRelationship={onManageRelationship}
                  handleOpenAddDrawer={handleOpenAddDrawer}
                  identityId={pathParam}
                />
              </ErrorBoundary>
            </TabPanel>
          </TabView>
        )}
        {identityTypeId === IdentityTypeIds.DATA_CONNECTION &&
          includeSitesAndSensors && (
            <TabView
              tabHeaders={[
                t('Sites'),
                t('Sensors'),
                t('Notes with Documents'),
                t('more'),
              ]}
              onTabChange={handleonTabChange}
              onSwipe={handleChangeIndex}
              value={tabIndex}
            >
              <TabPanel
                value={tabIndex}
                index={0}
                dir={theme.direction}
                data-testid="tabpanel-sites"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <SitesGrid
                    pathParamId={pathParam}
                    hasSites={includeSitesAndSensors}
                    tabIsLoading={isIdentityOverviewDetaisLoading}
                    dataConnectionName={identityOverviewDetails?.displayName}
                  />
                </ErrorBoundary>
              </TabPanel>
              <TabPanel
                value={tabIndex}
                index={1}
                dir={theme.direction}
                data-testid="tabpanel-sensors"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <SensorsGrid
                    pathParamId={pathParam}
                    hasSensors={includeSitesAndSensors}
                    tabIsLoading={isIdentityOverviewDetaisLoading}
                  />
                </ErrorBoundary>
              </TabPanel>
              <TabPanel
                value={tabIndex}
                index={2}
                dir={theme.direction}
                data-testid="tabpanel-dataconnection-notes"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <IdentityNotes
                    pathParamId={pathParam}
                    identityBaseURL={identityBaseURL}
                  />
                </ErrorBoundary>
              </TabPanel>
              <TabPanel
                value={tabIndex}
                index={3}
                dir={theme.direction}
                data-testid="tabpanel-dataconnection-more"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <OverviewGrids
                    t={t}
                    handleOperation={handleOperation}
                    isSubDetailsLoading={isSubDetailsLoading}
                    contactData={contactData}
                    classifications={classifications}
                    identityRefData={identityRefData}
                    identityRoles={identityRoles}
                    identityTypeId={identityTypeId}
                    identityRelationshipData={identityRelationshipData}
                    onManageRelationship={onManageRelationship}
                    handleOpenAddDrawer={handleOpenAddDrawer}
                    identityId={pathParam}
                  />
                </ErrorBoundary>
              </TabPanel>
            </TabView>
          )}
        {identityTypeId === IdentityTypeIds.DATA_CONNECTION &&
          !includeSitesAndSensors &&
          includeSyncMaps && (
            <TabView
              tabHeaders={[
                t('Sync Maps'),
                t('Notes with Documents'),
                t('more'),
              ]}
              onTabChange={handleonTabChange}
              onSwipe={handleChangeIndex}
              value={tabIndex}
            >
              <TabPanel
                value={tabIndex}
                index={0}
                dir={theme.direction}
                data-testid="tabpanel-dataconnection-syncmaps"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <SyncMaps pathParamId={pathParam} />
                </ErrorBoundary>
              </TabPanel>
              <TabPanel
                value={tabIndex}
                index={1}
                dir={theme.direction}
                data-testid="tabpanel-dataconnection-notes"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <IdentityNotes
                    pathParamId={pathParam}
                    identityBaseURL={identityBaseURL}
                  />
                </ErrorBoundary>
              </TabPanel>
              <TabPanel
                value={tabIndex}
                index={1}
                data-testid="tabpanel-dataconnection-more"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <OverviewGrids
                    t={t}
                    handleOperation={handleOperation}
                    isSubDetailsLoading={isSubDetailsLoading}
                    contactData={contactData}
                    classifications={classifications}
                    identityRefData={identityRefData}
                    identityRoles={identityRoles}
                    identityTypeId={identityTypeId}
                    identityRelationshipData={identityRelationshipData}
                    onManageRelationship={onManageRelationship}
                    handleOpenAddDrawer={handleOpenAddDrawer}
                    identityId={pathParam}
                  />
                </ErrorBoundary>
              </TabPanel>
            </TabView>
          )}
        {identityTypeId === IdentityTypeIds.DATA_CONNECTION &&
          !includeSitesAndSensors &&
          !includeSyncMaps && (
            <TabView
              tabHeaders={[t('Notes with Documents'), t('more')]}
              onTabChange={handleonTabChange}
              onSwipe={handleChangeIndex}
              value={tabIndex}
            >
              <TabPanel
                value={tabIndex}
                index={0}
                dir={theme.direction}
                data-testid="tabpanel-dataconnection-notes"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <IdentityNotes
                    pathParamId={pathParam}
                    identityBaseURL={identityBaseURL}
                  />
                </ErrorBoundary>
              </TabPanel>
              <TabPanel
                value={tabIndex}
                index={1}
                dir={theme.direction}
                data-testid="tabpanel-dataconnection-more"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <OverviewGrids
                    t={t}
                    handleOperation={handleOperation}
                    isSubDetailsLoading={isSubDetailsLoading}
                    contactData={contactData}
                    classifications={classifications}
                    identityRefData={identityRefData}
                    identityRoles={identityRoles}
                    identityTypeId={identityTypeId}
                    identityRelationshipData={identityRelationshipData}
                    onManageRelationship={onManageRelationship}
                    handleOpenAddDrawer={handleOpenAddDrawer}
                    identityId={pathParam}
                  />
                </ErrorBoundary>
              </TabPanel>
            </TabView>
          )}
        {identityTypeId === IdentityTypeIds.CONSUMABLE && (
          <TabView
            tabHeaders={[
              t('Notes with Documents'),
              t('more'),
              t('Transactions'),
            ]}
            onTabChange={handleonTabChange}
            onSwipe={handleChangeIndex}
            value={tabIndex}
          >
            <TabPanel
              value={tabIndex}
              index={0}
              dir={theme.direction}
              data-testid="tabpanel-notes"
              sx={{ paddingleft: '6px', paddingRight: '6px' }}
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <IdentityNotes
                  pathParamId={pathParam}
                  identityBaseURL={identityBaseURL}
                />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={1}
              dir={theme.direction}
              data-testid="tabpanel-more"
              sx={{ paddingleft: '6px', paddingRight: '6px' }}
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <OverviewGrids
                  t={t}
                  handleOperation={handleOperation}
                  isSubDetailsLoading={isSubDetailsLoading}
                  contactData={contactData}
                  classifications={classifications}
                  identityRefData={identityRefData}
                  identityRoles={identityRoles}
                  identityTypeId={identityTypeId}
                  identityRelationshipData={identityRelationshipData}
                  onManageRelationship={onManageRelationship}
                  handleOpenAddDrawer={handleOpenAddDrawer}
                  identityId={pathParam}
                />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={2}
              dir={theme.direction}
              data-testid="tabpanel-transations"
              sx={{ paddingleft: '6px', paddingRight: '6px' }}
            />
          </TabView>
        )}
        {identityTypeId === IdentityTypeIds.ACTIVITY && (
          <TabView
            tabHeaders={[t('Default Rates'), t('References'), t('Notes')]}
            onTabChange={handleonTabChange}
            onSwipe={handleChangeIndex}
            value={tabIndex}
          >
            <TabPanel
              value={tabIndex}
              index={0}
              dir={theme.direction}
              data-testid="tabpanel-defaultrates"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <ActivityDefaultRates pathParamId={pathParam} />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={1}
              dir={theme.direction}
              data-testid="tabpanel-references"
            >
              <Box margin={2}>
                <VineaCommonGrid
                  heading={t('References')}
                  manage={() => handleOperation('REFERENCES')}
                  colHeaders={['Reference', 'Value', 'From - To']}
                  isLoading={!!isSubDetailsLoading}
                  tableData={identityRefData.data}
                  identityId={pathParam}
                />
              </Box>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={2}
              dir={theme.direction}
              data-testid="tabpanel-notes"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <Box margin={2}>
                  <IdentityNotes
                    pathParamId={pathParam}
                    identityBaseURL={identityBaseURL}
                  />
                </Box>
              </ErrorBoundary>
            </TabPanel>
          </TabView>
        )}
        {identityTypeId === IdentityTypeIds.ACCOMMODATION && (
          <TabView
            tabHeaders={[t('Notes with Documents'), t('more')]}
            onTabChange={handleonTabChange}
            onSwipe={handleChangeIndex}
            value={tabIndex}
          >
            <TabPanel
              value={tabIndex}
              index={1}
              dir={theme.direction}
              data-testid="tabpanel-notes"
              sx={{ paddingleft: '6px', paddingRight: '6px' }}
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <IdentityNotes
                  pathParamId={pathParam}
                  identityBaseURL={identityBaseURL}
                />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={0}
              dir={theme.direction}
              data-testid="tabpanel-more"
              sx={{ paddingleft: '6px', paddingRight: '6px' }}
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <OverviewGrids
                  t={t}
                  handleOperation={handleOperation}
                  isSubDetailsLoading={isSubDetailsLoading}
                  contactData={contactData}
                  classifications={classifications}
                  identityRefData={identityRefData}
                  identityRoles={identityRoles}
                  identityTypeId={identityTypeId}
                  identityRelationshipData={identityRelationshipData}
                  onManageRelationship={onManageRelationship}
                  handleOpenAddDrawer={handleOpenAddDrawer}
                  identityId={pathParam}
                />
              </ErrorBoundary>
            </TabPanel>
          </TabView>
        )}
        {identityTypeId === IdentityTypeIds.SERVICE_PROVIDER && (
          <TabView
            tabHeaders={[
              t('Activity Mapping'),
              t('Notes with Documents'),
              t('more'),
            ]}
            onTabChange={handleonTabChange}
            onSwipe={handleChangeIndex}
            value={tabIndex}
          >
            <TabPanel
              value={tabIndex}
              index={0}
              dir={theme.direction}
              data-testid="tabpanel-serviceprovider-activities"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <ActivityMapping pathParamId={pathParam} />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={1}
              dir={theme.direction}
              data-testid="tabpanel-serviceprovider-notes"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <IdentityNotes
                  pathParamId={pathParam}
                  identityBaseURL={identityBaseURL}
                />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={2}
              dir={theme.direction}
              data-testid="tabpanel-serviceprovider-more"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <OverviewGrids
                  t={t}
                  handleOperation={handleOperation}
                  isSubDetailsLoading={isSubDetailsLoading}
                  contactData={contactData}
                  classifications={classifications}
                  identityRefData={identityRefData}
                  identityRoles={identityRoles}
                  identityTypeId={identityTypeId}
                  identityRelationshipData={identityRelationshipData}
                  onManageRelationship={onManageRelationship}
                  handleOpenAddDrawer={handleOpenAddDrawer}
                  identityId={pathParam}
                />
              </ErrorBoundary>
            </TabPanel>
          </TabView>
        )}
        {identityTypeId === IdentityTypeIds.SUPPLY_CONTRACT && (
          <>
            <TabView
              tabHeaders={[t('Overview'), t('Maps'), t('Notes with Documents')]}
              onTabChange={handleonTabChange}
              onSwipe={handleChangeIndex}
              value={tabIndex}
            >
              <TabPanel
                value={tabIndex}
                index={0}
                dir={theme.direction}
                data-testid="tabpanel-supplycontract-overview"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <OverviewGrids
                    t={t}
                    handleOperation={handleOperation}
                    isSubDetailsLoading={isSubDetailsLoading}
                    contactData={contactData}
                    classifications={classifications}
                    identityRefData={identityRefData}
                    identityRoles={identityRoles}
                    identityTypeId={identityTypeId}
                    identityRelationshipData={identityRelationshipData}
                    handleOpenAddDrawer={handleOpenAddDrawer}
                    supplyContractBlocks={supplyContractBlocksTableData}
                    handleEditSupplyContractBlock={
                      handleEditSupplyContractBlock
                    }
                    supplyContractBlocksColumnHeaders={
                      supplyContractBlocksColumnHeaders
                    }
                    identityId={pathParam}
                  />
                </ErrorBoundary>
              </TabPanel>
              <TabPanel
                value={tabIndex}
                index={1}
                dir={theme.direction}
                data-testid="tabpanel-supplycontract-map"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <Box height={mainPageHeight}>
                    <SupplyContractBlocksMap />
                  </Box>
                </ErrorBoundary>
              </TabPanel>
              <TabPanel
                value={tabIndex}
                index={2}
                dir={theme.direction}
                data-testid="tabpanel-supplycontract-notes"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <IdentityNotes
                    pathParamId={pathParam}
                    identityBaseURL={identityBaseURL}
                  />
                </ErrorBoundary>
              </TabPanel>
            </TabView>
            <EditSupplyContractBlockDrawer
              isOpen={editSupplyContractDrawerOpen}
              handleOnClose={handleCloseDrawer}
              handleOnSave={handleOnUpdateSupplyContractBlock}
              handleOnChange={handleOnEditSupplyContractBlockDrawerChange}
              handleOnDelete={handleOnDeleteSupplyContractBlock}
              supplyContractBlockData={editSupplyContractBlock}
              supplyingPartyIdentityID={
                identityOverviewDetails?.supplyingPartyIdentityID
              }
              validationErrors={validationsErrors}
            />
            <AddSupplyContractBlockDrawer
              isOpen={addSupplyContractDrawerOpen}
              handleOnClose={handleCloseDrawer}
              handleOnSave={handleOnAddSupplyContractBlock}
              handleOnChange={handleOnChangeSupplyContractBlock}
              supplyContractBlockData={addSupplyContractBlock}
              supplyingPartyIdentityID={
                identityOverviewDetails?.supplyingPartyIdentityID
              }
              existingBlocks={supplyContractBlocksTableData}
              validationErrors={validationsErrors}
            />
          </>
        )}
        {((identityTypeId === IdentityTypeIds.PERSON && !isWorker) ||
          identityTypeId === IdentityTypeIds.ORGANISATION ||
          identityTypeId === IdentityTypeIds.VEHICLE) && (
          <>
            <TabView
              tabHeaders={[t('Notes with Documents'), t('more')]}
              onTabChange={handleonTabChange}
              onSwipe={handleChangeIndex}
              value={tabIndex}
            >
              <TabPanel
                value={tabIndex}
                index={0}
                dir={theme.direction}
                data-testid="tabpanel-blocks"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <IdentityNotes
                    pathParamId={pathParam}
                    identityBaseURL={identityBaseURL}
                  />
                </ErrorBoundary>
              </TabPanel>
              <TabPanel
                value={tabIndex}
                index={1}
                dir={theme.direction}
                data-testid="tabpanel-more"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <OverviewGrids
                    t={t}
                    handleOperation={handleOperation}
                    isSubDetailsLoading={isSubDetailsLoading}
                    contactData={contactData}
                    classifications={classifications}
                    identityRefData={identityRefData}
                    identityRoles={identityRoles}
                    identityTypeId={identityTypeId}
                    identityRelationshipData={identityRelationshipData}
                    onManageRelationship={onManageRelationship}
                    handleOpenAddDrawer={handleOpenAddDrawer}
                    identityId={pathParam}
                  />
                </ErrorBoundary>
              </TabPanel>
            </TabView>
          </>
        )}
        {identityTypeId === IdentityTypeIds.PERSON && isWorker && (
          <TabView
            tabHeaders={[t('Overview'), t('Notes with Documents'), t('more')]}
            onTabChange={handleonTabChange}
            onSwipe={handleChangeIndex}
            value={tabIndex}
          >
            <TabPanel
              value={tabIndex}
              index={0}
              dir={theme.direction}
              data-testid="tabpanel-workeroverview"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <OverviewGrids
                  t={t}
                  handleOperation={handleOperation}
                  isSubDetailsLoading={isSubDetailsLoading}
                  classifications={classifications}
                  identityRefData={identityRefData}
                  identityRoles={identityRoles}
                  identityTypeId={identityTypeId}
                  isWorkerRole
                  isWorkerOverviewTab
                  handleOnOpenWorkerWorkRecords={handleOnOpenWorkerWorkRecords}
                  workerPayPeriods={workerPayPeriodsSorted}
                  identityId={pathParam}
                />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={1}
              dir={theme.direction}
              data-testid="tabpanel-workernotes"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <IdentityNotes
                  pathParamId={pathParam}
                  identityBaseURL={identityBaseURL}
                />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel
              value={tabIndex}
              index={2}
              dir={theme.direction}
              data-testid="tabpanel-workermore"
            >
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <OverviewGrids
                  t={t}
                  handleOperation={handleOperation}
                  isSubDetailsLoading={isSubDetailsLoading}
                  contactData={contactData}
                  identityTypeId={identityTypeId}
                  identityRelationshipData={identityRelationshipData}
                  onManageRelationship={onManageRelationship}
                  handleOpenAddDrawer={handleOpenAddDrawer}
                  isWorkerRole
                  isWorkerOverviewTab={false}
                  identityId={pathParam}
                />
              </ErrorBoundary>
            </TabPanel>
          </TabView>
        )}

        {identityTypeId === IdentityTypeIds.JOB && (
          <>
            <TabView
              tabHeaders={[t('Overview'), t('More'), t('Notes with Documents')]}
              onTabChange={handleonTabChange}
              onSwipe={handleChangeIndex}
              value={tabIndex}
            >
              <TabPanel
                value={tabIndex}
                index={0}
                dir={theme.direction}
                data-testid="tabpanel-jobs-overview"
                maxHeight="90%"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <OverviewGrids
                    t={t}
                    identityTypeId={identityTypeId}
                    handleOperation={handleOperation}
                    isSubDetailsLoading={isSubDetailsLoading}
                    contactData={contactData}
                    jobEventData={jobEventMapData}
                    jobSupervisorData={jobSupervisorData}
                    jobActivitiesData={jobActivitiesMappedData}
                    jobBlocksData={jobBlocksData}
                    handleEditJobSupervisor={handleEditJobSupervisor}
                    handleManageJobBlockRows={handleManageJobBlockRows}
                    jobBlocksColumnHeaders={jobBlocksColumnHeaders}
                    isOverviewTab
                    onRowClick={handleOnManageJobServiceContracts}
                    identityId={pathParam}
                    handleOpenAddDrawer={handleOpenAddDrawer}
                  />
                </ErrorBoundary>
              </TabPanel>
              <TabPanel
                value={tabIndex}
                index={1}
                dir={theme.direction}
                data-testid="tabpanel-jobs-more"
                maxHeight="90%"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <OverviewGrids
                    t={t}
                    handleOperation={handleOperation}
                    isSubDetailsLoading={isSubDetailsLoading}
                    contactData={contactData}
                    classifications={classifications}
                    identityRefData={identityRefData}
                    identityRoles={identityRoles}
                    identityTypeId={identityTypeId}
                    identityRelationshipData={identityRelationshipData}
                    onManageRelationship={onManageRelationship}
                    handleOpenAddDrawer={handleOpenAddDrawer}
                    identityId={pathParam}
                  />
                </ErrorBoundary>
              </TabPanel>
              <TabPanel
                value={tabIndex}
                index={2}
                dir={theme.direction}
                data-testid="tabpanel-job-notes"
              >
                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                  <IdentityNotes
                    pathParamId={pathParam}
                    identityBaseURL={identityBaseURL}
                  />
                </ErrorBoundary>
              </TabPanel>
            </TabView>
            <ManageJobSupervisorsDrawer
              isOpen={jobSupervisorDrawerOpen}
              isAdd={jobSupervisorAdd}
              handleOnClose={handleCloseDrawer}
              handleOnChange={handleOnChangeJobSupervisorData}
              editJobSupervisorData={editJobSupervisorData}
              jobSupervisorsData={jobSupervisorsData}
              businessUnitID={businessUnitID}
            />
            <EditJobBlocksDrawer
              isOpen={jobBlockDrawerOpen}
              handleOnClose={handleCloseDrawer}
              jobID={pathParam}
              jobBlocks={jobBlocksData}
            />
            <AddJobActivityDrawer
              isOpen={jobActivityDrawerOpen}
              handleOnClose={handleCloseDrawer}
              jobID={pathParam}
            />
          </>
        )}
        <EditRelationshipsDrawer
          isOpen={relationshipDrawerOpen}
          handleOnClose={handleCloseDrawer}
          identityId={pathParam}
          relationshipID={editRelationshipID}
        />
      </RootDiv>
    </Box>
  );
};

OverviewGrids.propTypes = {
  classifications: commonClassificationPropTypes.isRequired,
  isSubDetailsLoading: PropTypes.bool,
  identityRefData: commonReducerPropTypes,
  identityRelationshipData: commonRelatonshipPropTypes,
  t: PropTypes.func,
  handleOperation: PropTypes.func.isRequired,
  identityTypeId: PropTypes.number.isRequired,
  contactData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      contactMethodDetails: PropTypes.string,
      contactMethodType: PropTypes.string,
      effectiveFromDate: PropTypes.string,
      effectiveToDate: PropTypes.string,
    }),
  ),
};

OverviewGrids.defaultProps = {
  isSubDetailsLoading: false,
  identityRefData: {},
  identityRelationshipData: {},
  t: f => f,
  contactData: {},
};

IdentityScreen.propTypes = {
  fetchIdentityRoot: PropTypes.func.isRequired,
  classifications: commonClassificationPropTypes.isRequired,
  isSubDetailsLoading: PropTypes.bool,
  identityRefData: commonReducerPropTypes,
  identityRoles: commonReducerPropTypes,
  contactDetails: commonContactMethodPropTypes.isRequired,
  licenseStatus: PropTypes.bool.isRequired,
  tabIndex: PropTypes.number,
  setTabIndex: PropTypes.func,
};

IdentityScreen.defaultProps = {
  isSubDetailsLoading: false,
  identityRefData: {},
  identityRoles: {},
  tabIndex: 0,
  setTabIndex: () => {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    licenseStatus: getLicenseActiveStatus(state),
    isSubDetailsLoading: getIdentitySubDetailsIsLoading(state),
    identityRefData: getIdentityReferences(state),
    identityRoles: getAllIdentityRoles(state),
    classifications: getClassificationsById(state, {
      id: ownProps?.match?.params?.id,
    }),
    contactDetails: getAllIdentityContactMethods(state),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchIdentityRoot: data => {
    dispatch({ type: sagaActionTypes.FETCH_IDENTITIES, payload: data });
  },
  updateFavouriteIdentity: data => {
    const today = new Date();
    const dateValue = format(today, dateTimeFormat);
    dispatch({
      type: 'FAVOURITE_IDENTITY_UPDATE',
      payload: { ...data, updatedAt: dateValue },
    });
    dispatch({
      type: sagaActionTypes.USER_FAV_SETTINGS_UPDATE,
    });
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  IdentityScreen,
);
