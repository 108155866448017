import React, { useReducer, useEffect, useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import { filter, get, isEmpty, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/lab/Alert';
import { useSnackbar } from 'notistack';
import { FormHelperText, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';

import { FieldLabelInput } from '../../../components/TextField';
import { addGenericReducer, types, vineyardState } from '../stateReducers';
import { syncValidator } from '../../../utils/validator';
import { VineyardSchema } from '../validations';
import { VineaButton } from '../../../components/VineaButton';
import { VineaAlert } from '../../../components/VineaAlert';
import {
  DeleteIdentityAlert,
  IdentityActivationSwitch,
  DeleteIdentityButton,
} from '../../../components/IdentityComponents';
import { useIdentityTypeId } from '../../../hooks/useIdentityTypeId';
import {
  apiTypes,
  dateFormat,
  IdentityTypeIds,
  reducers,
  sagaActionTypes,
  vineaDetails,
} from '../../../constants';
import { getBusinessUnit, getGrowerSubRegion } from '../../../redux/selectors';
import { SplitButton } from '../../../components/SplitButton';
import { VineaAutoComplete } from '../../../components/ComboBox';
import { DiscardDialog } from '../../../components/Dialog';
import { statechecker } from '../../../utils/statecomparator';
import { useIdentityStatus } from '../../../hooks/useIdentityStatus';
import useIdentityTypeScreenNameHook from '../../../hooks/useIdentityTypeScreenNameHook';

const StyledFormControlBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
  minWidth: 350,
  width: 350,
  '& .MuiFormControl-root': {
    minWidth: 350,
  },
}));

const StyledFieldLabelInput = styled(FieldLabelInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 350,
  top: 'auto',
  left: 'auto',
  position: 'unset',
  transform: 'none',
  display: 'block',
  'transform-origin': 'top left',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: 350,
}));

export const ManageVineyards = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const today = format(new Date(), dateFormat);
  const dispatchAPI = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [errorInSubmit, setErrorInSubmit] = useState(false);
  const [warningInSubmit, setWarningInSubmit] = React.useState(false);
  const [initialAPITrigger, setInitialAPITrigger] = useState(true);
  const [formLoadingState, setFormLoadingState] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [saveIndex, setSaveIndex] = useState(0);
  const { vineyardTypeScreenName } = useIdentityTypeScreenNameHook();
  const identityTypeIdVal = useIdentityTypeId(vineyardTypeScreenName);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [formWriteReloadState, setFormWriteReloadState] = useState(false);
  const growersSubRegion = useSelector(getGrowerSubRegion);
  const businessUnitList = useSelector(state => getBusinessUnit(state));
  const [deleteHasFailed, setDeleteHasFailed] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const {
    isLoading: vineyardIsLoading,
    isLoaded: vineyardIsLoaded,
    hasError: vineyardHasError,
  } = useSelector(VineaNovaSelectors.getIdentityVineyardEntityMeta);

  const { data: rolesData = [] } = useSelector(
    state => state.entities.identityRole,
  );
  const activeRoles = useMemo(() => {
    return filter(rolesData, role => isEmpty(get(role, 'effectiveToDate')));
  }, [rolesData]);

  const isActive = useIdentityStatus();
  const { id: pathParam } = useParams();

  const [formdata, dispatch] = useReducer(addGenericReducer, vineyardState);
  const { isLoading, isLoaded, hasError, data, error } = useSelector(
    state => state.formWrite,
  );
  const vineyardData = Array.isArray(data) ? data[0] : data;

  let errorFromAPI = '';

  if (error) {
    errorFromAPI = error.data;
  }

  const [updateApiStatus, setUpdateApiStatus] = React.useState(false);

  const handleChange = event => {
    const {
      target: { value, name },
    } = event;
    dispatch({ type: types.UPDATE, payload: { [name]: value } });
  };

  const handleOnBlurOrgName = () => {
    const validationErrors = syncValidator(VineyardSchema)(formdata);
    dispatch({
      type: types.ERROR,
      payload: !isEmpty(validationErrors) ? validationErrors : {},
    });
  };

  const handleSave = (event, index) => {
    setSaveIndex(index);
    const validationErrors = syncValidator(VineyardSchema)(formdata);
    if (!isEmpty(validationErrors)) {
      dispatch({ type: types.ERROR, payload: validationErrors });
    } else if (
      vineyardData.vineyardName === formdata.vineyardName &&
      vineyardData.vineyardKnownAs === formdata.vineyardKnownAs &&
      vineyardData.businessUnitID === formdata.businessUnitID &&
      vineyardData.totalVineyardArea === formdata.totalVineyardArea &&
      vineyardData.plantedArea === formdata.plantedArea &&
      vineyardData.subRegionID === formdata.subRegionID
    ) {
      setWarningInSubmit(true);
    } else {
      setWarningInSubmit(false);
      setErrorInSubmit(false);
      const data = {
        id: vineyardData.id,
        identityTypeID: IdentityTypeIds.VINEYARD,
        vineyardName: formdata.vineyardName,
        vineyardKnownAs: formdata.vineyardKnownAs || formdata.vineyardName,
        totalVineyardArea: formdata.totalVineyardArea,
        plantedArea: formdata.plantedArea,
        subRegionID: formdata.subRegionID,
        businessUnitID: formdata.businessUnitID,
        preferredContactMethodTypeID: null,
        createdDate: today,
        ts: vineyardData.ts,
      };
      dispatchAPI({
        // need to change payload
        type: sagaActionTypes.FORM_SUBMIT,
        payload: {
          data,
          name: vineaDetails.vineyard,
          methodType: apiTypes.PUT,
        },
      });
      setUpdateApiStatus(true);
      setFormWriteReloadState(true);
    }
  };

  const handleManageRoles = () => {
    navigate(
      `/organisation/${vineyardTypeScreenName}s/${pathParam}/manageroles`,
    );
  };

  const handleOnDialogClose = () => {
    setDiscardDialogOpen(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleClose = () => {
    if (!saveButtonStatus) {
      setDiscardDialogOpen(true);
    } else {
      handleCancel();
    }
  };

  const handleOnConfirmDelete = () => {
    if (pathParam) {
      setIsDeleting(true);
      dispatchAPI(
        VineaNovaActions.api.v1.identityVineyard.delete.request({
          queryParams: {
            IdentityID: pathParam,
          },
        }),
      );
    }
  };

  const handleOnToggleActive = () => {
    navigate(
      `/organisation/${vineyardTypeScreenName}s/${pathParam}/managestatus`,
    );
  };

  useEffect(() => {
    if (!isLoading && isLoaded && !isEmpty(vineyardData) && !hasError) {
      const plantedArea = get(vineyardData, 'plantedArea', 0).toString();
      const totalVineyardArea = get(
        vineyardData,
        'totalVineyardArea',
        0,
      ).toString();
      const stubVineyardData = {
        ...vineyardData,
        plantedArea,
        totalVineyardArea,
      };
      const stubFormData = omit(formdata, 'errors');
      if (statechecker(stubFormData, stubVineyardData)) {
        setSaveButtonStatus(true);
      } else setSaveButtonStatus(false);
    }
  }, [isLoading, isLoaded, vineyardData, hasError, formdata]);

  useEffect(() => {
    if (isLoaded && !hasError && initialAPITrigger) {
      setInitialAPITrigger(false);
      setFormLoadingState(false);
      dispatch({
        type: types.UPDATE,
        payload: {
          vineyardName: vineyardData.vineyardName,
          vineyardKnownAs: vineyardData.vineyardKnownAs,
          totalVineyardArea: get(
            vineyardData,
            'totalVineyardArea',
            0,
          ).toString(),
          plantedArea: get(vineyardData, 'plantedArea', 0).toString(),
          subRegionID: vineyardData.subRegionID,
          businessUnitID: vineyardData.businessUnitID || 0,
        },
      });
    }
  }, [
    isLoaded,
    hasError,
    initialAPITrigger,
    vineyardData.vineyardName,
    vineyardData.vineyardKnownAs,
    vineyardData.totalVineyardArea,
    vineyardData.totalPlantedArea,
    vineyardData.subRegionID,
    vineyardData.businessUnitID,
    vineyardData.plantedArea,
    vineyardData.subRegion,
    isLoading,
    formLoadingState,
  ]);

  useEffect(() => {
    if (isLoading) {
      setFormLoadingState(true);
    }
    if (!hasError && isLoaded && updateApiStatus && formLoadingState) {
      enqueueSnackbar('Success!', { variant: 'success' });
      setAlertType(true);
      dispatchAPI({
        type: sagaActionTypes.REFRESH_PAGE_DATA,
        payload: {
          refreshPage: true,
        },
      });
      if (saveIndex === 0)
        navigate(`/organisation/${vineyardTypeScreenName}s/${vineyardData.id}`);
      else if (formWriteReloadState && saveIndex === 1) {
        setFormWriteReloadState(false);
        dispatchAPI({
          type: sagaActionTypes.FETCH_SPECIFIC_IDENTITY,
          payload: { id: vineyardData.id, name: reducers.vineyard },
        });
      }
      setFormLoadingState(false);
    } else if (hasError && isLoaded && updateApiStatus) {
      setErrorInSubmit(true);
      enqueueSnackbar(t('Error'), { variant: 'Error' });
    }
  }, [
    isLoaded,
    updateApiStatus,
    enqueueSnackbar,
    t,
    navigate,
    hasError,
    vineyardData.id,
    saveIndex,
    warningInSubmit,
    isLoading,
    formLoadingState,
    dispatchAPI,
    identityTypeIdVal,
    formWriteReloadState,
  ]);

  useEffect(() => {
    if (isDeleting && !vineyardIsLoading && vineyardIsLoaded) {
      if (vineyardHasError) {
        setDeleteHasFailed(true);
      } else {
        navigate(`/organisation/${vineyardTypeScreenName}s`);
      }

      setIsDeleting(false);
    }
  }, [
    vineyardIsLoaded,
    vineyardIsLoading,
    vineyardHasError,
    isDeleting,
    setIsDeleting,
  ]);

  return (
    <Paper
      elevation={0}
      data-testid="managevineyard-detail-info"
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} data-testid="grid-details">
        <Grid item xs={12}>
          <Box mb={4}>
            <VineaAlert
              isOpen={!!errorInSubmit}
              onExit={() => setErrorInSubmit(false)}
            >
              <p>{errorFromAPI}</p>
            </VineaAlert>
            <VineaAlert
              isOpen={alertType}
              onExit={() => setAlertType(false)}
              alertType="success"
              message="Vineyard Details Updated"
            />
            <DeleteIdentityAlert
              isOpen={deleteHasFailed}
              onExit={() => setDeleteHasFailed(false)}
              identityName="vineyard"
            />
          </Box>
          <Box px={2}>
            {warningInSubmit && (
              <Collapse in={warningInSubmit}>
                <Alert
                  variant="standard"
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setWarningInSubmit(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Warning: Nothing to Update
                </Alert>
              </Collapse>
            )}
          </Box>
          <StyledFormControlBox mt={1}>
            <StyledFieldLabelInput
              autoComplete="off"
              id="name"
              name="vineyardName"
              label={t('Vineyard Name')}
              size="small"
              inlineLabel
              value={formdata.vineyardName}
              onChange={handleChange}
              onBlur={handleOnBlurOrgName}
              error={formdata.errors.vineyardName}
              helperText={formdata.errors.vineyardName}
            />
          </StyledFormControlBox>
          <StyledFormControlBox mt={1}>
            <StyledFieldLabelInput
              id="knownas"
              name="vineyardKnownAs"
              label={t('Known As')}
              size="small"
              inlineLabel
              value={formdata.vineyardKnownAs}
              onChange={handleChange}
              onBlur={handleOnBlurOrgName}
              error={formdata.errors.vineyardKnownAs}
              helperText={formdata.errors.vineyardKnownAs}
            />
          </StyledFormControlBox>
          <StyledFormControlBox mt={1}>
            <StyledFieldLabelInput
              autoComplete="off"
              id="totalvineyardarea"
              name="totalVineyardArea"
              label={t('Total Area')}
              size="small"
              inlineLabel
              value={formdata.totalVineyardArea}
              onChange={handleChange}
              onBlur={handleOnBlurOrgName}
              error={formdata.errors.totalVineyardArea}
              helperText={formdata.errors.totalVineyardArea}
            />
          </StyledFormControlBox>
          <StyledFormControlBox mt={1}>
            <StyledFieldLabelInput
              autoComplete="off"
              id="plantedarea"
              name="plantedArea"
              label={t('Planted Area')}
              size="small"
              inlineLabel
              value={formdata.plantedArea}
              onChange={handleChange}
              onBlur={handleOnBlurOrgName}
              error={formdata.errors.plantedArea}
              helperText={formdata.errors.plantedArea}
            />
          </StyledFormControlBox>
          <Box mx={2} mt={1}>
            <StyledFormControl
              margin="none"
              error={!!formdata.errors.subRegionID}
            >
              <VineaAutoComplete
                value={formdata.subRegionID}
                onBlur={handleOnBlurOrgName}
                onChange={handleChange}
                options={growersSubRegion}
                labelVariant="body1"
                name={t('SubRegion')}
                label={t('Sub Region')}
                inlineLabel
                inputProps={{
                  name: 'subRegionID',
                }}
              />
              {formdata.errors.subRegionID && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.subRegionID}
                </FormHelperText>
              )}
            </StyledFormControl>
          </Box>
          <StyledFormControlBox mx={2} mt={1}>
            <StyledFormControl
              margin="none"
              error={!!formdata.errors.businessUnitID}
            >
              <VineaAutoComplete
                value={formdata.businessUnitID}
                onChange={handleChange}
                onBlur={handleOnBlurOrgName}
                options={businessUnitList}
                labelVariant="body1"
                name={t('Business Unit')}
                label={t('Business Unit')}
                inlineLabel
                inputProps={{
                  name: 'businessUnitID',
                }}
              />
              {formdata.errors.businessUnitID && (
                <FormHelperText id="component-error-text">
                  {formdata.errors.businessUnitID}
                </FormHelperText>
              )}
            </StyledFormControl>
          </StyledFormControlBox>
          <Box
            m={2}
            sx={{
              width: '350px',
              minWidth: '250px',
            }}
          >
            <div>{t('Current Role')}</div>
            <div>
              <div>
                <ol>
                  {!isEmpty(activeRoles) &&
                    Array.isArray(activeRoles) &&
                    activeRoles.map(item => (
                      <li key={item.id}>{item.roleType}</li>
                    ))}
                </ol>
              </div>
              <div>
                <VineaButton
                  data-testid="BtnManageRoles"
                  autoFocus
                  onClick={handleManageRoles}
                  variant="text"
                  color="secondary"
                  textTransform="capitalize"
                >
                  {t('Manage Roles')}
                </VineaButton>
              </div>
            </div>
          </Box>
          <IdentityActivationSwitch
            handleOnToggleActive={handleOnToggleActive}
            isActive={isActive}
            identityName="vineyard"
          />
          <Box my={2}>
            <DeleteIdentityButton
              handleOnDelete={handleOnConfirmDelete}
              identityName="vineyard"
              isDisabled={deleteHasFailed}
            />
          </Box>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
            }}
          >
            <Box mx={2} mt={1}>
              <SplitButton
                color="success"
                onClick={handleSave}
                variant="contained"
                minWidth={130}
                disabled={saveButtonStatus}
                data-testid="btnsuccess"
              />
            </Box>
            <Box mx={2} mt={1}>
              <VineaButton
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                {t('Close')}
              </VineaButton>
            </Box>
          </Grid>
          <DiscardDialog
            open={discardDialogOpen}
            onClose={handleOnDialogClose}
            handleSaveChanges={handleSave}
            handleDiscardChanges={handleCancel}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
