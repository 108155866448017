import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { VineaAutoComplete } from '../../components/ComboBox';
import { useBlockCostAllocation } from './hooks/useBlockCostAllocation';
import { VineaButton } from '../../components/VineaButton';
import CheckboxSelectionGrid from '../../components/Grid/CheckboxSelectionGrid';

type CostAllocationProps = {
  theme: any;
  vintages: any[];
  currentBlockVintage: any;
};

export const BlockVintageCostAllocations = ({
  theme,
  vintages,
  currentBlockVintage,
}: CostAllocationProps) => {
  const {
    form,
    costAllocationOptions,
    checkBoxGridData,
    canSave,
    pageLoading,
    handleOnFormChange,
    handleOnBlockSelectionChange,
    handleOnSave,
  } = useBlockCostAllocation({ currentBlockVintage: currentBlockVintage });

  return (
    <Paper
      elevation={0}
      data-testid="detail-info"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: 500,
      }}
    >
      {pageLoading ? (
        <Stack direction="column" margin={theme.spacing(4)}>
          <Stack direction="row">
            <Stack
              direction="column"
              sx={{
                width: 300,
                marginTop: theme.spacing(1),
                marginRight: theme.spacing(2),
              }}
            >
              <Skeleton variant="text" width="100%" height={60} />
              <Skeleton variant="text" width="100%" height={60} />
              <Stack
                sx={{
                  marginTop: theme.spacing(2),
                }}
              >
                <Skeleton variant="text" width="100%" height={30} />
                <Skeleton variant="text" width="100%" height={30} />
                <Skeleton variant="text" width="100%" height={30} />
              </Stack>
            </Stack>
            <Skeleton variant="rectangular" width={300} height={300} />
          </Stack>

          <Divider sx={{ marginTop: theme.spacing(2) }} />
          <Stack
            direction="row"
            justifyContent="flex-start"
            marginTop={theme.spacing(2)}
          >
            <VineaButton
              variant="contained"
              color="success"
              onClick={() => {}}
              disabled={!canSave}
            >
              {'Save'}
            </VineaButton>
          </Stack>
        </Stack>
      ) : (
        <Stack direction="column" margin={theme.spacing(4)}>
          <Stack direction="row">
            <Stack
              direction="column"
              sx={{
                width: 300,
                marginTop: theme.spacing(1),
                marginRight: theme.spacing(2),
              }}
            >
              <FormControl margin="none">
                <VineaAutoComplete
                  value={currentBlockVintage?.vintageID}
                  onChange={() => {}}
                  inlineLabel={false}
                  options={vintages || []}
                  labelVariant="body1"
                  label={'Vintage'}
                  name={'vintageID'}
                  id="vintageID"
                  inputProps={{
                    name: 'vintageID',
                  }}
                  fullWidth={false}
                  disabled={true}
                />
              </FormControl>
              <FormControl
                sx={{
                  marginTop: theme.spacing(2),
                }}
              >
                <VineaAutoComplete
                  value={form?.costAllocationFactorID || 0}
                  onChange={handleOnFormChange}
                  inlineLabel={false}
                  options={costAllocationOptions || []}
                  labelVariant="body1"
                  label={'Cost Allocation Factor'}
                  name={'costAllocationFactorID'}
                  id="costAllocationFactorID"
                  inputProps={{
                    name: 'costAllocationFactorID',
                  }}
                  fullWidth={false}
                  disabled={false}
                />
              </FormControl>
              <Box
                sx={{
                  marginTop: theme.spacing(2),
                }}
              >
                <Typography>
                  <FormHelperText>
                    {
                      'Costs recorded against this Adhoc block will be allocated across the blocks listed here according to their calculated share based on the Cost Allocation Factor. This rule may be changed from one vintage to the next.'
                    }
                  </FormHelperText>
                </Typography>
              </Box>
            </Stack>
            <Box sx={{ margin: theme.spacing(2), height: 350, width: 500 }}>
              <CheckboxSelectionGrid
                gridXData={checkBoxGridData}
                handleOnSelectionModelChange={handleOnBlockSelectionChange}
                selectionModel={form?.selectedBlocks || []}
                rowUnit={'Block'}
                isLoading={false}
              />
            </Box>
          </Stack>

          <Divider sx={{ marginTop: theme.spacing(2) }} />
          <Stack
            direction="row"
            justifyContent="flex-start"
            marginTop={theme.spacing(2)}
          >
            <VineaButton
              variant="contained"
              color="success"
              onClick={handleOnSave}
              disabled={!canSave}
            >
              {'Save'}
            </VineaButton>
          </Stack>
        </Stack>
      )}
    </Paper>
  );
};
