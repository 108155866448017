import { put, takeLatest, all, select } from 'redux-saga/effects';
import {
  VineaNovaActions,
  VineaNovaSelectors,
} from 'vineanova-redux-artifacts';
import { PAGE_LOOKSUPS } from '../../constants';

function* dashboardLookups(action) {
  const contactMethod = yield select(
    VineaNovaSelectors.getlookupContactMethodEntityMeta,
  );
  const growingRegions = yield select(
    VineaNovaSelectors.getlookupGrowingRegionEntityMeta,
  );
  const subRegions = yield select(
    VineaNovaSelectors.getlookupGrowingSubRegionEntityMeta,
  );
  const businessUnit = yield select(
    VineaNovaSelectors.getlookupBusinessUnitEntityMeta,
  );
  const allIdentities = yield select(
    VineaNovaSelectors.getSearchIdentityEntityMeta,
  );
  const displayAtttribute = yield select(
    VineaNovaSelectors.getlookupDisplayAttributeEntityMeta,
  );
  console.log('VineaNova', VineaNovaSelectors, VineaNovaActions);
  try {
    const { pageType } = action;
    const isHome = pageType === PAGE_LOOKSUPS.HOMEPAGE;
    yield put(
      VineaNovaActions.api.v1.lookupDashboardPageFilters.get.request({
        queryParams: {
          pageType: isHome ? 'Homepage' : 'Main Dashboard',
        },
      }),
    );
    yield put(
      VineaNovaActions.api.v1.lookupReferenceTypeIdentityType.get.request(),
    );
    yield put(VineaNovaActions.api.v1.lookupReferenceType.get.request());
    yield put(VineaNovaActions.api.v1.lookupChartFilters.get.request());
    if (!contactMethod.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupContactMethod.get.request());
    }
    if (!growingRegions.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupGrowingRegion.get.request());
    }
    if (!subRegions.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupGrowingSubRegion.get.request());
    }
    if (!businessUnit.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupBusinessUnit.get.request());
    }
    if (!displayAtttribute.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDisplayAttribute.get.request());
    }
    if (!allIdentities.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.searchIdentity.post.request({
          postBody: {
            IdentityTypeID: 3,
          },
        }),
      );
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'dashboardlookup',
    });
  }
}

function* identityLookups() {
  const classfification = yield select(
    VineaNovaSelectors.getlookupClassificationEntityMeta,
  );
  const classfificationGroup = yield select(
    VineaNovaSelectors.getlookupClassificationGroupEntityMeta,
  );
  const classfificationGroupIdentity = yield select(
    VineaNovaSelectors.getlookupClassificationGroupIdentityTypeEntityMeta,
  );
  const referenceType = yield select(
    VineaNovaSelectors.getlookupReferenceTypeEntityMeta,
  );
  const identityType = yield select(
    VineaNovaSelectors.getlookupIdentityTypeEntityMeta,
  );
  const roleType = yield select(VineaNovaSelectors.getlookupRoleTypeEntityMeta);
  const roleTypeIdentity = yield select(
    VineaNovaSelectors.getlookupRoleTypeIdentityTypeEntityMeta,
  );
  const businessUnit = yield select(
    VineaNovaSelectors.getlookupBusinessUnitEntityMeta,
  );
  const supplyContractType = yield select(
    VineaNovaSelectors.getlookupSupplyContractTypeEntityMeta,
  );
  const referenceTypeIdentity = yield select(
    VineaNovaSelectors.getlookupReferenceTypeIdentityTypeEntityMeta,
  );
  const relationshipType = yield select(
    VineaNovaSelectors.getlookupRelationshipTypeEntityMeta,
  );
  const contactMethod = yield select(
    VineaNovaSelectors.getlookupContactMethodEntityMeta,
  );
  const gender = yield select(VineaNovaSelectors.getlookupGenderEntityMeta);
  const nationality = yield select(
    VineaNovaSelectors.getlookupNationalityEntityMeta,
  );
  const ethnicity = yield select(
    VineaNovaSelectors.getlookupEthnicityEntityMeta,
  );
  const language = yield select(VineaNovaSelectors.getlookupLanguageEntityMeta);
  const country = yield select(VineaNovaSelectors.getlookupCountryEntityMeta);
  const salutation = yield select(
    VineaNovaSelectors.getlookupSalutationEntityMeta,
  );
  const town = yield select(VineaNovaSelectors.getlookupTownEntityMeta);
  const note = yield select(VineaNovaSelectors.getlookupNoteStatusEntityMeta);
  const keyword = yield select(VineaNovaSelectors.getlookupKeywordEntityMeta);
  const inactiveReason = yield select(
    VineaNovaSelectors.getlookupInactiveReasonEntityMeta,
  );
  const documentType = yield select(
    VineaNovaSelectors.getlookupDocumentTypeEntityMeta,
  );
  const documentStatus = yield select(
    VineaNovaSelectors.getlookupDocumentStatusEntityMeta,
  );
  const vintage = yield select(VineaNovaSelectors.getlookupVintageEntityMeta);
  const siteType = yield select(VineaNovaSelectors.getlookupSiteTypeEntityMeta);
  try {
    if (!classfification.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupClassification.get.request());
    }
    if (!classfificationGroup.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupClassificationGroup.get.request(),
      );
    }
    if (!classfificationGroupIdentity.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupClassificationGroupIdentityType.get.request(),
      );
    }
    if (!referenceType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupReferenceType.get.request());
    }
    if (!identityType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupIdentityType.get.request());
    }
    if (!roleType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupRoleType.get.request());
    }
    if (!roleTypeIdentity.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupRoleTypeIdentityType.get.request(),
      );
    }
    if (!businessUnit.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupBusinessUnit.get.request());
    }
    if (!supplyContractType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupSupplyContractType.get.request());
    }
    if (!referenceTypeIdentity.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupReferenceTypeIdentityType.get.request(),
      );
    }
    if (!relationshipType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupRelationshipType.get.request());
    }
    if (!contactMethod.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupContactMethod.get.request());
    }
    if (!gender.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupGender.get.request());
    }
    if (!nationality.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupNationality.get.request());
    }
    if (!ethnicity.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupEthnicity.get.request());
    }
    if (!language.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupLanguage.get.request());
    }
    if (!country.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupCountry.get.request());
    }
    if (!salutation.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupSalutation.get.request());
    }
    if (!town.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupTown.get.request());
    }
    if (!note.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupNoteStatus.get.request());
    }
    if (!keyword.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupKeyword.get.request());
    }
    if (!inactiveReason.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupInactiveReason.get.request());
    }
    if (!documentType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDocumentType.get.request());
    }
    if (!documentStatus.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDocumentStatus.get.request());
    }
    if (!vintage.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupVintage.get.request());
    }
    if (!siteType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupSiteType.get.request());
    }
    yield put(VineaNovaActions.api.v1.lookupPayrollSystem.get.request());
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'identitylookup',
    });
  }
}

function* identityActivityLookups() {
  const activityGroup = yield select(
    VineaNovaSelectors.getlookupActivityGroupEntityMeta,
  );
  const costingUnit = yield select(
    VineaNovaSelectors.getlookupCostingUnitEntityMeta,
  );
  const duration = yield select(VineaNovaSelectors.getlookupDurationEntityMeta);
  const resourceType = yield select(
    VineaNovaSelectors.getlookupResourceTypeEntityMeta,
  );
  const resourceTypeUnit = yield select(
    VineaNovaSelectors.getlookupResourceTypeUnitEntityMeta,
  );
  const workUnit = yield select(VineaNovaSelectors.getlookupWorkUnitEntityMeta);
  try {
    if (!activityGroup.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupActivityGroup.get.request());
    }
    if (!costingUnit.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupCostingUnit.get.request());
    }
    if (!duration.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDuration.get.request());
    }
    if (!resourceType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupResourceType.get.request());
    }
    if (!resourceTypeUnit.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupResourceTypeUnit.get.request());
    }
    if (!workUnit.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupWorkUnit.get.request());
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'identityActivitylookup',
    });
  }
}

function* planLookups() {
  const planStatus = yield select(
    VineaNovaSelectors.getlookupPlanStatusEntityMeta,
  );
  const vintages = yield select(VineaNovaSelectors.getlookupVintageEntityMeta);
  const planType = yield select(VineaNovaSelectors.getlookupPlanTypeEntityMeta);
  const costingUnit = yield select(
    VineaNovaSelectors.getlookupCostingUnitEntityMeta,
  );
  const duration = yield select(VineaNovaSelectors.getlookupDurationEntityMeta);
  const resourceTypeUnit = yield select(
    VineaNovaSelectors.getlookupResourceTypeUnitEntityMeta,
  );
  const workUnit = yield select(VineaNovaSelectors.getlookupWorkUnitEntityMeta);
  try {
    if (!planStatus.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupPlanStatus.get.request());
    }
    if (!vintages.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupVintage.get.request());
    }
    if (!planType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupPlanType.get.request());
    }
    if (!costingUnit.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupCostingUnit.get.request());
    }
    if (!duration.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDuration.get.request());
    }
    if (!resourceTypeUnit.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupResourceTypeUnit.get.request());
    }
    if (!workUnit.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupWorkUnit.get.request());
    }
    yield put(
      VineaNovaActions.api.v1.searchIdentity.post.request({
        postBody: {
          IdentityTypeID: 25,
        },
      }),
    );
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'planlookup',
    });
  }
}

function* planActivitiesLookups(action) {
  const { id } = action;
  const planActivities = yield select(
    VineaNovaSelectors.getPlanActivitiesByPlanEntityMeta,
  );
  try {
    if (!planActivities.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.planActivitiesByPlan.get.request({
          queryParams: {
            planID: id,
          },
        }),
      );
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'planActivitieslookups',
    });
  }
}

function* identityDetailsLookups() {
  const contactMethod = yield select(
    VineaNovaSelectors.getlookupContactMethodEntityMeta,
  );
  const roleType = yield select(VineaNovaSelectors.getlookupRoleTypeEntityMeta);
  const roleTypeIdentity = yield select(
    VineaNovaSelectors.getlookupRoleTypeIdentityTypeEntityMeta,
  );
  try {
    if (!contactMethod.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupContactMethod.get.request());
    }
    if (!roleType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupRoleType.get.request());
    }
    if (!roleTypeIdentity.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupRoleTypeIdentityType.get.request(),
      );
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'identityDetailslookup',
    });
  }
}

function* dataConnectionLookups() {
  const dataConnectionProvider = yield select(
    VineaNovaSelectors.getlookupDataProviderEntityMeta,
  );
  const dataConnectionProviderType = yield select(
    VineaNovaSelectors.getlookupDataProviderTypeEntityMeta,
  );
  const inactiveReason = yield select(
    VineaNovaSelectors.getlookupInactiveReasonEntityMeta,
  );
  const documentType = yield select(
    VineaNovaSelectors.getlookupDocumentTypeEntityMeta,
  );
  const documentStatus = yield select(
    VineaNovaSelectors.getlookupDocumentStatusEntityMeta,
  );
  try {
    if (!dataConnectionProvider.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDataProvider.get.request());
    }
    if (!dataConnectionProviderType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDataProviderType.get.request());
    }
    if (!inactiveReason.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupInactiveReason.get.request());
    }
    if (!documentType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDocumentType.get.request());
    }
    if (!documentStatus.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDocumentStatus.get.request());
    }
  } catch (err) {
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'dataConnectionLookups',
    });
  }
}

function* jobLookups() {
  const businessUnit = yield select(
    VineaNovaSelectors.getlookupBusinessUnitEntityMeta,
  );
  const vintages = yield select(VineaNovaSelectors.getlookupVintageEntityMeta);
  const jobEventAction = yield select(
    VineaNovaSelectors.getlookupJobEventActionEntityMeta,
  );
  const jobInvoiceMethod = yield select(
    VineaNovaSelectors.getlookupJobInvoiceMethodEntityMeta,
  );
  const jobQuantityType = yield select(
    VineaNovaSelectors.getlookupJobQuantityTypeEntityMeta,
  );
  const jobStatus = yield select(
    VineaNovaSelectors.getlookupJobStatusEntityMeta,
  );
  const identityActivity = yield select(
    VineaNovaSelectors.getlookupIdentityActivityEntityMeta,
  );
  const jobType = yield select(VineaNovaSelectors.getlookupJobTypeEntityMeta);
  const jobTypeWorkRecordType = yield select(
    VineaNovaSelectors.getlookupJobTypeWorkRecordTypeEntityMeta,
  );
  const workRecordLevel = yield select(
    VineaNovaSelectors.getlookupWorkRecordLevelEntityMeta,
  );
  const payrollRecordType = yield select(
    VineaNovaSelectors.getlookupPayrollRecordTypeEntityMeta,
  );
  const jobWorkUnit = yield select(
    VineaNovaSelectors.getlookupJobWorkUnitEntityMeta,
  );
  const workRecordUnitType = yield select(
    VineaNovaSelectors.getlookupWorkRecordUnitTypeEntityMeta,
  );
  const rowSpecialAttribute = yield select(
    VineaNovaSelectors.getlookupRowSpecialAttributeEntityMeta,
  );

  const systemData = yield select(
    VineaNovaSelectors.getlookupSystemDataEntityMeta,
  );
  try {
    if (!businessUnit.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupBusinessUnit.get.request());
    }
    if (!vintages.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupVintage.get.request());
    }
    if (!identityActivity.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupIdentityActivity.get.request());
    }
    if (!jobEventAction.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupJobEventAction.get.request());
    }
    if (!jobInvoiceMethod.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupJobInvoiceMethod.get.request());
    }
    if (!jobQuantityType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupJobQuantityType.get.request());
    }
    if (!jobStatus.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupJobStatus.get.request());
    }
    if (!jobType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupJobType.get.request());
    }
    if (!jobTypeWorkRecordType.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupJobTypeWorkRecordType.get.request(),
      );
    }
    if (!workRecordLevel.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupWorkRecordLevel.get.request());
    }
    if (!payrollRecordType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupPayrollRecordType.get.request());
    }
    if (!jobWorkUnit.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupJobWorkUnit.get.request());
    }
    if (!workRecordUnitType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupWorkRecordUnitType.get.request());
    }
    if (!rowSpecialAttribute.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupRowSpecialAttribute.get.request(),
      );
    }
    if (!systemData.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupSystemData.get.request());
    }
  } catch (err) {
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'jobLookups',
    });
  }
}

function* importDataLookups() {
  const importType = yield select(
    VineaNovaSelectors.getlookupImportTypeEntityMeta,
  );
  const variety = yield select(VineaNovaSelectors.getlookupVarietyEntityMeta);
  const rowSpecialAttribute = yield select(
    VineaNovaSelectors.getlookupRowSpecialAttributeEntityMeta,
  );
  try {
    if (!importType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupImporType.get.request());
    }
    if (!variety.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupVariety.get.request());
    }
    if (!rowSpecialAttribute.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupRowSpecialAttribute.get.request(),
      );
    }
  } catch (err) {
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'importDataLookups',
    });
  }
}

function* samplingTrackingLookups() {
  const vintages = yield select(VineaNovaSelectors.getlookupVintageEntityMeta);
  const regions = yield select(
    VineaNovaSelectors.getlookupGrowingRegionEntityMeta,
  );
  const subRegions = yield select(
    VineaNovaSelectors.getlookupGrowingSubRegionEntityMeta,
  );
  const measure = yield select(VineaNovaSelectors.getlookupMeasureEntityMeta);
  const measureGroup = yield select(
    VineaNovaSelectors.getlookupMeasureGroupEntityMeta,
  );
  const measureInstance = yield select(
    VineaNovaSelectors.getlookupMeasureInstanceEntityMeta,
  );
  const keyword = yield select(VineaNovaSelectors.getlookupKeywordEntityMeta);
  const sampleRequestStatus = yield select(
    VineaNovaSelectors.getlookupSampleRequestStatusEntityMeta,
  );
  const phenologyMeasure = yield select(
    VineaNovaSelectors.getlookupPhenologyMeasureEntityMeta,
  );
  const phenologyMeasureOption = yield select(
    VineaNovaSelectors.getlookupPhenologyMeasureOptionEntityMeta,
  );
  const phenologyMeasureSet = yield select(
    VineaNovaSelectors.getlookupPhenologyMeasureSetEntityMeta,
  );
  const phenologyMeasureType = yield select(
    VineaNovaSelectors.getlookupPhenologyMeasureTypeEntityMeta,
  );
  const cropSettingInstance = yield select(
    VineaNovaSelectors.getlookupCropSettingInstanceEntityMeta,
  );
  const cropSettingOption = yield select(
    VineaNovaSelectors.getlookupCropSettingOptionEntityMeta,
  );
  const inactiveReason = yield select(
    VineaNovaSelectors.getlookupInactiveReasonEntityMeta,
  );
  const documentType = yield select(
    VineaNovaSelectors.getlookupDocumentTypeEntityMeta,
  );
  const documentStatus = yield select(
    VineaNovaSelectors.getlookupDocumentStatusEntityMeta,
  );
  try {
    if (!vintages.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupVintage.get.request());
    }
    if (!regions.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupGrowingRegion.get.request());
    }
    if (!subRegions.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupGrowingSubRegion.get.request());
    }
    if (!measure.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupMeasure.get.request());
    }
    if (!measureGroup.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupMeasureGroup.get.request());
    }
    if (!measureInstance.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupMeasureInstance.get.request());
    }
    if (!keyword.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupKeyword.get.request());
    }
    if (!sampleRequestStatus.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupSampleRequestStatus.get.request(),
      );
    }
    if (!phenologyMeasure.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupPhenologyMeasure.get.request());
    }
    if (!phenologyMeasureOption.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupPhenologyMeasureOption.get.request(),
      );
    }
    if (!phenologyMeasureSet.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupPhenologyMeasureSet.get.request(),
      );
    }
    if (!phenologyMeasureType.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupPhenologyMeasureType.get.request(),
      );
    }
    if (!cropSettingInstance.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupCropSettingInstance.get.request(),
      );
    }
    if (!cropSettingOption.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupCropSettingOption.get.request());
    }
    if (!inactiveReason.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupInactiveReason.get.request());
    }
    if (!documentType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDocumentType.get.request());
    }
    if (!documentStatus.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDocumentStatus.get.request());
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'samplingtrackinglookup',
    });
  }
}

function* blocksLookup() {
  const vintages = yield select(VineaNovaSelectors.getlookupVintageEntityMeta);

  const growingSubRegion = yield select(
    VineaNovaSelectors.getlookupGrowingSubRegionEntityMeta,
  );
  const town = yield select(VineaNovaSelectors.getlookupTownEntityMeta);
  const variety = yield select(VineaNovaSelectors.getlookupVarietyEntityMeta);
  const blockstatus = yield select(
    VineaNovaSelectors.getlookupBlockVintageStatusEntityMeta,
  );
  const trellis = yield select(VineaNovaSelectors.getlookupTrellisEntityMeta);
  const rootStock = yield select(
    VineaNovaSelectors.getlookupRootStockEntityMeta,
  );
  const clone = yield select(
    VineaNovaSelectors.getlookupVarietyCloneEntityMeta,
  );
  const rowSpecial = yield select(
    VineaNovaSelectors.getlookupRowSpecialAttributeEntityMeta,
  );
  try {
    if (!vintages.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupVintage.get.request());
    }

    if (!growingSubRegion.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupGrowingSubRegion.get.request());
    }
    if (!town.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupTown.get.request());
    }
    if (!variety.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupVariety.get.request());
    }
    if (!blockstatus.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupBlockVintageStatus.get.request());
    }
    if (!trellis.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupTrellis.get.request());
    }
    if (!rootStock.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupRootStock.get.request());
    }
    if (!clone.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupVarietyClone.get.request());
    }
    if (!rowSpecial.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupRowSpecialAttribute.get.request(),
      );
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'blockslookup',
    });
  }
}

function* consumableLookups() {
  const consumableType = yield select(
    VineaNovaSelectors.getlookupConsumableTypeEntityMeta,
  );
  const inactiveReason = yield select(
    VineaNovaSelectors.getlookupInactiveReasonEntityMeta,
  );
  const documentType = yield select(
    VineaNovaSelectors.getlookupDocumentTypeEntityMeta,
  );
  const documentStatus = yield select(
    VineaNovaSelectors.getlookupDocumentStatusEntityMeta,
  );
  try {
    if (!consumableType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupConsumableType.get.request());
    }
    if (!inactiveReason.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupInactiveReason.get.request());
    }
    if (!documentType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDocumentType.get.request());
    }
    if (!documentStatus.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDocumentStatus.get.request());
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'consumablelookup',
    });
  }
}

function* vehicleLookups() {
  const vehicleType = yield select(
    VineaNovaSelectors.getlookupVehicleTypeEntityMeta,
  );
  const vehicleMake = yield select(
    VineaNovaSelectors.getlookupVehicleMakeEntityMeta,
  );
  try {
    if (!vehicleType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupVehicleType.get.request());
    }
    if (!vehicleMake.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupVehicleMake.get.request());
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'vehicleLookup',
    });
  }
}

function* sensorsLookups() {
  const sensorStatus = yield select(
    VineaNovaSelectors.getlookupSensorStatusEntityMeta,
  );
  const sensorType = yield select(
    VineaNovaSelectors.getlookupSensorTypeEntityMeta,
  );
  const sensorTypeMeasure = yield select(
    VineaNovaSelectors.getlookupSensorTypeMeasureUnitEntityMeta,
  );
  try {
    if (!sensorStatus.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupSensorStatus.get.request());
    }
    if (!sensorType.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupSensorType.get.request());
    }
    if (!sensorTypeMeasure.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.lookupSensorTypeMeasureUnit.get.request(),
      );
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'consumablelookup',
    });
  }
}

function* blockMapsLookups(action) {
  const { id } = action;
  const vineyardBlocks = yield select(
    VineaNovaSelectors.getVineyardBlocksGeometryEntityMeta,
  );
  const vineyard = yield select(
    VineaNovaSelectors.getVineyardGeometryEntityMeta,
  );
  try {
    if (!vineyard.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.vineyardGeometry.get.request({
          queryParams: {
            VineyardID: id,
          },
        }),
      );
    }
    if (!vineyardBlocks.isLoaded) {
      yield put(
        VineaNovaActions.api.v1.vineyardBlocksGeometry.get.request({
          queryParams: {
            VineyardID: id,
          },
        }),
      );
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'blockmapslookups',
    });
  }
}

function* userLookups() {
  const businessUnit = yield select(
    VineaNovaSelectors.getlookupBusinessUnitEntityMeta,
  );
  try {
    if (!businessUnit.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupBusinessUnit.get.request());
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'userLookups',
    });
  }
}

function* activityProgressLookups() {
  const vintage = yield select(VineaNovaSelectors.getlookupVintageEntityMeta);
  const activity = yield select(VineaNovaSelectors.getlookupActivityEntityMeta);
  const growingRegion = yield select(
    VineaNovaSelectors.getlookupGrowingRegionEntityMeta,
  );
  try {
    if (!activity.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupActivity.get.request());
    }
    if (!growingRegion.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupGrowingRegion.get.request());
    }
    if (!vintage.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupVintage.get.request());
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'activityprogresslookup',
    });
  }
}

function* vineyardExplorerLookups() {
  const displayAttribute = yield select(
    VineaNovaSelectors.getlookupDisplayAttributeMeta,
  );
  const growingRegion = yield select(
    VineaNovaSelectors.getlookupGrowingRegionEntityMeta,
  );
  const growingSubRegion = yield select(
    VineaNovaSelectors.getlookupGrowingSubRegionEntityMeta,
  );

  try {
    if (!displayAttribute.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupDisplayAttribute.get.request());
    }
    if (!growingRegion.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupGrowingRegion.get.request());
    }
    if (!growingSubRegion.isLoaded) {
      yield put(VineaNovaActions.api.v1.lookupGrowingRegion.get.request());
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'displayattributelookup',
    });
  }
}

function* userSettingsLookups() {
  try {
    yield put(
      VineaNovaActions.api.v1.searchIdentity.post.request({
        postBody: {
          IdentityTypeID: 3,
        },
      }),
    );
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: 'ERROR',
      payload: err,
      name: 'activityprogresslookup',
    });
  }
}

export default function* root() {
  yield all([takeLatest(PAGE_LOOKSUPS.DASHBOARD_LOOKUPS, dashboardLookups)]);
  yield all([takeLatest(PAGE_LOOKSUPS.IDENTITY_LOOKUPS, identityLookups)]);
  yield all([
    takeLatest(PAGE_LOOKSUPS.IDENTITY_DETAILS_LOOKUPS, identityDetailsLookups),
  ]);
  yield all([
    takeLatest(
      PAGE_LOOKSUPS.IDENTITY_ACTIVITY_LOOKUPS,
      identityActivityLookups,
    ),
  ]);
  yield all([
    takeLatest(
      PAGE_LOOKSUPS.SAMPLING_TRACKING_LOOKUPS,
      samplingTrackingLookups,
    ),
  ]);
  yield all([takeLatest(PAGE_LOOKSUPS.BLOCK_LOOKUPS, blocksLookup)]);
  yield all([takeLatest(PAGE_LOOKSUPS.CONSUMABLE_LOOKUPS, consumableLookups)]);
  yield all([takeLatest(PAGE_LOOKSUPS.VEHICLE_LOOKUPS, vehicleLookups)]);
  yield all([takeLatest(PAGE_LOOKSUPS.SENSORS_LOOKUPS, sensorsLookups)]);
  yield all([takeLatest(PAGE_LOOKSUPS.BLOCK_MAPS_LOOKUPS, blockMapsLookups)]);
  yield all([takeLatest(PAGE_LOOKSUPS.USER_LOOKUPS, userLookups)]);
  yield all([
    takeLatest(PAGE_LOOKSUPS.DATA_CONNECTION_LOOKUPS, dataConnectionLookups),
  ]);
  yield all([takeLatest(PAGE_LOOKSUPS.PLANS_LOOKUPS, planLookups)]);
  yield all([
    takeLatest(PAGE_LOOKSUPS.PLAN_ACTIVITIES_LOOKUPS, planActivitiesLookups),
  ]);
  yield all([takeLatest(PAGE_LOOKSUPS.JOB_LOOKUPS, jobLookups)]);
  yield all([
    takeLatest(
      PAGE_LOOKSUPS.ACTIVITY_PROGRESS_LOOKUPS,
      activityProgressLookups,
    ),
  ]);
  yield all([
    takeLatest(
      PAGE_LOOKSUPS.VINEYARD_EXPLORER_LOOKUPS,
      vineyardExplorerLookups,
    ),
  ]);
  yield all([
    takeLatest(PAGE_LOOKSUPS.USER_SETTINGS_LOOKUPS, userSettingsLookups),
  ]);
  yield all([takeLatest(PAGE_LOOKSUPS.IMPORT_DATA_LOOKUPS, importDataLookups)]);
}
