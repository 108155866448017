export const featureFlags = {
  PERSON: 'person',
  SAMPLES: 'samples',
  TRACKING: 'tracking',
  BLOCKSMAP: 'blockmaps',
  USER_MAINTENANCE: 'user_maintenance',
  VINEYARD_LOCATION: 'vineyard_location',
  ACTIVITY: 'activity',
  PLANNING: 'plan',
  PLANBLOCKS: 'planBlocks',
  SUPPLY_CONTRACTS: 'supplyContracts',
  IMPORT_DATA: 'importData',
};
